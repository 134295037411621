<template>
  <div>
    <overlay :active.sync="savingForm" :is-full-page="false"></overlay>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="flex items-center justify-center bg-gray-100 border-4 border-dashed border-gray-300 rounded-lg w-56 h-56" @click="$refs.image.click()">
        <input ref="image" type="file" name="image" @change="upload" accept="image/*" class="hidden">
        <PlusCircleIcon class="w-36 h-36 text-gray-500" />
      </div>
      <div class="relative w-56 h-56 border border-gray-200 rounded" v-for="listingGallery in listingGalleries" :key="listingGallery.id">
        <img :src="listingGallery.fileResource.url" class="w-full h-full" />
      </div>
      
    </div>
  </div>
</template>
<script>
  import FileResource from '@/models/FileResource'
  import ListingGallery from '@/models/ListingGallery'
  import APIService from '@/repositories/APIService'
  import { PlusCircleIcon } from '@heroicons/vue/24/solid'

  export default {
    components: {
      PlusCircleIcon
    },
    props: {
      listingId: {
        type: String,
      }
    },
    data(){
      return {
        listingGallery: new ListingGallery(),
        listingGalleries: [],
        listing: {},
        fileResource: new FileResource(),
        showImageUrlForm: false,
        savingForm: false,
      }
    },
    mounted(){
      let vm = this
      vm.fetchListingGalleries()
      this.$eventBus.on('listing_gallery:created', () => {
        vm.listingGallery = new ListingGallery()
        vm.listingGallery.listingId = this.listingId
        this.fetchListingGalleries()
      })

      this.$eventBus.on('listing_gallery:destroyed', () => {
        vm.fetchListingGalleries()
      })
    },
    methods: {
      async fetchListingGalleries(){
        this.savingForm = true
        this.errors = []

        try{
          let response = await this.$repositories.listingGalleries.get({listing_id: this.listingId})
          this.listingGalleries = response.data
        }catch(error){
          this.errors = error.errors
        }finally{
          this.savingForm = false
        }
      },
      async upload(){
        this.hasError = false
        try {
          let response = await APIService.post('/account/v1/file_resources', this.getFormData(), {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          if (response.data && response.data.data && response.data.data.id) {
            let fileModel = FileResource.fromJson(response.data.data)
            this.listingGallery.fileResourceId = fileModel.id
            this.$refs.image.value = null
            this.storeListingGallery()
          }
        }catch(e){
          console.log(e)
          this.hasError = true
        }
      },
      getFormData(){
        let formData = new FormData();
        formData.append('file', this.$refs.image.files[0])
        return formData
      },
      async storeListingGallery(){
        this.savingForm = true
        this.errors = []
        this.listingGallery.listingId = this.listingId
        try{
          await this.$repositories.listingGalleries.create(this.listingGallery)
        }catch(error){
          console.log(error)
          this.errors = error.errors
        }finally{
          this.savingForm = false
        }
      },
    }
  }
</script>