<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ isEditing ? 'Edit' : 'Create' + ' Category' }}
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex justify-between items-center w-full">
        <Button color="red" @click="destroyCategory" v-if="isEditing" v-tooltip="category.productsCount > 0 ? 'Before deleting a category, you must assign products from this category to another category.' : ''">
          {{ $t('Delete') }}
        </Button>
        <Button color="blue" @click="saveCategory">
          {{ isEditing ? $t('Update') : $t('Create') }}
        </Button>
      </div>
    </template>

    <div>
      <div>
        <Label name="name" required :errors="errors">{{ $t('Category Name') }}</Label>
        <InputText v-model="category.name" name="name" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="name" />
      </div>
    </div>

  </FormModal>
</template>
<script>
  import Department from "@/models/Department";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        category: new Department(),
        errors: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.category.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let category = new Department()
          _.assign(category, preset)
          this.category = category
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.category = new Department()
        this.errors = []
      },
      saveCategory(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          this.updateCategory()
        }else{
          this.storeCategory()
        }
      },
      async storeCategory(){
        try{
          let response = await this.$repositories.departments.store(this.category)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateCategory(){
        try{
          let response = await this.$repositories.departments.update(this.category.id, this.category)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
      async destroyCategory(){
        if(this.category.productsCount > 0){
          return
        }
        try{
          await this.$repositories.departments.destroy(this.category.id)
          this.$store.dispatch('categories/fetch')
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      }
    }
  }
</script>