import BaseModel from "@/models/BaseModel"
import Currency from '@/models/Currency'

export default class Company extends BaseModel{
  constructor(json) {
    super(json)

    this.city = ''
    this.country = ''
    this.email = ''
    this.id = null
    this.name = ''
    this.phoneNumber = ''
    this.invoiceEmail = ''
    this.state = ''
    this.street = ''
    this.street2 = ''
    this.stripeId = ''
    this.stripeCardId = ''
    this.cardInfo = null
    this.zipCode = ''
    this.status = ''
    this.feePercentage = 0
    this.invoiceStartAt = null
    this.invoiceEndAt = null
    this.cancelAt = null
    this.trialsEndAt = null
    this.gracePeriod = null
    this.fullAddress = ''
    this.defaultCurrency = new Currency()

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let company = new this()
    company.city = json.city
    company.country = json.country
    company.email = json.email
    company.id = json.id
    company.name = json.name
    company.phoneNumber = json.phone_number
    company.state = json.state
    company.street = json.street
    company.street2 = json.street2
    company.stripeId = json.stripe_id
    company.stripeCardId = json.stripe_card_id
    company.cardInfo = json.card_info
    company.zipCode = json.zip_code
    company.status = json.status
    company.feePercentage = json.fee_percentage
    company.invoiceStartAt = json.invoice_start_at
    company.invoiceEndAt = json.invoice_end_at
    company.invoiceEmail = json.invoice_email
    company.cancelAt = json.cancel_at
    company.trialsEndAt = json.trials_end_at
    company.gracePeriod = json.grace_period
    company.fullAddress = json.full_address
    company.defaultCurrency = Currency.fromJson(json.default_currency)

    company.createdAt = json.created_at
    company.updatedAt = json.updated_at

    return company
  }

  toJson(){
    return {
      company: {
        name: this.name,
        street: this.street,
        street2: this.street2,
        state: this.state,
        country: this.country,
        zip_code: this.zip_code,
        phone_number: this.phoneNumber,
        card_info: this.cardInfo,
        stripe_card_id: this.stripeCardId,
        invoice_email: this.invoiceEmail,
        default_currency: this.defaultCurrency.isoCode
      }
    }
  }
}