<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <PencilAltIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Update repricing strategy for up to {{ total }} listings ?
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    This operation is irreversible and recorded in the system.
                    <Select :items="strategyOptions" v-model="strategyId" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button @click="updateMany" :disabled="canSubmit">
                {{ $t('Update strategy for '+total+' listing(s)') }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { PencilAltIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    PencilAltIcon,
  },
  data(){
    return {
      loading: false,
      opened: false,
      filters: {},
      strategyId: 0,
      total: 0,
    }
  },
  computed: {
    strategyOptions(){
      let options = []
      this.$store.getters['strategies/all'].forEach((strategy) => {
        options.push({
          code: strategy.id,
          label: strategy.name
        })
      })
      options.push({
        code: 'null',
        label: 'No Strategy'
      })
      return options
    },
    canSubmit(){
      return !this.strategyId
    }
  },
  methods: {
    open(preset = {}){
      this.filters = preset.filters
      this.total = preset.total
      this.column_name = preset.column_name
      this.opened = true
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.column_name = ''
      this.filters = {}
      this.total = 0
      this.strategyId = null
    },
    async updateMany(){
      try {
        const data = {
          column: 'strategy_id',
          column_value: this.strategyId == 'null' ? null : this.strategyId,
        }
        await this.$repositories.listings.updateMany(data, this.filters)
        this.close()
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>