import BaseModel from "@/models/BaseModel"
import FileResource from '@/models/FileResource'

export default class RequestReport extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.requester = ''
    this.userId = ''
    this.reportType = null
    this.reportName = null
    this.status = ''
    this.counter = 0
    this.fileResourceId = null
    this.fileResource = null
    this.completedAt = null
    this.scheduled = false
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let requestReport = new this()

    requestReport.id = json.id
    requestReport.counter = json.counter
    requestReport.requester = json.requester
    requestReport.userId = json.user_id
    requestReport.reportType = json.report_type
    requestReport.reportName = json.report_name
    requestReport.status = json.status
    requestReport.fileResourceId = json.file_resource_id
    requestReport.fileResource = this.initRelation(json.file_resource, FileResource, null)
    requestReport.completedAt = json.completed_at
    requestReport.scheduled = json.scheduled
    requestReport.createdAt = json.created_at
    requestReport.updatedAt = json.updated_at

    return requestReport
  }

  toJson(){
    return {
      request_report: {
        report_type: this.reportType
      }
    }
  }
}