import BaseModel from "@/models/BaseModel"
import Listing from '@/models/Listing'
import Product from '@/models/Product'
import OrderLineConfirmation from '@/models/OrderLineConfirmation'
import OrderLineCancellation from '@/models/OrderLineCancellation'
import Pickup from '@/models/Pickup'
import ShipmentPackage from '@/models/ShipmentPackage'
import Currency from '@/models/Currency'

export default class OrderLine extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.orderId = null
    this.productName = ''
    this.listingId = null
    this.listing = null
    this.orderLineId = ''
    this.productId = null
    this.product = null
    this.sku = ''
    this.barcode = ''
    this.status = ''
    this.price = 0
    this.priceTax = 0
    this.shippingCost = 0
    this.shippingTax = 0
    this.fee = 0
    this.feeTax = 0


    this.quantity = 0
    this.pickedQuantity = 0
    this.isolatedQuantity = 0
    this.confirmedQuantity = 0
    this.shippedQuantity = 0
    this.canceledQuantity = 0

    this.intentToCancelReason = ''
    this.orderLineConfirmations = null
    this.orderLineCancellations = null
    this.shipmentPackages = null
    this.subtotal = 0
    this.total = 0
    this.currency = null
    this.pickups = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let orderLine = new this()

    orderLine.id = json.id
    orderLine.orderId = json.order_id
    orderLine.productName = json.product_name
    orderLine.listingId = json.listing_id
    orderLine.listing = this.initRelation(json.listing, Listing, null)
    orderLine.orderLineId = json.order_line_id
    orderLine.productId = json.product_id
    orderLine.product = this.initRelation(json.product, Product, null)
    orderLine.sku = json.sku
    orderLine.barcode = json.barcode
    orderLine.status = json.status
    orderLine.price = json.price
    orderLine.priceTax = json.price_tax
    orderLine.shippingCost = json.shipping_cost
    orderLine.shippingTax = json.shipping_tax
    orderLine.subtotal = json.subtotal
    orderLine.total = json.total
    orderLine.fee = json.fee
    orderLine.feeTax = json.fee_tax
    orderLine.quantity = parseInt(json.quantity)
    orderLine.shippedQuantity = parseInt(json.shipped_quantity)
    orderLine.pickedQuantity = parseInt(json.picked_quantity)
    orderLine.confirmedQuantity = parseInt(json.confirmed_quantity)
    orderLine.canceledQuantity = parseInt(json.canceled_quantity)
    orderLine.isolatedQuantity = parseInt(json.isolated_quantity)

    orderLine.intentToCancelReason = json.intent_to_cancel_reason
    
    orderLine.orderLineConfirmations = this.initRelation(json.order_line_confirmations, OrderLineConfirmation, [])
    orderLine.orderLineCancellations = this.initRelation(json.order_line_cancellations, OrderLineCancellation, [])
    orderLine.pickups = this.initRelation(json.pickups, Pickup, [])
    orderLine.shipmentPackages = this.initRelation(json.packages, ShipmentPackage, [])
    orderLine.currency = Currency.fromJson(json.currency)
    orderLine.createdAt = json.created_at
    orderLine.updatedAt = json.updated_at

    return orderLine
  }

  quantityToBeShipped(){
    return this.confirmedQuantity - this.shippedQuantity
  }

  toJson(){
    return {
      order_line: {
        product_id: this.productId
      }
    }
  }
}