import BaseModel from "@/models/BaseModel"
import User from '@/models/User'
import Listing from '@/models/Listing'
/*
json.assigned_to review.assigned_to
json.complete_details review.complete_details
json.completed_by review.completed_by
json.initiated_by review.initiated_by
json.reason review.reason
json.reviewable_id review.reviewable_id
json.reviewable_type review.reviewable_type
json.sourceable_id review.sourceable_id
json.sourceable_type review.sourceable_type

json.completed_at review.completed_at.to_i
json.created_at review.created_at.to_i
json.updated_at review.updated_at.to_i
*/
export default class Review extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.assignedTo = ''
    this.assignee = null
    this.assigneeId = null
    this.complete = false
    this.completedBy = null
    this.completeDetails = ''
    this.completer = null
    this.completerId = null
    this.initiatedBy = ''
    this.initiator = null
    this.initiatorId = null
    this.lastFollowupAt = null
    this.reason = ''
    this.listingId = null
    this.listing = null

    this.completedAt = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let review = new this()

    review.id = json.id
    review.assignedTo = json.assigned_to
    review.assigneeId = json.assignee_id
    review.completedBy = json.completed_by
    review.completeDetails = json.complete_details
    review.completerId = json.completer_id
    review.initiatedBy = json.initiated_by
    review.lastFollowupAt = json.last_followup_at
    review.reason = json.reason
    review.listingId = json.listing_id

    review.listing = this.initRelation(json.listing, Listing, null)
    review.assignee = this.initRelation(json.assignee, User, null)
    review.initiator = this.initRelation(json.initiator, User, null)
    review.completer = this.initRelation(json.completer, User, null)
    
    review.completedAt = json.completed_at
    review.createdAt = json.created_at
    review.updatedAt = json.updated_at

    return review
  }

  toJson(){
    return {
      review: {
        assignee_id: this.assigneeId,
        complete_details: this.completeDetails,
        completed_by: this.completedBy,
        initiator_id: this.initiatorId,
        reason: this.reason,
        listing_id: this.listingId,
      }
    }
  }
}