import BaseModel from '@/models/BaseModel'
import FileResource from '@/models/FileResource'

export default class ListingGallery extends BaseModel{
  constructor (json) {
    super(json)

    this.id = null
    this.listingId = null
    this.primary = false
    this.rank = null
    this.fileResourceId = null
    this.fileResource = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let listingGallery = new this()
    listingGallery.id = json.id
    listingGallery.rank = json.rank
    listingGallery.primary = json.primary

    listingGallery.listingId = json.listing_id
    listingGallery.fileResourceId = json.file_resource_id

    listingGallery.fileResource = this.initRelation(json.file_resource, FileResource, null)

    listingGallery.createdAt = json.created_at
    listingGallery.updatedAt = json.updated_at
    return listingGallery
  }

  toJson(){
    return {
      listing_gallery: {
        listing_id: this.listingId,
        file_resource_id: this.fileResourceId
      }
    }
  }
}