import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'scannings'
const version = 'v1'
import Shelf from "@/models/Shelf"
import InventoryMovement from "@/models/InventoryMovement"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  
  async shelf(payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/shelf`, Shelf, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the shelves!'
      })
      throw error
    }
  },

  async goingIn(payload, params = {}){
    try{
      let response = await APIService.postEntity(`${version}/${resource}/going-in`, payload , params)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory added with success!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()
      eventBus.emit('app:alert', {
        type: 'error',
        title: 'There is something fishy!',
        message: error.getMessage()
      })
      throw error
    }
  },

  async goingOut(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}/going-out`, payload)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory was took out with success!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        
      }
      new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()

      eventBus.emit('app:alert', {
        type: 'error',
        title: 'There is something fishy!',
        message: error.getMessage()
      })
      throw error
    }
  },

  async rescan(payload){
    try{
      let response = await APIService.post(`${version}/${resource}/rescan`, payload)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory cleared with success!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        
      }
      new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()

      // eventBus.emit('app:alert', {
      //   type: 'error',
      //   title: 'There is something fishy!',
      //   message: error.getMessage()
      // })
      throw error
    }
  },

  // rescan(payload){
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.post(`${version}/${resource}/rescan`, payload)
  //       .then((response) => {
  //         eventBus.emit('app:message', {
  //           type: 'success',
  //           message: response.data.message,
  //         })
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()
  //         if(error.response.status == 404){
  //           eventBus.emit('app:message', {
  //             type: 'error',
  //             message: error.response.data.message
  //           })
  //         }
  //         if(!Vue.prototype.$api.isCancel(error)){
  //           eventBus.emit('app:message', {
  //             type: 'error',
  //             message: error.response.data.message
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },

  
}
