
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'Page',
  props: {
    page: {
      type: Number,
      default: null
    },
    current: {
      type: Number,
      default: 0
    },
  },
  emits: ['update'],
  setup(props, { emit }){
    const isActive = computed(() => {
      return props.page == props.current
    })
    function clickHandler(){
      emit('update', props.page)
    }

    return {
      isActive,
      clickHandler
    }
  }
})
