export default {
  add(state, category){
    let found = state.categories.find((oldCategory) => oldCategory.id == category.id)
    if(found){
      found = category
    }else{
      state.categories.push(category)
    }
  },
  update(state, category){
    let found = state.categories.find((oldCategory) => oldCategory.id == category.id)
    if(found){
      let index = state.categories.indexOf(found)
      state.categories[index] = category
    }
  },
  reset(state){
    state.categories = []
  }
}