<script>
import Currency from '@/models/Currency'
import _ from 'lodash'
export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      loading: true,
      fullPage: false,
      currency: new Currency(),
      tableData: {},
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.fetchChartData()
      },
      deep: true,
    },
  },
  mounted(){
    this.fetchChartData()
  },
  computed: {
    chartData(){
      return Object.values(this.tableData)
    },
    chartOptions(){
      let vm = this
      return {
        chart: {
          width: 500,
          height: 500,
          type: 'donut',
        },
        legend: {
          position: 'right',
        },
        labels: Object.keys(this.tableData).sort(),
        tooltip:{
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: function (val) {
                    return vm.$filters.currency.format(val, vm.currency)
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return vm.$filters.currency.format(w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0), vm.currency)
                  }
                }
              },
            }
          }
        }
      }
    },
  },
  methods: {
    async fetchChartData(){
      this.loading = true
      try{
        let response = await this.$repositories.charts.inventoryCostByDepartment({...this.filters, ...{from: this.filters.date[0], to: this.filters.date[1]} })
        this.tableData = response.data
        this.currency = Currency.fromJson(response.meta.currency)
      }catch(_){

      }finally{
        this.loading = false
      }
    },
  }
}
</script>
<template>
  <div>
    <Loading v-model:active="loading" :is-full-page="fullPage"/>
    <apexchart height="400" :options="chartOptions" :series="chartData"></apexchart>
  </div>
</template>