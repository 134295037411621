const orderStatusFiltersEnum = {
  PAST_DUE: {
    filter: 'past_due',
    display: 'Past Due',
    color: 'red'
  },
  DUE_TODAY: {
    filter: 'due_today',
    display: 'Due Today',
    color: 'orange'
  },
  NEW_ORDERS: {
    filter: 'new_orders',
    display: 'New Orders',
    color: 'green'
  },
  UNFULFILLABLE: {
    filter: 'unfulfillable',
    display: 'Unfulfillable',
    color: 'red'
  },
  AWAITING_PAYMENT: {
    filter: 'pending',
    display: 'Pending',
    color: 'yellow'
  },
  PAST_ORDERS:  {
    filter: 'past_orders',
    display: 'Past Orders',
    color: 'gray'
  },
  CANCELED_ORDERS:  {
    filter: 'canceled',
    display: 'Canceled',
    color: 'red'
  },
}

export default orderStatusFiltersEnum