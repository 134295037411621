<script>
  import V1 from '@/components/Listing/Widgets/CompetitionDetails/V1'
  export default { 
    components: {
      V1,
    },
    props: {
      competitionDetails: {
        type: Object,
        default: null,
      }
    },
    mounted(){
      
    }
  }
</script>
<template>
  <VMenu :distance="6" placement="auto">
    <div class="text-center">
      <slot />
    </div>
    <template #popper>
      <div class="bg-white shadow rounded-md py-2 px-2">
        <h3 class="leading-8 text-lg text-left font-bold text-blue-700">Competition Details</h3>
        <component :is="'V'+competitionDetails.version" :competition-details="competitionDetails" v-if="competitionDetails"></component>
      </div>
    </template>
  </VMenu>
</template>