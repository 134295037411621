<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ isEditing ? 'Edit' : 'Create' + ' Product' }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="saveProduct">
        {{ isEditing ? $t('Update') : $t('Create') }}
      </Button>
    </template>
    
    <div class="grid grid-cols-2 gap-3">
      <div>
        <Label name="name" required :errors="errors">{{ $t('Product Name') }}</Label>
        <InputText v-model="product.name" name="name" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="name" />
      </div>
      <div>
        <Label name="sku" required :errors="errors">{{ $t('SKU') }}</Label>
        <InputText v-model="product.sku" name="sku" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="sku" />
      </div>
      <div class="col-span-2">
        <Label name="size" :errors="errors" required>
          {{ $t('Size') }}
        </Label>
        <InputText v-model="product.size" name="size" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="size" />
      </div>
      <div>
        <Label name="color" required :errors="errors">{{ $t('Color') }}</Label>
        <InputText v-model="product.color" name="color" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="color" />
      </div>
      <div>
        <Label name="barcode" :errors="errors" required hint="System will automatically detect what kind of barcode you entered.">
          {{ $t('Barcode') }}
        </Label>
        <InputText v-model="product.barcode" name="barcode" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="barcode" />
      </div>
      <div>
        <Label name="brand" :errors="errors" required>{{ $t('Brand') }}</Label>
        <Select :items="brandOptions" v-model="product.brandId" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
        <FormValidation :errors="errors" name="brand" />
      </div>
      <div>
        <Label name="department" :errors="errors" required>{{ $t('Category') }}</Label>
        <Select :items="departmentOptions" v-model="product.categoryId" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
        <FormValidation :errors="errors" name="department" />
      </div>
      <div>
        <Label name="lenghtValue" :errors="errors">{{ $t('Package Dimensions') }}</Label>
        <div class="flex -space-x-px">
          <div class="w-1/2 flex-1 min-w-0 mt-1 relative rounded-none rounded-t-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                L
              </span>
            </div>
            <InputText v-model="product.lengthValue" name="lenghtValue" :errors="errors" class="rounded-l-md w-full pl-7 pr-7" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                in
              </span>
            </div>
          </div>

          <div class="flex-1 min-w-0 mt-1 relative rounded-none rounded-t-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                W
              </span>
            </div>
            <InputText v-model="product.lengthValue" name="lenghtValue" :errors="errors" class="w-full pl-7 pr-7" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                in
              </span>
            </div>
          </div>

          <div class="flex-1 min-w-0 mt-1 relative rounded-none rounded-t-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                H
              </span>
            </div>
            <InputText v-model="product.lengthValue" name="lenghtValue" :errors="errors" class="rounded-r-md w-full pl-7 pr-7" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                in
              </span>
            </div>
          </div>

        </div>
      </div>

      <div>
        <Label name="weight_major_value" :errors="errors">{{ $t('Package Weight') }}</Label>
        <div class="flex -space-x-px">
          <div class="w-1/2 flex-1 min-w-0 mt-1 relative rounded-none rounded-t-md shadow-sm">
            <InputText v-model="product.weightMajorValue" name="weight_major_value" :errors="errors" class="rounded-l-md w-full pr-7" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                lb
              </span>
            </div>
          </div>

          <div class="flex-1 min-w-0 mt-1 relative rounded-none rounded-t-md shadow-sm">
            <InputText v-model="product.weightMinorValue" name="weight_minor_value" :errors="errors" class="rounded-r-md w-full pr-7" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">
                oz
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  
  </FormModal>
</template>
<script>
  import Product from "@/models/Product";
  import _ from 'lodash'
  //import ProductImageWidget from './ProductImageWidget.vue';

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        product: new Product(),
        errors: [],
        brands: [],
        departments: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.product.id != null
      },
      brandOptions(){
        let options = []
        this.brands.forEach((brand) => {
          options.push({
            label: brand.name,
            code: brand.id,
          })
        })
        return options
      },
      departmentOptions(){
        let options = []
        this.departments.forEach((department) => {
          options.push({
            label: department.name,
            code: department.id,
          })
        })
        return options
      },
      isParent(){
        return this.product.parentageType == 'parent'
      },
      isChild(){
        return this.product.parentageType == 'child'
      },
      isStandard(){
        return this.product.parentageType == 'standard'
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let product = new Product()
          _.assign(product, JSON.parse(JSON.stringify(preset)))
          this.product = product
          this.fetchBrands()
          this.fetchDepartments()
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.product = new Product()
        this.errors = []
        this.departments = []
        this.brands = []
      },
      saveProduct(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          this.updateProduct()
        }else{
          this.storeProduct()
        }
      },
      async storeProduct(){
        try{
          let response = await this.$repositories.products.store(this.product)
          this.close()
        }catch(error) {
          this.errors = error.errors
          this.savingForm = false
        }
        this.savingForm = false
      },
      async updateProduct(){
        try{
          let response = await this.$repositories.products.update(this.product.id, this.product)
          this.close()
        }catch(error) {
          this.errors = error.errors
          this.savingForm = false
        }
        this.savingForm = false
      },
      async fetchBrands(){
        try{
          let response = await this.$repositories.brands.get({per: 10000})
          this.brands = response.data
        }catch(error){
          console.log(error)
        }
      },
      async fetchDepartments(){
        try{
          let response = await this.$repositories.departments.get({per: 10000})
          this.departments = response.data
        }catch(error){
          console.log(error)
        }
      },

      uploaded(fileResource){
        this.product.fileResource = fileResource
        this.product.fileResourceId = fileResource.id
      }
    }
  }
</script>
