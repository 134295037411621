<script>
import _ from 'lodash'
import InventoryMovement from '@/models/InventoryMovement'
import { PencilIcon, PencilSlashIcon } from '@snsicons/icons/solid'

export default{
  components: {
    PencilIcon,
    PencilSlashIcon
  },
  props: {
    movement: { 
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      inventoryMovement: new InventoryMovement(),
      edit: false,
    }
  },
  beforeMount(){
    this.inventoryMovement = this.movement
  },
  methods: {
    async updateInventoryMovement(){
      try{
        let response = await this.$repositories.inventoryMovements.update(this.inventoryMovement.id, this.inventoryMovement)
        this.inventoryMovement = response.data
      } catch (error) {
        //this.inventoryMovement.quantity = oldQuantity
      }finally{
        this.edit = false
      }
    },
    debounceQuantity: _.debounce(function (event) {
      this.updateInventoryMovement(event.target.value)
    }, 800),
    enableEdit(){
      this.edit = true
    },
    disableEdit(){
      this.edit = false
    }
  }
}

</script>
<template>
  <li class="flex py-4" >
    <div class="w-full flex items-center justify-between">
      <div class="flex space-x-4">
        <div class="rounded-lg bg-gray-100 h-10 w-10 text-gray-800 font-semibold text-sm flex items-center justify-center">
          {{ index }}
        </div>
        <div>
          <h3 class="sm:text-base md:text-lg font-semibold">
            {{ inventoryMovement.inventory.product.name }} ({{ inventoryMovement.inventory.product.size }})
          </h3>
          <div class="flex items-center space-x-1 md:space-x-6">
            <div class="text-sm font-semibold">
              Barcode <span class="text-blue-500">{{ inventoryMovement.inventory.product.barcode }}</span>
            </div>
            <div class="text-sm font-semibold">
              SKU <span class="text-blue-500">{{ inventoryMovement.inventory.product.sku }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <p class="text-gray-500 font-semibold hidden md:block">Quantity</p>
        <div class="flex items-center space-x-2" v-if="inventoryMovement.scanningMethod == 'going_in'">
          <input type="text" data-quantity-input="true" class="rounded-lg bg-white h-12 w-14 flex items-center text-sm text-center text-gray-800 font-semibold" v-model="inventoryMovement.quantity" v-if="edit"/>
          <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm text-center justify-center text-gray-800 font-semibold" v-else>{{ inventoryMovement.quantity}}</div>
          <PencilIcon class="h-5 w-5 text-blue-500 cursor-pointer" @click="enableEdit" v-if="edit == false"/>
          <Button color="green" @click="updateInventoryMovement" v-else>
            Save
          </Button>
        </div>
        <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm text-center justify-center text-gray-800 font-semibold" v-else>{{ inventoryMovement.quantity}}</div>
      </div>
    </div>
  </li>
</template>