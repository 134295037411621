<script>
import { CircleXIcon, PencilIcon } from '@snsicons/icons/solid'
import Listing from '@/models/Listing'
export default{
  components: {
    PencilIcon
  },
  props: {
    listing: {
      type: Object,
      default: new Listing()
    },
    column: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      localValue: new Listing(),
      isEditing: false,
    }
  },
  mounted(){
    this.localValue = this.listing
  },
  methods: {
    async updateListing(){
      try{
        this.localValue.skipValidateFieldsSchema = true
        await this.$repositories.listings.update(this.localValue.id, this.localValue)
      }catch(error) {
        console.log(error)
      }finally{
        this.isEditing = false
      }
    },
  }
}
</script>
<template>
  <div class="flex items-center">
    <div class="mt-1 relative rounded-md shadow-sm" v-if="isEditing">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span class="text-gray-500 sm:text-sm">
          {{ listing.currency.symbol }}
        </span>
      </div>
      <InputText v-model="localValue[column]" @keyup.enter="updateListing"  name="refill_amount" class="pl-7" :errors="errors"/>
      <div class="absolute inset-y-0 right-0 flex items-center">
        <CircleXIcon class="h-5 w-5 text-gray-400" aria-hidden="true" @click="isEditing = false"/>
      </div>
    </div>
    <div :class="['flex space-x-1 items-center text-sm underline decoration-dotted decoration-current decoration-1 cursor-pointer', localValue[column] == 0 && column == 'minPrice' ? 'font-bold text-red-500 hover:text-red-700' : 'text-gray-500 hover:text-gray-700']" @click="isEditing = true" v-else>
      {{ localValue[column] > 0 ? $filters.currency.format(localValue[column], listing.currency) : $t('Not Set') }}
      <PencilIcon class="w-3 h-3" v-if="localValue[column] > 0"/>
    </div>
  </div>
</template>