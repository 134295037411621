<script>
import Currency from '@/models/Currency'
import _ from 'lodash'
export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      loading: true,
      currency: new Currency(),
      tableData: {},
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.fetchChartData()
      },
      deep: true,
    },
  },
  mounted(){
    this.fetchChartData()
  },
  methods: {
    chartData(totals){
      return Object.values(totals)
    },
    chartOptions(totals){
      return {
        chart: {
          width: 300,
          type: 'donut',
        },
        colors: ['#3b82f6', '#eab308', '#14b8a6', '#22c55e', '#ef4444', '#ef4444', '#6b7280', '#f97316', '#78716c'],
        legend: {
          position: 'bottom',
        },
        labels: Object.keys(totals),
        tooltip:{
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0)
                  }
                }
              },
            }
          }
        }
      }
    },
    async fetchChartData(){
      this.loading = true
      try{
        let response = await this.$repositories.charts.listingsCountBySalesChannelStatus(this.filters)
        this.tableData = response.data
      }catch(_){

      }finally{
        this.loading = false
      }
    },
  }
}
</script>
<template>
  <div class="mt-4 grid grid-cols-4 gap-4">
    <Loading v-model:active="loading" :is-full-page="false"/>
    <ChartCard v-for="(totals, salesChannelName) in tableData" :key="salesChannelName">
      <template v-slot:title>
        {{ $t('Listings count by sales channel '+salesChannelName) }}
      </template>
      <apexchart height="400" :options="chartOptions(totals)" :series="chartData(totals)"></apexchart>
    </ChartCard>
  </div>
</template>