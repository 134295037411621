<script>
  import Shelf from '@/models/Shelf'
  import Pull from '@/models/Pull'
  import { TriangleExclamationIcon, ShelvesIcon } from '@snsicons/icons/solid'
  import { HouseIcon } from '@snsicons/icons/regular'
  import _ from 'lodash'

  export default {
    components: {
      ShelvesIcon,
      HouseIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
        shelf: new Shelf(),
        pull: new Pull(),
        pickups: [],
        pickupHeaders: [
          {
            text: 'Product Name',
            value: 'productName',
          },
          {
            text: 'SKU',
            value: 'sku',
          },
          {
            text: 'Size',
            value: 'size',
          },
          {
            text: 'Barcode',
            value: 'barcode',
          },
          {
            text: 'Shelf',
            value: 'shelf',
          },
          {
            text: 'Quantity',
            value: 'quantity',
          },
        ]
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
      this.fetchShelf()
      this.fetchPickups()
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    computed: {
      totalToBePulled(){
        return _.sumBy(this.pickups, function(pick) { return pick.quantity })
      }
    },
    methods: {
      scan(event){
        if(event.target.dataset.quantityInput){
          return false
        }
        
        if(event.code === 'Enter'){
          this.initiatePull()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      resetPull(){
        this.pull = new Pull()
        this.quantity = 1
      },
      async fetchShelf(){
        try{
          let response = await this.$repositories.shelves.find(this.$route.params.shelfId)
          this.shelf = response.data
        }catch(e){}
        this.barcode = ''
      },
      async fetchPickups(){
        this.loading = true
        try{
          let response = await this.$repositories.pickups.get({shelf_id: this.$route.params.shelfId})
          this.pickups = response.data
        }catch(e){}
        this.loading = false
      },
      async initiatePull(pickUp = null){
        this.loading = true
        try{
          if(pickUp){
            this.pull.pickupId = pickUp.id
          }else{
            let pick = this.pickups.find(pick => pick.product.barcode == this.barcode)
            if(pick == null){
              throw 'no pickup'
            }
            this.pull.pickupId = pick.id
            this.pull.quantity = 1
          }
          this.pull.pullMethod = 'picklist'
          await this.$repositories.pulls.create(this.pull)
          this.fetchPickups()
        }catch(e){
          new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()
          this.$eventBus.emit('app:alert', {
            type: 'error',
            title: 'No pickup was found for the scanned barcode!',
            message: 'Please take a closer look at the list of pickups before scanning!'
          })
        }finally {
          this.resetPull()
          this.barcode = ''
          this.loading = false
        }
      },
      pickColor(pick){
        if(pick.dueToday){
          return 'bg-orange-100 text-orange-500'
        }else if(pick.pastDue){
          return 'bg-red-100 text-red-500'
        } else{
          return 'bg-gray-100 text-gray-800'
        }
      }
    }
  }
</script>
<template>

  <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
    <loading :active="loading" :is-full-page="true"></loading>
    <router-link :to="{name: 'scanning.home'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
      <HouseIcon class="h-5 text-blue-500" />
      <span>Start Over</span>
    </router-link>
    <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
      <span class="bg-purple-50 text-purple-500 rounded-lg inline-flex p-4 ring-4 ring-white">
        <ShelvesIcon class="h-5 w-5"/>
      </span>
      <div class="flex flex-col justify-start">
        <h3 class="font-semibold text-base">Pick orders from shelf <span class="text-blue-500">{{ shelf.name }}</span></h3>
        <p class="text-sm text-gray-500">Scan the product barcode in order to continue.<span class="font-semibold">The quantities highlighted in red are urgent and must be processed as soon as possible.</span></p>
      </div>
    </div>

    <ul role="list" class="divide-y divide-gray-200">
      <li class="flex py-4">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-4">
            <slot name="actions"></slot>
          </div>
          <div class="flex items-center space-x-4">
            <p class="text-gray-500 font-semibold">
              Qty. to be picked
            </p>
            <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm justify-center text-gray-800 font-semibold">
              {{ totalToBePulled }}
            </div>
          </div>
        </div>
      </li>
      <li class="flex py-4" v-for="(pick, index) in pickups" :key="pick.id">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-4">
            <div class="rounded-lg bg-gray-100 h-10 w-10 text-gray-800 font-semibold text-sm hidden md:flex items-center justify-center">
              {{ pickups.length-index }}
            </div>
            <div class="">
              <h3 class="sm:max-w-36 md:max-w-96 sm:text-base md:text-lg font-semibold">
                {{ $store.getters['brands/find'](pick.product.brandId).name }} {{ pick.product.name }} ({{ pick.product.size }})
              </h3>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-x-2 sm:pt-4 md:pt-0 flex items-center">
                <div class="text-sm text-gray-500 font-semibold">
                  Barcode <span class="text-blue-500">{{ pick.product.barcode }}</span>
                </div>
                <div class="text-sm text-gray-500 font-semibold">
                  SKU <span class="text-blue-500">{{ pick.product.sku }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <p class="text-gray-500 font-semibold hidden md:block">Quantity</p>
            <div :class="pickColor(pick)" class="rounded-lg  h-12 w-12 flex items-center text-sm justify-center  font-semibold">
              {{ pick.quantity }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="flex flex-col items-center" v-if="pickups.length == 0 && loading == false">
      <div class="w-1/2 items-center text-center">
        <img src="@/assets/no-pickups.jpg" class="w-full" />
        <p class="mt-2 text-xl font-semibold text-gray-900 tracking-tight">{{ $t('Congratulations!') }}</p>
        <p class="mt-2 text-md font-semibold text-gray-600">{{ $t('All pickups on this shelf have been completed.') }}</p>
        <p class="mt-2 text-md font-semibold text-gray-600">{{ $t('Before taking a well-deserved break, please check if there are any pickups to complete.') }}</p>
      </div>
    </div>
  </div>


</template>