<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <Disclosure as="header" class="bg-white shadow" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
      <div class="relative flex h-16 justify-between">
        <div class="relative z-10 flex px-2 lg:px-0">
          <div class="flex flex-shrink-0 items-center">
            <img class="block h-8 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell" />
          </div>
        </div>
        <div class="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
          <!-- <div class="w-full sm:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div> -->
        </div>
        <div class="relative z-10 flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <BarsIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
          <router-link :to="{name: 'dashboard.settings.index'}" class="text-gray-500 hover:text-gray-700" v-if="can('view', 'Settings')">
            <GearIcon class="w-5 h-5"/>
          </router-link>
          <NotificationButton />

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-4 flex-shrink-0">
            <div>
              <MenuButton class="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Open user menu</span>
                <UserAvatar class="block h-8 w-8" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <!-- <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">{{ item.name }}</a>
                </MenuItem> -->
                <MenuItem v-slot="{ active }">
                  <a @click="store.dispatch('auth/logout') && router.push({name: 'dashboard.login'})" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                    Log Out
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <nav class="hidden lg:flex lg:space-x-8 lg:py-2" aria-label="Global">
        <div v-for="item in navigation" :key="item.name">
          <Menu as="div" class="relative flex-shrink-0" v-if="item.routes">
            <div>
              <MenuButton class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                {{ item.name }}
              </MenuButton>
            </div>
            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg dividy-y divide-gray-200 shadow">
              <MenuItem v-for="link in item.routes" :key="link.name" v-slot="{ active }">
                <router-link :to="{name: link.route}" :class="[link.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900', 'rounded-md py-2 px-3 flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                  {{ link.name }}
                </router-link>
              </MenuItem>
            </MenuItems>
          </Menu>
          <router-link v-else :to="{name: item.route}" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900', 'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
            {{ item.name }}
          </router-link>
        </div>
      </nav>
    </div>

    <DisclosurePanel as="nav" class="lg:hidden" aria-label="Global">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="div"  :aria-current="item.current ? 'page' : undefined">
          <div class="flex flex-col" v-if="item.routes">
            <router-link :to="{name: link.route}" v-for="link in item.routes" :key="link.name" :class="[link.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900', 'block rounded-md py-2 px-3 text-base font-medium']">
              {{ link.name }}
            </router-link>
          </div>
          <router-link :to="{name: item.route}" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900', 'block rounded-md py-2 px-3 text-base font-medium']" v-else>
            {{ item.name }}
          </router-link>
          
        </DisclosureButton>
      </div>
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <UserAvatar class="block h-8 w-8" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ store.getters['auth/user'].fullname }}</div>
            <div class="text-sm font-medium text-gray-500">{{ store.getters['auth/user'].email }}</div>
          </div>
          <NotificationButton />
        </div>
        <div class="mt-3 space-y-1 px-2">
          <!-- <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.href" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">{{ item.name }}</DisclosureButton> -->
          <DisclosureButton @click="store.dispatch('auth/logout') && router.push({name: 'dashboard.login'})" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Log Out</DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@snsicons/icons/solid'
import { BarsIcon, XIcon,GearIcon } from '@snsicons/icons/regular'
import { onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useAbility } from '@casl/vue'
import NotificationButton from '../Notification/NotificationButton.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { can } = useAbility()

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = computed(() => [
  { 
    name: 'Home', 
    route: 'dashboard.home', 
    current: route.name == 'dashboard.home'
  },
  { 
    name: 'Products', 
    route: 'dashboard.products.index', 
    current: route.name == 'dashboard.products.index'
  },
  { 
    name: 'Inventory', 
    route: 'dashboard.inventories.index', 
    current: route.name == 'dashboard.inventories.index' 
  },
  { 
    name: 'Orders', 
    route: 'dashboard.orders.index', 
    current: route.name == 'dashboard.orders.index'
  },
  { 
    name: 'Listings', 
    route: 'dashboard.listings.index', 
    current: route.name == 'dashboard.listings.index'
  },
  {
    name: 'Import/Export',
    routes: [
      { 
        name: 'Import', 
        route: 'dashboard.imports.index', 
        current: route.name == 'dashboard.imports.index'
      },
      {
        name: 'Export',
        route: 'dashboard.reports.index',
        current: route.name == 'dashboard.reports.index'
      },
    ]
  },
  // {
  //   name: 'Purchasing',
  //   routes: [
  //     { 
  //       name: 'Suppliers', 
  //       route: 'dashboard.suppliers.index', 
  //       current: route.name == 'dashboard.suppliers.index'
  //     },
  //     {
  //       name: 'Purchase Orders',
  //       route: 'dashboard.reports.index',
  //       current: route.name == 'dashboard.purchase_orders.index'
  //     },
  //   ]
  // },
  {
    name: 'Analytics',
    route: 'dashboard.analytics.index',
    current: route.name == 'dashboard.analytics.index'
  },
  // {
  //   name: 'Analytics',
  //   routes: [
  //     {
  //       name: 'Sales',
  //       route: 'dashboard.analytics.sales',
  //       current: route.name == 'dashboard.analytics.sales'
  //     },
  //     {
  //       name: 'Inventory',
  //       route: 'dashboard.analytics.inventory',
  //       current: route.name == 'dashboard.analytics.inventory'
  //     },
  //     {
  //       name: 'Listings',
  //       route: 'dashboard.analytics.listings',
  //       current: route.name == 'dashboard.analytics.listings'
  //     },
  //     // {
  //     //   name: 'Purchasing',
  //     //   route: 'dashboard.analytics.index',
  //     //   current: route.name == 'dashboard.analytics.index'
  //     // },
  //   ]
  // },
  {
    name: 'Strategies',
    route: 'dashboard.strategies.index',
    current: route.name == 'dashboard.strategies.index'
  },
])

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
]

onMounted(() => {
  store.dispatch('notifications/syncCounter')
})
</script>