import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import RequestReport from "@/models/RequestReport"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'request_reports'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, RequestReport, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the request report.'
      })

      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('request_report:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Request report was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create request report. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

}
