import BaseModel from "@/models/BaseModel"

export default class PlatformCategory extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.title = ''
    this.schema = {}
    this.titleSchema = ''
  }

  static fromJson(json){
    let platformCategory = new this()

    platformCategory.id = json.id
    platformCategory.title = json.title
    platformCategory.schema = json.schema
    platformCategory.titleSchema = json.title_schema
    return platformCategory
  }
}