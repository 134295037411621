<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close()">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                <TriangleExclamationIcon class="h-6 w-6 text-orange-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('Are you sure you want to delete inventory from aisle {aisle}?', { aisle: aisle.name }) }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ $t('Wiping inventory from an aisle is an irreversible procedure and will lead to emptying the inventory on the shelves in that aisle.') }}
                    {{ $t('Shelves that have a quantity blocked as a result of an order will be ignored.') }}
                    <strong>{{ $t('This operation will generate inventory moves under your name.') }}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 flex sm:flex-row-reverse space-x-1">
              <Button color="orange" @click="wipe">
                Wipe Inventory
              </Button>
              <Button color="white" @click="close">
                Close
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
  import Aisle from "@/models/Aisle";
  import _ from 'lodash'
  import { TriangleExclamationIcon } from '@snsicons/icons/solid'

  export default {
    components: {
      TriangleExclamationIcon,
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        aisle: new Aisle(),
        errors: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.aisle.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let aisle = new Aisle()
          _.assign(aisle, preset)
          this.aisle = aisle
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.aisle = new Aisle()
        this.errors = []
      },
      async wipe(){
        try{
          await this.$repositories.aisles.wipe(this.aisle.id)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
    }
  }
</script>
