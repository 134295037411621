import BaseModel from './BaseModel'
import SearchCount from '@/models/SearchCount'

export default class PagedPagination extends BaseModel {
  constructor() {
    super()

    this.total = null
    this.count = null
    this.perPage = null
    this.totalPages = null
    this.currentPage = null
    this.statusCount = null
    this.authors = []
    this.errors = []
  }

  static fromJson(json){
    let entity = new this()

    entity.total = json.total
    entity.count = json.count
    entity.perPage = json.per_page
    entity.totalPages = json.total_pages
    entity.currentPage = json.current_page
    entity.searchCount = json.search_count
    entity.authors = json.authors
    entity.errors = json.errors

    return entity
  }
}