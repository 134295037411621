import BaseModel from '@/models/BaseModel'

export default class Platform extends BaseModel{
  constructor (json) {
    super(json)

    this.id = null
    this.name = ''
    this.code = ''
    this.shortDescription = ''
    this.logo = ''
    this.currency = ''
    this.countryFlag = ''
    this.domain = ''
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let platform = new this()

    platform.id = json.id
    platform.name = json.name
    platform.code = json.code
    platform.shortDescription = json.short_description
    platform.logo = json.logo
    platform.currency = json.currency
    platform.countryFlag = json.country_flag
    platform.domain = json.domain
    platform.createdAt = json.created_at
    platform.updatedAt = json.updated_at

    return platform
  }

}