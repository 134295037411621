<script>
import { CircleCheckIcon } from '@snsicons/icons/solid'
export default {
  components: {
    CircleCheckIcon
  },
  data(){
    return {
      loading: false,
      pagination: {
        total: 0,
      }
    }
  },
  mounted(){
    this.fetchOrders()
  },
  methods: {
    async fetchOrders(){
      this.loading = true
      try{
        let response = await this.$repositories.listings.get({per: 1, status: ['unpublished', 'failed'], quantity: 'with', fulfillment_network: 'MFN'})
        this.pagination = response.pagination
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <Card >
    <CardHeader>
      <h2 class="text-base font-medium text-gray-900">
        {{ $t('Fix Listings') }}
      </h2>
    </CardHeader>
    <CardBody class="flex flex-col justify-between" v-if="pagination.total > 0">

      <div class="text-center py-20 text-green-800 px-2">
        <h2 class="text-4xl leading-2 font-medium text-gray-900">
          {{ pagination.total }}
        </h2>
        <p class="text-xs">MFN Listings only with quantity</p>
        <p class="text-base font-medium text-gray-600">
          Repair these listings with quantity as soon as possible in order to increase sales.
        </p>
      </div>
      <div class="flex justify-between px-2 pt-6 space-x-2">
        <router-link :to="{name: 'dashboard.listings.review', query: { quantity: 'with', status: 'unpublished,failed', fulfillment_network: 'MFN' }}" target="_blank" class="text-center rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 px-3 py-2 text-sm leading-4 font-medium bg-orange-600 border border-transparent text-white hover:bg-orange-700 focus:ring-orange-700 w-full">
          Fix Listings
        </router-link>
      </div>

    </CardBody>
    <CardBody class="flex flex-col items-center text-center justify-center" v-else>
      <div class="flex flex-col items-center justify-center text-center py-24 space-y-5 text-green-800">
        <CircleCheckIcon class="w-20 h-20 text-green-500" />
        <p class="text-sm font-medium text-gray-600">
          Congratulations! <br/> These are not listings that require attention.
        </p>
      </div>
      
    </CardBody>
  </Card>
</template>