<script>
  import ScheduledReport from "@/models/ScheduledReport";
  import _ from 'lodash'
  import { CircleQuestionIcon, PlusIcon, MinusIcon } from '@snsicons/icons/solid'
  import moment from "moment";

  export default {
    components: {
      CircleQuestionIcon,
      PlusIcon,
      MinusIcon
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        scheduledReport: new ScheduledReport(),
        errors: [],
        timezones: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
      'scheduledReport.reportType': function(newVal){
        if(newVal){
          this.scheduledReport.name = this.reportTypeOptions.find(element => element.code == newVal).label
        }
      }
    },
    computed: {
      isEditing(){
        return this.scheduledReport.id != null
      },
      isDaily(){
        return this.scheduledReport.frequency == 'daily'
      },
      isWeekly(){
        return this.scheduledReport.frequency == 'weekly'
      },
      isMonthly(){
        return this.scheduledReport.frequency == 'monthly'
      },
      reportTypeOptions(){
        let options = []
        options.push({
          label: 'Inventory Report',
          code: 'inventory'
        })
        options.push({
          label: 'Listings Report',
          code: 'listings'
        })
        options.push({
          label: 'Products Report',
          code: 'products'
        })
        options.push({
          label: 'Sales Report',
          code: 'sales'
        })
        options.push({
          label: 'Canceled Orders Report',
          code: 'canceled-orders'
        })
        options.push({
          label: 'Listings at Maximum Price',
          code: 'listings-at-maximum'
        })
        options.push({
          label: 'Listings at Minimum Price',
          code: 'listings-at-minimum'
        })
        options.push({
          label: 'Listings with No Minimum Price',
          code: 'listings-no-minimum'
        })
        return _.sortBy(options, 'label')
      },
      timezoneOptions(){
        let options = []
        this.timezones.forEach((timezone) => {
          options.push({
            label: timezone.name,
            code: timezone.id,
          })
        })

        return options
      },
      frequencyOptions(){
        let options = []
        options.push({
          label: 'Daily',
          code: 'daily'
        })
        options.push({
          label: 'Weekly',
          code: 'weekly'
        })
        options.push({
          label: 'Monthly',
          code: 'monthly'
        })
        return options
      },
      dayOfMonthOptions(){
        let options = []
        Array.from({length: 31}, (_, i) => i + 1).forEach((day) => {
          options.push({
            label: day+""+this.getOrdinal(day),
            code: day
          })
        })
        return options
      },
      timeOfDayOptions(){
        let options = []
        Array.from({length: 24}, (_, i) => i + 1).forEach((hour) => {
          options.push({
            label: moment(`${hour}:00`, "HH:mm").format("hh:mm a"),
            code: hour
          })
        })
        return options
      },
      weekdaysOptions(){
        let options = []
        moment.weekdays().forEach((weekday) => {
          options.push({
            label: weekday,
            code: weekday.toLowerCase()
          })
        })
        return options
      }
    },
    methods: {
      open(preset = {}) {
        try{
          this.fetchTimezones()
          let scheduledReport = new ScheduledReport()
          _.assign(scheduledReport, preset)
          this.scheduledReport = scheduledReport
          this.opened = true
          if(!this.isEditing){
            this.scheduledReport.timeZone = this.$store.getters['auth/user'].timezone
          }
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.scheduledReport = new ScheduledReport()
        this.errors = []
      },
      async saveScheduledReport(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          await this.updateScheduledReport()
        }else{
          await this.storeScheduledReport()
        }
      },
      async storeScheduledReport(){
        try{
          await this.$repositories.scheduledReports.store(this.scheduledReport)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateScheduledReport(){
        try{
          await this.$repositories.scheduledReports.update(this.scheduledReport.id, this.scheduledReport)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async deleteScheduleReport(){
        try{
          await this.$repositories.scheduledReports.destroy(this.scheduledReport.id)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async fetchTimezones(){
        try{
          let response = await this.$repositories.timezones.get()
          this.timezones = response.data
        }catch(_){}
      },
      addRecipient(){
        this.scheduledReport.recipients.push("")
      },
      removeRecipient(index){
        this.scheduledReport.recipients.splice(index,1)
      },
      getOrdinal(n) {
        let ord = 'th';

        if (n % 10 == 1 && n % 100 != 11)
        {
          ord = 'st';
        }
        else if (n % 10 == 2 && n % 100 != 12)
        {
          ord = 'nd';
        }
        else if (n % 10 == 3 && n % 100 != 13)
        {
          ord = 'rd';
        }

        return ord;
      }
    }
  }
</script>
<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ isEditing ? 'Reschedule Report' : 'Schedule New Report' }}
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex items-center justify-between w-full">
        <Button color="red" @click="deleteScheduleReport()" v-if="isEditing">
          {{ $t('Remove') }}
        </Button>
        <Button color="blue" outline @click="saveScheduledReport()">
          {{ isEditing ? $t('Update') : $t('Create') }}
        </Button>
      </div>
    </template>

    <div class="rounded-md bg-blue-50 p-4" v-if="isMonthly">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-blue-800">Please keep the following in mind:</h3>
          <div class="mt-2 text-sm text-blue-700">
            <ul role="list" class="list-disc space-y-1 pl-5">
              <li>If you chose the 31st day and the month has 28, 29 or 30 days, the report will run on the last day of the month.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-3">
      <div>
        <Label name="report_type" required :errors="errors">{{ $t('Report Type') }}</Label>
        <Select :items="reportTypeOptions" v-model="scheduledReport.reportType" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
        <FormValidation :errors="errors" name="report_type" />
      </div>

      <div>
        <Label name="name" required :errors="errors">{{ $t('Report Name') }}</Label>
        <InputText v-model="scheduledReport.name" name="name" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="name" />
      </div>

      <div>
        <Label name="time_zone" required :errors="errors">{{ $t('Time zone') }}</Label>
        <Select :items="timezoneOptions" v-model="scheduledReport.timeZone" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
        <FormValidation :errors="errors" name="time_zone" />
      </div>
    </div>
    <div class="w-full">
      <div class="flex items-center space-x-1 w-auto py-2">
        <div class="flex items-center space-x-1">
          <p>Run</p>
          <Select :items="frequencyOptions" v-model="scheduledReport.frequency" labelKey="label" valueKey="code" :searchable="true" class="w-36"/>
        </div>

        <div class="flex items-center space-x-1" v-if="isMonthly">
          <p>on the</p>
          <Select :items="dayOfMonthOptions" v-model="scheduledReport.dayOfMonth" labelKey="label" valueKey="code" :searchable="true" class="w-36"/>
          <p>day at</p>
        </div>

        <div class="flex items-center space-x-1" v-if="isWeekly">
          <p>on</p>
          <Select :items="weekdaysOptions" v-model="scheduledReport.dayOfWeek" labelKey="label" valueKey="code" :searchable="true" class="w-36"/>
          <p>day</p>
        </div>

        <div class="flex items-center space-x-1">
          <p>at</p>
          <Select :items="timeOfDayOptions" v-model="scheduledReport.timeOfDay" labelKey="label" valueKey="code" :searchable="true" class="w-36"/>
        </div>

      </div>
      <FormValidation :errors="errors" name="time_of_day" />
      <FormValidation :errors="errors" name="day_of_month" />
      <FormValidation :errors="errors" name="frequency" />
    </div>
  </FormModal>
</template>