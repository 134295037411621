<template>
  <FormModal :show="opened" @close="close" size="w-400">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Change Product
      </div>
    </template>
    
    <div class="w-full flex justify-between pb-5">
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
        </div>
        <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
      </div>
    </div>
    <TTable :headers="productHeaders" :items="products" :loading="loading" :filters="filters" :allowBulk="false">
      <template v-slot:[`item.name`]="{ item }">
        <div class="flex items-center space-x-2 text-left text-blue-600 hover:text-blue-900 ml-4">
          <ProductPreviewImage :fileResourceId="item.fileResourceId" />
          <span class="w-56 truncate" v-tooltip="item.name">{{ item.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.barcode`]="{ item }">
        {{ item.barcode }}
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ item.size }}<br/>{{ item.color }}
      </template>
      <template v-slot:[`item.categoryId`]="{ item }">
        <span v-if="$store.getters['categories/find'](item.categoryId)">{{ $store.getters['categories/find'](item.categoryId).name }}</span>
        <span v-else>N/A</span>
      </template>
      <template v-slot:[`item.brandId`]="{ item }">
        <span v-if="$store.getters['brands/find'](item.brandId)">{{ $store.getters['brands/find'](item.brandId).name }}</span>
        <span v-else>N/A</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="flex items-center space-x-3">
          <Button size="sm" color="green" @click="changeProduct(item.id)">
            Change
          </Button>
        </div>
      </template>
    </TTable>
  
  </FormModal>
</template>
<script>
  import OrderLine from "@/models/OrderLine";
  import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
  import _ from 'lodash'

  export default {
    components: {
      MagnifyingGlassIcon,
    },
    data(){
      return {
        opened: false,
        loading: false,
        savingForm: false,
        orderLine: new OrderLine(),
        errors: [],
        products: [],
        filters: {
          term: ''
        },
        pagination: {
          per: 100,
        },
        productHeaders: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'SKU',
            value: 'sku',
          },
          {
            text: 'Barcode',
            value: 'barcode',
          },
          {
            text: 'Size/Color',
            value: 'size',
          },
          {
            text: 'Brand',
            value: 'brandId',
          },
          {
            text: 'Quantity',
            value: 'quantitySum',
          },
          {
            text: '',
            value: 'actions'
          }
        ],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let orderLine = new OrderLine()
          _.assign(orderLine, JSON.parse(JSON.stringify(preset)))
          this.orderLine = orderLine
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.orderLine = new OrderLine()
        this.errors = []
      },

      debounceTerm: _.debounce(function (event) {
        this.filters.term = event.target.value
        this.fetchProducts(1)
      }, 800),

      async fetchProducts(){
        this.loading = true
        this.products = []
        try {
          let response = await this.$repositories.products.get({...this.filters, ...this.pagination})
          this.products = response.data
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      },

      async changeProduct(productId){
        try{
          this.orderLine.productId = productId
          await this.$repositories.orderLines.update(this.orderLine.id, this.orderLine)
          this.close(0)
        }catch(error){
          console.log(error)
        }
      },

    }
  }
</script>
