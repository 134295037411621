import BaseModel from '@/models/BaseModel'
import Platform from '@/models/Platform'
import PerformanceMetric from '@/models/PerformanceMetric'

export default class SalesChannel extends BaseModel {
  constructor (json) {
    super(json)

    this.id = null
    this.platformId = null
    this.name = ''
    this.clientId = ''
    this.clientSecret = ''
    this.consumerId = ''
    this.strategyId = null
    this.status = ''
    this.priceSync = true
    this.inventorySync = true
    this.errorMessage = null
    this.inventoryRules = null
    this.amazon = false
    this.isAmazon = false
    this.listingCount = 0
    this.bannedBrands = []
    this.performanceMetrics = []
    this.pause = false
    this.maximumQuantityToSend = 0
    this.platform = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let salesChannel = new this()
    salesChannel.id = json.id
    salesChannel.name = json.name
    salesChannel.platformId = json.platform_id
    salesChannel.clientId = json.client_id
    salesChannel.clientSecret = json.client_secret
    salesChannel.consumerId = json.consumer_id
    salesChannel.strategyId = json.strategy_id
    salesChannel.status = json.status
    salesChannel.priceSync = json.price_sync
    salesChannel.inventorySync = json.inventory_sync
    salesChannel.inventoryRules = json.inventory_rules
    salesChannel.amazon = json.amazon
    salesChannel.isAmazon = json.amazon
    salesChannel.pause = json.pause
    salesChannel.listingCount = json.listing_count
    salesChannel.repricing = json.repricing
    salesChannel.bannedBrands = json.banned_brands
    salesChannel.maximumQuantityToSend = json.maximum_quantity_to_send
    salesChannel.createdAt = json.created_at
    salesChannel.updatedAt = json.updated_at

    salesChannel.platform = this.initRelation(json.platform, Platform, null)
    salesChannel.performanceMetrics = this.initRelation(json.performance_metrics, PerformanceMetric, [])
    return salesChannel
  }

  toJson(){
    return {
      sales_channel: {
        name: this.name,
        platform_id: this.platformId,
        client_id: this.clientId,
        client_secret: this.clientSecret,
        inventory_sync: this.inventorySync,
        inventory_rules: this.inventoryRules,
        price_sync: this.priceSync,
        pause: this.pause,
        strategy_id: this.strategyId,
        repricing: this.repricing,
        banned_brands: this.bannedBrands,
        maximum_quantity_to_send: this.maximumQuantityToSend
      }
    }
  }
}