<template>
  <FormModal :show="opened" @close="close" size="w-320">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ isEditing ? 'Edit' : 'Create' + ' Strategy' }}
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex w-full border-t py-2">
        <div class="flex-shrink space-x-2">
          <Button white outline @click="back" v-if="currentStep > 1">
            Back
          </Button>
        </div>
        <div class="flex-grow text-right">
          <button class="text-blue-700 ml-2 p-2 text-sm hover:underline" @click="close">
            Cancel
          </button>
          <Button @click="saveStrategy" class="bg-blue-700 text-white p-2 rounded text-sm font-medium focus:outline-none" v-if="currentStep == steps.length && hasName">
            {{ isEditing ? 'Update' : 'Create' }} strategy
          </Button>
          <Button blue @click="next" v-else-if="currentStep == 3 && !hasErrors">
            Review Strategy
          </Button>
          <Button blue @click="next" v-else-if="!hasErrors && hasStrategyType">
            Continue
          </Button>
        </div>
      </div>
    </template>

    <div v-if="opened">
      <Steps :steps="steps" :current-step="currentStep" />

      <div class="pt-10 flex-row content-center" v-if="currentStep == 1">
        <div class="w-1/2 py-10 text-center m-auto border-b border-gray-200 pb-10">
          <h2 class="text-2xl font-bold leading-7 text-blue-700 sm:text-3xl sm:truncate py-4">
            Select Strategy Type
          </h2>
          <p class="text-md text-gray-500">Strategies are your unique repricing rules that determine exactly how your listings will compete against other sellers on the marketplace.</p>
        </div>
        
        <StrategyTypeSelector v-model="strategy.strategyType"></StrategyTypeSelector>
      </div>

      <div class="pt-10 flex-row content-center" v-if="currentStep == 2">
        <component :is="strategy.strategyType+'-form'" v-model="strategy.rules" @hasErrors="onHasErrors"></component>
      </div>

      <div class="pt-10 flex-row content-center" v-if="currentStep == 3">
        <strategy-min-max-form v-model="strategy.minMax"></strategy-min-max-form>
      </div>

      <div class="pt-10 flex-row content-center" v-if="currentStep == 4">
        <strategy-review-form v-model="strategy"></strategy-review-form>
      </div>
    </div>
  </FormModal>
</template>
<script>
import Strategy from '@/models/Strategy'
import StrategyTypeSelector from '@/components/Strategy/StrategyTypeSelector'
import StrategyMinMaxForm from '@/components/Strategy/StrategyMinMaxForm'
import StrategyReviewForm from '@/components/Strategy/StrategyReviewForm'
import BuyBoxForm from '@/components/Strategy/Strategies/BuyBoxForm'
import _ from 'lodash'

export default {
  components: {
    StrategyTypeSelector,
    StrategyMinMaxForm,
    StrategyReviewForm,
    BuyBoxForm,
  },
  data(){
    return {
      strategy: new Strategy(),
      opened: false,
      errors: [],
      currentStep: 1,
      hasErrors: false,
      savingForm: false,
    }
  },
  mounted(){

  },
  computed: {
    isEditing(){
      return this.strategy.id != null
    },
    hasStrategyType(){
      return this.strategy.strategyType == 'buy-box'
    },
    hasName(){
      return this.strategy.name.length > 0
    },
    steps(){
      return [
        {
          title: 'Strategy Type',
        },
        {
          title: 'Configure Scenario'
        },
        {
          title: 'Min/Max Pricing'
        },
        {
          title: 'Review'
        }
      ]
    },
    
  },
  watch: {
    opened (newValue) {
      if (!newValue) {
        this.reset()
      }
    },
    strategy: {
      handler(value){
        console.log(value)
      },
      deep: true
    }
  },
  methods: {
    open(preset = {}) {
      try{
        let strategy = new Strategy()
        _.assign(strategy, preset)
        this.strategy = strategy
        this.opened = true
      }catch(e){
        console.log(e)
      }
    },
    close () {
      this.opened = false
      this.reset()
    },
    reset(){
      this.strategy = new Strategy()
      this.currentStep = 1
    },
    
    next(){
      if(this.currentStep == this.steps.length){

      }else{
        this.currentStep += 1
      }
    },

    back(){
      this.currentStep -= 1
    },
    onHasErrors(value){
      console.log(value)
      this.hasErrors = value
    },

    saveStrategy(){
      this.savingForm = true
      this.errors = []
      if(this.isEditing){
        this.updateStrategy()
      }else{
        this.storeStrategy()
      }
    },
    async storeStrategy(){
      try{
        let response = await this.$repositories.strategies.store(this.strategy)
        this.close()
      }catch(error) {
        this.errors = error.errors
      }
      this.savingForm = false
    },
    async updateStrategy(){
      try{
        let response = await this.$repositories.strategies.update(this.strategy.id, this.strategy)
        this.close()
      }catch(error) {
        this.errors = error.errors
      }
    }
  }
}
</script>