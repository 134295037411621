import { eventBus } from '@/composables/eventBus'
import APIService from '@/repositories/APIService'
import Listing from '@/models/Listing'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import Product from '@/models/Product'

const resource = 'reports'

export default {

  async sales(payload) {
    try{
      return await APIService.get(`reports/v1/sales`, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales'
      })
      throw error
    }
  },

  // async product_missing_picture(payload){
  //   try{
  //     return await APIService.fetchEntities(`${resource}/missing_image_products`, Product, payload)
  //   }catch(e){
  //     if(!error instanceof CanceledRequest){
  //       bus.$emit('app:message', {
  //         type: 'error',
  //         message: 'An error occurred when fetching the brands'
  //       })
  //     }
  //   }
  // },
  // async not_listed_products(payload) {
  //   try{
  //     return await APIService.fetchEntities(`${resource}/not_listed_products`, Listing, payload)
  //   }catch(e){
  //     if(!error instanceof CanceledRequest){
  //       bus.$emit('app:message', {
  //         type: 'error',
  //         message: 'An error occurred when fetching the brands'
  //       })
  //     }
  //   }
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.get(`reports/not_listed_products`, payload)
  //       .then((response) => {
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         if (!Vue.prototype.$api.isCancel(error)) {
  //           bus.$emit('app:message', {
  //             type: 'error',
  //             message: 'Could generate report. Try again later!'
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },
  // listings(payload) {
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.post(`reports/listings`, payload)
  //       .then((response) => {
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         if (!Vue.prototype.$api.isCancel(error)) {
  //           bus.$emit('app:message', {
  //             type: 'error',
  //             message: 'Could generate report. Try again later!'
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },
  

  // ordersRequiresAttention() {
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.get(`reports/orders/requires_attention`, {})
  //       .then((response) => {
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         if (!Vue.prototype.$api.isCancel(error)) {
  //           bus.$emit('app:message', {
  //             type: 'error',
  //             message: 'Could generate report. Try again later!'
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },
}