<script>
import Currency from '@/models/Currency'
import _ from 'lodash'
export default {
  data(){
    return {
      loading: true,
      tableData: {},
    }
  },
  computed: {
    chartData(){
      return Object.values(this.tableData)
    },
    chartOptions(){
      return {
        chart: {
          height: 300,
          width: 500,
          type: 'donut',
        },
        colors: ['#3b82f6', '#eab308', '#14b8a6', '#22c55e', '#ef4444', '#ef4444', '#6b7280', '#f97316', '#78716c'],
        legend: {
          position: 'top',
        },
        labels: Object.keys(this.tableData),
        tooltip:{
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0)
                  }
                }
              },
            }
          }
        }
      }
    },
  },
  mounted(){
    this.fetchChartData()
  },
  methods: {
    async fetchChartData(){
      try{
        let response = await this.$repositories.charts.listingsCountByStatus()
        this.tableData = response.data
      }catch(_){}
    },
  }
}
</script>
<template>
  <ChartCard>
    <template v-slot:title>
      {{ $t('Total Listings') }}
    </template>
    <template v-slot:subtitle>
      Include only MFN listings with quantity larger than 0.
    </template>
    <div class="flex items-center justify-center w-full">
      <apexchart :options="chartOptions" :series="chartData"></apexchart>
    </div>
  </ChartCard>
</template>