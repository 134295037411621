import BaseModel from "@/models/BaseModel"

export default class Brand extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.stripeId = ''
    this.stripeProductName = ''
    this.feePercentage = ''
    this.PAYG = false
    this.features = []
    
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let billingProduct = new this()

    billingProduct.id = json.id
    billingProduct.stripeId = json.stripe_id
    billingProduct.stripeProductName = json.stripe_product_name
    billingProduct.feePercentage = json.fee_percentage
    billingProduct.PAYG = json.pay_as_you_go
    billingProduct.features = json.features
    billingProduct.createdAt = json.created_at
    billingProduct.updatedAt = json.updated_at

    return billingProduct
  }
}