import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import ScheduledReport from "@/models/ScheduledReport"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'scheduled_reports'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, ScheduledReport, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the scheduled reports.'
      })

      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('scheduled_report:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Scheduled report was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create scheduled report. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('scheduled_report:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Scheduled report was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create scheduled report. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`)
      eventBus.emit('scheduled_report:deleted')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Scheduled Report was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete scheduled report. Please try again later!'
      })
    }
  }

}
