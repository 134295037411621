<script>
import Listing from '@/models/Listing'
import SalesChannel from '@/models/SalesChannel'
import Product from '@/models/Product'
import PlatformCategory from '@/models/PlatformCategory'
import ListingStatusBadge from './ListingStatusBadge.vue'
import ReviewFormModal from '../Review/ReviewFormModal.vue'
import CardHeader from '../Tailwind/CardHeader.vue'
import ListingStatusNotificationBar from './ListingStatusNotificationBar.vue'
import _ from 'lodash'
import { CircleInfoIcon, CircleXIcon, UploadIcon, CheckIcon, ChevronDownIcon, CalendarPlusIcon, TimelineArrowIcon, FloppyDiskIcon, MagnifyingGlassIcon, PlusIcon, MinusIcon, SquareParkingIcon, SquareParkingSlashIcon } from '@snsicons/icons/solid'
import ListingGalleryForm from '@/components//ListingGallery/ListingGalleryForm.vue'
import ListingRetireModal from '@/components/Listing/ListingRetireModal.vue'
import ListingUnretireModal from '@/components/Listing/ListingUnretireModal.vue'
import ListingDeleteModal from '@/components/Listing/ListingDeleteModal.vue'

export default {
  components: {
    ReviewFormModal,
    ListingStatusBadge,
    CircleInfoIcon,
    CircleXIcon,
    UploadIcon,
    CheckIcon,
    ChevronDownIcon,
    ListingStatusNotificationBar,
    CalendarPlusIcon,
    TimelineArrowIcon,
    FloppyDiskIcon,
    MagnifyingGlassIcon,
    ListingGalleryForm,
    PlusIcon,
    MinusIcon,
    ListingRetireModal,
    ListingDeleteModal,
    ListingUnretireModal,
    SquareParkingIcon,
    SquareParkingSlashIcon
  },
  props: {
    modelValue: {
      type: Object,
      default: new Listing()
    }
  },
  data(){
    return {
      listing: new Listing(),
      platformCategories: [],
      reviews: [],
      loading: false,
      schema: null,
      fields: {},
      errors: {},
      product: new Product(),
      platformCategory: '',
      loadingAi: false,
    }
  },
  beforeMount(){
    this.listing = this.modelValue
    this.fetchPlatformCategories()
    this.fetchProduct()
  },
  mounted(){
  
    if(this.isEditing){
      this.fetchReviews()
      this.setPlatformCategory()
    }else{

    }

    

    this.$eventBus.on('review:created', () => {
      location.reload()
    })

    this.$eventBus.on('review:updated', () => {
      location.reload()
    })

    this.$eventBus.on('listing:retired', () => {
      location.reload()
    })
  },
  watch: {
    'listing.platformCategoryId': function(newVal){
      this.setPlatformCategory()

    },
    'listing.modelNumber': function(){
      this.setListingName()
    },
    'listing.brand': function(){
      this.setListingName()
    },
    'listing.sku': function(){
      this.setListingName()
    },
    'listing.fields': {
      handler(){
        setTimeout(() => this.setListingName(), 200)
      },
      deep: true
    },
    'modelValue': {
      handler(newValue){
        this.listing = newValue
      },
      deep: true,
    },
  },
  computed:{
    isEditing(){
      return this.listing.id != null
    },
    productIdTypeOptions(){
      return [ 'UPC', 'EAN', 'GTIN', 'ISBN']
    },

    weightOptions(){
      return ['lb', 'oz', 'kg', 'g']
    },
    availableStatuses(){
      let options = []
      options = this.$enums.listingStatuses.filter(item => this.listing.availableStatusesForChange().includes(item.code))
      console.log(options)
      return options
    },
    skuUpdateOptions(){
      return [
        {
          code: true,
          label: "True"
        },
        {
          code: false,
          label: "false"
        }
      ]
    },
    selectedStatus(){
      console.log(this.listing)
      console.log(this.$enums.listingStatuses.find(item => item.code == this.listing.status))
      return this.$enums.listingStatuses.find(item => item.code == this.listing.status)
    },

    selectedFulfillmentNetwork(){
      return this.$enums.fulfillmentNetworks.find(item => item.code == this.listing.fulfillmentNetwork)
    },
    onGoingReviews(){
      return this.reviews.filter(item => item.completedAt == 0)
    },
    platformCategoryOptions(){
      let options = []
      this.platformCategories.forEach((pc) => {
        options.push({
          code: pc.id,
          label: pc.title
        })
      })
      return options
    },
    strategyOptions(){
      let options = []
      options.push({
        code: null,
        label: "None"
      })
      this.$store.getters['strategies/all'].forEach((strategy) => {
        options.push({
          code: strategy.id,
          label: strategy.title
        })
      })
      return options
    },
    sortFieldsByRequirement(){
      let requiredFields = {}
      let recomendedFields = {}
      Object.entries(this.platformCategory.schema.properties).forEach(([key, value]) => {
        if(this.platformCategory.schema.required.includes(key)){
          requiredFields[key] = { ...value, ...{ required: true } }
        }else{
          recomendedFields[key] = { ...value, ...{ required: false } }
        }
      })
      return {...requiredFields, ...recomendedFields}
    },
  },
  methods: {
    setPlatformCategory(){
      console.log(this.platformCategories)
      this.platformCategory = this.platformCategories.find(pc => pc.id == this.listing.platformCategoryId)
      console.log(this.platformCategory)
      if(this.platformCategory){
        this.defineListingFields()
      }
    },
    uploaded(imageId){
      this.listing.mainImageId = imageId
    },
    setListingName(){
      if(this.listing.setupByMatch || !this.platformCategory || !this.platformCategory.titleSchema){
        return
      }
      this.listing.name = ''
      let titleComponents = this.platformCategory.titleSchema.split(" ")
        
      let newTitle = ''
      titleComponents.forEach((componentSchema) => {
        let val = componentSchema.split('.').reduce(function(prev, curr) {
          return prev ? (Array.isArray(prev[curr]) ? prev[curr][0] : prev[curr]) : null
        }, this.listing)
        console.log(componentSchema+' '+val)
        if(val){
          if(Array.isArray(val)){
            
            //newTitle += val[0]+' '
          }else{
            newTitle += val+' '
          }
        }
      })
      this.listing.name = newTitle
    },

    async saveListing(skipValidateFieldsSchema = false){
      if(this.isEditing){
        await this.updateListing(skipValidateFieldsSchema)
      }else{
        await this.storeListing(skipValidateFieldsSchema)
      }
    },

    async storeListing (skipValidateFieldsSchema = false){
      try{
        this.listing.skipValidateFieldsSchema = skipValidateFieldsSchema
        let response = await this.$repositories.listings.create(this.listing)
        this.$router.push({name: 'dashboard.listing.index', params: { listingId: response.data.id}})
      }catch(error){
        this.errors = error.errors
      }finally{
      
      }
    },

    async aiOptimizer(){
      this.loadingAi = true
      try{
        let response = await this.$repositories.listings.ai({ fields: {...this.listing.fields, ...{product: this.product.name, sku: this.product.sku, brand: this.listing.brand, category:this.$store.getters['categories/find'](this.product.categoryId).name}} })
        this.listing.name = response.data.title
        this.listing.description = response.data.description
        this.listing.keyFeatures = response.data.key_features
      }catch(error){
        this.$eventBus.emit('app:alert', {
          type: 'error',
          title: 'Artificial intelligence error',
          message: "You'll call me artificial intelligence, but sometimes I make mistakes too. Please try one more time."
        })
      }finally{
        this.loadingAi = false
      }
    },

    async updateListing (skipValidateFieldsSchema = false){
      try{
        this.listing.skipValidateFieldsSchema = skipValidateFieldsSchema
        await this.$repositories.listings.update(this.listing.id, this.listing)
      }catch(error){
        this.errors = error.errors
      }finally{
        
      }
    },

    async fetchPlatformCategories() {
      try{
        const response = await this.$repositories.platformCategories.get({platform_id: this.modelValue.salesChannel.platformId})
        this.platformCategories = response.data
        this.setPlatformCategory()
      }catch(error){
        console.log(error)
      }
    },

    async fetchProduct() {
      try{
        const response = await this.$repositories.products.find(this.modelValue.productId)
        this.product = response.data
      }catch(error){
        console.log(error)
      }
    },

    async fetchReviews() {
      try{
        const response = await this.$repositories.reviews.get({listing_id: this.listing.id })
        this.reviews = response.data
      }catch(error){
        console.log(error)
      }
    },

    defineListingFields(){
      if(!this.platformCategory || this.listing.setupByMatch){
        return
      }
      Object.entries(this.platformCategory.schema.properties).forEach(([key, field]) => {
        if(field.type == 'object'){
          this.defineObjectField(key, Object.entries(field.properties))
        }else if(field.type == 'array' && field.items.type != 'object'){
          this.defineArrayField(key)
        }else if(field.type == 'array' && field.items.type == 'object'){
          this.defineArrayOfObjectsField(key, Object.entries(field.items.properties))
        }else{
          this.defineTextField(key)
        }
      })
      this.listing.fields = _.assign(this.fields, this.listing.fields)
    },

    defineTextField(key){
      this.fields[key] = ''
    },

    defineObjectField(key, schema){
      this.fields[key] = {}
      schema.forEach(([k, f]) => {
        this.fields[key][k] = ''
      })
    },

    defineArrayField(key){
      this.fields[key] = ['']
    },

    defineArrayOfObjectsField(key, schema){
      let localObject = {}
      schema.forEach(([k, f]) => {
        localObject[k] = ''
      })
      this.fields[key] = [localObject]
    },

    addNewArrayInput(name){
      this.listing.fields[name].push('')
    },

    removeArrayInput(name, index){
      this.listing.fields[name].splice(index, 1)
    }
  }
}
</script>
<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        {{ listing.name }}
      </template>
      <template v-slot:actions>
        <button v-if="!listing.salesChannel.isAmazon" @click="aiOptimizer" :disabled="loading" class="flex space-x-2 border border-transparent rounded-lg px-4 py-2 text-sm text-white bg-gradient-to-r from-[#D04943] to-[#666BE3] hover:bg-[#666BE3]">
          AI Optimizer <NewFeatureLabel />
        </button>
        <!-- <Listbox as="div" v-model="listing.fulfillmentNetwork">
          <ListboxLabel class="sr-only">
            Change Fulfillment Network
          </ListboxLabel>
          <div class="relative">
            <div :class="'inline-flex shadow-sm rounded-md divide-x divide-'+selectedFulfillmentNetwork.color+'-600'">
              <div :class="'relative z-0 inline-flex shadow-sm rounded-md divide-x divide-'+selectedFulfillmentNetwork.color+'-600'">
                <div :class="'relative inline-flex items-center bg-'+selectedFulfillmentNetwork.color+'-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white'">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  <p class="ml-2.5 text-sm font-medium"> {{ $t('Fulfillment Network:') }} {{ listing.fulfillmentNetwork }}</p>
                </div>
                <ListboxButton :disabled="isEditing && !listing.salesChannel.isAmazon" :class="'relative inline-flex items-center bg-'+selectedFulfillmentNetwork.color+'-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-'+selectedFulfillmentNetwork.color+'-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-'+selectedStatus.color+'-50 focus:ring-'+selectedStatus.color+'-500'">
                  <span class="sr-only">Change published status</span>
                  <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </ListboxButton>
              </div>
            </div>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ListboxOption :disabled="fulfillmentNetwork.disabled" as="template" v-for="fulfillmentNetwork in $enums.fulfillmentNetworks" :key="fulfillmentNetwork.name" :value="fulfillmentNetwork.code" v-slot="{ active, selected }">
                  <li :class="[active ? 'text-white bg-'+fulfillmentNetwork.color+'-500' : 'text-gray-900', 'cursor-pointer select-none relative p-4 text-sm']">
                    <div class="flex flex-col">
                      <div class="flex justify-between">
                        <p :class="selected ? 'font-semibold' : 'font-normal'">
                          {{ fulfillmentNetwork.name }}
                        </p>
                        <span v-if="selected" :class="active ? 'text-white' : 'text-'+fulfillmentNetwork.color+'-500'">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        <span v-if="fulfillmentNetwork.disabled" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          Soon
                        </span>
                      </div>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox> -->
        <Listbox as="div" v-model="listing.status" v-tooltip="'After changing the status please click Save button'">
          <ListboxLabel class="sr-only">
            Change published status
          </ListboxLabel>
          <div class="relative">
            <div :class="'inline-flex shadow-sm rounded-md divide-x divide-'+selectedStatus.color">
              <div :class="'relative z-0 inline-flex shadow-sm rounded-md divide-x divide-'+selectedStatus.color">
                <div :class="'relative inline-flex items-center bg-'+selectedStatus.color+' py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white'">
                  <p class="ml-2.5 text-sm font-medium"> {{ $t('Status') }}: {{ selectedStatus.name }}</p>
                </div>
                <ListboxButton :disabled="(listing.locked && isEditing) || listing.isParked()" :class="'relative inline-flex items-center bg-'+selectedStatus.color+' p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-'+selectedStatus.color+' focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-'+selectedStatus.color+' focus:ring-'+selectedStatus.color">
                  <span class="sr-only">Change published status</span>
                  <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </ListboxButton>
              </div>
            </div>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0" v-if="!listing.locked && isEditing">
              <ListboxOptions class="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ListboxOption as="template" v-for="status in availableStatuses" :key="status.name" :value="status.code" v-slot="{ active, selected }">
                  <li :class="[active ? 'text-white bg-'+status.color : 'text-gray-900', 'cursor-pointer select-none relative p-4 text-sm']">
                    <div class="flex flex-col">
                      <div class="flex justify-between">
                        <p :class="selected ? 'font-semibold' : 'font-normal'">
                          {{ status.name }}
                        </p>
                        <span v-if="selected" :class="active ? 'text-white' : 'text-'+status.color">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </div>
                      <p :class="[active ? 'text-'+status.color+'-200' : 'text-gray-500', 'mt-2']">
                        {{ status.description }}
                      </p>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
    
        <Button color="blue" @click="$refs.retireModal.open({filters: {id: [listing.id]}, total: 1})" :loading="loading" :disabled="disabled" class="flex items-center space-x-1" v-if="!listing.isParked()">
          <SquareParkingIcon class="w-5 h-5" />
          <span>
            Park
          </span>
        </Button>
        <Button color="blue" @click="$refs.unretireModal.open({filters: {id: [listing.id]}, total: 1})" :loading="loading" :disabled="disabled" class="flex items-center space-x-1" v-if="listing.isParked()">
          <SquareParkingSlashIcon class="w-5 h-5" />
          <span>
            Unpark
          </span>
        </Button>
        <Button color="deleted" @click="$refs.deleteModal.open({filters: {id: [listing.id]}, total: 1})" :loading="loading" :disabled="disabled" class="flex items-center space-x-1" v-if="listing.status != 'in_progress' && listing.status != 'deleting' && listing.status != 'deleted' && !listing.isParked()">
          <span>
            Delete
          </span>
        </Button>
        <Button color="white" @click="saveListing" :loading="loading" :disabled="disabled" class="flex items-center space-x-1">
          <FloppyDiskIcon class="w-4 h-4 text-green-600" />
          <span>
            Save
          </span>
        </Button>
      </template>
      <template v-slot:details>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <CalendarPlusIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" v-tooltip="'Created at'"/>
          {{ $filters.date.dateTimeFormat(listing.createdAt) }}
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <TimelineArrowIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" v-tooltip="'Last modification'"/>
          {{ $filters.date.dateTimeFormat(listing.updatedAt) }}
        </div>
        <div class="flex items-center" v-if="listing.salesChannel">
          <img :src="listing.salesChannel.platform.logo" class="w-9" v-tooltip="listing.salesChannel.platform.name">
          {{ listing.salesChannel.name }}
        </div>
        <ListingSuppressedBadge v-if="(listing.status == 'failed' || listing.status == 'unpublished') && listing.suppressed"/>
        <div class="flex items-center justify-center" v-if="onGoingReviews.length > 0">
          <VTooltip>
            <Badge color="yellow">
              {{ $t('Under Review') }}
            </Badge>
            <template #popper>
              {{ $t("This listing has {count} requests for review. Please access the 'Reviews' tab to complete them.", { count: onGoingReviews.length}) }}
            </template>
          </VTooltip>
        </div>
      </template>
    </PageHeader>


    <ListingStatusNotificationBar :status="listing.status" />
    
    <div class="bg-deleted p-4" v-if="listing.status == 'deleted' || listing.status == 'deleting'">
      <div class="flex">
        <div class="flex-shrink-0">
          <CircleInfoIcon class="h-5 w-5 text-deleted" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <div class="text-sm text-deleted">
            Was deleted by <span class="font-semibold text-deleted">{{ listing.deletedBy }}</span> at <span class="font-semibold text-deleted">{{ $filters.date.dateTimeFormat(listing.deletedAt) }}</span> with reason <span class="font-semibold text-deleted">{{ listing.deleteReason }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-blue-600 p-4" v-if="listing.isParked()">
      <div class="flex">
        <div class="flex-shrink-0">
          <SquareParkingIcon class="h-5 w-5 text-white" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <div class="text-sm text-white">
            Was parked by <span class="font-semibold text-white">{{ listing.retireAuthor }}</span> at <span class="font-semibold text-white">{{ $filters.date.dateTimeFormat(listing.retiredAt) }}</span> with reason <span class="font-semibold text-white">{{ listing.retireReason }}</span>
          </div>
        </div>
      </div>
    </div>
    

    <div class="py-8 xl:py-10 bg-gray-50 h-screen">
      <Loading v-model:active="loading" :is-full-page="true"/>
      <Loading v-model:active="loadingAi" :is-full-page="true" :loader="'bars'" color="#666BE3">

      </Loading>
      <div class="w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="mt-3">
          
          
          <div class="rounded-md bg-red-50 p-4 my-2" v-if="listing.cost > 0 && (listing.price <= listing.cost)">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/exclamation -->
                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">Profit Loss!</h3>
                <div class="mt-2 text-sm text-red-700">
                  <p>Please review the sale price as it is below the purchase cost.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="rounded-md bg-red-50 p-4 my-2" v-if="listing.status == 'failed'">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/x-circle -->
                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ $t('The listing failed because there are {count} errors', { count: listing.errors.length }) }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc pl-5 space-y-1">
                    <li v-for="error in listing.errors" :key="error">{{ error }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div class="rounded-md bg-orange-50 p-4 my-2" v-if="listing.status == 'unpublished'">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/x-circle -->
                <svg class="h-5 w-5 text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-orange-800">{{ $t('The listing has been unpublished for the following reasons:') }}</h3>
                <div class="mt-2 text-sm text-orange-700">
                  <ul role="list" class="list-disc pl-5 space-y-1">
                    <li v-for="error in listing.errors" :key="error">{{ error }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div class="flex items-center space-x-2 w-full">
              <div>
                <ImageUpload :fileResourceId="listing.mainImageId" class="md:w-56 md:h-56" @uploaded="uploaded" />
                <FormValidation :errors="errors" name="main_image" />
              </div>
              <Card class="bg-white w-full">
                <CardBody class="px-4 py-4 space-y-2">
                  <div>
                    <Label name="name" required :errors="errors">{{ $t('Listing Name') }} </Label>
                    <InputText v-model="listing.name" name="name" class="w-full" :errors="errors" :disabled="listing.locked" />
                    <FormValidation :errors="errors" name="name" />
                  </div>
                  <div class="grid gap-4 sm:grid-cols-6 sm:gap-6">
                    <div class="sm:col-span-6 md:col-span-3">
                      <Label name="sku" required :errors="errors">{{ $t('SKU') }}</Label>
                      <InputText v-model="listing.sku" name="sku" :errors="errors" class="w-full" />
                      <FormValidation :errors="errors" name="sku" />
                    </div>
                    
                    <div class="sm:col-span-4 md:col-span-3">
                      <div>
                        <Label name="product_id_value" required :errors="errors">{{ $t('Identifier') }}</Label>
                        <div class="relative rounded-md shadow-sm">
                          <div class="absolute inset-y-0 left-0 flex items-center">
                            <select :disabled="listing.locked" id="country" name="country" v-model="listing.productIdType" class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                              <option v-for="productIdType in productIdTypeOptions" :key="productIdType">
                                {{ productIdType }}
                              </option>
                            </select>
                          </div>
                          <InputText v-model="listing.productIdValue" name="product_id_value" :errors="errors" class="block w-full pl-16" />
                          <FormValidation :errors="errors" name="product_id_value" />
                          <FormValidation :errors="errors" name="product_id_type" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid gap-4 sm:grid-cols-6 sm:gap-6">
                    <div class="col-span-1">
                      <Label name="model_number" :errors="errors">{{ $t('SKU Update?') }}</Label>
                      <Switch
                        v-model="listing.skuUpdate"
                        :class="listing.skuUpdate ? 'bg-green-700' : 'bg-gray-500'"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
                      >
                        <span
                          aria-hidden="true"
                          :class="listing.skuUpdate ? 'translate-x-5' : 'translate-x-0'"
                          class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        />
                      </Switch>
                      <FormValidation :errors="errors" name="model_number" />
                    </div>
                    <div class="col-span-1">
                      <Label name="model_number" :errors="errors">{{ $t('Product Id Update?') }}</Label>
                      <Switch
                        v-model="listing.productIdUpdate"
                        :class="listing.productIdUpdate ? 'bg-green-700' : 'bg-gray-500'"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
                      >
                        <span
                          aria-hidden="true"
                          :class="listing.productIdUpdate ? 'translate-x-5' : 'translate-x-0'"
                          class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        />
                      </Switch>
                      <FormValidation :errors="errors" name="model_number" />
                    </div>
                    <div class="sm:col-span-6 md:col-span-2">
                      <Label name="listing_id" :errors="errors">{{ $t('Listing ID') }}</Label>
                      <InputText v-model="listing.listingId" name="listing_id" class="w-full" :errors="errors" :disabled="listing.locked" />
                      <FormValidation :errors="errors" name="listing_id" />
                    </div>
                    <div class="sm:col-span-6 md:col-span-2">
                      <Label name="platform_category_id" required :errors="errors">{{ $t('Category') }}</Label>
                      <Select :items="platformCategoryOptions" v-model="listing.platformCategoryId" labelKey="label" valueKey="code" :searchable="true" class="w-full" :disabled="listing.locked"/>
                      <FormValidation :errors="errors" name="platform_category_id" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <Card class="bg-white" v-if="product.id != null">
              <CardBody class="px-4 py-4 space-y-2">
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Product Inventory Source
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500" v-if="isEditing && listing.salesChannel">
                    {{ $t("Inventory of this product will be sent to the {marketplace}.", { marketplace: listing.salesChannel.platform.name }) }}
                    {{ $t('Click') }} <router-link :to="{name: 'dashboard.listing.inventory_source', params: { listingId: listing.id }}" class="text-blue-500 hover:text-blue-700">{{ $t('here') }}</router-link> {{ $t('to change it.') }}
                  </p>
                </div>
                <div class="border-t border-gray-200 py-5">
                  <dl class="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
                    <div class="sm:col-span-1">
                      <dt class="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ product.name }}
                      </dd>
                    </div>
                    <div class="sm:col-span-1" v-if="$store.getters['brands/find'](product.brandId)">
                      <dt class="text-sm font-medium text-gray-500">
                        Brand
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ $store.getters['brands/find'](product.brandId).name }}
                      </dd>
                    </div>
                    <div class="sm:col-span-1">
                      <dt class="text-sm font-medium text-gray-500">
                        SKU
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ product.sku }}
                      </dd>
                    </div>
                    <div class="sm:col-span-1">
                      <dt class="text-sm font-medium text-gray-500">
                        Barcode
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ product.barcode }}
                      </dd>
                    </div>
                    <div class="sm:col-span-1">
                      <dt class="text-sm font-medium text-gray-500">
                        Size
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ product.size }}
                      </dd>
                    </div>
                    <div class="sm:col-span-1">
                      <dt class="text-sm font-medium text-gray-500">
                        Cost
                      </dt>
                      <dd class="text-sm text-gray-900">
                        {{ $filters.currency.format(product.cost, product.currency) }}
                      </dd>
                    </div>
                  </dl>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <div class="my-4 pb-8" >
          <div class="rounded-md bg-red-50 p-4" v-if="hasPlatformErrors">
            <div class="flex">
              <div class="flex-shrink-0">
                <CircleXIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-red-700">
                  {{ $t('Some errors were found in tab {tab}. Please fix them so you can continue listing the product.', { tab: listing.salesChannel.platform.name+' Content'}) }}
                </p>
              </div>
            </div>
          </div>
          
          <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col">
              <TabGroup manual>
                <TabList as="nav" class="border-b border-gray-200 -mb-px flex space-x-8">
                  <Tab as="template" v-slot="{ selected }">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Vital Info
                    </a>
                  </Tab>
                  <Tab as="template" v-slot="{ selected }">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Pricing
                    </a>
                  </Tab>
                  <Tab as="template" v-slot="{ selected }">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Shipping
                    </a>
                  </Tab>
                  <Tab as="template" v-slot="{ selected }">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Gallery
                    </a>
                  </Tab>
                  <Tab as="template" v-slot="{ selected }" v-if="isEditing">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Reviews ({{ onGoingReviews.length }})
                    </a>
                  </Tab>
                  <Tab as="template" v-slot="{ selected }" v-if="isEditing">
                    <a :class="[selected? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selected ? 'page' : undefined">
                      Comments
                    </a>
                  </Tab>
                </TabList>
                <TabPanels class="my-4">
                  <TabPanel class="flex space-x-4">
                    <Card class="w-full">
                      <CardBody class="flex px-4 py-4 space-y-2">
                        <div class="w-full space-y-2">
                          <div>
                            <Label name="model_number" required :errors="errors">{{ $t('Model Number') }}</Label>
                            <InputText v-model="listing.modelNumber" name="model_number" :errors="errors" class="w-full" :disabled="listing.locked"/>
                            <FormValidation :errors="errors" name="model_number" />
                          </div>
                          <div>
                            <Label name="brand" required :errors="errors">{{ $t('Brand') }}</Label>
                            <InputText v-model="listing.brand" name="brand" :errors="errors" class="w-full" :disabled="listing.locked"/>
                            <FormValidation :errors="errors" name="brand" />
                          </div>
                          <div>
                            <Label name="manufacturer" :errors="errors">{{ $t('Manufacturer') }}</Label>
                            <InputText v-model="listing.manufacturer" name="manufacturer" :errors="errors" class="w-full" :disabled="listing.locked"/>
                            <FormValidation :errors="errors" name="manufacturer" />
                          </div>
                          <div>
                            <Label name="description" required :errors="errors">{{ $t('Description') }}</Label>
                            <Textarea v-model="listing.description" name="description" :errors="errors" class="w-full" :disabled="listing.locked"/>
                            <p class="text-xs text-gray-500">Required for Walmart US only</p>
                            <FormValidation :errors="errors" name="description" />
                          </div>
                          <div v-for="(keyFeature, index) in listing.keyFeatures" :key="index">
                            <Label name="key_features" :errors="errors">{{ $t('Key Feature '+(index+1)) }}</Label>
                            <Input v-model="listing.keyFeatures[index]" name="key_features" :errors="errors" class="w-full" :disabled="listing.locked"/>
                            <FormValidation :errors="errors" name="key_features" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      <CardBody class="px-4 py-4 space-y-2">
                        <div class="sm:w-full space-y-2">
                          <div>
                            <Label name="price" required :errors="errors">{{ $t('Price') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.price" name="price" :errors="errors" class="w-full pl-7" :disabled="listing.locked"/>
                            </div>
                            <FormValidation :errors="errors" name="price" />
                          </div>
                          <div>
                            <Label name="cost" required :errors="errors">{{ $t('Cost') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.cost" name="cost" :errors="errors" class="w-full pl-7" :disabled="listing.locked"/>
                            </div>
                            <FormValidation :errors="errors" name="cost" />
                          </div>
                          <div>
                            <Label name="msrp" :errors="errors">{{ $t('MSRP') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.msrp" name="msrp" :errors="errors" class="w-full pl-7" :disabled="listing.locked"/>
                            </div>
                            <FormValidation :errors="errors" name="msrp" />
                          </div>
                          <div>
                            <Label name="map" :errors="errors">{{ $t('MAP') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.map" name="map" :errors="errors" class="w-full pl-7" :disabled="listing.locked"/>
                            </div>
                            <FormValidation :errors="errors" name="map" />
                          </div>
                          <div>
                            <Label name="min_price" :errors="errors" :hint="$t('This field is used for the repricing strategy.')">{{ $t('Min Price') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.minPrice" name="min_price" :errors="errors" class="w-full pl-7" :disabled="listing.locked"/>
                            </div>
                            <FormValidation :errors="errors" name="min_price" />
                          </div>
                          <div>
                            <Label name="max_price" :errors="errors" :hint="$t('This field is used for the repricing strategy.')">{{ $t('Max Price') }}</Label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span class="text-gray-500 sm:text-sm">
                                  {{ listing.currency.symbol }}
                                </span>
                              </div>
                              <InputText v-model="listing.maxPrice" name="max_price" :errors="errors" class="w-full pl-7" />
                            </div>
                            <FormValidation :errors="errors" name="max_price" />
                          </div>
                          <div>
                            <Label name="strategy" :errors="errors" :hint="$t('This field is used for the repricing strategy.')">{{ $t('Strategy') }}</Label>
                            <Select :items="strategyOptions" v-model="listing.strategyId" labelKey="label" valueKey="code" class="w-full"/>
                            <FormValidation :errors="errors" name="strategy" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      <CardBody class="px-4 py-4 space-y-2">
                        <div class="sm:w-full space-y-2">
                          <div>
                            <Label name="fulfillment_lag_tine" required :errors="errors">{{ $t('Fulfillment Lag Time') }}</Label>
                            <InputText v-model="listing.fulfillmentLagTime" name="fulfillment_lag_tine" :errors="errors" class="w-full"/>
                            <FormValidation :errors="errors" name="fulfillment_lag_tine" />
                          </div>
                          <div>
                            <Label name="weight" required :errors="errors">{{ $t('Weight') }}</Label>
                            <div class="relative rounded-md shadow-sm">
                              <InputText v-model="listing.weightValue" name="weight" :errors="errors" :disabled="listing.locked" class="block w-full pr-16"/>
                              <div class="absolute inset-y-0 right-0 flex items-center">
                                <select id="country" name="country" :disabled="listing.locked" v-model="listing.weightUnit" class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                                  <option v-for="weightOption in weightOptions" :key="weightOption">
                                    {{ weightOption }}
                                  </option>
                                </select>
                              </div>
                              <FormValidation :errors="errors" name="weight" />
                            </div>
                          </div>
                          <div>
                            <Label name="model_number" :errors="errors">{{ $t('Must Ship Alone') }}</Label>
                            <Switch
                              v-model="listing.mustShipAlone"
                              :class="listing.mustShipAlone ? 'bg-green-700' : 'bg-gray-500'"
                              class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
                            >
                              <span
                                aria-hidden="true"
                                :class="listing.mustShipAlone ? 'translate-x-5' : 'translate-x-0'"
                                class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              />
                            </Switch>
                            <FormValidation :errors="errors" name="model_number" />
                          </div>
                          <div>
                            <Label name="ships_in_original_package" :errors="errors">{{ $t('Ships in Original Package') }}</Label>
                            <Switch
                              v-model="listing.shipesInOriginalPackage"
                              :class="listing.shipesInOriginalPackage ? 'bg-green-700' : 'bg-gray-500'"
                              class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
                            >
                              <span
                                aria-hidden="true"
                                :class="listing.shipesInOriginalPackage ? 'translate-x-5' : 'translate-x-0'"
                                class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              />
                            </Switch>
                            <FormValidation :errors="errors" name="ships_in_original_package" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel v-if="isEditing">
                    <ListingGalleryForm :listingId="listing.id" />
                  </TabPanel>
                  <TabPanel v-if="isEditing">
                    <Card class="bg-white">
                      <CardBody class="px-4 py-4 space-y-2">
                        <div class="rounded-md bg-blue-50 p-4">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <CircleInfoIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                              <p class="text-sm text-blue-700">
                                {{ $t('Reviews is a way to tell the seller that there is a problem with that listing. Please take these notifications seriously in order to resolve the reported issues as soon as possible.') }}
                              </p>
                              <p class="mt-3 text-sm md:mt-0 md:ml-6">
                                <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Learn more <span aria-hidden="true">&rarr;</span></a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="bg-white shadow sm:rounded-md border border-gray-300">
                          <ul role="list" class="divide-y divide-gray-200">
                            <li v-for="review in reviews" :key="review.id">
                              <ReviewLine :review="review"/>
                            </li>
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel v-if="isEditing">
                    <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden w-full" v-if="listing.id">
                      <div class="divide-y divide-gray-200">
                        <div class="p-4">
                          <h2 id="notes-title" class="text-md font-medium text-gray-900">{{ $t('Comments') }}</h2>
                        </div>
                        <CommentList :commentableId="$route.params.listingId" commentableType="Listing" />
                      </div>
                      <div class="bg-gray-50 px-4 py-6 sm:px-6">
                        <CommentForm :commentableId="$route.params.listingId" commentableType="Listing" />
                      </div>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>

            <Card >
              <CardHeader>
                {{ listing.salesChannel.platform.name }} Content
              </CardHeader>
              <CardBody class="px-2">
                <div class="bg-red-50 border-l-4 border-red-400 p-3 my-1" v-if="listing.setupByMatch">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <CircleInfoIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <p class="text-sm text-red-700">
                        {{ $t('As the setting method is set to match, the listing details are currently inaccessible. If you wish to modify the listing beyond what is permitted in Sitnsell, kindly proceed to the sales channel page.') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="bg-blue-50 border-l-4 border-blue-400 p-3 my-1">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CircleInfoIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <p class="text-sm text-blue-700">
                          {{ $t('The info shown here reflects the content displayed on marketplace. Your content edits may not be approved and are at the discretion of marketplace.') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="bg-red-50 border-l-4 border-red-400 p-3 my-1" v-if="!platformCategory">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CircleInfoIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3">
                        <p class="text-sm text-red-700">
                          {{ $t('Please select a platform category in order to view the content requested by the sales channel!') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div class=""  v-if="platformCategory && !listing.setupByMatch">
                  
                  <div class="sm:col-span-3 md:col-span-1 space-y-2">
                    <div class="space-y-2" v-for="[name, field] in Object.entries(sortFieldsByRequirement)" :key="name+''+field">
                      <div v-if="field.type == 'string' && listing.fields.hasOwnProperty(name)">
                        <Label :name="'#/'+name" :required="field.required" :errors="errors">{{ $t(field.title) }}</Label>
                        <Select :items="field.enum.map(item => { return {label: item, code: item} })" v-model="listing.fields[name]" labelKey="label" valueKey="code" :name="'#/'+name" class="w-full" v-if="field.hasOwnProperty('enum')" :disabled="listing.locked"/>
                        <InputText v-model="listing.fields[name]" :name="'#/'+name" class="w-full" :errors="errors" v-debounce="800" :disabled="listing.locked" v-else />
                        <FormValidation :errors="errors" :name="'#/'+name" />
                      </div>

                      <div v-if="field.type == 'object' && listing.fields.hasOwnProperty(name)">
                        <Label :name="'#/'+name+'/'+childName" :required="field.required" :errors="errors">{{ $t(field.title) }}</Label>
                        <div :class="['grid grid-cols-2 flex items-center gap-2']">
                          <div v-for="[childName, childField] in Object.entries(field.properties)" :key="childName">
                            <Select :items="childField.enum.map(item => { return {label: item, code: item} })" v-model="listing.fields[name][childName]" :name="'#/'+name+'/'+childName" labelKey="label" valueKey="code" class="w-full" :disabled="listing.locked" v-if="childField.hasOwnProperty('enum')"/>
                            <InputText v-model="listing.fields[name][childName]" :name="'#/'+name+'/'+childName" class="w-full" :errors="errors" v-debounce="800" :disabled="listing.locked" v-else/>
                            <FormValidation :errors="errors" :name="'#/'+name+'/'+childName" />
                          </div>
                        </div>
                      </div>
                      
                      <div v-if="field.type == 'array' && listing.fields.hasOwnProperty(name) && field.items.type != 'object'">  
                        <Label :name="'#/'+name" :required="field.required" :errors="errors">{{ $t(field.title) }}</Label>
                        <div class="flex items-center space-x-2" v-for="(_, index) in listing.fields[name]" :key="index">
                          <Select :items="field.items.enum.map(item => { return {label: item, code: item} })" v-model="listing.fields[name][index]" :name="'#/'+name" labelKey="label" valueKey="code" class="w-full" :disabled="listing.locked" v-if="field.items.hasOwnProperty('enum')"/>
                          <InputText v-model="listing.fields[name][index]" :name="'#/'+name" class="w-full" :errors="errors" v-debounce="800" :disabled="listing.locked" v-else-if="field.items.type == 'string' && !field.items.hasOwnProperty('enum')"/>                         
                          <Button @click="removeArrayInput(name, index)" size="sm" color="white" :disabled="disabled" class="flex items-center" v-if="listing.fields[name].length > index+1 && !listing.locked">
                            <MinusIcon class="w-4 h-4 text-red-600" />
                          </Button>
                          <Button @click="addNewArrayInput(name)" size="sm" color="white" :disabled="disabled" class="flex items-center" v-if="listing.fields[name].length == index+1 && !listing.locked">
                            <PlusIcon class="w-4 h-4 text-green-600" />
                          </Button>
                        </div>
                        <FormValidation :errors="errors" :name="'#/'+name" />
                      </div>

                      <div :class="['grid items-center gap-2', 'grid-cols-'+Object.entries(field.items.properties).length]" v-if="field.type == 'array' && listing.fields.hasOwnProperty(name) && field.items.type == 'object'">
                        <div v-for="[childName, childField] in Object.entries(field.items.properties)" :key="childName">
                          <Label :name="'#/'+name+'/0'+childName" :required="field.required" :errors="errors">{{ $t(childField.title) }}</Label>
                          <Select :items="childField.enum.map(item => { return {label: item, code: item} })" v-model="listing.fields[name][0][childName]" :name="'#/'+name+'/0/'+childName" labelKey="label" valueKey="code" class="w-full" :disabled="listing.locked" v-if="childField.hasOwnProperty('enum')"/>
                          <InputText v-model="listing.fields[name][0][childName]" :name="'#/'+name+'/0/'+childName" class="w-full" :errors="errors" :disabled="listing.locked" v-debounce="800" v-else/>
                          <FormValidation :errors="errors" :name="'#/'+name+'/0/'+childName" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                
              </CardBody>
            </Card>
          </div>
            
        </div>

      </div>
      <ReviewFormModal ref="reviewFormModal" />
      <ListingRetireModal ref="retireModal" />
      <ListingUnretireModal ref="unretireModal" />
      <ListingDeleteModal ref="deleteModal" />
    </div>
  </div>
</template>