export default {
  all: (state) => {
    return state.strategies
  },
  find: (state) => (id) => {
    return state.strategies.find((strategy) => strategy.id == id)
  },
  findByName: (state) => (name) => {
    return state.strategies.find((strategy) => strategy.name == name)
  }
}