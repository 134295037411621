import APIService from '@/repositories/APIService'

import CanceledRequest from "@/repositories/Response/CanceledRequest";
import User from "@/models/User";
//import AlertService from "../services/AlertService";

export default {
  async getAuthenticatedUser() {
    try {
      return await APIService.fetchEntity(`auth/v1/o2/tokens`, User)
    } catch (error) {
      if (error instanceof CanceledRequest) {
        return ;
      }

      throw error
    }
  },

  async login(params) {
    try {
      return await APIService.post(`auth/v1/o2/tokens`, params)
    } catch (error) {
      if (error instanceof CanceledRequest) {
        return ;
      }

      throw error
    }
  }
};