export default {
  add(state, orderCancellationReason){
    let found = state.orderCancellationReasons.find((old) => old.id == orderCancellationReason.id)
    if(found){
      found = orderCancellationReason
    }else{
      state.orderCancellationReasons.push(orderCancellationReason)
    }
  },
  update(state, orderCancellationReason){
    let found = state.orderCancellationReasons.find((old) => old.id == orderCancellationReason.id)
    if(found){
      let index = state.orderCancellationReasons.indexOf(found)
      state.orderCancellationReasons[index] = orderCancellationReason
    }
  },
  reset(state){
    state.orderCancellationReasons = []
  }
}