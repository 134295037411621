import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'file_resources'
const version = 'v1'
import FileResource from "@/models/FileResource"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`account/${version}/${resource}/${id}`, FileResource, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the file!'
      })
      throw error
    }
  },

  async create(payload){
    try{
      let response = await APIService.postEntity(`account/${version}/${resource}`, payload)
      eventBus.emit('file_resource:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Brand was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create brand. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.destroyEntity(`account/${version}/${resource}/${id}`)
      eventBus.emit('file_resource:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Brand was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete brand. Please try again later!'
      })

      throw error
    }
  }

}
