<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Warehouse
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="saveWarehouse">
        {{ $t('Save') }}
      </Button>
    </template>

    <div class="w-full">
      <div class="grid grid-cols-2 gap-2">
        <div class="col-span-2">
          <div class="text-xs text-gray-600 font-medium">Warehouse Name<span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.name" name="name" class="w-full"></InputText>
          <FormValidation :errors="errors" name="nickname"></FormValidation>
        </div>
        <div class="md:mt-2 col-span-1">
          <div class="text-xs text-gray-600 font-medium">Email<span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.email" name="email" class="w-full"></InputText>
          <FormValidation :errors="errors" name="email"></FormValidation>
        </div>
        <div class="md:mt-2 col-span-1">
          <div class="text-xs text-gray-600 font-medium">Phone<span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.phoneNumber" name="phone_number" class="w-full"></InputText>
          <FormValidation :errors="errors" name="phone_number"></FormValidation>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">Street<span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.street" name="street" class="w-full"></InputText>
          <FormValidation :errors="errors" name="street"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">Street 2</div>
          <InputText v-model="warehouse.street2" name="street2" class="w-full"></InputText>
          <FormValidation :errors="errors" name="street2"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">Country<span class="text-red-500">*</span></div>
          <Select v-model="warehouse.country" labelKey="label" valueKey="code" :searchable="true" :items="countryOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="country"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">State<span class="text-red-500">*</span></div>
          <Select v-model="warehouse.state" labelKey="label" valueKey="code" :searchable="true" :items="stateOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="state"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">City <span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.city" name="city" class="w-full"></InputText>
          <FormValidation :errors="errors" name="city"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">Zip Code<span class="text-red-500">*</span></div>
          <InputText v-model="warehouse.zipCode" name="zip_code" class="w-full"></InputText>
          <FormValidation :errors="errors" name="zip_code"></FormValidation>
        </div>
        <div class="md:mt-2">
          <div class="text-xs text-gray-600 font-medium">Timezone<span class="text-red-500">*</span></div>
          <Select v-model="warehouse.timezone" labelKey="label" valueKey="code" :searchable="true" :items="timezoneOptions" placeholder="Please select a timezone" class="w-full"></Select>
          <FormValidation :errors="errors" name="timezone"></FormValidation>
        </div>
      </div>
    </div>
  </FormModal>
</template>
<script>
  import Warehouse from "@/models/Warehouse";
  import _ from 'lodash'
  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        warehouse: new Warehouse(),
        errors: [],
        countries: [],
        states: [],
        timezones: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
      'warehouse.country': function(value){
        this.fetchStates()
      }
    },
    computed: {
      isEditing(){
        console.log(this.warehouse)
        return this.warehouse.id != null
      },
      countryOptions(){
        let countries = []
        this.countries.forEach((country) => {
          countries.push({
            label: country.name,
            code: country.id,
          })
        })
        return countries
      },
      stateOptions(){
        let states = []
        this.states.forEach((state) => {
          states.push({
            label: state.name,
            code: state.id,
          })
        })
        return states
      },
      timezoneOptions(){
        var options = []
        this.timezones.forEach((timezone) => {
          options.push({
            label: timezone.name,
            code: timezone.id,
          })
        })
        return options
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let warehouse = new Warehouse()
          _.assign(warehouse, JSON.parse(JSON.stringify(preset)))
          this.warehouse = warehouse
          this.fetchCountries()
          this.fetchStates()
          this.fetchTimezones()
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.warehouse = new Warehouse()
        this.errors = []
        this.countries = []
        this.timezones = []
        this.states = []
      },
      async saveWarehouse(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          await this.updateWarehouse()
        }else{
          await this.storeWarehouse()
        }
      },
      async storeWarehouse(){
        try{
          let response = await this.$repositories.warehouses.store(this.warehouse)
          this.close()
        }catch(error) {
          console.log(error.errors)
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateWarehouse(){
        try{
          let response = await this.$repositories.warehouses.update(this.warehouse.id, this.warehouse)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
      
      async fetchCountries(){
        try{
          let response = await this.$repositories.countries.get()
          this.countries = response.data
        }catch(e){
          console.log(e)
        }
      },
      async fetchStates(){
        try{
          let response = await this.$repositories.states.get({country_id: this.warehouse.country})
          this.states = response.data
        }catch(_){}
      },
      async fetchTimezones(){
        try{
          let response = await this.$repositories.timezones.get()
          this.timezones = response.data
        }catch(_){}
      },
    }
  }
</script>