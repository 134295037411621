<script lang="js">
import { CircleNotchIcon } from '@snsicons/icons/solid'
export default {
  inheritAttrs: false,
  components: {
    CircleNotchIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'blue'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      working: false
    }
  },
  computed: {
    listeners(){
      return {
        ...this.$attrs,
        onClick: this.handleClick
      }
      // const onRE = /^on[^a-z]/
      // const attributes = {}
      
      // for (const property in this.$attrs) {
      //   if (!onRE.test(property)) {
      //     attributes[property] = this.$attrs[property]
      //   }else{
      //     attributes[property] = this.wrap(this.$attrs[property])
      //   }
      // }
      // return attributes
    },
    sizeStyle(){
      switch(this.size){
        case 'xs':
          return 'px-2.5 py-1.5 text-xs leading-4 font-medium'
        case 'sm':
          return 'px-3 py-2 text-sm leading-4 font-medium'
        case 'md':
          return 'px-4 py-2 text-sm leading-5 font-medium'
        case 'lg':
          return 'py-2 px-4 text-base leading-5 font-medium'
        case 'xl':
          return 'px-6 py-3 text-base leading-6 font-medium'
        default:
          return 'px-4 py-2 text-sm leading-5 font-medium'
      }
    },
    colorStyle(){
      switch(this.color){
        case 'white':
          return 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
        case 'amber':
          return 'bg-amber-600 border border-transparent text-white hover:bg-amber-700 focus:ring-amber-700'
        case 'black':
          return 'bg-black-600 border border-transparent text-white hover:bg-black-700 focus:ring-black-700'
        case 'blue':
          return 'bg-blue-600 border border-transparent text-white hover:bg-blue-700 focus:ring-blue-700'
        case 'cyan':
          return 'bg-cyan-600 border border-transparent text-white hover:bg-cyan-700 focus:ring-cyan-700'
        case 'emerald':
          return 'bg-emerald-600 border border-transparent text-white hover:bg-emerald-700 focus:ring-emerald-700'
        case 'fuchsia':
          return 'bg-fuchsia-600 border border-transparent text-white hover:bg-fuchsia-700 focus:ring-fuchsia-700'
        case 'gray':
          return 'bg-gray-600 border border-transparent text-white hover:bg-gray-700 focus:ring-gray-700'
        case 'green':
          return 'bg-green-600 border border-transparent text-white hover:bg-green-700 focus:ring-green-700'
        case 'indigo':
          return 'bg-indigo-600 border border-transparent text-white hover:bg-indigo-700 focus:ring-indigo-700'
        case 'lime':
          return 'bg-lime-600 border border-transparent text-white hover:bg-lime-700 focus:ring-lime-700'
        case 'neutral':
          return 'bg-neutral-600 border border-transparent text-white hover:bg-neutral-700 focus:ring-neutral-700'
        case 'orange':
          return 'bg-orange-600 border border-transparent text-white hover:bg-orange-700 focus:ring-orange-700'
        case 'pink':
          return 'bg-pink-600 border border-transparent text-white hover:bg-pink-700 focus:ring-pink-700'
        case 'purple':
          return 'bg-purple-600 border border-transparent text-white hover:bg-purple-700 focus:ring-purple-700'
        case 'red':
          return 'bg-red-600 border border-transparent text-white hover:bg-red-700 focus:ring-red-700'
        case 'rose':
          return 'bg-rose-600 border border-transparent text-white hover:bg-rose-700 focus:ring-rose-700'
        case 'sky':
          return 'bg-sky-600 border border-transparent text-white hover:bg-sky-700 focus:ring-sky-700'
        case 'slate':
          return 'bg-slate-600 border border-transparent text-white hover:bg-slate-700 focus:ring-slate-700'
        case 'stone':
          return 'bg-stone-600 border border-transparent text-white hover:bg-stone-700 focus:ring-stone-700'
        case 'teal':
          return 'bg-teal-600 border border-transparent text-white hover:bg-teal-700 focus:ring-teal-700'
        case 'violet':
          return 'bg-violet-600 border border-transparent text-white hover:bg-violet-700 focus:ring-violet-700'
        case 'yellow':
          return 'bg-yellow-600 border border-transparent text-white hover:bg-yellow-700 focus:ring-yellow-700'
        case 'zinc':
          return 'bg-zinc-600 border border-transparent text-white hover:bg-zinc-700 focus:ring-zinc-700'
        case 'retired':
          return 'bg-retired border border-transparent text-white hover:bg-white hover:text-retired hover:border-retired focus:ring-zinc-700'
        case 'deleting':
          return 'bg-deleting border border-transparent text-white hover:bg-white hover:text-deleting hover:border-deleting focus:ring-zinc-700'
        case 'deleted':
          return 'bg-deleted border border-transparent text-white hover:bg-white hover:text-gray-800 hover:border-deleted focus:ring-zinc-700'
        default:
          return 'bg-blue-600 border border-transparent text-white hover:bg-blue-700 focus:ring-blue-700'
      }
    },
    generalStyle(){
      return 'rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50'
    }
  },
  methods: {
    wrap(invoker){
      return async () => {
        if (this.working) {
          return
        }
        this.working = true
        await invoker()
        this.working = false
      }
    },
    async handleClick(e) {
      try {
        // set the internal loading state
        this.working = true;
        // call the parent click listener and await it
        // Using Async/Await lets us await even none promises
        // pass the Event so modifiers can work
        await this.$attrs.onClick(e);
      } catch (error) {
        console.log(error)
      } finally {
        this.working = false;
      }
    },
  }
}
</script>

<template>
  <button :disabled="disabled || loading || working" :class="[generalStyle, sizeStyle, colorStyle]" v-bind="listeners">
    <CircleNotchIcon class="w-5 h-5 animate-spin" v-if="loading || working" />
    <slot v-else />
  </button>
</template>