<script>
import { UpRightFromSquareIcon, FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, TriangleExclamationIcon, MagnifyingGlassIcon, CheckCircleIcon } from '@snsicons/icons/solid'
import { XIcon } from '@heroicons/vue/24/outline'
import CopyButton from '@/components/UI/CopyButton.vue'
import ListingCompetitionButton from '@/components/Listing/ListingCompetitionButton.vue'
import ListingPriceSmallForm from '@/components/Listing/ListingPriceSmallForm.vue'
import ListingPublishModal from '@/components/Listing/ListingPublishModal.vue'
import ListingRetireModal from '@/components/Listing/ListingRetireModal.vue'
import ListingStatusBadge from '@/components/Listing/ListingStatusBadge.vue'
import ListingStrategySmallForm from '@/components/Listing/ListingStrategySmallForm.vue'

import ListingAdjustPricesModal from '@/components/Listing/ListingAdjustPricesModal.vue'
import ListingChangeStrategyModal from '@/components/Listing/ListingChangeStrategyModal.vue'

import _ from 'lodash'

export default {
  components: {
    CopyButton,
    ListingPriceSmallForm,
    MagnifyingGlassIcon,
    TriangleExclamationIcon,
    UpRightFromSquareIcon,
  },
  data() {
    return {
      listingErrors: [],
      listingHeaders: [
        {
          text: 'Error',
          value: 'error',
          sortable: '',
          whitespace: 'normal'
        },
        {
          text: 'Listings Under',
          value: 'count',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      filters: {
        term: '',
      },
    }
  },
  mounted(){
    this.fetchErrors()
  },
  computed: {
    errorOptions(){
      let errors = this.listingErrors
      if(this.filters.term){
        return errors.filter((item) => {
          return item.error.toLowerCase().includes(this.filters.term.toLowerCase())
        })
      }else{
        return errors
      }
    },
  },
  methods: {
    async fetchErrors(){
      try {
        let response = await this.$repositories.listings.errors()
        this.listingErrors = response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
  }
}
</script>

<template>
  <div class="px-4">
    <div class="md:flex md:items-center md:justify-between pt-5 pb-10">
      <div class="flex-1 min-w-0">
        <h2 class="text-xl font-bold leading-7 sm:truncate">
          Grouped Errors
        </h2>
        <p class="text-sm text-gray-700">
          This is the area where you can see all the errors received by the listings.
        </p>
      </div>
      <div class="mt-2 flex md:mt-0 md:ml-4 space-x-2">

      </div>
    </div>
    <div class="w-full flex justify-between pb-5">
      <div class="flex items-center space-x-4">

      </div>
      <div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" placeholder="Search...." />
        </div>
      </div>
    </div>

    <div class="w-full">
      <TTable :headers="listingHeaders" :items="errorOptions" :loading="loading" :allowBulk="false" style="height: 80vh;">

        <template v-slot:[`item.error`]="{ item }">
          <div class="w-1/2">
            {{ item.error }}
          </div>
        </template>

        <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex items-center space-x-3">
            <router-link :to="{name: 'dashboard.listings.review', query: { ...$route.query, ...{error_messages: item.error, page: 1} }}" class="text-blue-500 hover:text-blue-700" v-tooltip="'View listings with this error'">
              <UpRightFromSquareIcon class="w-4 h-4"/>
            </router-link>
          </div>
        </template>


      </TTable>
    </div>
  </div>
</template>