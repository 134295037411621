import BaseModel from "@/models/BaseModel"

export default class Integration extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.code = ''
    this.shortDescription = ''
    this.description = ''
    this.logo = ''
    this.integrationType = ''
    this.authenticationType = {}
    this.notLaunched = false
    this.limit = 1
    this.connectionsCount = 0

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let integration = new this()

    integration.id = json.id
    integration.name = json.name
    integration.code = json.code
    integration.shortDescription = json.short_description
    integration.description = json.description
    integration.integrationType = json.integration_type
    integration.authenticationType = json.authentication_type
    integration.notLaunched = json.not_launched
    integration.limit = json.limit
    integration.logo = json.logo
    integration.connectionsCount = json.connections_count
    
    integration.createdAt = json.created_at
    integration.updatedAt = json.updated_at

    return integration
  }
}