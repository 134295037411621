<script>
import { MagnifyingGlassIcon, TriangleExclamationIcon, BadgeCheckIcon, CircleExclamationIcon, CircleInfoIcon } from '@snsicons/icons/solid'
import SalesChannel from "@/models/SalesChannel"

export default {
    data() {
      return {
        loading: true,
        salesChannels: [],
        tableData: [],
        salesChannel: new SalesChannel(),
        salesChannelId: null,
        filters: {
            department_id: [],
            brand: [],
            quantity: 'with',
            date: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
        },
      };
    },
    watch: {
        salesChannelId: function(){
          this.fetchChartData()
          this.fetchSalesChannel()
        },
    },
    computed: {
      kpis(){
        return Object.keys(this.tableData)
      },
      hasMetricsOverTarget(){
        let hasOverTarget = false
        this.salesChannel.performanceMetrics.forEach((item) => {
          if(item.value > item.targetValue){
            hasOverTarget = true
          }
        })
        return hasOverTarget
      },
      salesChannelOptions(){
        let options = []
        this.salesChannels.forEach((item) => {
          options.push({
            label: item.name+"("+item.platform.name+")",
            code: item.id
          })
        })
        return options
      },
      chartOptions(){
        return {
          chart: {
            type: 'line',
            height: 350,
            stacked: false,
          },
          colors: ['#00E396', '#E91E63'],
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '55%',
          //     endingShape: 'rounded',
          //   },
          // },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
          },
          markers: {
            size: 0
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
            },
          },
          xaxis: {
            type: 'datetime'
          },
          yaxis: {
            show: true
          },
          
        }
      }
    },
    beforeMount(){
      this.fetchSalesChannels()
    },
    methods: {
        async fetchChartData(){
          try{
            let response = await this.$repositories.charts.performanceMetrics({sales_channel_id: this.salesChannelId})
            this.tableData = response.data
          }catch(_){}
        },
        async fetchSalesChannel(){
          this.loading = true
          try{
            let response = await this.$repositories.salesChannels.find(this.salesChannelId, {include: ['performance_metrics']})
            this.salesChannel = response.data
          }catch(_){

          }finally{
            this.loading = false
          }
        },
        async fetchSalesChannels(){
          this.loading = true
          try{
            let response = await this.$repositories.salesChannels.get()
            this.salesChannels = response.data
            this.salesChannelId = response.data[0].id
          }catch(_){}
          this.loading = false
        },
        chartData(key){
          return [
            {
              name: "Value",
              type: 'area',
              data: this.tableData[key].values
            },
            {
              name: "Target Value",
              type: 'line',
              data: this.tableData[key].target_values
            },
          ]
        },
        chartXAxis(key){
          return {
            xaxis: {
              categories: this.tableData[key].dates,
            },
          }
        },
        tooltip(kpi){
          return {
            tooltip: {
              y: {
                formatter: function (val) {
                  if(kpi.toLowerCase().includes('rate')){
                    return val+"%"
                  }else{
                    return val
                  }
                }
              }
            }
          }
        },
        kpiValue(kpi, value){
          if(kpi.toLowerCase().includes("rate")){
            return value+"%"
          }
          return parseInt(value)
        },
        kpiOverTarget(kpi){
          return kpi.value > kpi.targetValue
        },
        kpiBadgeTooltip(kpi){
          if(kpi.score == 0){
            return 'The value of the indicator is close to the limit'
          }
          if(kpi.score > 0){
            return 'The value of the indicator in the limit'
          }
          return 'The value of the indicator is over to the limit'
        }
    },
    components: { 
      MagnifyingGlassIcon,
      TriangleExclamationIcon,
      BadgeCheckIcon,
      CircleExclamationIcon,
      CircleInfoIcon
    }
}
</script>
<template>
  <div>
    <Loading v-model:active="loading" :is-full-page="true"/>
    <div class="flex items-center justify-between pb-4">
      <div>
        <h2 class="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          <span v-if="salesChannel.id">{{ salesChannel.platform.name }}</span> - {{ salesChannel.name }}
        </h2>
      </div>
      <Select :items="salesChannelOptions" v-model="salesChannelId" labelKey="label" valueKey="code" class="w-56"/>
    </div>
    <div class="rounded-md bg-blue-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <CircleInfoIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">The KPIs shown below are those given by the sales channel and may be less than what can be seen in the sales channel dashboard.</p>
        </div>
      </div>
    </div>
    <div class="rounded-md bg-yellow-50 p-4 my-4" v-if="hasMetricsOverTarget">
      <div class="flex">
        <div class="flex-shrink-0">
          <TriangleExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">Attention needed</h3>
          <div class="mt-2 text-sm text-yellow-700">
            <p>
              Addressing your policy violations in a timely manner will prevent unnecessary disruption to your selling account.
            </p>
            <p v-if="salesChannel.isAmazon">
              Your account may be at immediate risk of deactivation if you reach the maximum number of repeat violations for infringement-related policies or restricted products policies in a 180-day period. In most cases, the maximum number of repeat violations is five for infringement-related policies and two for restricted products policies.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3 class="text-lg font-medium leading-6 text-gray-900">As of today your account <span class="underline decoration-dotted" v-tooltip="'Key Performance Indicator(s)'">KPIs</span> are</h3>
      <p class="text-sm text-gray-600 font-medium">These data are ingested by Sitnsell every day at 01:00 UTC. "Good", "Bad" and "At risk" represent marks given based on system analysis and represent suggestions where you need to work to keep your account healthy.</p>
      <dl class="mt-5 grid grid-cols-1 divide-y md:divide-x md:divide-gray-200 rounded-lg bg-white shadow md:grid-cols-3 lg:grid-cols-4">
        <div v-for="kpi in salesChannel.performanceMetrics" :key="kpi.name" class="px-4 py-5 sm:p-6">
          <dt class="text-base font-normal text-gray-900">{{ $t('kpi.'+kpi.key+'.title') }}</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div class="flex items-baseline text-2xl font-semibold text-blue-600">
              {{ kpiValue(kpi.key, kpi.value) }}
              <span class="ml-2 text-sm font-medium text-gray-500">target {{ kpiValue(kpi.key, kpi.targetValue) }}</span>
            </div>

            <div :class="[kpi.score < 0 ? 'bg-red-100 text-red-800' : kpi.score == 0 ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800', 'inline-flex items-baseline px-3 py-1.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']" v-tooltip="kpiBadgeTooltip(kpi)">
              <CircleExclamationIcon v-if="kpi.score < 0" class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
              <TriangleExclamationIcon v-else-if="kpi.score == 0" class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-yellow-500" aria-hidden="true" />
              <BadgeCheckIcon v-else class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
              {{ kpi.score < 0 ? 'Bad' : (kpi.score == 0 ? 'At risk' : 'Good') }}
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <div class="py-4 mt-4">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Values of KPIs in the last 30 days</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="mt-4" v-for="kpi in kpis" :key="kpi">
          <ChartCard class="col-span-2">
            <template v-slot:title>
              {{ $t('kpi.'+kpi+'.title') }}
            </template>
            <template v-slot:subtitle>
              {{ $t('kpi.'+kpi+'.description') }}
            </template>
            <apexchart height="400" :options="{...chartOptions, ...chartXAxis(kpi), ...tooltip(kpi)}" :series="chartData(kpi)"></apexchart>
          </ChartCard>
        </div>
      </div>
    </div>
  </div>
</template>