<script>
  import SalesChannel from "@/models/SalesChannel";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        salesChannel: new SalesChannel(),
        errors: [],
        carriers: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.salesChannel.id != null
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let salesChannel = new SalesChannel()
          _.assign(salesChannel, JSON.parse(JSON.stringify(preset)))
          this.salesChannel = salesChannel
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.salesChannel = new SalesChannel()
        this.errors = []
      },
      async updateSalesChannel(){
        try{
          await this.$repositories.salesChannels.update(this.salesChannel.id, this.salesChannel)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },

    }
  }
</script>



<template>
  <FormModal :show="opened" @close="close" size="w-150">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Maximum Quantity') }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="updateSalesChannel">
        {{ $t('Update') }}
      </Button>
    </template>

    <div class="rounded-md bg-yellow-50 p-4">
      <p class="text-sm text-yellow-700">
        Even in the event of modifying the maximum quantity allowable for transmission per listing, this alteration will be implemented gradually.
      </p>
    </div>

    <div class="grid grid-cols-3 overflow-y-scroll p-2">
      <div>
        <Label name="maximum_quantity_to_send" required :errors="errors">{{ $t('The maximum quantity that can be sent') }}</Label>
        <InputText v-model="salesChannel.maximumQuantityToSend" name="maximum_quantity_to_send" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="maximum_quantity_to_send" />
      </div>
    </div>
  </FormModal>
</template>