<template>
  <div class="min-h-full bg-gray-50">
    <Header />
   
    <main class="pb-4">
      <router-view/>
    </main>
    
    <Alert/>
    <AlertModal/>
    <div id="modals"></div>
  </div>
</template>
<script>
import Header from '@/components/Layout/Header'
import Sidebar from '@/components/Layout/Sidebar'
import MobileMenu from '@/components/Layout/MobileMenu'
import Alert from '@/components/UI/Alert'
import AlertModal from '@/components/UI/AlertModal'

export default {
  components: {
    Header,
    Alert,
    AlertModal,
    MobileMenu
  },
  channels: {
    'Noticed::NotificationChannel': {
      connected() {},
      rejected(data) {
        console.log(data)
      },
      received(data) {
        this.$store.dispatch('notifications/syncCounter')
        if(data && data.hasOwnProperty('url')){
          this.$eventBus.emit('app:message', {
            type: 'download',
            message: data.message,
            title: data.title,
            url: data.url
          })
        }
      },
      disconnected() {},
    },
  },
  mounted(){
    this.$store.dispatch('initialize')
    this.$cable.subscribe({
      channel: "Noticed::NotificationChannel"
    })
  },
}
</script>
