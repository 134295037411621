import BaseModel from "@/models/BaseModel"

export default class FileResource extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.path = ''
    this.size = ''
    this.type = ''
    this.url = ''
    this.folderId = ''
    this.file = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let fileResource = new this()

    fileResource.id = json.id
    fileResource.name = json.name
    fileResource.path = json.path
    fileResource.size = json.size
    fileResource.extension = json.extension
    fileResource.url = json.url
    fileResource.folderId = json.folder_id
    fileResource.createdAt = json.created_at
    fileResource.updatedAt = json.updated_at

    return fileResource
  }

  toJson(){
    return {
      folder_id: this.folderId,
      file: this.file
    }
  }
}