import BaseModel from "@/models/BaseModel"

export default class PackageType extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.widthValue = 0
    this.heightValue = 0
    this.lengthValue = 0
    this.sizeUnit = null
    this.default = false
    this.companyId = null
    this.uspsPackageType = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let packageType = new this()

    packageType.id = json.id
    packageType.name = json.name
    packageType.widthValue = json.width_value
    packageType.heightValue = json.height_value
    packageType.lengthValue = json.length_value
    packageType.sizeUnit = json.size_unit
    packageType.uspsPackageType = json.usps_package_type
    packageType.default = json.default
    packageType.companyId = json.company_id
    packageType.createdAt = json.created_at
    packageType.updatedAt = json.updated_at

    return packageType
  }

  toJson(){
    return {
      package_type: {
        name: this.name,
        width_value: this.widthValue,
        length_value: this.lengthValue,
        height_value: this.heightValue,
        size_unit: this.sizeUnit,
        default: this.default,
      }
    }
  }
}