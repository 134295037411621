<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ (isEditing ? 'Edit' : 'Create') + ' Aisle' }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="saveAisle">
        {{ $t('Save') }}
      </Button>
    </template>
    <div>
      <div class="w-full mb-6">
        <Label name="name" required :errors="errors">{{ $t('Aisle Name') }}</Label>
        <InputText v-model="aisle.name" name="name" class="w-full" :errors="errors" :disabled="isEditing"/>
        <FormValidation :errors="errors" name="name" />
      </div>
      <div class="w-full grid grid-cols-2 gap-3">
        <div>
          <Label name="number_of_rows" required :errors="errors">{{ $t('Number of Shelves') }}</Label>
          <InputText v-model="aisle.numberOfRows" name="number_of_rows" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="number_of_rows" />
        </div>
        <div>
          <Label name="number_of_columns" required :errors="errors">{{ $t('Number of Columns') }}</Label>
          <InputText v-model="aisle.numberOfColumns" name="number_of_columns" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="number_of_columns" />
        </div>
      </div>
    </div>

  </FormModal>
</template>
<script>
  import Aisle from "@/models/Aisle";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        aisle: new Aisle(),
        errors: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.aisle.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let aisle = new Aisle()
          _.assign(aisle, preset)
          this.aisle = aisle
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.aisle = new Aisle()
        this.errors = []
      },
      async saveAisle(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          await this.updateAisle()
        }else{
          await this.storeAisle()
        }
      },
      async storeAisle(){
        try{
          await this.$repositories.aisles.store(this.aisle)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateAisle(){
        try{
          await this.$repositories.aisles.update(this.aisle.id, this.aisle)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      }
    }
  }
</script>
