<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
    <loading :active="loading" :is-full-page="true"></loading>
    <div class="sm:mx-auto sm:w-full sm:max-w-md" v-if="(failed == false)">
      <img class="mx-auto h-12 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell">
      <h2 class="mt-6 mb-1 text-center text-lg font-semibold text-gray-900">Reset your password</h2>
      <p class="text-center text-sm">Enter the new password together with its confirmation in the form below.</p>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md" v-else>
      <img class="mx-auto h-12 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell">
      <div class="flex items-center justify-center py-4">
        <CircleXIcon class="w-8 h-8 text-red-500" />
      </div>
      <h2 class="mb-1 text-center text-lg font-semibold text-gray-900">Invalid password reset token</h2>
      <p class="text-center text-sm">The password reset token has expired or is no longer active.</p>
      <div class="mt-6 gap-3">
        <router-link :to="{name: 'dashboard.login'}" class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
          Sign in
        </router-link>
      </div>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md" v-if="failed == false">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
          <div>
            <Label name="password" required :errors="errors">{{ $t('Password') }}</Label>
            <InputText type="password" v-model="user.password" name="password" class="w-full" :errors="errors"/>
          </div>

          <div>
            <Label name="password_confirmation" required :errors="errors">{{ $t('Password Confirmation') }}</Label>
            <InputText type="password" v-model="user.passwordConfirmation" name="password_confirmation" class="w-full" :errors="errors"/>
          </div>

          <div>
            <Button @click="resetPassword" class="w-full">
              {{ $t('Update your password') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from '@/models/User'
import { CircleXIcon } from '@snsicons/icons/solid'
import _ from 'lodash'

export default{
  components: {
    CircleXIcon
  },
  data(){
    return {
      email: '',
      loading: false,
      user: new User(),
      errors: [],
      failed: true
    }
  },
  mounted(){
    this.getToken()
  },
  methods: {
    async getToken(){
      this.loading = true
      try{
        let response = await this.$repositories.users.showResetPassword(this.$route.params.token)
        this.user = response.data
        this.failed = false
      }catch (e) {
        console.log(e)
        this.error = e
        this.failed = true
      }finally{
        this.loading = false
      }
    },
    async resetPassword(){
      this.loading = true
      try{
        await this.$repositories.users.resetPassword(this.user.id, this.user)
        this.$router.push({name: 'dashboard.login'})
      }catch(error) {
        this.errors = error.errors
      }finally{
        this.loading = false
      }
    },
  }
}
</script>
