import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Warehouse from '@/models/Warehouse'
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'warehouses'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Warehouse, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the warehouses'
      })
      throw error
    }
  },

  async print(id, payload){
    try{
      return await APIService.post(`${version}/${resource}/${id}/print`, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the warehouse!'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Warehouse, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the warehouse!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('warehouse:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Warehouse was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create warehouse. Please verify form!'
      })
      
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('warehouse:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Warehouse was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update warehouse. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`)
      eventBus.emit('warehouse:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Warehouse was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete warehouse. Please try again later!'
      })

      throw error
    }
  },

  async attach(id, payload){
    try{
      let response = await APIService.post(`${version}/${resource}/${id}/attach_user`, payload)
      eventBus.emit('warehouse:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Associated was added to this warehouse!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete warehouse. Please try again later!'
      })

      throw error
    }
  },

  async detach(id, payload){
    try{
      let response = await APIService.post(`${version}/${resource}/${id}/detach_user`, payload)
      eventBus.emit('warehouse:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Associated was removed to this warehouse!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete warehouse. Please try again later!'
      })

      throw error
    }
  },

}
