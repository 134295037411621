<script>
  import FileSaver from 'file-saver'
  import UploadForm from '@/components/Upload/UploadForm.vue'
import { CircleCheckIcon, SpinnerIcon, CircleXmarkIcon } from '@snsicons/icons/solid'
import { DownloadIcon } from '@snsicons/icons/regular'

  export default {
    components: { 
      UploadForm,
      CircleCheckIcon,
      SpinnerIcon,
      DownloadIcon,
      CircleXmarkIcon,
    },
    channels: {
      ExportChannel: {
        connected() {},
        rejected() {},
        received(data) {
          if(data.hasOwnProperty('error')){
            this.craftingFailed(data)
          }else{
            this.downloadTemplate(data)
          }
        },
        disconnected() {}
      }
    },
    data(){
      return {
        loading: false,
        choice: null,
        uploads: [],
        waitingForReport: false,
        templateGenerated: false,
        resolution: '',
        filters: {
          page: 1,
          per: 10,
          last_page: 1,
          total: 0,
          sort_by: 'created_at',
          sort_direction: 'desc',
        },
        uploadHeaders: [
          {
            text: 'Upload Type',
            value: 'uploadType',
          },
          {
            text: 'By',
            value: 'author',
          },
          {
            text: 'Uploaded At',
            value: 'createdAt',
          },
          {
            text: 'Completed At',
            value: 'completedAt',
          },
          {
            text: 'Status',
            value: 'status',
          },
          {
            text: 'Results',
            value: 'results'
          },
          {
            text: '',
            value: 'actions',
          },
        ],
        templateOptions: [
          {
            code: 'template-products',
            text: 'Products template'
          },
        ]
      }
    },
    watch: {
      'filters.page': function(value){
        this.fetchUploads()
      }
    },
    mounted(){
      let vm = this
      this.fetchUploads()
      this.$eventBus.on('upload:created', function(){
        vm.fetchUploads()
      })
      this.$cable.subscribe({
        channel: 'ExportChannel'
      });
    },
    beforeUmount(){
      this.$cable.unsubscribe({
        channel: 'ExportChannel'
      });
    },
    methods:{
      requestTemplate(template){
        this.resolution = ''
        this.$cable.perform({
          channel: 'ExportChannel',
          action: 'products_template',
          data: {
            template: template
          }
        });
        this.waitingForReport = true
      },
      async fetchUploads(){
        this.loading = true
        try{
          let response = await this.$repositories.uploads.get(this.filters)
          this.uploads = response.data
          this.filters.per = response.pagination.perPage
          this.filters.page = response.pagination.currentPage
          this.filters.total = response.pagination.total
          this.filters.last_page = response.pagination.totalPages
        }catch(_){}
        this.loading = false
      },
      downloadUpload(upload){
        let byteCharacters = atob(upload.content)
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        let blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
        FileSaver.saveAs(blob, upload.filename)
      },
      changedPage(value){
        this.filters.page = value
        this.fetchUploads()
      },
      craftingFailed(data){
        this.waitingForReport = false
        this.$eventBus.emit('app:message', {
          type: 'error',
          message: data.error
        })
      },
      async downloadTemplate(data){
        this.waitingForReport = false
        this.$eventBus.emit('app:message', {
          type: 'success',
          message: 'Your template has been downloaded.'
        })
        this.loading = false

        let byteCharacters = atob(data.content)
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: data.type })
        FileSaver.saveAs(blob, data.filename)
      }
    }
  }
</script>


<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Import
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 my-5 space-x-5">
          <Card>
            <CardBody class="px-4 py-4 space-y-2">
              <h4 class="block text-base font-medium leading-6 text-gray-900">
                {{ $t('Upload Changes') }}
              </h4>
              <p class="text-sm text-gray-500">Make a variety of bulk updates to your listings in a single upload. Please note the following guidelines for uploading changes to Sitnsell.</p>
              <ul class="list-disc text-sm text-gray-500 pt-4 px-5">
                <li>Files must be in .csv(comma separated values) or .xlsx (Excel 2007 or newer)</li>
                <li>Do not remove or add extra columns to the file.</li>
                <li>No changes will be made to blank fields.</li>
              </ul>
              <UploadForm />
            </CardBody>
          </Card>

          <Card>
            <CardBody class="px-4 py-4 space-y-2">
              
              <h4 class="block text-base font-medium leading-6 text-gray-900">
                {{ $t('Download a Template') }}
              </h4>
              <div class="flex flex-col justify-between">
                <div class="text-sm text-gray-500">
                  Upload templates can be used in various cases to make bulk changes to your lists or products. Templates downloaded from the Import page will be downloaded as needed in advance with information from your account. You can edit the information in the template file and upload it in Sitnsell to make changes in bulk.
                </div>
                <div></div>
                <div class="rounded-md bg-blue-50 p-4 mb-4" v-if="waitingForReport">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm text-blue-700">
                        Please do not leave the current page until the template is generated. The generated template will be downloaded automatically.
                      </p>
                    </div>
                  </div>
                </div>
                <Menu as="div" class="w-full relative inline-block text-left">
                  <div>
                    <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <SpinnerIcon class="animate-spin w-5 h-5 text-gray-500" v-if="waitingForReport"/>
                      {{ waitingForReport ? 'Crafting your template...' : 'Download Template'}}
                      <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95" v-if="!waitingForReport">
                    <MenuItems class="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1">
                        <MenuItem v-slot="{ active }" v-for="templateOption in templateOptions" :key="templateOption.code">
                          <a @click="requestTemplate(templateOption.code)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                            {{ templateOption.text }}
                          </a>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <div class="w-full ">
        <div class="flex flex-col mt-2 ">
          <div class="mt-36" v-if="uploads.length == 0">
            <no-results>
              <h3 class="text-lg leading-6 font-bold text-gray-900">Sorry, no results found!</h3>
              <p class="text-gray-600 text-md">What you searched was unfortunately not found or doesn't exist.</p>
            </no-results>
          </div>
          <div class="bg-white border border-gray-200 rounded mb-4" v-for="upload in uploads" :key="upload.id">
            <div class="">
              <div class="flex justify-between px-4 py-2 border-b border-gray-200">
                <h3 class="font-medium text-gray-800">
                  <span class="text-xs text-gray-500">#{{ upload.counter }}</span> {{ upload.uploadType }} import by <span class="text-md text-gray-600">{{ upload.author }}</span>
                </h3>
                <div class="flex justify-between space-x-2">
                  <a :href="upload.errorFile.url" target="_blank" class="flex items-center space-x-1 font-medium text-sm text-red-500 hover:text-red-600" v-if="upload.errorFile">
                    <DownloadIcon class="w-4 h-4" />
                    <span>Error File</span>
                  </a>
                  <a :href="upload.fileResource.url" target="_blank"  class="font-medium text-sm text-blue-500 hover:text-blue-600" v-if="upload.fileResource">
                    Download
                  </a>
                </div>
              </div>
              <div class="py-2">
                <dl class="w-1/2">
                  <div class="sm:grid sm:grid-cols-3 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Status:
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="text-yellow-400" v-if="upload.status === 'uploaded'">
                        <fa icon="file-upload"></fa> {{ upload.status }}
                      </span>

                      <span class="text-yellow-600" v-if="upload.status === 'processing'">
                        <fa icon="clock"></fa> {{ upload.status }}
                      </span>

                      <span class="text-green-500" v-if="upload.status === 'finished'">
                        <fa icon="check"></fa> {{ upload.status }}
                      </span>

                      <span class="text-red-500" v-if="upload.status === 'failed'">
                        <fa icon="times"></fa> {{ upload.status }}
                      </span>
                    </dd>
                  </div>
                  <div class="sm:grid sm:grid-cols-3 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Uploaded At:
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{ $filters.date.dateTimeFormat(upload.createdAt) }}
                    </dd>
                  </div>
                  <div class="sm:grid sm:grid-cols-3 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Completed At:
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex space-x-1">
                      <span>{{ $filters.date.dateTimeFormat(upload.updatedAt) }}</span>
                      <span v-if="upload.errorCount > 0">
                        with errors. <a href="https://help.sitnsell.com/bulk-operations/upload-errors" target="blank" class="cursor-pointer text-blue-500 hover:text-blue-600">Learn more</a>
                      </span>
                    </dd>
                  </div>
                  <div class="sm:grid sm:grid-cols-3 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Success count:
                    </dt>
                    <dd class="mt-1 text-sm font-semibold sm:mt-0 sm:col-span-2 flex space-x-2 items-center">
                      <span class="text-gray-600">{{ upload.successCount }}</span>
                      <CircleCheckIcon class="w-3 h-3 text-green-500" v-if="upload.successCount"/>
                    </dd>
                  </div>
                  <div class="sm:grid sm:grid-cols-3 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Error count:
                    </dt>
                    <dd class="mt-1 text-sm text-gray-600 font-semibold sm:mt-0 sm:col-span-2 flex space-x-2">
                      <span>{{ upload.errorCount }}</span>
                      <span v-if="upload.errorCount">
                        <a :href="upload.errorFile.url" target="_blank" class="flex items-center space-x-2 font-semibold text-sm text-red-500 hover:text-red-600" v-if="upload.errorFile">
                          <CircleXmarkIcon class="w-3 h-3" />
                          <span>Download Error File</span>
                        </a>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <Pagination :lastPage="filters.last_page" v-model="filters.page" :total-items="filters.total" :per-page="filters.per" @page="value => changedPage(value)" lass="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6" />
        </div>
      </div>

    </div>
  </div>
</template>