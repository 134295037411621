import BaseModel from "@/models/BaseModel"
import Currency from "./Currency"

export default class Strategy extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.strategyType = ''
    this.listingsCount = 0
    this.rules = {}
    this.minMax = {}
    this.activeListings = 0
    this.listingsWithoutCost = 0
    this.grossSales = 0
    this.grossProfit = 0
    this.itemsSold = 0
    this.currency = new Currency()
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let strategy = new this()

    strategy.id = json.id
    strategy.name = json.name
    strategy.strategyType = json.strategy_type
    strategy.listingsCount = json.listings_count
    strategy.rules = json.rules
    strategy.minMax = json.min_max
    strategy.activeListings = json.active_listings
    strategy.listingsWithoutCost = json.listings_without_cost
    strategy.grossProfit = json.gross_profit
    strategy.grossSales = json.gross_sales
    
    strategy.createdAt = json.created_at
    strategy.updatedAt = json.updated_at

    return strategy
  }

  toJson(){
    return {
      strategy: {
        name: this.name,
        strategy_type: this.strategyType,
        rules: this.rules,
        min_max: this.minMax
      }
    }
  }
}