import BaseModel from "@/models/BaseModel"
import Role from "@/models/Role"
import Warehouse from "@/models/Warehouse"
import Company from "@/models/Company"

export default class User extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.companyName = ''
    this.email = ''
    this.firstname = ''
    this.fullname = ''
    this.lastname = ''
    this.notificationPreferences = {}
    this.password = null
    this.passwordConfirmation = null
    this.phoneNumber = ''
    this.role = 'data_management'
    this.timezone = ''
    this.username = ''
    this.warehouseIds = []
    this.warehouses = null
    this.company = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let user = new this()
    
    user.id = json.id
    user.companyName = json.company_name
    user.email = json.email
    user.firstname = json.firstname
    user.fullname = json.fullname
    user.lastname = json.lastname
    user.notificationPreferences = json.notification_preferences
    user.phoneNumber = json.phone_number
    user.role = json.role
    user.timezone = json.timezone
    user.username = json.username
    user.warehouseIds = json.warehouse_ids
    user.company = this.initRelation(json.company, Company, {})

    user.createdAt = json.created_at
    user.updatedAt = json.updated_at

    return user
  }

  toJson(){
    return {
      user: {
        firstname: this.firstname,
        lastname: this.lastname,
        role: this.role,
        email: this.email,
        timezone: this.timezone,
        username: this.username,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        notification_preferences: this.notificationPreferences,
        phone_number: this.phoneNumber,
      }
    }
  }
}