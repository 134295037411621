<script>
  import Shelf from '@/models/Shelf'
  import InventoryMovement from '@/models/InventoryMovement'
  import ScanProductIntro from '@/components/Scanning/ScanProductIntro.vue'
  import InventoryMovementsList from '@/components/Scanning/InventoryMovementsList.vue'
  import { HouseIcon } from '@snsicons/icons/regular'
  import { RotateIcon } from '@snsicons/icons/regular'
  import _ from 'lodash'

  export default {
    components: {
      InventoryMovementsList,
      ScanProductIntro,
      HouseIcon,
      RotateIcon,
    },
    data(){
      return {
        barcode: '',
        loading: false,
        shelf: new Shelf(),
        inventoryMovement: new InventoryMovement(),
        inventoryMovements: [],
        error: '',
        quantity: 1
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
      this.fetchShelf()

      this.$eventBus.on('inventory_movement:updated', (event) => {
        this.reloadMovements(event.data)
      })
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    computed: {
      totalInventoryScanned(){
        return _.sumBy(this.inventoryMovements, function(inv) { return inv.quantity })
      }
    },
    methods: {
      scan(event){
        if(event.target.dataset.quantityInput){
          return false
        }

        if(event.code === 'Enter'){
          this.createEntry()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      reloadMovements(movement){
        this.inventoryMovements = this.inventoryMovements.map((move) => {
          return move.id == movement.id ? movement : move
        })
      },
      async fetchShelf(){
        try{
          let response = await this.$repositories.shelves.find(this.$route.params.shelfId)
          this.shelf = response.data
        }catch(e){}
        
      },
      async createEntry(){
        this.loading = true
        try{
          this.inventoryMovement.quantity = this.quantity
          this.inventoryMovement.productBarcode = this.barcode
          this.inventoryMovement.shelfId = this.$route.params.shelfId
          let response = await this.$repositories.scannings.goingIn(this.inventoryMovement)
          this.inventoryMovements = [response.data].concat(this.inventoryMovements)
        }catch(e){}finally {
          this.quantity = 1
          this.barcode = ''
          this.loading = false
          this.inventoryMovement = new InventoryMovement()
        }
      },
    }
  }
</script>
<template>

  <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
    <loading :active="loading" :is-full-page="true"></loading>
    <router-link :to="{name: 'scanning.rescan.index'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
      <HouseIcon class="h-5 text-blue-500" />
      <span>Start Over</span>
    </router-link>
    <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
      <span class="bg-yellow-50 text-yellow-500 rounded-lg inline-flex p-4 ring-4 ring-white">
        <RotateIcon class="h-5 w-5"/>
      </span>
      <div class="flex flex-col justify-start">
        <h3 class="font-semibold text-base">Add items for shelf <span class="text-blue-500">{{ shelf.name }}</span></h3>
        <p class="text-sm text-gray-500">Scan the product barcode in order to continue.</p>
      </div>
    </div>
   
    <InventoryMovementsList :movements="inventoryMovements" >
      <template v-slot:totalHeader>
        Scanned In
      </template>
      <template v-slot:actions>
        
      </template>
    </InventoryMovementsList>
    <ScanProductIntro :failed="hasError" v-if="(inventoryMovements.length == 0 || hasError)">
      <template v-slot:error>
        <span v-if="error">{{ error }}</span>
        <span v-else></span>
      </template>
    </ScanProductIntro>
    
  </div>
</template>