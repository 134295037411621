export default {
  add(state, brand){
    let found = state.brands.find((oldBrand) => oldBrand.id == brand.id)
    if(found){
      found = brand
    }else{
      state.brands.push(brand)
    }
  },
  update(state, brand){
    let found = state.brands.find((oldBrand) => oldBrand.id == brand.id)
    if(found){
      let index = state.brands.indexOf(found)
      state.brands[index] = brand
    }
  },
  reset(state){
    state.brands = []
  }
}