import BaseModel from "@/models/BaseModel"

export default class Role extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.usersCount = 0
    this.permissions = []
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let role = new this()

    role.id = json.id
    role.name = json.name
    role.usersCount = json.users_count
    role.permissions = json.permissions
    role.createdAt = json.created_at
    role.updatedAt = json.updated_at

    return role
  }

  toJson(){
    return {
      role: {
        name: this.name,
        permissions: this.permissions
      }
    }
  }
}