import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

import Comment from "@/models/Comment"
const resource = 'comments'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Comment, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Comment, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comment!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('comment:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Note was created!'
      })

      return response
    }catch(error){
      
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create comment. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.destroyEntity(`${version}/${resource}/${id}`)
      eventBus.emit('comment:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Brand was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete comment. Please try again later!'
      })
      throw error
    }
  }

}
