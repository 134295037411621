import { eventBus } from '@/composables/eventBus'
import SalesChannel from '@/models/SalesChannel'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import ValidationError from '@/repositories/Response/ValidationError'
import APIService from '@/repositories/APIService'

const resource = 'sales_channels'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`saleschannel/${version}/${resource}`, SalesChannel, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales channels'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`saleschannel/${version}/${resource}/${id}`, SalesChannel, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales channel!'
      })
      throw error
    }
  },

  async consentUrl(platform = "amazon", payload = {}){
    try{
      return await APIService.get(`v1/${platform}/authentication/consent_url`, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales channel!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`saleschannel/${version}/${resource}`, payload)
      eventBus.emit('sales_channel:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Sales Channel was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }

      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create sales channel. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`saleschannel/${version}/${resource}/${id}`, payload)
      eventBus.emit('sales_channel:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Sales Channel was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not update sales channel. Please verify form!'
      })
      throw error
    }
  },

  async destroy(id, payload = {}){
    try{
      let response = await APIService.delete(`saleschannel/${version}/${resource}/${id}`, payload)
      eventBus.emit('sales_channel:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Sales Channel was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not delete sales channel. Please try again later!'
      })
      throw error
    }
  },
}