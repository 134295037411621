import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import Order from '@/models/Order'

const resource = 'processing_table'
const version = 'v1'

export default {
  async create(payload) {
    try{
      let response = await APIService.post(`${version}/${resource}`, payload)
      return Order.fromJson(response.data.data)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      new Audio('https://cdn.sitnsell.com/sounds/scan-error.wav').play()
      eventBus.emit('app:alert', {
        type: 'error',
        title: 'There is something wrong!',
        message: error.response.data.message
      })
      throw error
    }
  },

}
