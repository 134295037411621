<script>
export default {
  data(){
    return {
      loading: false,
      ordersCount: [],
      pagination: {
        total: 0,
      }
    }
  },
  mounted(){
    this.fetchOrders()
  },
  methods: {
    async fetchOrders(){
      this.loading = true
      try{
        let response = await this.$repositories.orders.get({per: 1, status: 'new_orders'})
        this.pagination = response.pagination
        this.ordersCount = response.pagination.searchCount
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <Card v-if="pagination.total > 0">
    <CardHeader>
      <h2 class="text-base font-medium text-gray-900">
        {{ $t('New Orders') }}
      </h2>
    </CardHeader>
    <CardBody class="flex flex-col justify-between">

      <div class="text-center py-24 text-green-800 px-2">
        <h2 class="text-4xl leading-2 font-medium text-gray-900">
          {{ pagination.total }}
        </h2>
        <p class="text-base font-medium text-gray-600">
          You must ship these orders in order to avoid ODR(order defect rate) sanctions.
        </p>
      </div>
      <div class="flex justify-between p-2 space-x-2">
        <Button color="teal" @click="$router.push({name: 'scanning.home'})" class="w-full">
          Process Orders
        </Button>
        <Button color="blue" @click="$router.push({name: 'dashboard.orders.index'})" class="w-full">
          View Orders
        </Button>
      </div>

    </CardBody>
  </Card>
</template>