<script>
import _ from 'lodash'
import Order from '@/models/Order'
import { MagnifyingGlassIcon, TruckFastIcon } from '@snsicons/icons/solid'
export default {
  components: {
    MagnifyingGlassIcon,
    TruckFastIcon
},
  data(){
    return {
      searchCount: [],
      orders: [],
      order: new Order(),
      salesChannels: [],
      loading: false,
      filters: {
        term: '',
        status: 'new_orders',
        sales_channel_id: null,
      },
      sortBy: {
        sort_by: 'placed_at',
        sort_direction: 'desc'
      },
      pagination: {
        page: 1,
        per: 50,
        last_page: 1,
        total: 0,
      },
      orderHeaders: [
        {
          text: 'Sales Channel',
          value: 'salesChannel',
        },
        {
          text: 'Order Date',
          value: 'placedAt',
        },
        {
          text: 'Order #',
          value: 'purchaseOrderId',
        },
        {
          text: 'Ship By',
          value: 'estimatedShipDate',
        },
        {
          text: 'Country',
          value: 'country',
        },
        {
          text: 'Quantity',
          value: 'quantity',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
    }
  },
  watch: {
    'pagination.page': function(newValue, oldValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchOrders(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchOrders(1)
      },
      deep: true,
    },
  },
  computed: {
    activeOrder(){
      return this.order.id != null
    },
    salesChannelOptions(){
      let options = []
      this.salesChannels.forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    orderStatuses(){
      return Object.values(this.$enums.orderStatusFiltersEnum)
    },
    selectedStatus(){
      let statuses = this.orderStatuses
      let status = statuses.find(entry => entry.filter == this.filters.status)
      return status
    },
    destinationOptions(){
      return [
        {
          label: 'International',
          code: 'international'
        },
        {
          label: 'Domestic',
          code: 'domestic'
        }
      ]
    },
    filterOptions(){
      const options = [
        {
          name: 'sales_channel_id',
          display: 'Sales Channel',
          options: this.salesChannelOptions,
          type: 'single',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'destination',
          display: 'Destination',
          options: this.destinationOptions,
          type: 'single',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'status',
          display: 'Status',
          type: 'alwaysHidden',
          verb: 'is',
          group: 'Details'
        },
      ]
      return options
    },
  },
  beforeMount(){
    this.setFilters()
  },
  mounted(){
    this.fetchSalesChannels()
    this.fetchOrders(1)
  },
  methods: {
    async fetchSalesChannels(){
      try{
        let response = await this.$repositories.salesChannels.get({per:10000})
        this.salesChannels = response.data
      }catch(e){
        console.log(e)
      }
    },
    async fetchOrders(page){
      this.loading = true
      try{
        let response = await this.$repositories.orders.get({...this.filters, ...this.sortBy, ...this.pagination, ...{page: page}})
        this.orders = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.searchCount = response.pagination.searchCount
      }catch(e){
        console.log(e)
      }
      this.loading = false
    },

    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),

    setFilters() {
      let params = this.$route.query;
      let filters = {}
      
      if (params.sales_channel_id) {
        filters.sales_channel_id = params.sales_channel_id
      }

      if (params.term) {
        filters.term = params.term
      }

      if (params.destination) {
        filters.destination = params.destination
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }

      if (params.status) {
        filters.status = params.status
      }else{
        filters.status = 'new_orders'
      }

      this.filters = filters
    },
    showOrderStatusCounts(status){
      const counter = this.searchCount.find(entry => entry.status == status)
      return counter ? counter.total : 0
    }
  }
}
</script>
<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Orders
      </template>
      <template v-slot:actions>
        <ExportButton resource="orders" :filters="filters" />
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full px-5">
        <div class="my-2">
          <nav class="-mb-px flex space-x-8" >
            <a v-for="orderStatus in orderStatuses" :key="orderStatus.display" @click="filters.status = orderStatus.filter" :class="[orderStatus.filter == filters.status ? 'border-'+orderStatus.color+'-500 text-'+orderStatus.color+'-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm']">
              {{ orderStatus.display }}
              <span :class="[orderStatus.filter == filters.status ? 'bg-'+orderStatus.color+'-100 text-'+orderStatus.color+'-600' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{ showOrderStatusCounts(orderStatus.filter) }}</span>
            </a>
          </nav>
        </div>

        <TTable :headers="orderHeaders" :items="orders" :loading="loading" :allowBulk="false">
          <template v-slot:[`item.salesChannel`]="{ item }">
            <div class="flex items-center">
              <img :src="item.salesChannel.platform.logo" class="w-10"/>
              {{ item.salesChannel.name }}
            </div>
          </template>
          <template v-slot:[`item.placedAt`]="{ item }">
            <div class="items-center">
              {{ $filters.date.dateTimeFormat(item.placedAt) }}
            </div>
          </template>
          <template v-slot:[`item.purchaseOrderId`]="{ item }">
            <div class="flex space-x-1">
              <router-link :to="{name: 'dashboard.order.details', params: { orderId: item.id } }" class="inline-flex text-blue-600 hover:text-blue-900">
                {{ item.purchaseOrderId }}
              </router-link>
              <CopyButton class="w-4 h-4" :copy="item.purchaseOrderId" />
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="flex items-end">
              <OrderBadge :order="item" />
            </div>
          </template>
          <template v-slot:[`item.estimatedShipDate`]="{ item }">
            <div class="flex items-center space-x-2">  
              <span>{{ $filters.date.dateFormat(item.estimatedShipDate) }}</span>
              <TruckFastIcon class="h-5 w-5 text-green-500" v-tooltip="'Second Day Shipping'" v-if="item.secondDay"/>
            </div>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            {{ item.quantity }}
          </template>

          <template v-slot:[`item.country`]="{ item }">
            {{ item.shippingInfo.country }}
          </template>

          <template v-slot:[`item.total`]="{ item }">
            {{ $filters.currency.format(item.total, item.currency) }}
          </template>
          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
          </template>
        </TTable>
      </div>

    </div>
  </div>
</template>