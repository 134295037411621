<script>
  import SalesChannel from "@/models/SalesChannel";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        salesChannel: new SalesChannel(),
        errors: [],
        carriers: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.salesChannel.id != null
      },
      brandOptions(){
        let options = []
        this.$store.getters['brands/all'].forEach((item) => {
          options.push(item.name)
        })
        return options
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let salesChannel = new SalesChannel()
          _.assign(salesChannel, JSON.parse(JSON.stringify(preset)))
          this.salesChannel = salesChannel
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.salesChannel = new SalesChannel()
        this.errors = []
      },
      async updateSalesChannel(){
        try{
          await this.$repositories.salesChannels.update(this.salesChannel.id, this.salesChannel)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },

    }
  }
</script>



<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Ban or unban brands') }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="updateSalesChannel">
        {{ $t('Update') }}
      </Button>
    </template>

    <div class="rounded-md bg-yellow-50 p-4">
      <p class="text-sm text-yellow-700">
        The action of <strong>banning/unbanning</strong> a brand will lead to the <strong>retiring/publication</strong> of the listings associated with the sales channel <strong>{{ salesChannel.name }}</strong>!
      </p>
      <p class="text-sm font-semibold text-yellow-700">
        This operation may take longer depending on the number of listings and the response given by the sales channel.
      </p>
    </div>

    <div class="grid grid-cols-3 overflow-y-scroll h-96 p-2">
      <div v-for="(brand, index) in brandOptions" :key="index">
        <div class="relative flex items-start">
          <div class="flex items-center h-5">
            <input :id="'service_'+brand" name="brands[]" type="checkbox" v-model="salesChannel.bannedBrands" :value="brand" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="'service_'+brand" class="font-medium text-gray-700">{{ brand }}</label>
          </div>
        </div>
      </div>
    </div>
  </FormModal>
</template>