<script>
import { FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, TriangleExclamationIcon, MagnifyingGlassIcon, CheckCircleIcon } from '@snsicons/icons/solid'
import { XIcon } from '@heroicons/vue/24/outline'
import CopyButton from '@/components/UI/CopyButton.vue'
import ListingCompetitionButton from '@/components/Listing/ListingCompetitionButton.vue'
import ListingPriceSmallForm from '@/components/Listing/ListingPriceSmallForm.vue'
import ListingPublishModal from '@/components/Listing/ListingPublishModal.vue'
import ListingRetireModal from '@/components/Listing/ListingRetireModal.vue'
import ListingStatusBadge from '@/components/Listing/ListingStatusBadge.vue'
import ListingStrategySmallForm from '@/components/Listing/ListingStrategySmallForm.vue'

import ListingAdjustPricesModal from '@/components/Listing/ListingAdjustPricesModal.vue'
import ListingChangeStrategyModal from '@/components/Listing/ListingChangeStrategyModal.vue'

import _ from 'lodash'

export default {
  components: {
    CopyButton,
    ListingPriceSmallForm,
    ListingPublishModal,
    ListingRetireModal,
    ListingStatusBadge,
    ListingAdjustPricesModal,
    MagnifyingGlassIcon,
    TriangleExclamationIcon,
  },
  data() {
    return {
      listings: [],
      salesChannels: [],
      strategies: [],
      selectFiltered: false,
      searchCount: {},
      loading: false,
      selected: [],
      selectAll: false,
      listingErrors: [],
      listingHeaders: [
        {
          text: 'SKU',
          value: 'sku',
          sortable: 'sku'
        },
        {
          text: 'Listing Title',
          value: 'name',
          sortable: 'name'
        },
        {
          text: 'Price',
          value: 'price',
          sortable: 'price_cents'
        },
        {
          text: 'Inventory',
          value: 'quantity',
          sortable: 'quantity'
        },
        {
          text: 'Errors',
          value: 'errors',
          whitespace: 'normal'
        },
      ],
      filters: {
        term: '',
        brand: [],
        department: [],
        quantity: '',
        sales_channel: [],
        strategy: [],
        under_review: '',
        fulfillment_network: '',
        competition_price: '',
        price_below_cost: '',
        cost_presence: '',
        not_repricing_status: '',
        error_messages: '',
      },
      sortBy: {
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.listings.forEach(listing => {
          this.selected.push(listing.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue, oldValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchListings(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchListings(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchListings(1)
      },
      deep: true,
    },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.listings.length
    },
    filterOptions(){
      return [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'sales_channel',
          display: 'Sales Channel',
          options: this.salesChannelOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'strategy',
          display: 'Strategy',
          options: this.strategyOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'under_review',
          display: 'Under Review',
          type: 'single',
          options: this.underReviewOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'fulfillment_network',
          display: 'Fulfillment Network',
          type: 'single',
          options: this.fulfillmentNetworkOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'price_below_cost',
          display: 'Potential Profit Loss',
          type: 'single',
          options: this.potentialProfitLossOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'competition_price',
          display: 'Competition Price',
          type: 'single',
          options: this.competitionPriceOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'cost_presence',
          display: 'Cost',
          type: 'single',
          options: this.costPresenceOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'not_repricing_status',
          display: 'Reason For Not Repricing',
          type: 'single',
          options: this.notRepricingStatusOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'error_messages',
          display: 'Error',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
      ]
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With",
        code: 'with',
      })
      options.push({
        label: "Without",
        code: 'without',
      })
      return options
    },
    costPresenceOptions(){
      let options = []
      options.push({
        label: "present",
        code: 'present',
      })
      options.push({
        label: "not present",
        code: 'not_present',
      })
      return options
    },
    currentPriceOptions(){
      let options = []
      options.push({
        label: "At min price",
        code: 'at_min_price',
      })
      options.push({
        label: "At max price",
        code: 'at_max_price',
      })
      return options
    },
    notRepricingStatusOptions(){
      let options = []
      options.push({
        label: "No min price",
        code: 'no_min_price',
      })
      options.push({
        label: "Listing Suppressed",
        code: 'suppressed',
      })
      return options
    },
    competitionPriceOptions(){
      let options = []
      options.push({
        label: "Below My Min",
        code: 'below_min',
      })
      options.push({
        label: "Above My Max",
        code: 'above_max',
      })
      return options
    },
    fulfillmentNetworkOptions(){
      let options = []
      options.push({
        label: "MFN",
        code: 'MFN',
      })
      options.push({
        label: "FBA",
        code: 'FBA',
      })
      options.push({
        label: "WFS",
        code: 'WFS',
      })
      return options
    },
    underReviewOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      options.push({
        label: "false",
        code: 'false',
      })
      return options
    },
    potentialProfitLossOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      options.push({
        label: "false",
        code: 'false',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    salesChannelOptions(){
      let options = []
      this.salesChannels.forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    strategyOptions(){
      let options = []
      this.$store.getters['strategies/all'].forEach((strategy) => {
        options.push({
          label: strategy.name,
          code: strategy.id,
        })
      })
      return options
    },
  },
  beforeMount(){
    this.setFilters()
  },
  mounted(){
    const vm = this
    this.fetchListings(1)
    this.fetchSalesChannels()
    this.$store.dispatch('strategies/fetch')

    this.$eventBus.on('listing:created', () => {
      this.fetchListings(1)
    })

    this.$eventBus.on('listing:updated', () => {
      this.fetchListings(this.pagination.page)
    })
  },
  
  methods: {
    async fetchListings(page){
      this.loading = true
      this.listings = []
      try {
        let response = await this.$repositories.listings.get({...this.filters, ...this.pagination, ...this.sortBy, ...{page: page}, ...{status: ['unpublished', 'failed']}})
        this.listings = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.searchCount = response.pagination.searchCount
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchErrors(){
      try {
        let response = await this.$repositories.listings.errors()
        this.listingErrors = response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),

    openRetireModal(selected, selectFiltered){
      this.$refs.listingRetireModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openPublishModal(selected, selectFiltered){
      this.$refs.listingPublishModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openChangeStrategyModal(selected, selectFiltered){
      this.$refs.listingChangeStrategyModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openAdjustPriceModal(column, selected, selectFiltered){
      this.$refs.listingAdjustPricesModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
        column_name: column
      })
    },

    setFilters() {
      let params = this.$route.query;

      if (params.term) {
        this.filters.term = params.term
      }

      if (params.quantity) {
        this.filters.quantity = params.quantity
      }

      if (params.error_messages) {
        this.filters.error_messages = params.error_messages
      }

      if (params.under_review) {
        this.filters.under_review = params.under_review
      }

      if (params.current_price) {
        this.filters.current_price = params.current_price
      }

      if (params.fulfillment_network) {
        this.filters.fulfillment_network = params.fulfillment_network
      }

      if (params.price_below_cost) {
        this.filters.price_below_cost = params.price_below_cost
      }
      
      if (params.cost_presence) {
        this.filters.cost_presence = params.cost_presence
      }

      if (params.not_repricing_status) {
        this.filters.not_repricing_status = params.not_repricing_status
      }

      if (params.competition_price) {
        this.filters.competition_price = params.competition_price
      }

      if (params.department) {
        this.filters.department = params.department.split(",")
      }

      if (params.sales_channel) {
        this.filters.sales_channel = params.sales_channel.split(",")
      }

      if (params.brand) {
        this.filters.brand = params.brand.split(",")
      }

      if (params.parentage_type) {
        this.filters.parentage_type = params.parentage_type.split(",")
      }

      if (params.missing_on_channel) {
        this.filters.missing_on_channel = params.missing_on_channel.split(",")
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        this.filters.only_with_quantity = params.only_with_quantity
      }

      if (params.without_image) {
        this.filters.without_image = params.without_image
      }


      if (params.sort_by) {
        this.sortBy.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        this.sortBy.sort_direction = params.sort_direction
      }
    },
  }
}
</script>

<template>
  <div class="px-4">
    <div class="md:flex md:items-center md:justify-between pt-5 pb-10">
      <div class="flex-1 min-w-0">
        <h2 class="text-xl font-bold leading-7 sm:truncate">
          Listings to be fixed
        </h2>
        <p class="text-sm text-gray-700">
          Do you want to look for a specific error? See <router-link :to="{name: 'dashboard.listings.errors', query: $route.query}" class="text-blue-500 hover:text-blue-600 font-semibold">grouped errors</router-link>.
        </p>
      </div>
      <div class="mt-2 flex md:mt-0 md:ml-4 space-x-2">
        <ShareLink />
        <ExportButton resource="listings" :filters="{...filters, ...{status: ['unpublished', 'failed']}}" />
      </div>
    </div>
    <div class="w-full flex justify-between pb-5">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" :disabled="loading" class="pl-8" placeholder="Search...." />
        </div>
      </div>
    </div>

    <div class="w-full">
      <TTable :headers="listingHeaders" :items="listings" :loading="loading" :pagination="pagination" :sort-By="sortBy" style="height: 80vh;">
        <template #actions="{ selected, selectFiltered}">
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton as="template">
                <Button color="white" size="sm">
                  {{ $t('Change Prices') }}
                </Button>
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute top-10 mt-2 w-72 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a @click="openAdjustPriceModal('cost', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('Change Cost') }}
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click="openAdjustPriceModal('price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('Change Price') }}
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click="openAdjustPriceModal('min_price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('Change Min Price') }}
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click="openAdjustPriceModal('max_price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('Change Max Price') }}
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <Button color="green" size="sm" @click="openPublishModal(selected, selectFiltered)">
            Publish
          </Button>
          <Button color="red" size="sm" @click="openRetireModal(selected, selectFiltered)">
            Retire
          </Button>
        </template>
        <template v-slot:[`item.sku`]="{ item }">
          <div class="space-y-1">
            <div class="flex space-x-1 items-center w-56 ">
              <div class="text-sm text-gray-500 truncate">
                {{ item.sku }}
              </div>
              <CopyButton :copy="item.sku" class="w-3 h-3" />
            </div>
            <div class="flex space-x-2">
              <img :src="item.salesChannel.platform.logo" class="w-6" v-tooltip="item.salesChannel.name"/>
              <ListingFulfillmentBadge :listing="item" />
              <Badge color="gray" small class="flex items-center space-x-1" v-if="item.listingId">
                <span>{{ item.listingId }}</span>
                <CopyButton class="w-3 h-3" :copy="item.listingId" />
              </Badge>
            </div>
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <div class="truncate w-56 space-y-1">
            <router-link :to="{name: 'dashboard.listing.index', params: { listingId: item.id }}" target="_blank" class=" text-blue-600 hover:text-blue-400 text-left cursor-pointer" v-tooltip="item.name">
              {{ item.name }}
            </router-link>
            <div class="flex space-x-2 align-middle">
              <ListingStatusBadge :status="item.status" />
              <ListingSuppressedBadge v-if="(item.status == 'failed' || item.status == 'unpublished') && item.suppressed"/>
              <a :href="item.salesChannelUrl" target="_blank" class="text-blue-500 hover:text-blue-800 cursor-pointer pt-3" v-if="item.salesChannelUrl.length > 0" v-tooltip="'View listing on marketplace'">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
              </a>
              <TriangleExclamationIcon class="h-5 w-5 text-orange-500" v-tooltip="$t('This listing has {count} requests for review. Please assess them as soon as possible!', {count: item.reviewsCount})" v-if="item.reviewsCount > 0"/>
            </div>
          </div>
        </template>

        <template v-slot:[`item.price`]="{ item }">
          <ListingPriceSmallForm :listing="item" column="price"/>
        </template>

        <template v-slot:[`item.cost`]="{ item }">
          <ListingPriceSmallForm :listing="item" column="cost"/>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          <span class="text-gray-700 text-center">
            {{ item.quantity }}
          </span>
        </template>

        
        <template v-slot:[`item.errors`]="{ item }">
          <ul class="whitespace-normal">
            <li v-for="error in item.errors" :key="error">{{ error }}</li>
          </ul>
        </template>
        <template v-slot:pagination>
          <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
        </template>
      </TTable>
    </div>

    <ListingRetireModal ref="listingRetireModal" />
    <ListingPublishModal ref="listingPublishModal" />
    <ListingAdjustPricesModal ref="listingAdjustPricesModal" />
  </div>
</template>