<template>
<svg :style='svgStyle'>
  <text x='50%' y='50%' text-anchor='middle' dominant-baseline='central' :style='textStyle'>
    {{ avatarLetter }}
  </text>
</svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 40
    },
    rounded: {
      type: Boolean,
      default: false
    },
    username: {
      type: String,
      required: true
    }
  },
  computed: {
    avatarLetter() {
      let parts = this.username.split(/[ -]/)
      let initials = ''
      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0)
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }
      initials = initials.substr(0, 3).toUpperCase()
      return initials
    },
    svgStyle() {
      // use hsl to define the background color.
      const letterIndex = this.avatarLetter.charCodeAt() - 64;
      const hue = (360 / 26) * letterIndex;
      const backgroundColor = `hsl(${hue}, 68%, 48%)`;
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '100%' : '0',
        background: backgroundColor
      };
    },
    textStyle() {
      return {
        fill: 'rgba(255, 255, 255, .7)',
        fontFamily: "'Lucida Console', Monaco, monospace",
        fontSize: `${this.size * 0.4}px`
      };
    }
  }
};
</script>