import BaseModel from "@/models/BaseModel"
import Currency from '@/models/Currency'

export default class Rate extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.addOns = []
    this.cost = 0
    this.currency = new Currency()
    this.deliverDate = null
    this.deliverDays = ''
    this.logo = ''
    this.service_id = ''
    this.serviceName = ''
  }

  static fromJson(json){
    let rate = new this()

    rate.id = json.id
    rate.addOns = json.add_ons
    rate.cost = json.cost
    rate.currency = Currency.fromJson(json.currency)
    rate.deliverDays = json.deliver_days
    rate.deliverDate = json.deliver_date
    rate.logo = json.logo
    rate.serviceId = json.service_id
    rate.serviceName = json.service_name

    return rate
  }
}