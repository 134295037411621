<template>
  <div>
    <PageHeader :backTo="{name: 'dashboard.settings.index'}">
      <template v-slot:header>
        Billing
      </template>
    </PageHeader>
    <div class="font-sans antialiased min-h-screen sm:mx-2 md:mx-10 my-4">
      <div class="min-h-screen flex ">

        <div class="flex-1">
          <Loading v-model="loading" :is-full-page="true"></Loading>
          <a href="/" id="topNavReturnLink" class="lg:hidden flex items-center w-full px-4 py-4 bg-white shadow-lg">
            <svg viewBox="0 0 20 20" fill="currentColor" class="arrow-left w-4 h-4 text-gray-400">
              <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
            </svg>
            <div class="ml-2 text-gray-600 underline">
              Return to Sitnsell
            </div>
          </a>
          <div class="pb-6 pt-6 lg:pt-20 lg:pb-24 lg:max-w-4xl lg:mx-auto">
            <div>
              <h1 class="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                Subscribe
              </h1>
              <div class="mt-6 sm:px-8">
                <div class="mb-10" v-if="company.status != 'active'">
                  <div class="px-6 py-4 bg-gray-200 border border-gray-300 sm:rounded-lg shadow-sm mb-6">
                    <div class="max-w-2xl text-sm text-gray-600">
                      It looks like you do not have an active subscription. You may choose one of the subscription plans below to get started. Subscription plans may be changed or cancelled at your convenience.
                    </div>
                  </div>
                </div>
                <div class="mb-10" v-if="company.status == 'active'">
                  <div class="px-6 py-4 bg-gray-200 border border-gray-300 sm:rounded-lg shadow-sm mb-6">
                    <div class="max-w-2xl text-sm text-gray-600">
                      With <strong>2 weeks</strong> billing cycle, we charge your card <strong>twice a month</strong>, there will be 2 invoices for this period. See details below.
                      <span v-if="upcomingInvoice">The next invoice will be on <strong>{{ $filters.date.dateFormat(upcomingInvoice.billAt) }}</strong></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-y-6 mt-6 sm:px-8">
                <div class="bg-white sm:rounded-lg shadow-sm overflow-hidden">
                  <div>
                    <div class="flex justify-between">
                      <h2 class="px-6 pt-4 text-xl font-semibold text-gray-700">
                        Pay as you go
                      </h2>
                      <!---->
                    </div>
                    <div class="px-6 pb-4">
                      <!-- <div class="mt-2 text-md font-semibold text-gray-700">
                        <span>{{ company.feePercentage}}%</span> from GMV
                      </div> -->
                      <div class="mt-3 max-w-xl text-sm text-gray-600">
                        You have all the modules at your disposal so that you can take your business to the next level.
                      </div>
                      <div class="mt-3 space-y-2">
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Free Updates
                          </div>
                        </div>
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Unlimited Products
                          </div>
                        </div>
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Unlimited Warehouses
                          </div>
                        </div>
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Unlimited Sales Markets
                          </div>
                        </div>
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Unlimited Orders
                          </div>
                        </div>
                        <div class="flex items-center">
                          <svg viewBox="0 0 20 20" fill="currentColor" class="text-green-400 opacity-75 w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                          </svg>
                          <div class="ml-2 text-sm text-gray-600">
                            Repricing
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="px-6 py-4 bg-opacity-50 border-t border-gray-100 text-right" v-if="company.status == 'canceled'">
                    <button class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Subscribe
                    </button>
                  </div>
                  <div class="px-6 py-4 bg-gray-100 bg-opacity-50 border-t border-gray-100 text-right" v-else>
                    <button class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Cancel
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
            
            <div class="mt-10">
              <h1 class="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                Payment method
              </h1>
              <div class="mt-3 sm:px-8">
                <div class="bg-white sm:rounded-lg shadow-sm">
                  <div class="px-6 py-4 " v-if="company.cardInfo">
                    <div class="max-w-2xl text-md text-gray-600">
                      <span>Your current payment method is a <strong>{{ company.cardInfo.brand }} {{ company.cardInfo.funding }} card</strong> ending in <strong>{{ company.cardInfo.last4 }}</strong> that expires on <strong>{{ company.cardInfo.exp_month }}/{{ company.cardInfo.exp_year }}</strong></span>
                    </div>
                    <button @click="$refs.companyPaymentMethodFormModal.open(company)" class="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Update Payment Method
                    </button>
                  </div>
                  <div class="px-6 py-4 " v-else>
                    <div class="max-w-2xl text-md text-gray-600">
                      You have not added any payment methods yet. Please click the button below to add one.
                    </div>
                    <button @click="$refs.companyPaymentMethodFormModal.open(company)" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Add Payment Method
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-10">
              <h1 class="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                Billing Informations
              </h1>
              <div class="mt-3 sm:px-8">
                <div class="bg-white sm:rounded-lg shadow-sm">
                  <div class="px-6 py-4 ">
                    <div class="max-w-2xl text-md text-gray-600">
                      The information below will appear on each invoice issued from now on.
                    </div>
                    <dl class="sm:divide-y sm:divide-gray-200">
                      <div class="py-6 sm:py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Company Name
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {{ company.name }}
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Address
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {{ company.fullAddress }}
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Phone Number
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {{ company.phoneNumber }}
                        </dd>
                      </div>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          E-mail
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {{ company.email }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="px-6 py-4 bg-gray-100 bg-opacity-50 border-t border-gray-100 text-right">
                    <button @click="$refs.companyFormModal.open(company)" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <div class="mt-10">
              <h1 class="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                Invoice Email Addresses
              </h1>
              <div class="mt-3 sm:px-8">
                <div class="bg-white sm:rounded-lg shadow-sm">
                  <div class="px-6 py-4 ">
                    <div class="max-w-2xl text-sm text-gray-600">
                      We will send a invoice download link to the email addresses that you specify below. You may separate multiple email addresses using commas.
                    </div>
                    <div class="mt-6 flex">
                      <label for="receipt_emails" class="w-1/3 mr-10 mt-2 text-gray-800 text-sm font-semibold">Email Addresses</label> 
                      <Input type="text" v-model="company.invoiceEmail" id="receipt_emails" placeholder="Email Addresses" class="w-full bg-white border border-gray-200 px-3 py-2 rounded outline-none" />
                    </div>
                  </div>
                  <div class="px-6 py-4 mt-5 bg-gray-100 bg-opacity-50 border-t border-gray-100 text-right">
                    <button @click="saveInvoiceEmail" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none transition ease-in-out duration-150 bg-blue-700 hover:bg-blue-600">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-10">
              <h1 class="text-2xl font-semibold text-gray-700 px-4 sm:px-8">
                Invoices
              </h1>
              <div class="mt-3 sm:px-8">
                <div class="bg-white sm:rounded-lg shadow-sm divide-y divide-gray-100" v-for="invoice in invoices" :key="invoice.id">
                  <div class="flex items-center px-6 py-4 mb-2">
                    <div class="text-sm  w-40">
                      {{ $filters.date.dateFormat(invoice.billAt  ) }}
                    </div>
                    <div class="ml-10 text-sm w-40" v-if="invoice.status == 'upcoming'">
                      <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                        Upcoming invoice
                      </span>
                    </div>
                    <div class="ml-10 text-sm w-40" v-else>
                      <span>{{ invoice.amount }}</span>
                    </div>
                    <div class="ml-10 space-x-3">
                      <a :href="invoice.receipt.url" target="_blank" class="hover:underline text-sm text-gray-500" v-if="invoice.receipt">
                        Download Receipt
                      </a>
                      <a :href="invoice.report.url" target="_blank" class="hover:underline text-sm text-gray-500" v-if="invoice.report">
                        Download Report
                      </a>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <!---->
            
          </div>
        </div>
      </div>
      <CompanyPaymentFormModal ref="companyPaymentMethodFormModal"></CompanyPaymentFormModal>
      <CompanyFormModal ref="companyFormModal"></CompanyFormModal>
    </div>
  </div>
</template>

<script>
  import Company from '@/models/Company'
  import CompanyPaymentFormModal from '@/components/Company/CompanyPaymentFormModal.vue'
  import CompanyFormModal from '@/components/Company/CompanyFormModal.vue'
  import _ from 'lodash'

  export default {
    components: {
      CompanyPaymentFormModal,
      CompanyFormModal,
    },
    data(){
      return {
        company: new Company(),
        invoices: [],
        errors: [],
        loading: false,
      }
    },
    mounted(){
      let vm = this
      this.$store.dispatch('initialize')
      this.fetchBillingProfile()
      this.fetchInvoices()
      this.$eventBus.on('billing_profile:updated', function(){
        vm.fetchBillingProfile()
        vm.fetchInvoices()
      })
    },
    computed:{
      upcomingInvoice(){
        let invoices = this.invoices.filter(invoice => invoice.status == 'upcoming')
        if(invoices.length >= 2){
          return invoices[1]
        }
        return invoices[0]
      }
    },
    methods: {
      async fetchBillingProfile(){
        this.loading = true
        try{
          let response = await this.$repositories.billingProfile.current()
          this.company = response.data
        }catch(e) {
          console.log(e)
        }
        this.loading = false
      },
      async fetchInvoices(){
        this.loading = true
        try{
          let response = await this.$repositories.billingProfile.invoices()
          this.invoices = response.data
        }catch(e) {
          console.log(e)
        }
        this.loading = false
      },
      async saveInvoiceEmail(){
        this.loading = true
        try{
          await this.$repositories.billingProfile.update(this.company.id, this.company)
        }catch(error){
          this.errors = error.errors
        }
        this.loading = false
      },
    }
  }
</script>