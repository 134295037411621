<script>
import { FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, MagnifyingGlassIcon, TriangleExclamationIcon, CircleInfoIcon, ArrowLeftIcon } from '@snsicons/icons/solid'
import ProductsAdjustCostModal from '@/components/Product/ProductsAdjustCostModal'
import ProductsChangeBrandModal from '@/components/Product/ProductsChangeBrandModal'
import ProductsModifyDepartmentModal from '@/components/Product/ProductsModifyDepartmentModal'
import ProductFormModal from '@/components/Product/ProductFormModal'
import _ from 'lodash'
import ProductBulkListModal from '@/components/Product/ProductBulkListModal.vue'
import Listing from '@/models/Listing'

const BASE_FILTERS = {
  term: '',
  brand: [],
  category: [],
  quantity: 'with',
  missing_on_channel: '',
  exclude_brand: [],
  sort_by: null,
  sort_direction: null,
}

export default {
  components: {
    PencilIcon,
    EyeIcon,
    PlusIcon,
    MagnifyingGlassIcon,
    ProductsAdjustCostModal,
    ProductsChangeBrandModal,
    ProductsModifyDepartmentModal,
    ProductFormModal,
    ProductBulkListModal,
    TriangleExclamationIcon,
    CircleInfoIcon,
    ArrowLeftIcon
  },
  data() {
    return {
      products: [],
      salesChannels: [],
      selectFiltered: false,
      loading: false,
      selected: [],
      selectAll: false,
      loadingExport: false,
      listing: new Listing(),
      productHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: 'name',
        },
        {
          text: 'SKU',
          value: 'sku',
          sortable: 'sku'
        },
        {
          text: 'Barcode',
          value: 'barcode',
          sortable: 'barcode'
        },
        {
          text: 'Size/Color',
          value: 'size',
        },
        {
          text: 'Brand',
          value: 'brandId',
        },
        {
          text: 'Category',
          value: 'categoryId',
        },
        {
          text: 'Cost',
          value: 'cost',
          sortable: 'cost'
        },
        {
          text: 'Quantity',
          value: 'quantitySum',
          sortable: 'quantity'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      filters: BASE_FILTERS,
      sortBy: {
        sort_by: null,
        sort_direction: null,
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.products.forEach(product => {
          this.selected.push(product.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchProducts(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    'filters.missing_on_channel': function(newValue){
      if(newValue){
        // let channel = this.$store.getters['sales_channels/find'](this.filters.missing_on_channel)
        // if(channel){
        //   this.filters.exclude_brand = channel.bannedBrands
        // }
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchProducts(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchProducts(1)
      },
      deep: true,
    },
    // 'filters.department': function(value){
      
    // },
    // 'filters.brand': function(value){
    //   this.$router.push({path: this.$route.path,query: {...this.$route.query, brand: value.toString()}})
    //   this.fetchProducts()
    // },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.products.length
    },
    filterOptions(){
      return [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'category',
          display: 'Category',
          options: this.categoryOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'missing_on_channel',
          display: 'Not Listed On',
          type: 'single',
          options: this.salesChannelOptions,
          verb: '',
          group: 'Marketplace'
        },
        {
          name: 'without_image',
          display: 'Without Image',
          type: 'single',
          options: this.withoutImageOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'exclude_brand',
          display: 'Exclude Brands',
          type: 'alwaysHidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'sort_by',
          display: 'Sort',
          type: 'alwaysHidden',
          verb: 'by',
          group: 'Details'
        },
        {
          name: 'sort_direction',
          display: 'Sort direction',
          type: 'alwaysHidden',
          verb: '',
          group: 'Details'
        },
      ]
    },
    withoutImageOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      return options
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With",
        code: 'with',
      })
      options.push({
        label: "Without",
        code: 'without',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    salesChannelOptions(){
      let options = []
      this.$store.getters['sales_channels/all'].forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    selectedSalesChannel(){
      return this.$store.getters['sales_channels/find'](this.filters.missing_on_channel)
    },
    amazonSalesChannels(){
      return this.$store.getters['sales_channels/all'].filter((salesChannel) => salesChannel.isAmazon)
    }
  },
  beforeMount(){
    this.setFilters()
  },
  unmounted(){
    this.filters = BASE_FILTERS
  },
  mounted(){
    const vm = this
    this.fetchListing()
    this.fetchProducts(1)
    this.fetchSalesChannels()
    this.$store.dispatch('brands/fetch')
    this.$store.dispatch('categories/fetch')
  },
  
  methods: {
    async fetchListing(){
      try{
        const response = await this.$repositories.listings.find(this.$route.params.listingId)
        this.listing = response.data
      }catch(error){
        console.log(error)
      }
    },
    async updateInventorySource(productId){
      try{
        this.listing.productId = productId
        this.listing.skipValidateFieldsSchema = true
        const response = await this.$repositories.listings.update(this.listing.id, this.listing)
        this.$router.push({name: 'dashboard.listing.index', params: { listingId: this.$route.params.listingId }})
      }catch(error){
        console.log(error)
      }
    },
    async fetchProducts(page){
      this.loading = true
      this.products = []
      try {
        let response = await this.$repositories.products.get({...this.filters, ...this.pagination, ...this.sortBy, ...{page: page}})
        this.products = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async destroyProduct(id){
      try {
        await this.$repositories.products.destroy(id)
      } catch (error) {}
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
    deleteProduct(item){
      this.$refs.deleteConfirmationModal.open(
        this.$t("Delete {product} ?", {product: item.name}),
        this.$t("Deleting the product is an irreversible operation, and will lead to the deletion of the inventory and associated listings."),
        item.id,
      )
    },
    openChangeBrandModal(){
      this.$refs.productsChangeBrandModal.open({
        filters: this.selectFiltered ? this.filters : {...this.filters, ...{id: this.selected}}, 
        total: this.selectFiltered ? this.pagination.total : this.selected.length
      })
    },
    openModifyDepartmentModal(){
      this.$refs.productModifyDepartmentModal.open({
        filters: this.selectFiltered ? this.filters : {...this.filters, ...{id: this.selected}}, 
        total: this.selectFiltered ? this.pagination.total : this.selected.length
      })
    },
    openBulkListModal(selected, selectFiltered, salesChannel){
      this.$refs.productBulkListModal.open({
        salesChannel: salesChannel,
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length
      })
    },
    openCostAdjustmentModal(selected, selectFiltered){
      this.$refs.productsAdjustCostModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length
      })
    },
    setFilters() {
      let params = this.$route.query;
      let filters = _.cloneDeep(BASE_FILTERS)

      if (params.term) {
        filters.term = params.term
      }

      if (params.without_image) {
        filters.without_image = params.without_image
      }

      if (params.quantity) {
        filters.quantity = params.quantity
      }else{
        filters.quantity = 'with'
      }

      if (params.category) {
        filters.category = params.category.split(",")
      }

      if (params.brand) {
        filters.brand = params.brand.split(",")
      }

      if (params.missing_on_channel) {
        filters.missing_on_channel = params.missing_on_channel
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        this.filters.only_with_quantity = params.only_with_quantity
      }


      if (params.sort_by) {
        filters.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        filters.sort_direction = params.sort_direction
      }

      this.filters = filters
    },
  }
}
</script>

<template>
  <div class="px-4">
    <div class="md:flex md:items-center md:justify-between pt-5 pb-10">
      <div class="flex-1 min-w-0">
        <div class="flex items-center space-x-2">
          <div>
            <router-link :to="{name: 'dashboard.listing.index', params: { listingId: $route.params.listingId }}" class="flex space-x-1 text-sm items-center text-gray-500 hover:text-gray-700">
              <ArrowLeftIcon class="w-4 h-4" />
              <span>{{ $t('Back') }}</span>
            </router-link>
          </div>
          <h2 class="text-xl font-bold leading-7 sm:truncate">
            Change Inventory Source For {{ listing.name }}
          </h2>
        </div>
      </div>
      <div class="mt-4 flex items-center md:mt-0 md:ml-4 space-x-2">
        <ShareLink />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      <Card class="bg-white" v-if="listing.product != null">
        <CardBody class="px-4 py-4 space-y-2">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Listing
            </h3>
          </div>
          <div class="border-t border-gray-200 py-5">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Sales Channel
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.salesChannel.name }} ({{ listing.salesChannel.platform.name }})
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-medium text-gray-500">
                  Name
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.name }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  SKU
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.sku }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  ASIN/Walmart Item Id
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.listingId }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Brand
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.brand }}
                </dd>
              </div>
            </dl>
          </div>
        </CardBody>
      </Card>

      <Card class="bg-white" v-if="listing.product != null">
        <CardBody class="px-4 py-4 space-y-2">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Current Inventory Source
            </h3>
          </div>
          <div class="border-t border-gray-200 py-5">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Name
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.product.name }}
                </dd>
              </div>
              <div class="sm:col-span-1" v-if="$store.getters['brands/find'](listing.product.brandId)">
                <dt class="text-sm font-medium text-gray-500">
                  Brand
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ $store.getters['brands/find'](listing.product.brandId).name }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  SKU
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.product.sku }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Barcode
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.product.barcode }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Size
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ listing.product.size }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Cost
                </dt>
                <dd class="text-sm text-gray-900">
                  {{ $filters.currency.format(listing.product.cost, listing.product.currency) }}
                </dd>
              </div>
            </dl>
          </div>
        </CardBody>
      </Card>
    </div>
    
    <div class="w-full flex justify-between pb-5">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
        </div>
      </div>
    </div>

    <div class="w-full">
      <TTable :headers="productHeaders" :items="products" :loading="loading" :filters="filters" :pagination="pagination" :allowBulk="false">
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="{name: 'dashboard.product.index', params: {productId: item.id}}" class="flex items-center space-x-2 text-left text-blue-600 hover:text-blue-900 ml-4">
            <ProductPreviewImage :fileResourceId="item.fileResourceId" />
            <span class="w-56 truncate" v-tooltip="item.name">{{ item.name }}</span>
          </router-link>
        </template>
        <template v-slot:[`item.barcode`]="{ item }">
          {{ item.barcode }}
        </template>
        <template v-slot:[`item.size`]="{ item }">
          {{ item.size }}<br/>{{ item.color }}
        </template>
        <template v-slot:[`item.categoryId`]="{ item }">
          <span v-if="$store.getters['categories/find'](item.categoryId)">{{ $store.getters['categories/find'](item.categoryId).name }}</span>
          <span v-else>N/A</span>
        </template>
        <template v-slot:[`item.brandId`]="{ item }">
          <span v-if="$store.getters['brands/find'](item.brandId)">{{ $store.getters['brands/find'](item.brandId).name }}</span>
          <span v-else>N/A</span>
        </template>
        <template v-slot:[`item.cost`]="{ item }">
          {{ $filters.currency.format(item.cost, item.currency) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex items-center space-x-3">
            <Button size="sm" color="green" @click="updateInventorySource(item.id)">
              Change
            </Button>
          </div>
        </template>
        <template v-slot:pagination>
          <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
        </template>
      </TTable>
    </div>
    <ProductsAdjustCostModal ref="productsAdjustCostModal"/>
    <ProductsChangeBrandModal ref="productsChangeBrandModal" />
    <ProductsModifyDepartmentModal ref="productModifyDepartmentModal" />
    <ProductFormModal ref="productFormModal" />
    <DeleteConfirmationModal ref="deleteConfirmationModal" />
    <ProductBulkListModal ref="productBulkListModal" />
    
  </div>
</template>