import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import Company from "@/models/Company"
import BillingSubscription from "@/models/BillingSubscription"
import BillingInvoice from "@/models/BillingInvoice"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'billing_profiles'
const version = 'v1'
const platformModule = 'account'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, Company, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the company.'
      })
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Company, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the company!'
      })
    }
  },

  async current(){
    try{
      return await APIService.fetchEntity(`${platformModule}/${version}/${resource}/current`, Company)
    }catch(error){
      console.log(error)
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the billing profile!'
      })
    }
  },

  async subscriptions(){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}/subscriptions`, BillingSubscription, {})
    }catch(error){
      console.log(error)
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the billing profile!'
      })
    }
  },

  async invoices(){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}/invoices`, BillingInvoice, {})
    }catch(error){
      console.log(error)
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching invoices!'
      })
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${platformModule}/${version}/${resource}`, payload)
      eventBus.emit('billing_profile:created', response.data)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Brand was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return 
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create brand. Please verify form!'
      })

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${platformModule}/${version}/${resource}/${id}`, payload)
      eventBus.emit('billing_profile:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Billing profile was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update brand. Please verify form!'
      })

      throw error
    }
  },

}
