const roles = [
  {
    display_name: 'Owner',
    name: 'owner',
    description: 'This role is for people who need similar access as the account owner. This role can see and manage almost everything.'
  },
  {
    display_name: 'Manager',
    name: 'manager',
    description: 'This role is for people who need similar access as the account owner. This role can see and manage almost everything except billing.'
  },
  {
    display_name: 'Data Management',
    name: 'data_management',
    description: 'This role is for people who need to view, edit and delete records. This role can also export/import data and download reports.'
  },
]

export default roles
