import PackageTypesRepository from "@/repositories/PackageTypesRepository"

export default {
  async fetch(context){
    const response = await PackageTypesRepository.get({per: 10000, page: 1, sort_by: 'name', sort_direction: 'asc'})
    response.data.forEach((packageType) => {
      context.dispatch('addOrUpdate', packageType)
    })
  },
  addOrUpdate(context, packageType){
    let found = context.getters['find'](packageType.id)

    if(found){
      context.commit('update', packageType)
    }else{
      context.commit('add', packageType)
    }
  }
}