import { createStore } from 'vuex'
import { auth } from '@/store/auth.module.js'
import brands from '@/store/brands'
import categories from '@/store/categories'
import order_cancellation_reasons from '@/store/order_cancellation_reasons'
import package_types from '@/store/package_types'
import route from '@/store/route'
import strategies from '@/store/strategies'
import user_prefs from '@/store/user_prefs'
import notifications from '@/store/notifications'
import sales_channels from '@/store/sales_channels'

const store = createStore({
  modules: {
    auth,
    brands,
    categories,
    order_cancellation_reasons,
    package_types,
    route,
    strategies,
    user_prefs,
    notifications,
    sales_channels,
  },
  state: {
  },
  mutations: {
  },
  actions: {
    initialize: ({ state, dispatch, getters }) => {
      dispatch('auth/sync')
      dispatch('brands/fetch')
      dispatch('categories/fetch')
      dispatch('strategies/fetch')
      dispatch('sales_channels/fetch')
    }
  },
  
})

export default store
