import BaseModel from "@/models/BaseModel"

export default class History extends BaseModel{
  constructor(json) {
    super(json)

    this.title = ''
    this.author = ''
    this.action = ''
    this.createdAt = null
  }

  static fromJson(json){
    let history = new this()
    history.title = json.title
    history.author = json.author
    history.action = json.action
    history.createdAt = json.created_at
    return history
  }
}