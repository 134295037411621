import BaseModel from "@/models/BaseModel"

export default class State extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.code = ''
    this.bankName = ''
    this.bankBrach = ''
    this.bankAccount = ''
    this.gstVatNumber = ''

    this.name = ''
    this.street = ''
    this.street2 = null
    this.street3 = null
    this.city = ''
    this.stateOrProvince = ''
    this.country = ''
    this.postalCode = ''

    this.contactName = null
    this.contactEmail = null
    this.contactPhone = null

    this.createdBy = null
    this.updatedBy = null

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let supplier = new this()

    supplier.id = json.id
    supplier.bankName = json.bank_name
    supplier.bankBranch = json.bank_branch
    supplier.bankAccount = json.bank_account
    supplier.gstVatNumber = json.gst_vat_number

    supplier.code = json.code
    supplier.name = json.name
    supplier.street = json.street
    supplier.street2 = json.street2
    supplier.street3 = json.street3
    supplier.city = json.city
    supplier.stateOrProvince = json.state_or_province
    supplier.country = json.country
    supplier.postalCode = json.postal_code
    supplier.contactName = json.contact_name
    supplier.contactEmail = json.contact_email
    supplier.contactPhone = json.contact_phone

    supplier.createdBy = json.created_by
    supplier.updatedBy = json.updated_by
    supplier.createdAt = json.created_at
    supplier.updatedAt = json.updated_at

    return supplier
  }

  toJson(){
    return {
      supplier: {
        code: this.code,
        name: this.name,
        street: this.street,
        street2: this.street2,
        street3: this.street3,
        city: this.city,
        state_or_province: this.stateOrProvince,
        postal_code: this.postalCode,
        country: this.country,
        contact_name: this.contactName,
        contact_email: this.contactEmail,
        contact_phone: this.contactPhone,
        bank_name: this.bankName,
        bank_branch: this.bankBranch,
        bank_account: this.bankAccount,
        gst_vat_number: this.gstVatNumber
      }
    }
  }
}