<script>
  import Shelf from '@/models/Shelf'
  import { TriangleExclamationIcon, CircleQuestionIcon, ShelvesIcon } from '@snsicons/icons/solid'
  import { HouseIcon } from '@snsicons/icons/regular'
  export default {
    components: {
      TriangleExclamationIcon,
      CircleQuestionIcon,
      ShelvesIcon,
      HouseIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
        warehouse: null,
        shelf: new Shelf(),
        shelves: {},
        pastDueShelves: {},
        dueTodayShelves: {},
        needsAttentionShelves: {},
        secondDay: {},
        pickups: [],
        pickupHeaders: [
          {
            text: 'Product Name',
            value: 'productName',
          },
          {
            text: 'SKU',
            value: 'sku',
          },
          {
            text: 'Size',
            value: 'size',
          },
          {
            text: 'Barcode',
            value: 'barcode',
          },
          {
            text: 'Shelf',
            value: 'shelf',
          },
          {
            text: 'Quantity',
            value: 'quantity',
          },
        ]
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
      this.fetchShelves()
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    computed:{
      hasPastDueShelves(){
        return Object.keys(this.pastDueShelves).length > 0
      },
      hasDueTodayShelves(){
        return Object.keys(this.dueTodayShelves).length > 0
      },
      hasSecondDayShelves(){
        return Object.keys(this.secondDay).length > 0
      },
      hasPickups(){
        return Object.keys(this.shelves).length > 0
      },
      urgentPicks(){
        return this.pickups.filter(pick => pick.urgent == true)
      },
      nonUrgentPicks(){
        return this.pickups.filter(pick => pick.urgent == false)
      },
      secondDayQuantity(){
        return Object.values(this.secondDay).reduce((a, b) => a+b, 0)
      },
      needsAttentionQuantity(){
        return Object.values(this.needsAttentionShelves).reduce((a, b) => a+b, 0)
      },
      normalQuantity(){
        return Object.values(this.shelves).reduce((a, b) => a+b, 0)
      }
    },
    methods: {
      scan(event){
        console.log(event)
        if(event.code === 'Enter'){
          this.fetchShelf()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      async fetchShelves(){
        this.loading = true
        try{
          let response = await this.$repositories.pickups.shelves({warehouse_id: this.$store.getters['user_prefs/currentWarehouse']})
          this.shelves = response.data.normal
          this.needsAttentionShelves = response.data.needs_attention
          this.secondDay = response.data.second_day
        }catch(e){}
        this.barcode = ''
        this.loading = false
      },
      async fetchShelf(){
        this.loading = true
        try{
          let response = await this.$repositories.scannings.shelf({barcode: this.barcode, warehouse_id: this.$store.getters['user_prefs/currentWarehouse']})
          this.shelf = response.data
          this.$router.push({name: 'scanning.picklist.shelf', params: {shelfId: this.shelf.id}})
        }catch(e){
          
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
    }
  }
</script>
<template>

  <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
    <loading :active="loading" :is-full-page="true"></loading>
    <router-link :to="{name: 'scanning.home'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
      <HouseIcon class="h-5 text-blue-500" />
      <span>Go Home</span>
    </router-link>
    <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
      <span class="bg-purple-50 text-purple-500 rounded-lg inline-flex p-4 ring-4 ring-white">
        <ShelvesIcon class="h-5 w-5"/>
      </span>
      <div class="flex flex-col justify-start">
        <h3 class="font-semibold text-base">Pick orders</h3>
        <p class="text-sm text-gray-500">Scan the shelf barcode in order to continue.</p>
      </div>
    </div>

    <div class="py-4 border-b border-gray-200" v-if="hasSecondDayShelves">
      <div class="border-b border-gray-200 py-2">
        <h2 class="text-xl font-bold text-red-500 leading-7 sm:leading-9 sm:truncate">
          {{ $t('2nd Day Pick List') }} ({{ secondDayQuantity }})
        </h2>
        <p class="text-sm text-gray-500">The following shelves contain inventory that requires 2nd day shipping</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4">
        <div class="flex-1 flex flex-col md:items-center p-8 border border-transparent rounded-lg shadow" v-for="(quantity, shelfName) in secondDay" :key="shelfName">
          <h3 class="mt-1 text-gray-900 text-lg leading-5 font-semibold">{{ shelfName }}</h3>
          <p class="text-base text-gray-500">Urgent Qty: <span class="font-semibold">{{ quantity }}</span></p>
        </div>
      </div>
    </div>
    
    <div class="py-4 border-b border-gray-200" v-if="needsAttentionShelves">
      <div class="border-b border-gray-200 py-2">
        <h2 class="text-xl font-bold text-red-500 leading-7 sm:leading-9 sm:truncate">
          {{ $t('Require Attention') }} ({{ needsAttentionQuantity }})
        </h2>
        <p class="text-sm text-gray-500">The following shelves contain inventory that must be picked up and shipped as soon as possible.</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4">
        <div class="flex-1 flex flex-col md:items-center p-8 border border-transparent rounded-lg shadow" v-for="(quantity, shelfName) in needsAttentionShelves" :key="shelfName">
          <h3 class="mt-1 text-gray-900 text-lg leading-5 font-semibold">{{ shelfName }}</h3>
          <p class="text-base text-gray-500">Urgent Qty: <span class="font-semibold">{{ quantity }}</span></p>
        </div>
      </div>
    </div>

    <div class="py-4">
      <div class="border-b border-gray-200 py-2">
        <h2 class="text-xl font-bold text-gray-600 leading-7 sm:leading-9 sm:truncate">
          {{ $t('Pick List') }} ({{ normalQuantity }})
        </h2>
        <p class="text-sm text-gray-500">The following shelves contain all the inventory that needs to be picked up and shipped.</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4">
        <div class="flex-1 flex flex-col items-center p-8 border border-transparent rounded-lg shadow" v-for="(quantity, shelfName) in shelves" :key="shelfName">
          <h3 class="mt-1 text-gray-900 text-lg leading-5 font-semibold">{{ shelfName }}</h3>
          <p class="text-base text-gray-500">Quantity: <span class="font-semibold">{{ quantity }}</span></p>
        </div>
      </div>
    </div>
    
  </div>
</template>