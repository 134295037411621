export default {
  checkStorageTypeSupport: function(sessionStorage) {
    var type = (sessionStorage) ? 'sessionStorage' : 'localStorage';

    try {
      return type in window && window[type] !== null;
    } catch (e) {
      return false;
    }
  },

  set: function(name, value, days, session) {
    var storageType = (session) ? 'sessionStorage' : 'localStorage';

    if (this.checkStorageTypeSupport(session)) {
      window[storageType].setItem(name, value);
    }
    else {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }

      document.cookie = name + "=" + value + expires + "; path=/";
    }
  },

  get: function(name, session) {
    var storageType = (session) ? 'sessionStorage' : 'localStorage';

    if (this.checkStorageTypeSupport(session)) {
      return window[storageType].getItem(name);
    }

    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  },

  del: function(name, session) {
    var storageType = (session) ? 'sessionStorage' : 'localStorage';

    if (this.checkStorageTypeSupport(session)) {
      window[storageType].removeItem(name);
    } else {
      this.set(name, "", -1);
    }
  },

  // uses the set function but this stringify before saving
  setObject: function(name, value, days, session) {
    this.set(name, JSON.stringify(value), days, session);
  },

  // uses the get function but this parses the stringify value before returning
  getObject: function(name, session) {
    var value = this.get(name, session);
    return value && JSON.parse(value);
  }
};
