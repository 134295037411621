<template>
  <span v-tooltip="$filters.date.dateTimeFormat(modelValue)">
    {{ $filters.date.dateFormat(modelValue) }}
  </span>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
    }
  },
  
}
</script>