import ServerError from "@/repositories/Response/ServerError"
import { has, get } from 'lodash'

export default class ValidationErrors extends ServerError {
  constructor (errors) {
    super()

    this.errors = errors
  }

  has (error) {
    return has(this.errors, error)
  }

  first (error) {
    return get(this.errors, error)[0]
  }

  clearAll () {
    this.errors = null
  }
}