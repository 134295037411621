<script>
import { FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, TriangleExclamationIcon, MagnifyingGlassIcon, CircleCheckIcon } from '@snsicons/icons/solid'
import { XIcon } from '@heroicons/vue/24/outline'
import CopyButton from '@/components/UI/CopyButton.vue'
import ListingCompetitionButton from '@/components/Listing/ListingCompetitionButton.vue'
import ListingPriceSmallForm from '@/components/Listing/ListingPriceSmallForm.vue'
import ListingPublishModal from '@/components/Listing/ListingPublishModal.vue'
import ListingRetireModal from '@/components/Listing/ListingRetireModal.vue'
import ListingStatusBadge from '@/components/Listing/ListingStatusBadge.vue'
import ListingStrategySmallForm from '@/components/Listing/ListingStrategySmallForm.vue'

import ListingAdjustPricesModal from '@/components/Listing/ListingAdjustPricesModal.vue'
import ListingChangeStrategyModal from '@/components/Listing/ListingChangeStrategyModal.vue'

import _ from 'lodash'
import ReviewFormModal from '@/components/Review/ReviewFormModal.vue'

export default {
  components: {
    CopyButton,
    ListingPriceSmallForm,
    ListingStatusBadge,
    MagnifyingGlassIcon,
    TriangleExclamationIcon,
    CircleCheckIcon,
    ReviewFormModal
  },
  data() {
    return {
      listings: [],
      salesChannels: [],
      strategies: [],
      selectFiltered: false,
      searchCount: {},
      loading: false,
      selected: [],
      selectAll: false,
      listingErrors: [],
      users: [],
      listingHeaders: [
        {
          text: 'Item',
          value: 'item',
        },
        
        {
          text: 'Qty.',
          value: 'quantity',
          sortable: 'quantity'
        },
        {
          text: 'By',
          value: 'initiated_by',
        },
        {
          text: 'To',
          value: 'assigned_to',
        },
        {
          text: 'Reason',
          value: 'reason',
          whitespace: 'normal'
        },
        {
          text: 'Resolution',
          value: 'resolution',
          whitespace: 'normal'
        },
        {
          text: 'Completed At',
          value: 'completedAt',
          whitespace: 'normal',
          sortable: 'completed_at'
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      filters: {
        term: '',
        brand: [],
        department: [],
        quantity: '',
        sales_channel: [],
        fulfillment_network: '',
        assigned_to: ['Me'],
        initiated_by: [],
      },
      sortBy: {
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.listings.forEach(listing => {
          this.selected.push(listing.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue, oldValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchReviews(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchReviews(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchReviews(1)
      },
      deep: true,
    },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.listings.length
    },
    filterOptions(){
      return [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'sales_channel',
          display: 'Sales Channel',
          options: this.salesChannelOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'assigned_to',
          display: 'Assigned To',
          options: this.userOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'initiated_by',
          display: 'Initiated By',
          options: this.userOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'fulfillment_network',
          display: 'Fulfillment Network',
          type: 'single',
          options: this.fulfillmentNetworkOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },

      ]
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With",
        code: 'with',
      })
      options.push({
        label: "Without",
        code: 'without',
      })
      return options
    },
    fulfillmentNetworkOptions(){
      let options = []
      options.push({
        label: "MFN",
        code: 'MFN',
      })
      options.push({
        label: "FBA",
        code: 'FBA',
      })
      options.push({
        label: "WFS",
        code: 'WFS',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.name,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    salesChannelOptions(){
      let options = []
      this.salesChannels.forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    userOptions(){
      let options = []
      this.users.forEach((user) => {
        options.push({
          label: this.$store.getters['auth/user'].fullname == user.fullname ? 'Me' : user.fullname,
          code: this.$store.getters['auth/user'].fullname == user.fullname ? 'Me' : user.fullname,
        })
      })
      return options
    },
  },
  beforeMount(){
    this.setFilters()
    this.fetchUsers()
  },
  mounted(){
    const vm = this
    this.fetchReviews(1)
    this.fetchSalesChannels()
    this.$store.dispatch('strategies/fetch')

    this.$eventBus.on('listing:created', () => {
      this.fetchReviews(1)
    })

    this.$eventBus.on('review:updated', () => {
      this.fetchReviews(1)
    })
  },
  
  methods: {
    async fetchReviews(page){
      this.loading = true
      this.listings = []
      try {
        let response = await this.$repositories.reviews.get({...this.filters, ...this.pagination, ...this.sortBy, ...{page: page}, ...{include_listing: 'true'}})
        this.listings = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.searchCount = response.pagination.searchCount
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUsers(){
      try {
        let response = await this.$repositories.users.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.users = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchErrors(){
      try {
        let response = await this.$repositories.listings.errors()
        this.listingErrors = response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),

    setFilters() {
      let params = this.$route.query;

      if (params.term) {
        this.filters.term = params.term
      }

      if (params.quantity) {
        this.filters.quantity = params.quantity
      }

      if (params.fulfillment_network) {
        this.filters.fulfillment_network = params.fulfillment_network
      }

      if (params.price_below_cost) {
        this.filters.price_below_cost = params.price_below_cost
      }

      if (params.department) {
        this.filters.department = params.department.split(",")
      }

      if (params.assigned_to) {
        this.filters.assigned_to = params.assigned_to.split(",")
      }

      if (params.initiated_by) {
        this.filters.initiated_by = params.initiated_by.split(",")
      }

      if (params.sales_channel) {
        this.filters.sales_channel = params.sales_channel.split(",")
      }

      if (params.brand) {
        this.filters.brand = params.brand.split(",")
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.sort_by) {
        this.sortBy.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        this.sortBy.sort_direction = params.sort_direction
      }
    },
  }
}
</script>

<template>
  <div class="px-4">
    <div class="md:flex md:items-center md:justify-between pt-5 pb-10">
      <div class="flex-1 min-w-0">
        <h2 class="text-xl font-bold leading-7 sm:truncate">
          Reviews
        </h2>
        <p class="text-sm text-gray-700">
          
        </p>
      </div>
      <div class="mt-2 flex md:mt-0 md:ml-4 space-x-2">
        <ShareLink />
        <ExportButton resource="listings" :filters="{...filters, ...{status: ['unpublished', 'failed']}}" />
      </div>
    </div>
    <div class="w-full flex justify-between pb-5">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" :disabled="loading" class="pl-8" placeholder="Search...." />
        </div>
      </div>
    </div>

    <div class="w-full">
      <TTable :headers="listingHeaders" :items="listings" :loading="loading" :pagination="pagination" :sort-By="sortBy" :allowBulk="false" style="height: 80vh;">
        <template v-slot:[`item.item`]="{ item }">
          <div class="flex flex-col space-y-2 px-4">
            <div class="truncate w-96">
              <router-link :to="{name: 'dashboard.listing.index', params: { listingId: item.listingId }}" class=" text-blue-600 hover:text-blue-400 text-left cursor-pointer" v-tooltip="item.name">
                {{ item.listing.name }}
              </router-link>
            </div>
            <div class="flex space-x-2 align-middle">
              <div class="flex space-x-2">
                <img :src="item.listing.salesChannel.platform.logo" class="w-6" v-tooltip="item.listing.salesChannel.name"/>
                <ListingFulfillmentBadge :listing="item.listing" />
                <ListingStatusBadge :status="item.listing.status" />
                <Badge color="gray" small class="flex items-center space-x-1" v-if="item.listing.listingId">
                  <span>{{ item.listing.listingId }}</span>
                  <CopyButton class="w-4 h-4" :copy="item.listing.listingId" />
                </Badge>
              </div>
              <div class="flex space-x-1 items-center w-48 ">
                <div class="text-sm text-gray-500 truncate">
                  {{ item.listing.sku }}
                </div>
                <CopyButton :copy="item.listing.sku" class="w-3 h-3" />
              </div>
            </div>
          </div>
        </template>

        <template v-slot:[`item.price`]="{ item }">
          <ListingPriceSmallForm :listing="item" column="price"/>
        </template>

        <template v-slot:[`item.initiated_by`]="{ item }">
          {{ item.initiatedBy }}
        </template>

        <template v-slot:[`item.assigned_to`]="{ item }">
          {{ item.assignedTo }}
        </template>

        <template v-slot:[`item.reason`]="{ item }">
          {{ item.reason }}
        </template>

        <template v-slot:[`item.resolution`]="{ item }">
          {{ item.completeDetails }}
        </template>

        <template v-slot:[`item.completedAt`]="{ item }">
          <div class="text-gray-700 text-center" v-if="item.completedAt > 0">
            {{ $filters.date.dateTimeFormat(item.completedAt) }}
          </div>
          <p class="text-gray-700 text-center" v-else>
            On going for 
            {{ ' ' }}
            <time :datetime="$filters.date.timeAgo(item.createdAt)">{{ $filters.date.timeAgo(item.createdAt) }}</time>
          </p>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          <p class="text-gray-700 text-center" v-if="item.listing">
            {{ item.listing.quantity }}
          </p>
        </template>

  
        
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex items-center space-x-3" v-if="item.completedAt == 0">
            <a class="text-green-500 hover:text-green-700" @click="$refs.reviewFormModal.open(item)" v-tooltip="'Mark as Finished'">
              <CircleCheckIcon class="w-5 h-5"/>
            </a>
          </div>
        </template>

        <template v-slot:pagination>
          <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
        </template>
      </TTable>
    </div>

    <ReviewFormModal ref="reviewFormModal" />
  </div>
</template>