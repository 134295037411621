<template>
  <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center" @click="router.push({name: 'scanning.home'})">
            <img class="block h-8 w-auto lg:hidden" src="/assets/logo_normal_full.svg" alt="Your Company" />
            <img class="hidden h-8 w-auto lg:block" src="/assets/logo_normal_full.svg" alt="Your Company" />
          </div>
        </div>
        
       
        <div class="-mr-2 flex items-center">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
            <span class="sr-only">Open main menu</span>
            <BarsIcon v-if="!open" class="block h-6 w-6 text-gray-400" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6 text-gray-400" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="">
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <UserAvatar class="mx-auto" :size="20"/>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ store.getters['auth/user'].fullname }}</div>
            <div class="text-sm font-medium text-gray-500">Warehouse {{ warehouse.name }}</div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton as="a" href="#" class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
            <a @click="eventBus.emit('warehouse:select')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
              Change Warehouse
            </a>
          </DisclosureButton>
          <DisclosureButton as="a" href="#" class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
            <router-link :to="{name: 'dashboard.home'}" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
              Exit
            </router-link>
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BarsIcon, XIcon } from '@snsicons/icons/regular'

import { onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Warehouse from '@/models/Warehouse'

const $repositories = inject('$repositories')
const eventBus = inject('eventBus')
const store = useStore()
const router = useRouter()

let warehouse = ref(new Warehouse())

const fetchWarehouse = async () => {
  try{
    let response = await $repositories.warehouses.find(store.getters['user_prefs/currentWarehouse'])
    warehouse.value = response.data
  }catch(error){
    eventBus.emit('warehouse:select')
  }
}

onMounted(() => {

  if(store.getters['user_prefs/currentWarehouse']){
    fetchWarehouse()
  }

  eventBus.on('warehouse:selected', () => {
    fetchWarehouse()
  })
})
</script>