<script>
  import { CircleQuestionIcon, XmarkIcon } from '@snsicons/icons/solid'
  import Pull from "@/models/Pull"
  import Pickup from "@/models/Pickup"
  import _ from 'lodash'

  export default {
    components: {
      CircleQuestionIcon,
      XmarkIcon
    },
    data(){
      return {
        opened: false,
        pull: new Pull(),
        pickup: new Pickup(),
        errors: {},
      }
    },
    computed: {
      quantityOptions(){
        let options = []

        for(var i = 1; i <= this.pickup.quantity; i++){
          options.push({
            label: ''+i+'',
            code: i,
          })
        }

        return options
      },
      canSubmit(){
        return this.pull.quantity > 0
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let pickup = new Pickup()
          _.assign(pickup, JSON.parse(JSON.stringify(preset)))
          this.pickup = pickup
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.pull = new Pull()
        this.pickup = new Pickup()
        this.errors = {}
      },

      async storePull(){
        try{
          this.pull.pullMethod = 'manual'
          this.pull.pickupId = this.pickup.id
          await this.$repositories.pulls.create(this.pull)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
    }
  }
</script>

<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-1000" @close="close()">
      <div class="absolute inset-0 overflow-hidden">

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-blue-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        {{ $t('Pull a pickup manually') }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="rounded-md text-white hover:text-white" @click="close()">
                          <span class="sr-only">Close panel</span>
                          <XmarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        {{ $t('You can manually pull a pick from the shelf.') }}
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <Label name="quantity" :errors="errors" required>
                            {{ $t('Quantity to be pulled from shelf {shelf}', { shelf: pickup.id ? pickup.inventory.shelf.name : '' }) }}
                          </Label>
                          <Select :items="quantityOptions" v-model="pull.quantity" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
                          <FormValidation :errors="errors" name="quantity" />
                          <p class="text-sm text-red-500" v-if="pull.quantity == 0">
                            {{ $t('In order to save this operation, the quantity must be greater than 0.') }}
                          </p>
                        </div>
                      </div>
                      <div class="pt-4 pb-6">
                        <div class="text-sm">
                          <a href="#" class="group inline-flex items-start space-x-1 text-gray-500 hover:text-gray-900">
                            <CircleQuestionIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            <p>
                              {{ $t('This pull will be recored as being made by {user}.', { user: $store.getters['auth/user'].fullname }) }}
                            </p>
                          </a>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2">
                  
                  <Button color="white" @click="close">
                    {{ $t('Close') }}
                  </Button>
                  <Button color="blue" @click="storePull" :disabled="!canSubmit">
                    {{ $t('Save') }}
                  </Button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>