import { eventBus } from '@/composables/eventBus'
import History from '@/models/History'
import OrderLine from '@/models/OrderLine'
import ValidationError from '@/repositories/Response/ValidationError'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import APIService from '@/repositories/APIService'

const version = 'v1'
const resource = 'order_lines'

export default {

  async get(payload){
    try{
      return await APIService.fetchEntities(`oms/${version}/${resource}`, OrderLine, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the order lines'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`oms/${version}/${resource}/${id}`, OrderLine, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the order line!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`oms/${version}/${resource}`, payload)
      eventBus.emit('order_line:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order line was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }

      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create order. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`oms/${version}/${resource}/${id}`, payload)
      eventBus.emit('order_line:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order line was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not update order line. Please verify form!'
      })
      throw error
    }
  },

  async destroy(id, payload = {}){
    try{
      let response = await APIService.delete(`oms/${version}/${resource}/${id}`, payload)
      eventBus.emit('order_line:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order line was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete order line. Please try again later!'
      })
      throw error
    }
  },

  async history(id){
    try{
      return await APIService.fetchEntities(`oms/${version}/${resource}/${id}`, History)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the order lines'
      })
      throw error
    }
  },

  // async cancel(id, payload){
  //   try{
  //     let response = await APIService.postEntity(`oms/${version}/${resource}/${id}/cancel`, payload)
  //     eventBus.emit('order_line:created')
  //     eventBus.emit('app:message', {
  //       type: 'success',
  //       message: 'Order line was canceled with success!'
  //     })

  //     return response
  //   }catch(error){
  //     if(error instanceof ValidationError){
  //       throw error
  //     }
  //     if(error instanceof CanceledRequest){
  //       return
  //     }

  //     eventBus.emit('app:message', {
  //       type: 'error',
  //       message: 'Could not cancel order. Please verify form!'
  //     })
  //     throw error
  //   }
  // },

  // async print(id){
  //   try{
  //     let response = await APIService.postEntity(`oms/${version}/${resource}/${id}/print`)
  //     eventBus.emit('order_line:created')
  //     eventBus.emit('app:message', {
  //       type: 'success',
  //       message: 'Order line was printed with success!'
  //     })

  //     return response
  //   }catch(error){
  //     if(error instanceof ValidationError){
  //       throw error
  //     }
  //     if(error instanceof CanceledRequest){
  //       return
  //     }

  //     eventBus.emit('app:message', {
  //       type: 'error',
  //       message: 'Could not print order!'
  //     })
  //     throw error
  //   }
  // },

}
