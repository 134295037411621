<script>
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        name: '',
        platformId: '',
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    methods: {
      open(platformId) {
        try{
          this.platformId = platformId
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.name = ''
        this.platformId = ''
      },
      async connect(){
        this.savingForm = true
        try{
          let response = await this.$repositories.salesChannels.consentUrl("ebay", { platform_id: this.platformId, name: this.name})
          window.location.replace(response.data.url)
        }catch(e){
          console.log(e)
        }finally{
          this.savingForm = false
        }
      },

    }
  }
</script>



<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Connect New Ebay Account') }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="connect" :disabled="name.length == 0">
        {{ $t('Connect') }}
      </Button>
    </template>

    <div class="rounded-md bg-yellow-50 p-4">
      <p class="text-sm text-yellow-700">
        To link a new eBay account, we kindly request you to provide a name for your account. We recommend selecting a name that is memorable to you or can be associated with the account. Please note that you can modify this name at a later time.
      </p>
    </div>

    <div class="grid grid-cols-2 gap-3">
      <div>
        <Label name="name" required :errors="errors">{{ $t('Account Name') }}</Label>
        <InputText v-model="name" name="name" class="w-full" :errors="errors"/>
      </div>
    </div>
  </FormModal>
</template>