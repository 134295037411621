import BaseModel from "@/models/BaseModel"
import Warehouse from "@/models/Warehouse"

export default class OrderLineConfirmation extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    
    this.orderLineId = null
    this.author = null
    this.quantity = null
    this.warehouseId = null
    this.warehouse = null
    this.confirmationMethod = 'scan'

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let orderLineConfrimation = new this()

    orderLineConfrimation.id = json.id
    orderLineConfrimation.orderLineId = json.order_line_id
    orderLineConfrimation.author = json.author
    orderLineConfrimation.quantity = json.quantity
    orderLineConfrimation.warehouseId = json.warehouse_id
    orderLineConfrimation.warehouse = this.initRelation(json.warehouse, Warehouse, null)
    orderLineConfrimation.createdAt = json.created_at
    orderLineConfrimation.updatedAt = json.updated_at

    return orderLineConfrimation
  }

  toJson(){
    return {
      order_line_confirmation: {
        order_line_id: this.orderLineId,
        quantity: this.quantity,
        warehouse_id: this.warehouseId,
      }
    }
  }
}