<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";

export default{
  name: 'Pie',
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {

    }
  },
  watch: {
    chartData: {
      handler: function(){
        this.root.dispose()
        this.initializeChart()
      },
      deep: true
    }
  },
  mounted(){
    this.initializeChart()
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose()
    }
  },
  methods: {
    initializeChart(){
      let root = am5.Root.new(this.$refs.chart);
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Responsive.new(root)
      ]);

      let chart = root.container.children.push(
        am5xy.new(root, {
          panX: false,
          panY: false,
        }) 
      );

      let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      }));

      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
        })
      );
      series.data.setAll(this.chartData);

      this.root = root
    }
  }
}

</script>
<template>
  <div class="w-full h-64 my-4" ref="chart"></div>
</template>