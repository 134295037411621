<template>
  <Menu v-slot="{ open }" as="div" class="relative inline-block text-left">
    <MenuButton class="inline-flex items-center justify-center space-x-1 w-full border border-gray-300 rounded-lg shadow-sm px-4 py-2 bg-transparent text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
      <AdjustmentsHorizontalIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
      <span>{{ $t('Add Filter') }}</span>
    </MenuButton>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems v-show="open" class="origin-top-right absolute left-0 mt-2 w-72 min-w-72 border-1 border-gray-300 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20 focus:outline-none">
        <div v-if="selected">
          <nav class="flex items-start px-4 py-5 sm:px-6 lg:px-8 border-b shadow border-gray-300">
            <a href="#" class="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <ChevronLeftIcon @click="selected = null" class="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>{{ selectedFilter.display }}</span>
            </a>
          </nav>
          <ul role="list" class="max-h-72 overflow-y-scroll" v-if="isMultiple">
            <li v-for="option in selectedFilter.options" :key="option.code" class="py-4 px-3 hover:bg-blue-50 cursor-pointer">
              <div class="flex space-x-4">
                <input :id="option.code" :value="option.code" v-model="localFilters[selectedFilter.name]" :name="selectedFilter.name" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
                <label :for="option.code" :class="[localFilters[selectedFilter.name].includes(option.code) ? 'font-bold text-blue-700' : 'text-gray-700', 'cursor-pointer text-sm font-medium truncate']">
                  {{ option.label }}
                </label>
              </div>
            </li>
          </ul>
          <ul role="list" class="max-h-72 overflow-y-scroll" v-if="isSingle">
            <li v-for="option in selectedFilter.options" :key="option.code" class="py-4 px-3 hover:bg-blue-50 cursor-pointer">
              <div class="flex space-x-4">
                <input :id="selectedFilter.name" :value="option.code" v-model="localFilters[selectedFilter.name]" :name="selectedFilter.name" type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
                <a :for="option.code" @click="setSingleFilter(selectedFilter.name, option.code)" :class="[localFilters[selectedFilter.name] == option.code ? 'font-bold text-blue-700' : 'text-gray-700', 'cursor-pointer text-sm font-medium  truncate']">
                  {{ option.label }}
                </a>
              </div>
            </li>
          </ul>
          <ul role="list" class="max-h-72 overflow-y-scroll" v-if="isBoolean">
            <li v-for="option in selectedFilter.options" :key="option.code" class="py-4 px-3 hover:bg-blue-50 cursor-pointer">
              <div class="flex space-x-4">
                <a :for="option.code" @click="setSingleFilter(selectedFilter.name, option.code)" :class="[localFilters[selectedFilter.name] == option.code ? 'font-bold text-blue-700' : 'text-gray-700', 'cursor-pointer text-sm font-medium  truncate']">
                  {{ option.label }}
                </a>
              </div>
            </li>
          </ul>
          <div role="list" class="max-h-72 overflow-y-scroll" v-if="isNumberRange">
            <div class="px-2 pt-2 pb-4 flex space-x-2">
              <div>
                <label for="start" class="block text-sm font-medium leading-6 text-gray-900">Start</label>
                <InputText v-model="localFilters[selectedFilter.name][0]" name="start" class="w-full" placeholder="Any" />
              </div>
              <div>
                <label for="end" class="block text-sm font-medium leading-6 text-gray-900">End</label>
                <InputText v-model="localFilters[selectedFilter.name][1]" name="end" class="w-full" placeholder="Any" />
              </div>
            </div>
          </div>
          <div role="list" class="max-h-72 overflow-y-scroll" v-if="isDateRange">
            <div class="px-2 pt-2 pb-4">
              <Datepicker v-model="localFilters[selectedFilter.name]" range format="dd-MM-yyyy" :enable-time-picker="false"/>
            </div>
          </div>
        </div>
        <TabGroup v-else>
          <TabList as="div" class="border-b border-gray-200 -mb-px flex" >
            <Tab v-for="group in filterGroups" :key="group" as="template" v-slot="{ selected }">
              <a :class="[selected ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm']">
                 {{ group }}
              </a>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel v-for="group in filterGroups" :key="group">
              <ul role="list">
                <li @click="selected = filterOption.name" v-for="filterOption in groupedOptions(group)" :key="filterOption.name" class="py-5 px-3 hover:bg-blue-50 cursor-pointer" :class="filterOption.type == 'alwaysHidden' ? 'hidden' : ''">
                  <div class="space-x-4 ">
                    <p :class="[filterOption.type == 'multiple' && localFilters[filterOption.name].length > 0 ? 'font-bold text-blue-700' : filterOption.type == 'number-range' && (localFilters[filterOption.name][0] || localFilters[filterOption.name][1]) ? 'font-bold text-blue-700' : filterOption.type == 'date-range' && localFilters[filterOption.name].length > 0 ? 'font-bold text-blue-700' : (filterOption.type == 'single' || filterOption.type == 'hidden' || filterOption.type == 'alwaysHidden') && localFilters[filterOption.name] ? 'font-bold text-blue-700' : 'text-gray-700', 'text-sm font-medium  truncate']">
                      {{ filterOption.display }}
                    </p>
                  </div>
                </li>
              </ul>
            </TabPanel>
            <TabPanel>

            </TabPanel>
          </TabPanels>
        </TabGroup>
        <MenuItem>
          <button @click="applyFilters" type="button" class="w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-b-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none">
            Apply Filters
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script>
import _ from 'lodash'
import { ChevronLeftIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/24/solid'
export default {
  components: {
    ChevronLeftIcon,
    AdjustmentsHorizontalIcon
  },
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['update:filters'],
  data(){
    return {
      selected: null,
      localFilters: {},
    }
  },
  mounted(){
    this.localFilters = JSON.parse(JSON.stringify(this.filters))
  },
  watch:{
    filters: {
      handler(value){
        this.localFilters = JSON.parse(JSON.stringify(value))
      },
      deep: true 
    }
  },

  computed:{
    filterGroups(){
      return _.uniq(_.map(this.filterOptions.filter(item => item.type != 'hidden'), 'group'))
    },
    selectedFilter(){
      return _.find(this.filterOptions, filter => filter.name == this.selected && filter.type != 'hidden')
    },
    selectedFilterOptions(){
      return _.sortBy(this.selectedFilter.options, option => this.filters[this.selectedFilter.name])
    },
    isBoolean(){
      return this.selectedFilter && this.selectedFilter.type == 'boolean'
    },
    isMultiple(){
      return this.selectedFilter && this.selectedFilter.type == 'multiple'
    },
    isSingle(){
      return this.selectedFilter && this.selectedFilter.type == 'single'
    },
    isNumberRange(){
      return this.selectedFilter && this.selectedFilter.type == 'number-range'
    },
    isDateRange(){
      return this.selectedFilter && this.selectedFilter.type == 'date-range'
    }
  },

  methods: {
    groupedOptions(group){
      return this.filterOptions.filter(item => item.type != 'hidden' && item.group == group)
    },
    applyFilters(){
      this.$emit('update:filters', this.localFilters)
    },
    setSingleFilter(filterName, value){
      this.localFilters[filterName] = value
    },
    setFilters() {
      let params = this.$route.query;

      this.filterOptions.forEach(filterOption => {
        if(params[filterOption.name]){
          if(filterOption.type == 'multiple'){
            this.localFilters[filterOption.name] = params[filterOption.name].split(",")
          }else if(filterOption.type == 'number-range'){
            this.localFilters[filterOption.name] = params[filterOption.name].split(",")
          }else if(filterOption.type == 'date-range'){
            this.localFilters[filterOption.name] = params[filterOption.name].split(",")
          }else if(filterOption.type == 'integer'){
            this.localFilters[filterOption.name] = parseInt(params[filterOption.name])
          }else{
            this.localFilters[filterOption.name] = params[filterOption.name]
          }
        }else{
          if(filterOption.type == 'multiple'){
            this.localFilters[filterOption.name] = []
          }else if(filterOption.type == 'integer'){
            this.localFilters[filterOption.name] = 0
          }else if(filterOption.type == 'number-range'){
            this.localFilters[filterOption.name] = []
          }else if(filterOption.type == 'date-range'){
            this.localFilters[filterOption.name] = []
          }else{
            this.localFilters[filterOption.name] = ''
          }
        }
      })
    },
  }

}
</script>