import BaseModel from "@/models/BaseModel"
import Carrier from "@/models/Carrier"
import CarrierService from "@/models/CarrierService"
import Currency from '@/models/Currency'

export default class CarrierAccount extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.accountId = ''
    this.carrierId = null
    this.username = ''
    this.password = ''
    this.autoRefill = false
    this.carrier = null
    this.carrierServices = []
    this.carrierServiceIds = []
    this.currency = {}
    this.balanceThreshhold = 0
    this.signatureOver = 0
    this.insuranceOver = 0
    this.refillAmount = 0
    this.settingsUrl = null
    this.status = 'connected'
    this.balance = 0
    this.disconnectReason = ''
    this.canBeDeleted = true
    this.shipstationCarriers = []

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let carrierAccount = new this()

    carrierAccount.id = json.id
    carrierAccount.accountId = json.account_id
    carrierAccount.carrierId = json.carrier_id
    carrierAccount.username = json.username
    carrierAccount.password = json.password
    carrierAccount.autoRefill = json.auto_refill
    carrierAccount.currency = Currency.fromJson(json.currency)
    carrierAccount.balanceThreshold = json.balance_threshold
    carrierAccount.refillAmount = json.refill_amount
    carrierAccount.balance = json.balance
    carrierAccount.settingsUrl = json.settings_url
    carrierAccount.carrier = this.initRelation(json.carrier, Carrier, null)
    carrierAccount.carrierServices = this.initRelation(json.carrier_services, CarrierService, [])
    carrierAccount.carrierServiceIds = carrierAccount.carrierServices.map((service) => service.id)
    carrierAccount.signatureOver = json.signature_over
    carrierAccount.insuranceOver = json.insurance_over
    carrierAccount.status = json.status
    carrierAccount.disconnectReason = json.disconnect_reason
    carrierAccount.canBeDeleted = json.can_be_deleted
    carrierAccount.shipstationCarriers = json.shipstation_carrier
    carrierAccount.createdAt = json.created_at
    carrierAccount.updatedAt = json.updated_at

    return carrierAccount
  }

  toJson(){
    return {
      carrier_account: {
        username: this.username,
        password: this.password,
        carrier_id: this.carrierId,
        amount: this.amount,
        auto_refill: this.autoRefill,
        balance_threshold: this.balanceThreshold,
        refill_amount: this.refillAmount,
        carrier_service_ids: this.carrierServiceIds,
        insurance_over: this.insuranceOver,
        signature_over: this.signatureOver
      }
    }
  }
}