import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const version = 'v1'
const resource = 'charts'

export default {

  async orders(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/orders`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async sales(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/sales`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async performanceMetrics(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/performance_metrics`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async salesValueByBrand(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/sales/value_by_brand`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async salesValueByCategory(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/sales/value_by_category`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async inventoryMovements(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/inventory_movements`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async inventoryByBrand(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/inventories/brand`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async inventoryByDepartment(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/inventories/department`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async inventoryCostByDepartment(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/inventories/cost_by_department`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async inventoryCostByBrand(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/inventories/cost_by_brand`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },
  
  async listings(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/listings`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async listingsCountBySalesChannelStatus(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/listings/count_by_sales_channel_status`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the chart data!'
      })

      throw error
    }
  },

  async listingsCountByStatus(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/listings/count_by_status`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the chart data!'
      })

      throw error
    }
  },

  async listingsCountByInterest(payload = {}){
    try{
      let response = await APIService.get(`${version}/${resource}/listings/count_by_interest`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  async productsListingsMeter(payload){
    try{
      let response = await APIService.get(`${version}/${resource}/products/meter`, payload)
      return response.data
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the comments'
      })

      throw error
    }
  },

  // salesVolumes(payload){
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.get(`${resource}/sales_volumes`, payload)
  //       .then((response) => {
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         if(!Vue.prototype.$api.isCancel(error)){
  //           bus.$emit('app:message', {
  //             type: 'error',
  //             message: 'Could not fetch carriers. Try again later!'
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },
}
