import { eventBus } from '@/composables/eventBus'

import PackageType from '@/models/PackageType'
import APIService from "@/repositories/APIService"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'package_types'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, PackageType, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the package types.'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, PackageType, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the package type!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('package_type:created', response.data)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Package type was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create package type. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('package_type:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Package type was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update package type. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`)
      eventBus.emit('package_type:deleted')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Package type was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete package type. Please try again later!'
      })
    }
  }

}
