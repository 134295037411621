<template>
  <div>
    <LoadingOverlay :active="loading" :is-full-page="false" />
    <div class="py-4 flex flex-col space-y-10 justify-between" v-if="rates.length == 0 && !hasShipment">
      <div class="divide-y divide-gray-200 gap-2 space-y-2">
        <div class="grid grid-cols-2">
          <div>
            <Label name="ships_from">{{ $t('Ship From:') }}</Label>
            <p class="text-sm">{{ rateRequest.shipFrom.company }}</p>
            <p class="text-sm">{{ [rateRequest.shipFrom.address1, rateRequest.shipFrom.address2].join(',') }}</p>
            <p class="text-sm">{{ rateRequest.shipFrom.city }}, {{ rateRequest.shipFrom.state }}, {{ rateRequest.shipFrom.country }} - {{ rateRequest.shipFrom.postal_code }}</p>
            <p class="text-sm">Phone: {{ rateRequest.shipFrom.phone }}</p>
          </div>
          <div>
            <Label name="ships_to">{{ $t('Ship To:') }}</Label>
            <p class="text-sm">{{ rateRequest.shipTo.name }}</p>
            <p class="text-sm">{{ [rateRequest.shipTo.address1, rateRequest.shipTo.address2].join(',') }}</p>
            <p class="text-sm">{{ rateRequest.shipTo.city }}, {{ rateRequest.shipTo.state }}, {{ rateRequest.shipTo.country }} - {{ rateRequest.shipTo.postal_code }}</p>
            <p class="text-sm">Phone: {{ rateRequest.shipTo.phone }}</p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 pt-2">
          <div>
            <div class="flex justify-between items-end">
              <Label name="ships_from" required>{{ $t('Packaging:') }}</Label>
              <p class="text-xs text-gray-500">{{ rateRequest.lengthValue }} x {{ rateRequest.widthValue }} x {{ rateRequest.heightValue }} {{ rateRequest.sizeUnit }}</p>
            </div>
            <p class="mt-1 flex text-sm text-gray-900 sm:mt-0">
              <Select :items="packageTypeOptions" v-model="rateRequest.packageType" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
            </p>
          </div>
          <div>
            <Label name="weight_value" required :errors="errors">{{ $t('Weight') }}</Label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <InputText v-model="rateRequest.weightValue" name="cost" :errors="errors" class="pr-12 w-full"/>
              <div class="absolute inset-y-0 right-0 flex items-center">
                <label for="weight_unit" class="sr-only">Weigth Unit</label>
                <select id="weight_unit" name="weight_unit" v-model="rateRequest.weightUnit" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                  <option :value="weightUnit.code" v-for="weightUnit in weightUnitOptions" :key="weightUnit.code">
                    {{ weightUnit.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <Button @click="findRates" class="flex items-center space-x-1">
          <MagnifyingGlassIcon class="w-4 h-4" />
          <span>{{ $t('Search') }}</span>
        </Button>
      </div>
    </div>
    <div v-if="rates.length > 0 && !hasShipment">
      <div class="rounded-md bg-blue-50 p-4 mt-2">
        <div class="flex">
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              <vue-countdown :time="3 * 60 * 1000" v-slot="{ minutes, seconds }" @end="cancelRates" @abort="cancelRates">
                {{ $t('These estimates will expire in {minutes} minutes {seconds} seconds.', {minutes: minutes, seconds: seconds}) }}
              </vue-countdown>
              <a @click="cancelRates" class="text-blue-500 hover:text-blue-700">{{ $t('Search again.') }}</a>
            </p>
          </div>
        </div>
      </div>
      <ul class="divide-y divide-gray-200">
        <li class="py-4" v-for="(rate, index) in rates" :key="index">
          <div class="flex items-center space-x-4">
            <div class="flex-shrink-0">
              <img class="h-10 rounded-full" :src="rate.logo" alt="">
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900">
                {{ rate.serviceName }}
              </p>
              <p class="text-sm text-gray-500">
                Deliver date: {{ $filters.date.dateFormat(rate.deliverDate) }}
              </p>
              <p class="text-sm text-gray-500" v-if="rate.addOns.length > 0">
                Including {{ getAddOns(rate) }}
              </p>
              <p class="text-sm text-gray-500">
                <Button size="sm" @click="buy(rate)">
                  Buy Label - {{ $filters.currency.format(rate.cost, rate.currency) }}
                </Button>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="hasShipment">
      <div class="rounded-md bg-blue-50 p-4 mt-2">
        <div class="flex">
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              <vue-countdown :time="4 * 60 * 1000" v-slot="{ minutes, seconds }">
                {{ $t('These page will refresh in {minutes} minutes {seconds} seconds. Please print the label immediately.', {minutes: minutes, seconds: seconds}) }}
              </vue-countdown>
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-between p-4">
        <Button color="blue" @click="printLabel" class="w-full">
          Print label
        </Button>
        <Button color="green" @click="printPackingSlip" class="w-full">
          Print Packing Slip
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
  import printJS from 'print-js'
  import moment from 'moment'
  import RateRequest from '@/models/RateRequest'
  import Shipment from '@/models/Shipment'
  import { MagnifyingGlassIcon } from '@snsicons/icons/solid'

  export default {
    props: {
      orderLines: {
        type: Array,
      },
      order: {
        type: Object,
      },
      warehouse: {
        type: Object,
      }
    },
    components: {
      MagnifyingGlassIcon
    },
    data(){
      return {
        loading: false,
        label: {},
        packageTypes: [],
        rates: [],
        rateRequest: new RateRequest(),
        errors: [],
        shipment: new Shipment()
      }
    },
    mounted(){
      this.$store.dispatch('package_types/fetch')
      this.sumWeight()
      this.setShipFrom()
      this.setShipTo()
      // let vm = this
      // this.$store.dispatch('package_types/fetch')
      // this.fetchPackageTypes()
      
      // this.setWeight()
      // this.setOrderLines()
      
      // this.$bus.$on(['package_type:created'], () => {
      //   vm.fetchPackageTypes()
      // })
    },
    watch: {
      'rateRequest.packageType': function(value){
        if(value){
          this.$store.dispatch('user_prefs/setCurrentBox', value)
          this.setPackageDimensions()
        }
      },
      'orderLines': function(value){
        this.sumWeight()
      }
    },
    computed: {
      hasShipment(){
        return this.shipment.id != null
      },
      packageTypeOptions(){
        let options = []

        this.$store.getters['package_types/all'].forEach((package_type) => {
          options.push({
            code: package_type.id,
            label: package_type.name
          })
        })

        return options
      },

      hasStoredPackageType(){
        return this.$store.getters['package_types/find'](this.$store.getters['user_prefs/currentBox']) ? true : false
      },

      storedPackageType(){
        return this.$store.getters['package_types/find'](this.$store.getters['user_prefs/currentBox'])
      },
      weightUnitOptions(){
        let options = []
          options.push({
            label: 'lb',
            code: "lb",
          })
          options.push({
            label: 'oz',
            code: "oz",
          })
          options.push({
            label: 'kg',
            code: "kg",
          })
          options.push({
            label: 'g',
            code: "g",
          })
          return options
      }
    },
    methods: {
      cancelRates(){
        this.rates = []
      },
      refreshPage(){
        this.$router.push({name: 'scanning.processing_table.index'})
      },
      sumWeight(){
        this.rateRequest.weightValue = 0
        this.rateRequest.weightUnit = 'lb'
        console.log(this.orderLines)
        this.orderLines.forEach((orderLine) => {
          if(orderLine.product){
            this.rateRequest.weightValue += (orderLine.product.weightValue*(orderLine.confirmedQuantity-orderLine.shippedQuantity))
          }
        })
        this.rateRequest.weightValue = parseFloat(this.rateRequest.weightValue).toFixed(2)
      },
      async fetchPackageTypes(){
        this.loadingRates = true
        try{
          let response = await this.$repositories.packageTypes.get()
          this.packageTypes = response.data
        }catch(_){}
        this.loadingRates = false
      },
      async findRates(){
        this.rates = []
        this.loading = true
        try{
          this.rateRequest.orderLineIds = this.orderLines.map(orderLine => orderLine.id)
          this.rateRequest.warehouseId = this.warehouse.id
          await this.$repositories.rates.store(this.rateRequest)
          this.fetchRates()
        }catch(_){}
        this.loading = false
      },
      async fetchRates(){
        this.loading = true
        try{
          let response = await this.$repositories.rates.get({warehouse_id: this.warehouse.id, order_id: this.order.id})
          this.rates = response.data
          console.log(this.rates)
        }catch(_){}
        this.loading = false
      },

      async buy(rate){
        this.loading = true
        try{
          let response = await this.$repositories.rates.buy(rate.id, this.rateRequest)
          console.log(response)
          this.shipment = response
          console.log(this.shipment)
        }catch(_){}
        this.loading = false
      },

      getAddOns(rate){
        return rate.addOns.map((r) => r.text).join(" & ")
      },

      printLabel(){
        printJS({printable: this.shipment.label, type: 'pdf', base64: true})
      },
      printPackingSlip(){
        printJS({printable: this.shipment.packingSlip, type: 'pdf', base64: true})
      },
      setShipFrom(){
        this.rateRequest.shipFrom.address1 = this.warehouse.street
        this.rateRequest.shipFrom.address2 = this.warehouse.street2
        this.rateRequest.shipFrom.city = this.warehouse.city
        this.rateRequest.shipFrom.state = this.warehouse.state
        this.rateRequest.shipFrom.postal_code = this.warehouse.zipCode
        this.rateRequest.shipFrom.country = this.warehouse.country
        this.rateRequest.shipFrom.phone = this.warehouse.phoneNumber
        this.rateRequest.shipFrom.email = this.warehouse.email
        this.rateRequest.shipFrom.company = this.order.salesChannel.name
      },
      setShipTo(){
        this.rateRequest.shipTo.name = this.order.shippingInfo.name
        this.rateRequest.shipTo.phone = this.order.shippingInfo.phone
        this.rateRequest.shipTo.address1 = this.order.shippingInfo.address1
        this.rateRequest.shipTo.address2 = this.order.shippingInfo.address2
        this.rateRequest.shipTo.city = this.order.shippingInfo.city
        this.rateRequest.shipTo.state = this.order.shippingInfo.state
        this.rateRequest.shipTo.postal_code = this.order.shippingInfo.postalCode
        this.rateRequest.shipTo.country = this.order.shippingInfo.country
      },
      setPackageDimensions(){
        this.rateRequest.widthValue = this.storedPackageType.widthValue
        this.rateRequest.lengthValue = this.storedPackageType.lengthValue
        this.rateRequest.heightValue = this.storedPackageType.heightValue
        this.rateRequest.sizeUnit = this.storedPackageType.sizeUnit
      },
    }
  }
</script>
