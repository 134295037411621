import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Pickup from '@/models/Pickup'
import CanceledRequest from '@/repositories/Response/CanceledRequest'

const resource = 'pickups'
const version = 'v1'
const platformModule = 'oms'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Pickup, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching pickups.'
      })
    }
  },

  async shelves(payload){
    try{
      return await APIService.get(`${version}/${resource}/shelves`, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching pickups.'
      })
    }
  },

  // store(payload){
  //   return new Promise((resolve, reject) => {
  //     Vue.prototype.$api.post(`${platformModule}/${version}/${resource}`, payload)
  //       .then((response) => {
  //         bus.$emit('app:message', {
  //           type: 'success',
  //           message: 'Picked with success!'
  //         })
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         if(!Vue.prototype.$api.isCancel(error)){
  //           bus.$emit('app:message', {
  //             type: 'error',
  //             message: error.response.data.message
  //           })
  //         }
  //         reject(error)
  //       })
  //   })
  // },

}
