export default {
  all: (state) => {
    return state.packageTypes
  },
  find: (state) => (id) => {
    return state.packageTypes.find((packageType) => packageType.id == id)
  },
  findByName: (state) => (name) => {
    return state.packageTypes.find((packageType) => packageType.name == name)
  }
}