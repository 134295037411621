<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Print Warehouse Shelves Barcodes
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="updateWarehouse">
        {{ $t('Save') }}
      </Button>
    </template>

    <div class="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-blue-700">
            Team from warehouse {{ warehouse.name }} will receive email notifications regarding deviations during the specified operating hours.
          </p>
        </div>
      </div>
    </div>

    <div class="space-y-10">
      <div class="grid grid-cols-5 flex items-center" v-for="(hour, index) in warehouse.operatingHours.hours" :key="index">
        <div class="space-x-3 col-span-1">
          <input :id="'operating_hours_'+index" v-model="hour.is_enabled" :value="hour.is_enabled" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
          <span class="font-semibold">{{ index }}</span>  
        </div>
        <div class="col-span-2 flex">
          <span class="flex-grow" v-if="hour.is_enabled">
            {{ formatTime(hour.from) }} - {{ formatTime(hour.to) }}
          </span>
          <span class="flex-grow font-bold" v-else>
            Closed all day
          </span>
        </div>
        <div class="col-span-2 ">
          <Slider :modelValue="initialValuesForDay(index)" @change="value => setHourValues(index, value)" :disabled="!hour.is_enabled" :tooltip="false" :min="0" :max="1410" :step="30" :format="(value) => moment().startOf('day').add(value, 'minutes').format('hh:mm A')"/>
        </div>
      </div>
    </div>
  </FormModal>
</template>
<script>
  import Warehouse from "@/models/Warehouse";
  import _ from 'lodash'
  import Slider from '@vueform/slider'
  import moment from 'moment'
  
  export default {
    components: {
      Slider
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        warehouse: new Warehouse(),
        errors: [],
        countries: [],
        states: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.warehouse.id != null
      },
      stepperBgStyle(){
        return {
          backgroundColor: '#fff',
          boxShadow: 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)'
        }
      },
      stepperProcessStyle(){
        return {
          backgroundColor: '#999'
        }
      }
    },
    methods: {
      formatTime(time){
        return moment(time, 'HH:mm').format('hh:mm A')
      },
      open(preset = {}) {
        try{
          let warehouse = new Warehouse()
          _.assign(warehouse, JSON.parse(JSON.stringify(preset)))
          this.warehouse = warehouse
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.warehouse = new Warehouse()
        this.errors = []
        this.countries = []
        this.states = []
      },
      saveWarehouse(){
        this.savingForm = true
        this.errors = []
        this.updateWarehouse()
      },
      async updateWarehouse(){
        try{
          await this.$repositories.warehouses.update(this.warehouse.id, this.warehouse)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
      initialValuesForDay(index){
        let day = this.warehouse.operatingHours.hours[index]
        let from = day.from.split(":")
        let to = day.to.split(":")
        return [(parseInt(from[0])*60)+parseInt(from[1]), (parseInt(to[0])*60)+parseInt(to[1])]
      },
      setHourValues(day, values){
        console.log(values)
        this.warehouse.operatingHours.hours[day].from = moment().startOf('day').add(values[0], 'minutes').format("HH:mm")
        this.warehouse.operatingHours.hours[day].to = moment().startOf('day').add(values[1], 'minutes').format("HH:mm")
      }
    }
  }
</script>
<style src="@vueform/slider/themes/default.css"></style>