import { eventBus } from '@/composables/eventBus'
import APIService from '@/repositories/APIService'
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";
import OrderLineCancellation from "@/models/OrderLineCancellation"

const resource = 'order_line_cancellations'
const version = 'v1'

export default {
  async create(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('order_line_cancellation:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order line was canceled with success!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
       return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not cancel order line. Please try again later!'
      })

      throw error
    }
  },
}
