<script>
import { CircleInfoIcon } from '@snsicons/icons/solid'
import Product from "@/models/Product";
import SalesChannel from "@/models/SalesChannel";
import _ from 'lodash'
//import ProductImageWidget from './ProductImageWidget.vue';

export default {
  components: {
    CircleInfoIcon,
  },
  data(){
    return {
      opened: false,
      savingForm: false,
      product: new Product(),
      filters: {},
      total: 0,
    }
  },
  watch: {
    opened (newValue) {
      if (!newValue) {
        this.reset()
      }
    },
  },
  methods: {
    open(preset = {}) {
      try{
        let salesChannel = new SalesChannel()
        _.assign(salesChannel, JSON.parse(JSON.stringify(preset.salesChannel)))
        this.salesChannel = salesChannel
        this.filters = preset.filters
        this.total = preset.total
        this.opened = true
      }catch(e){
        console.log(e)
      }
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.salesChannel = new SalesChannel()
      this.filters = {}
      this.total = 0
    },
    async listProducts(){
      try{
        await this.$repositories.products.listMany({ sales_channel_id: this.salesChannel.id }, this.filters)
        this.close()
      }catch(error) {
        console.log(error)
      }
    },
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-1000 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <CircleInfoIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('Are you sure you want to list the products on the {salesChannel} sales channel?', { salesChannel: salesChannel.name}) }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ $t("Are you sure you want to list these products on the {salesChannel} sales channel? Products already listed will be ignored.", { salesChannel: salesChannel.name }) }}
                  </p>
                  <p class="text-sm text-gray-500 pt-1" v-html="$t('Note: Products from brands banned from listing on the {salesChannel} sales channel will be ignored.', { salesChannel: salesChannel.name })">
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-4 flex justify-end space-x-3">
              <Button color="white" @click="close">
                {{ $t('Cancel') }}
              </Button>
              <Button color="blue" @click="listProducts">
                {{ $t('List {count} products', { count: total }) }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>