<script>
import Currency from '@/models/Currency'
import moment from 'moment'

export default{
  data(){
    return {
      filters: {
        from: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      },
      currency: new Currency(),
      tableData: [],
    }
  },
  mounted(){
    this.fetchData()
  },
  computed: {
    chartData(){
      if(Object.values(this.tableData).length > 0){
        return Object.values(this.tableData)
      }
      return [
        {
          sales_channel: "Sales Channel Test (Walmart)",
          count: 100
        },
        {
          sales_channel: "Sales Channel Test 2 (Amazon)",
          count: 75
        }
      ]
    },
    chartOptions(){
      let vm = this
      return {
        chart: {
          width: 400,
          height: 300,
          type: 'pie',
        },
        legend: {
          position: 'top',
        },
        labels: Object.keys(this.tableData),
        
      }
    },
  },
  methods: {
    async fetchData(){
      try{
        let response = await this.$repositories.charts.orders(this.filters)
        this.tableData = response.data
        this.currency = Currency.fromJson(response.meta.currency)
      }catch(_){}
    },
  }
}

</script>
<template>
  <ChartCard>
    <template v-slot:title>
      {{ $t('Total Orders') }}
    </template>
    <template v-slot:subtitle>
      {{ $t('Last 30 days') }}
    </template>
    <div class="flex items-center justify-center w-full">
      <apexchart :options="chartOptions" :series="chartData"></apexchart>
    </div>
  </ChartCard>
</template>