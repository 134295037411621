<script>
import NotificationLine from '@/components/Notification/NotificationLine.vue'
import { MagnifyingGlassIcon } from '@snsicons/icons/solid'
import _ from 'lodash'

export default {
  components: {
    NotificationLine,
    MagnifyingGlassIcon,
  },
  data(){
    return {
      filters: {
        status: 'unread',
        term: '',
      },
      pagination: {
        page: 1,
        per: 20,
        last_page: 1,
        total: 0
      },
      notifications: []
    }
  },
  watch: {
    'pagination.page': function(newValue, oldValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchNotifications(newValue)
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchNotifications(1)
      },
      deep: true,
    },
  },
  computed: {
    filterOptions(){
      return [
        {
          name: 'status',
          display: 'Search Term',
          type: 'alwaysHidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
      ]
    },
  },
  beforeMount(){
    this.setFilters()
  },
  mounted(){
    this.fetchNotifications(1)
    // this.$eventBus.on('notification:updated', () => {
    //   this.fetchNotifications(1)
    // })
  },
  methods: {
    async fetchNotifications(page){
      try {
        let response = await this.$repositories.notifications.get({...this.filters, ...this.pagination, ...{page: page}})
        this.notifications = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
      } catch (error) {
      
      }
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
    setFilters() {
      let params = this.$route.query;

      if (params.term) {
        this.filters.term = params.term
      }

      if (params.status) {
        this.filters.status = params.status
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

    },
  }
}


</script>
<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Notifications
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <span class="relative z-0 inline-flex shadow-sm rounded-md">
            <button type="button" @click="filters.status = null" :class="['relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-l-md border', filters.status == null ? 'border-blue-500 bg-blue-500 text-gray-50 hover:bg-blue-600' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50']">
              All
            </button>
            <button type="button" @click="filters.status = 'unread'" :class="['-ml-px relative inline-flex items-center px-4 py-2 border text-sm font-medium', filters.status == 'unread' ? 'border-blue-500 bg-blue-500 text-gray-50 hover:bg-blue-600' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50']">
              Unread
            </button>
            <button type="button" @click="filters.status = 'read'" :class="['-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-r-md border', filters.status == 'read' ? 'border-blue-500 bg-blue-500 text-gray-50 hover:bg-blue-600' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50']">
              Read
            </button>
          </span>
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full">
        <div class="bg-white shadow-lg rounded-lg">
          <ul role="list" class="divide-y divide-gray-200">
            <li v-for="notification in notifications" :key="notification.id">
              <NotificationLine :notification="notification" />
            </li>
          </ul>
          <Pagination class="rounded-b-lg" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
        </div>
      </div>

    </div>
  </div>
</template>