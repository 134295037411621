<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Connect new Shipping Carrier') }}
      </div>
    </template>
    <template v-slot:actions >
      <Button color="blue" @click="saveCarrierAccount" v-if="carrierAccount.carrierId">
        {{ $t('Save') }}
      </Button>
      <Button size="sm" @click="carrierAccount.carrierId = carrier.id" v-if="!carrierAccount.carrierId">
        Connect
      </Button>
    </template>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3" v-if="!carrierAccount.carrierId">
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow" v-for="carrier in carriers" :key="carrier.id">
        <div class="flex-1 flex flex-col p-8">
          <img class="w-32 h-32 flex-shrink-0 mx-auto bg-opacity-100 " :src="carrier.logo" :alt="carrier.name">
          <h3 class="text-gray-900 leading-5 font-medium">{{ carrier.name }}</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-sm leading-5">{{ carrier.short_description }}</dd>
            <dd class="mt-3">
              <Button size="sm" @click="carrierAccount.carrierId = carrier.id">
                Connect
              </Button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>

    <div v-if="carrierAccount.carrierId">
      <div class="w-full grid grid-cols-2 gap-3">
        <div>
          <Label name="username" required :errors="errors">{{ $t('Username') }}</Label>
          <InputText v-model="carrierAccount.username" name="username" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="username" />
        </div>
        <div>
          <Label name="password" required :errors="errors">{{ $t('Password') }}</Label>
          <InputText v-model="carrierAccount.password" name="password" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="password" />
        </div>
      </div>
    </div>

  </FormModal>
</template>
<script>
  import CarrierAccount from "@/models/CarrierAccount";
  import _ from 'lodash'
  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        carrierAccount: new CarrierAccount(),
        errors: [],
        carriers: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.carrierAccount.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let carrierAccount = new CarrierAccount()
          _.assign(carrierAccount, preset)
          this.carrierAccount = carrierAccount
          this.fetchCarriers()
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.carrierAccount = new CarrierAccount()
        this.errors = []
      },
      async fetchCarriers(){
        try{
          let response = await this.$repositories.carriers.get()
          this.carriers = response.data
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async saveCarrierAccount(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          await this.updateCarrierAccount()
        }else{
          await this.storeCarrierAccount()
        }
      },
      async storeCarrierAccount(){
        try{
          await this.$repositories.carrierAccounts.store(this.carrierAccount)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateCarrierAccount(){
        try{
          await this.$repositories.carrierAccounts.update(this.carrierAccount.id, this.carrierAccount)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
    }
  }
</script>
