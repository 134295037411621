<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('New '+selectedIntegration.name+' integration') }}
      </div>
    </template>
    <template v-slot:actions >
      <Button color="blue" @click="storeConnection" :disabled="!canSave">
        {{ $t('Install') }}
      </Button>
    </template>

    <div>
      <div class="rounded-md bg-blue-50 p-4">
        <div class="flex-1 md:flex md:justify-between" v-if="selectedIntegration.code == 'SHIPSTATION'">
          <p class="text-sm text-blue-700">Click on "Details" to find out how to connect {{ selectedIntegration.name }} to Sitnsell.</p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <a href="https://help.sitnsell.com/article/167-connecting-your-shipstation-account" target="_blank" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
              Details
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>

        <div class="flex-1 md:flex md:justify-between" v-if="selectedIntegration.code == 'WALMART_US' || selectedIntegration.code == 'WALMART_CA' || selectedIntegration.code == 'WALMART_MX'">
          <p class="text-sm text-blue-700">Click on "Details" to find out how to connect {{ selectedIntegration.name }} to Sitnsell.</p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <a href="https://help.sitnsell.com/category/166-integrations" target="_blank" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
              Details
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>

        <div class="flex-1 md:flex md:justify-between" v-if="selectedIntegration.code == 'AMAZON'">
          <p class="text-sm text-blue-700">Click on "Details" to find out how to connect {{ selectedIntegration.name }} to Sitnsell.</p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <a href="https://help.sitnsell.com/article/169-connect-amazon-account" target="_blank" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
              Details
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>
      </div>

      <div v-if="selectedIntegration.code == 'AMAZON'">
        <Label name="name" required :errors="errors">{{ $t('Name of the Amazon Account') }}</Label>
        <InputText v-model="name" name="name" class="w-full" :errors="errors"/>
      </div>
      <div v-else-if="selectedIntegration.code == 'EBAY'">

      </div>
      <div class="w-full grid grid-cols-2 gap-3" v-else>
        <div>
          <Label name="api_key" required :errors="errors">{{ $t('API Key') }}</Label>
          <InputText v-model="connection.apiKey" name="api_key" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="api_key" />
        </div>
        <div>
          <Label name="api_secret" required :errors="errors">{{ $t('API Secret') }}</Label>
          <InputText v-model="connection.apiSecret" name="api_secret" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="api_secret" />
        </div>
      </div>
    </div>

  </FormModal>
</template>
<script>
  import Connection from "@/models/Connection";
  import _ from 'lodash'
  export default {
    data(){
      return {
        name: '',
        opened: false,
        savingForm: false,
        connection: new Connection(),
        errors: [],
        carriers: [],
        selectedIntegration: {}
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.connection.id != null
      },
      canSave(){
        if(this.selectedIntegration.code == 'AMAZON'){
          return this.name.length > 0
        }else if(this.selectedIntegration.code == 'EBAY'){
          return true
        }else{
          return this.connection.apiKey.length > 0 && this.connection.apiSecret.length > 0
        }
      }
    },
    methods: {
      open(selectedIntegration = {}) {
        try{
          this.selectedIntegration = selectedIntegration
          this.connection.integrationId = selectedIntegration.id
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.connection = new Connection()
        this.errors = []
        this.name = ''
      },
      async storeConnection(){
        this.savingForm = true
        this.errors = []

        if(this.selectedIntegration.code === 'AMAZON'){
          try{
            let response = await this.$repositories.salesChannels.consentUrl("amazon", {name: this.name})
            window.location.replace(response.data.url)
          }catch(e){
            console.log(e)
          }
        }else if(this.selectedIntegration.code === 'EBAY'){
          // $refs.connectEbayModal.open(platform.id)
          try{
            let response = await this.$repositories.salesChannels.consentUrl("ebay")
            window.location.replace(response.data.url)
          }catch(e){
            console.log(e)
          }
        }else{
          try{
            await this.$repositories.connections.store(this.connection)
            this.close()
          }catch(error) {
            this.errors = error.errors
          }
        }

        this.savingForm = false
      }
    }
  }
</script>
