<script>
import { FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, MagnifyingGlassIcon, TriangleExclamationIcon, CircleInfoIcon } from '@snsicons/icons/solid'
import ProductsAdjustCostModal from '@/components/Product/ProductsAdjustCostModal'
import ProductsChangeBrandModal from '@/components/Product/ProductsChangeBrandModal'
import ProductsModifyDepartmentModal from '@/components/Product/ProductsModifyDepartmentModal'
import ProductFormModal from '@/components/Product/ProductFormModal'
import _ from 'lodash'
import ProductBulkListModal from '@/components/Product/ProductBulkListModal.vue'

const BASE_FILTERS = {
  term: '',
  brand: [],
  category: [],
  quantity: 'with',
  missing_on_channel: '',
  exclude_brand: [],
  sort_by: null,
  sort_direction: null,
}

export default {
  components: {
    PencilIcon,
    EyeIcon,
    PlusIcon,
    MagnifyingGlassIcon,
    ProductsAdjustCostModal,
    ProductsChangeBrandModal,
    ProductsModifyDepartmentModal,
    ProductFormModal,
    ProductBulkListModal,
    TriangleExclamationIcon,
    CircleInfoIcon
  },
  data() {
    return {
      products: [],
      salesChannels: [],
      selectFiltered: false,
      loading: false,
      selected: [],
      selectAll: false,
      loadingExport: false,
      productHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: 'name',
        },
        {
          text: 'SKU',
          value: 'sku',
          sortable: 'sku'
        },
        {
          text: 'Size/Color',
          value: 'size_color',
        },
        {
          text: 'Barcode',
          value: 'barcode',
          sortable: 'barcode'
        },
        {
          text: 'Brand',
          value: 'brandId',
        },
        {
          text: 'Category',
          value: 'categoryId',
        },
        {
          text: 'Cost',
          value: 'cost',
          sortable: 'cost'
        },
        {
          text: 'Quantity',
          value: 'quantitySum',
          sortable: 'quantity'
        },
        {
          text: 'Velocity',
          value: 'velocity30',
          sortable: 'velocity_30'
        },
        // {
        //   text: '',
        //   value: 'actions'
        // }
      ],
      filters: BASE_FILTERS,
      sortBy: {
        sort_by: null,
        sort_direction: null,
      },
      pagination: {
        page: 1,
        per: 50,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.products.forEach(product => {
          this.selected.push(product.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchProducts(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    'filters.missing_on_channel': function(newValue){
      if(newValue){
        // let channel = this.$store.getters['sales_channels/find'](this.filters.missing_on_channel)
        // if(channel){
        //   this.filters.exclude_brand = channel.bannedBrands
        // }
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchProducts(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchProducts(1)
      },
      deep: true,
    },
    // 'filters.department': function(value){
      
    // },
    // 'filters.brand': function(value){
    //   this.$router.push({path: this.$route.path,query: {...this.$route.query, brand: value.toString()}})
    //   this.fetchProducts()
    // },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.products.length
    },
    filterOptions(){
      return [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'category',
          display: 'Category',
          options: this.categoryOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'missing_on_channel',
          display: 'Not Listed On',
          type: 'single',
          options: this.salesChannelOptions,
          verb: '',
          group: 'Marketplace'
        },
        {
          name: 'without_image',
          display: 'Without Image',
          type: 'single',
          options: this.withoutImageOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'exclude_brand',
          display: 'Exclude Brands',
          type: 'alwaysHidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'sort_by',
          display: 'Sort',
          type: 'alwaysHidden',
          verb: 'by',
          group: 'Details'
        },
        {
          name: 'sort_direction',
          display: 'Sort direction',
          type: 'alwaysHidden',
          verb: '',
          group: 'Details'
        },
      ]
    },
    withoutImageOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      return options
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With",
        code: 'with',
      })
      options.push({
        label: "Without",
        code: 'without',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    salesChannelOptions(){
      let options = []
      this.$store.getters['sales_channels/all'].forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    selectedSalesChannel(){
      return this.$store.getters['sales_channels/find'](this.filters.missing_on_channel)
    },
    amazonSalesChannels(){
      return this.$store.getters['sales_channels/all'].filter((salesChannel) => salesChannel.isAmazon)
    }
  },
  beforeMount(){
    this.setFilters()
  },
  unmounted(){
    this.filters = BASE_FILTERS
  },
  mounted(){
    const vm = this
    this.fetchSalesChannels()
    this.$store.dispatch('brands/fetch')
    this.$store.dispatch('categories/fetch')

    this.$eventBus.on('product:created', () => {
      this.fetchProducts()
    })

    this.$eventBus.on('product:deleted', () => {
      this.fetchProducts()
    })

    this.$eventBus.on('product:updated', () => {
      this.fetchProducts()
    })

    this.$eventBus.on('deleteRecord', (id) => {
      this.destroyProduct(id)
    })
  },
  
  methods: {
    async fetchProducts(page){
      this.loading = true
      this.products = []
      try {
        let response = await this.$repositories.products.get({...this.filters, ...this.pagination, ...{page: page}})
        this.products = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async destroyProduct(id){
      try {
        await this.$repositories.products.destroy(id)
      } catch (error) {}
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
    deleteProduct(item){
      this.$refs.deleteConfirmationModal.open(
        this.$t("Delete {product} ?", {product: item.name}),
        this.$t("Deleting the product is an irreversible operation, and will lead to the deletion of the inventory and associated listings."),
        item.id,
      )
    },
    openChangeBrandModal(){
      this.$refs.productsChangeBrandModal.open({
        filters: this.selectFiltered ? this.filters : {...this.filters, ...{id: this.selected}}, 
        total: this.selectFiltered ? this.pagination.total : this.selected.length
      })
    },
    openModifyDepartmentModal(){
      this.$refs.productModifyDepartmentModal.open({
        filters: this.selectFiltered ? this.filters : {...this.filters, ...{id: this.selected}}, 
        total: this.selectFiltered ? this.pagination.total : this.selected.length
      })
    },
    openBulkListModal(selected, selectFiltered, salesChannel){
      this.$refs.productBulkListModal.open({
        salesChannel: salesChannel,
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length
      })
    },
    openCostAdjustmentModal(selected, selectFiltered){
      this.$refs.productsAdjustCostModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length
      })
    },
    setFilters() {
      let params = this.$route.query;
      let filters = _.cloneDeep(BASE_FILTERS)

      if (params.term) {
        filters.term = params.term
      }

      if (params.without_image) {
        filters.without_image = params.without_image
      }

      if (params.quantity) {
        filters.quantity = params.quantity
      }else{
        filters.quantity = 'with'
      }

      if (params.category) {
        filters.category = params.category.split(",")
      }

      if (params.brand) {
        filters.brand = params.brand.split(",")
      }

      if (params.missing_on_channel) {
        filters.missing_on_channel = params.missing_on_channel
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        filters.only_with_quantity = params.only_with_quantity
      }


      if (params.sort_by) {
        filters.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        filters.sort_direction = params.sort_direction
      }

      this.filters = filters
    },
  }
}
</script>

<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Products
      </template>
      <template v-slot:actions>
        <Button color="white" @click="$router.push({name: 'dashboard.brands_and_departments.index'})" :loading="loading" :disabled="disabled" class="flex items-center">
          <span>
            Manage brands and categories
          </span>
        </Button>
        <ExportButton resource="products" :filters="filters" :disabled="loading"/>
        <Button color="white" @click="$refs.productFormModal.open()" :loading="loading" :disabled="disabled" class="flex items-center">
          <PlusIcon class="w-4 h-4 text-green-600" />
          <span>
            Add New
          </span>
        </Button>
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="rounded-md bg-yellow-100 p-4 my-2" v-if="selectedSalesChannel && selectedSalesChannel.bannedBrands.length > 0">
        <div class="flex">
          <div class="flex-shrink-0">
            <TriangleExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-yellow-700">
              The following brands are banned by the owner on the <span class="font-semibold">{{ selectedSalesChannel.name }}({{ selectedSalesChannel.platform.name }})</span> sales channel and will not be listed:
              <span class="font-semibold">{{ selectedSalesChannel.bannedBrands.join(",") }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="rounded-md bg-blue-100 p-4 my-2" v-if="selectedSalesChannel && !selectedSalesChannel.isAmazon">
        <div class="flex">
          <div class="flex-shrink-0">
            <CircleInfoIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              <span class="font-semibold">{{ selectedSalesChannel.name }}({{ selectedSalesChannel.platform.name }})</span> sales channel does not currently support bulk listing. An individual listing must be created for each product.
            </p>
          </div>
        </div>
      </div>
      
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full">
        <TTable :headers="productHeaders" :items="products" :loading="loading" :filters="filters" :pagination="pagination" :allowBulk="true">
          <template #actions="{ selected, selectFiltered}">
            
            <Button color="white" size="sm" @click="openBulkListModal(selected, selectFiltered, selectedSalesChannel)" v-if="selectedSalesChannel && selectedSalesChannel.isAmazon">
              List on {{ selectedSalesChannel.name }} ({{ selectedSalesChannel.platform.name }})
            </Button>
            <Menu as="div" class="relative inline-block text-left" v-else>
              <div>
                <MenuButton as="template">
                  <Button color="white" size="sm">
                    List On
                  </Button>
                </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute top-10 mt-2 w-72 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">
                  <div class="py-1">
                    <MenuItem v-slot="{ active }" v-for="salesChannel in amazonSalesChannels" :key="salesChannel.id">
                      <a @click="openBulkListModal(selected, selectFiltered, salesChannel)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ salesChannel.name }} ({{ salesChannel.platform.name }})
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <Button color="white" size="sm" @click="openCostAdjustmentModal(selected, selectFiltered)">
              Change Cost
            </Button>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="{name: 'dashboard.product.index', params: {productId: item.id}}" class="flex items-center space-x-2 text-left text-blue-600 hover:text-blue-900 ml-4">
              <ProductPreviewImage :fileResourceId="item.fileResourceId" />
              <span class="w-56 truncate" v-tooltip="item.name">{{ item.name }}</span>
            </router-link>
          </template>
          <template v-slot:[`item.barcode`]="{ item }">
            {{ item.barcode }}
          </template>
          <template v-slot:[`item.size_color`]="{ item }">
            {{ item.size }} {{ item.color }}
          </template>
          <template v-slot:[`item.categoryId`]="{ item }">
            <span v-if="$store.getters['categories/find'](item.categoryId)">{{ $store.getters['categories/find'](item.categoryId).name }}</span>
            <span v-else>N/A</span>
          </template>
          <template v-slot:[`item.brandId`]="{ item }">
            <span v-if="$store.getters['brands/find'](item.brandId)">{{ $store.getters['brands/find'](item.brandId).name }}</span>
            <span v-else>N/A</span>
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            {{ $filters.currency.format(item.cost, item.currency) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center space-x-3">
              <a class="text-blue-500 hover:text-blue-700" @click="$refs.productFormModal.open(item)" v-tooltip="'Edit'">
                <PencilIcon class="w-5 h-5"/>
              </a>
              <router-link :to="{name: 'dashboard.product.index', params: {productId: item.id}}" class="text-gray-500 hover:text-gray-700" v-tooltip="'View'">
                <EyeIcon class="w-5 h-5"/>
              </router-link>
            </div>
          </template>
          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
          </template>
        </TTable>
      </div>
      <ProductsAdjustCostModal ref="productsAdjustCostModal"/>
      <ProductsChangeBrandModal ref="productsChangeBrandModal" />
      <ProductsModifyDepartmentModal ref="productModifyDepartmentModal" />
      <ProductFormModal ref="productFormModal" />
      <DeleteConfirmationModal ref="deleteConfirmationModal" />
      <ProductBulkListModal ref="productBulkListModal" />
      
    </div>
  </div>
</template>