<script>
import { CircleCheckIcon } from '@snsicons/icons/solid'
import InventoryMovementTimeline from '@/components/InventoryMovement/InventoryMovementTimeline.vue'
import moment from 'moment'

export default {
  components: {
    CircleCheckIcon,
    InventoryMovementTimeline
  },
  data(){
    return {
      loading: false,
      inventoryMovements: [],

    }
  },
  mounted(){
    this.fetchInventoryMovements()
  },
  methods: {
    async fetchInventoryMovements(){
      try{
        let response = await this.$repositories.inventoryMovements.get({sort_by: "created_at", sort_direction: "DESC", per: 20})
        this.inventoryMovements = response.data
      }catch (error){
        
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <Card >
    <CardHeader>
      <h2 class="text-base font-medium text-gray-900">
        Latest Inventory Movements
      </h2>
    </CardHeader>
    <CardBody class="flex flex-col justify-between p-4">
      <Loading v-model:active="loading" :is-full-page="false"/>
      <div class="h-72 overflow-y-scroll">
        <InventoryMovementTimeline :inventoryMovements="inventoryMovements" />
      </div>
      <p class="text-center w-full">
        <router-link :to="{name: 'dashboard.inventory_movements.index'}" class="text-blue-500 hover:text-blue-600 text-sm">
          See all inventory movements
        </router-link>
      </p>
    </CardBody>
  </Card>
</template>