<script>
import Listing from '@/models/Listing'
import { ArrowLeftIcon, ArrowRightIcon, CircleCheckIcon } from '@snsicons/icons/solid'
import ListingPriceSmallForm from '@/components/Listing/ListingPriceSmallForm.vue'
export default {
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    CircleCheckIcon,
    ListingPriceSmallForm
  },
  data() {
    return {
      index: 0,
      listings: [],
      listing: new Listing(),
      loading: false,
      total: 0,
    };
  },
  mounted() {
    this.fetchListings()
    this.$eventBus.on('listing:updated', () => {
      this.fetchListings()
    })
  },
  methods: {
    next() {
      this.index = (this.index + 1) % this.listings.length;
      this.listing = this.listings[this.index];
    },
    prev() {
      this.index = (this.index - 1) % this.listings.length;
      this.listing = this.listings[this.index];
    },
    async fetchListings() {
      this.loading = true
      this.listings = [];
      try {
        let response = await this.$repositories.listings.get({ page: 1, per: 1, price_below_cost: true, quantity: 'with' });
        this.listings = response.data;
        this.total = response.pagination.total
        this.listing = this.listings[0];
      }
      catch (error) {
      }finally{
        this.loading = false
      }
    },
  },
}
</script>
<template>
  <Card>
    <CardHeader>
      Potential Profit Loss
    </CardHeader>
    <CardBody v-if="listings.length > 0 && listing && listing.id">
      <LoadingOverlay :loading="loading" />
      <div class="flex flex-col">
        <div class="flex items-start px-2 py-2 space-x-2 bg-gray-50">
          <img src="@/assets/no-picture.png" class="h-20" v-if="listing.fileResource == null"/>
          <img :src="listing.fileResource.url" class="h-20" v-else/>
          <div class="flex flex-col truncate">
            <h4 class="block text-sm font-semibold leading-6 text-gray-800" v-tooltip="listing.name">
              {{ listing.name }}
            </h4>
            <dl class="text-left">
              <div class="flex justify-between items-center">
                <dt class="text-sm font-bold text-gray-800">SKU:</dt>
                <dd class="mt-1 text-sm text-right text-gray-500 sm:mt-0">{{ listing.sku }}</dd>
              </div>
              <div class="flex justify-between items-center">
                <dt class="text-sm font-bold text-gray-800">Listing ID:</dt>
                <dd class="mt-1 text-sm text-right text-gray-500 sm:mt-0">{{ listing.listingId }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <dl class="text-left px-2">
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Sales Channel:</dt>
            <dd class="mt-1 text-sm text-right text-gray-900 sm:mt-0">{{ listing.salesChannel.platform.name }}</dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Sales Channel Account:</dt>
            <dd class="mt-1 text-sm text-right text-gray-900 sm:mt-0">{{ listing.salesChannel.name }}</dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Cost:</dt>
            <dd class="mt-1 text-sm text-right text-gray-900 sm:mt-0">{{ $filters.currency.format(listing.cost, listing.currency) }}</dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Price:</dt>
            <dd class="mt-1 text-sm text-right flex justify-end text-gray-900 sm:mt-0">
              <ListingPriceSmallForm :listing="listing" column="price" class=""/>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Min Price:</dt>
            <dd class="mt-1 text-sm text-right flex justify-end text-gray-900 sm:mt-0">
              <ListingPriceSmallForm :listing="listing" column="min_price" class=""/>
            </dd>
          </div>
          <div class="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
            <dt class="text-sm font-bold text-gray-500">Competition Price:</dt>
            <dd class="mt-1 text-sm text-right text-gray-900 sm:mt-0">{{ $filters.currency.format(listing.competitionPrice, listing.currency) }}</dd>
          </div>
        </dl>
      </div>
      <div class="flex flex-col p-2 mt-4">
        <router-link :to="{name: 'dashboard.listings.index', query:{ price_below_cost: true, quantity: 'with' }}" target="_blank" class="text-center rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 px-3 py-2 text-sm leading-4 font-medium bg-yellow-600 border border-transparent text-white hover:bg-yellow-700 focus:ring-yellow-700 w-full">
          Review potential pricing errors for {{ total }} listings
        </router-link>
        <!-- <div class="flex justify-between" v-if="listings.length > 1">
          <a @click="prev" class="text-gray-600 hover:text-gray-500 font-semibold flex items-center space-x-1">
            <ArrowLeftIcon class="w-4 h-4" />
            <span>Prev</span>
          </a>
          <a @click="next" class="text-gray-600 hover:text-gray-500 font-semibold flex items-center space-x-1">
            <span>Next</span>
            <ArrowRightIcon class="w-4 h-4" />
          </a>
        </div> -->
      </div>
    </CardBody>
    <CardBody class="flex flex-col items-center text-center justify-center" v-else>
      <div class="flex flex-col items-center justify-center text-center py-24 space-y-5 text-green-800">
        <CircleCheckIcon class="w-20 h-20 text-green-500" />
        <p class="text-sm font-medium text-gray-600">
          Congratulations! <br/>There is no list of potential financial losses.
        </p>
      </div>
      
    </CardBody>
    
  </Card>
</template>