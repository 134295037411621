<script>
  import { CircleQuestionIcon, BadgeCheckIcon} from '@snsicons/icons/solid'
  import { CircleArrowRightIcon } from '@snsicons/icons/regular'
  import ScanShelfIntro from '@/components/Scanning/ScanShelfIntro.vue'
  import { HouseIcon } from '@snsicons/icons/regular'
  import _ from 'lodash'

  export default {
    components: {
      CircleQuestionIcon,
      ScanShelfIntro,
      CircleArrowRightIcon,
      HouseIcon,
      BadgeCheckIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
        inventories: []
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    computed: {
      totalInventoryScanned(){
        return _.sumBy(this.inventories, function(inv) {
          return parseInt(inv.quantity) 
        })
      }
    },
    methods: {
      scan(event){
        if(event.code === 'Enter'){
          this.fetchInventories()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      async fetchInventories(){
        this.loading = true
        if(this.barcode.length == 0){
          this.loading = false
          this.inventories = []
          return
        }
        try{
          let response = await this.$repositories.inventories.get({barcode: this.barcode, warehouse: this.$store.getters['user_prefs/currentWarehouse'], per: 999, quantity: 'with'})
          this.inventories = response.data
        }catch(e){
          
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
    }
  }
</script>
<template>
  <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
    <loading :active="loading" :is-full-page="false"></loading>
    <router-link :to="{name: 'scanning.home'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
      <HouseIcon class="h-5 text-blue-500" />
      <span>Go Home</span>
    </router-link>
    <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
      <span class="bg-yellow-50 text-yellow-500 rounded-lg inline-flex p-4 ring-4 ring-white">
        <BadgeCheckIcon class="h-5 w-5"/>
      </span>
      <div class="flex flex-col justify-start">
        <h3 class="font-semibold text-base">Check product or shelf stock</h3>
        <p class="text-sm text-gray-500">Scan the barcode of the product or shelf to check stock. If you want to verify different stock, simply scan the barcode of the product or shelf again.</p>
      </div>
    </div>
    <ScanShelfIntro v-if="inventories.length == 0">
      <template v-slot:content>
        Scan Product or Shelf Barcode
      </template>
    </ScanShelfIntro>

    <ul role="list" class="divide-y divide-gray-200" v-else>
      <li class="flex py-4">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-4">
            <slot name="actions"></slot>
          </div>
          <div class="flex items-center space-x-4">
            <p class="text-gray-500 font-semibold">
              <slot name="totalHeader"></slot>
            </p>
            <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm justify-center text-gray-800 font-semibold">
              {{ totalInventoryScanned }}
            </div>
          </div>
        </div>
      </li>
      
      <li class="flex py-4" v-for="(inventory, index) in inventories" :key="index">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-4">
            <div class="rounded-lg bg-gray-100 h-10 w-10 text-gray-800 font-semibold text-sm flex items-center justify-center">
              {{ index+1 }}
            </div>
            <div>
              <h3 class="sm:text-base md:text-lg font-semibold">
                {{ inventory.product.name }} ({{ inventory.product.size }})
              </h3>
              <div class="flex items-center space-x-1 md:space-x-6">
                <div class="text-sm font-semibold">
                  Barcode <span class="text-blue-500">{{ inventory.product.barcode }}</span>
                </div>
                <div class="text-sm font-semibold">
                  SKU <span class="text-blue-500">{{ inventory.product.sku }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <div class="flex items-center space-x-4">
              <p class="text-gray-500 font-semibold hidden md:block">Shelf</p>
              <div class="rounded-lg bg-gray-100 h-12 w-14 flex items-center text-sm text-center justify-center text-gray-800 font-semibold">
                {{ inventory.shelf.name }}
              </div>
            </div>

            <div class="flex items-center space-x-4">
              <p class="text-gray-500 font-semibold hidden md:block">Quantity</p>
              <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm text-center justify-center text-gray-800 font-semibold">
                {{ inventory.quantity }}
              </div>
            </div>
          </div>
        </div>
      </li>

    </ul>

  </div>
</template>