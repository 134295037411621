export default {
  add(state, strategy){
    let found = state.strategies.find((oldStrategy) => oldStrategy.id == strategy.id)
    if(found){
      found = strategy
    }else{
      state.strategies.push(strategy)
    }
  },
  update(state, strategy){
    let found = state.strategies.find((oldStrategy) => oldStrategy.id == strategy.id)
    if(found){
      let index = state.strategies.indexOf(found)
      state.strategies[index] = strategy
    }
  },
  reset(state){
    state.strategies = []
  }
}