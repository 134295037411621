import BaseModel from "@/models/BaseModel"
import SalesChannel from '@/models/SalesChannel'
import OrderLine from '@/models/OrderLine'
import Currency from '@/models/Currency'

export default class Order extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.salesChannelId = null
    this.salesChannel = null
    this.integrationName = ''
    this.platformName = ''
    this.purchaseOrderId = ''
    this.customerOrderId = ''
    this.orderDate = ''
    this.placedAt = ''
    this.estimatedShipDate = ''
    this.estimatedDeliveryDate = ''
    this.customer = null
    this.shippingInfo = {}
    this.status = ''
    this.currency = null
    this.subtotal = 0
    this.tax = 0
    this.shippingCarrier = ''
    this.shippingService = ''
    this.shippingCost = 0
    this.shippingTax = 0
    this.secondDay = false
    this.total = 0
    this.quantity = 0
    this.isUrgent = false
    this.shipstationId = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let order = new this()

    order.id = json.id
    order.integrationName = json.integration_name
    order.platformName = json.platform_name
    order.salesChannelId = json.sales_channel_id
    order.salesChannel = this.initRelation(json.sales_channel, SalesChannel, null)
    order.purchaseOrderId = json.purchase_order_id
    order.customerOrderId = json.customer_order_id
    order.placedAt = json.placed_at
    order.estimatedShipDate = json.estimated_ship_date
    order.estimatedDeliveryDate = json.estimated_delivery_date
    order.customer = json.customer
    order.shippingInfo = json.shipping_info
    order.status = json.status
    order.currency = Currency.fromJson(json.currency)
    order.subtotal = json.subtotal
    order.tax = json.tax
    order.shippingCarrier = json.shipping_carrier
    order.shippingService = json.shipping_service
    order.shippingCost = json.shipping_cost
    order.shippingTax = json.shipping_tax
    order.secondDay = json.second_day
    order.total = json.total
    order.quantity = json.quantity
    order.isUrgent = json.is_urgent
    order.shipstationId = json.shipstation_id
    order.orderLines = this.initRelation(json.order_lines, OrderLine, null)
    order.createdAt = json.created_at
    order.updatedAt = json.updated_at

    return order
  }

}