<script>
import moment from 'moment'
import SupplierFormModal from '@/components/Supplier/SupplierFormModal.vue'
import { CalendarCheckIcon, PlusIcon, FileChartColumnIcon, PencilIcon } from '@snsicons/icons/solid'
export default {
  components: {
    SupplierFormModal,
    CalendarCheckIcon,
    PlusIcon,
    FileChartColumnIcon,
    PencilIcon
  },
  data(){
    return {
      loading: false,
      suppliers: [],
      suppliersHeader: [
        {
          text: 'Supplier Code',
          value: 'code',
        },
        {
          text: 'Supplier Name',
          value: 'name',
        },
        {
          text: 'City',
          value: 'city',
        },
        {
          text: 'Country',
          value: 'country',
        },
        {
          text: 'POs',
          value: 'poCounter',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      filters: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      },
      reloadRequestReports: null,
    }
  },
  watch: {
    'filters.page': function(value){
      this.fetchSuppliers()
    }
  },
  mounted(){
    let vm = this
    this.fetchSuppliers()
    this.$eventBus.on('supplier:created', function(){
      vm.fetchSuppliers()
    })
    this.$eventBus.on('supplier:updated', function(){
      vm.fetchSuppliers()
    })
  },
  methods: {
    async fetchSuppliers(){
      this.loading = true
      try{
        let response = await this.$repositories.suppliers.get(this.filters)
        this.suppliers = response.data
        this.filters.per = response.pagination.perPage
        this.filters.page = response.pagination.currentPage
        this.filters.total = response.pagination.total
        this.filters.last_page = response.pagination.totalPages
      }catch(_){}
      this.loading = false
    },
  }
}
</script>


<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Suppliers
      </template>
      <template v-slot:actions>
        <Button color="white" @click="$refs.supplierFormModal.open()" :loading="loading" :disabled="disabled" class="flex items-center space-x-1">
          <PlusIcon class="w-4 h-4 text-green-600" />
          <span>
            Add New
          </span>
        </Button>
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full pb-5">
        <TTable :headers="suppliersHeader" :items="suppliers" :loading="loading" :allowBulk="false" >
          <template v-slot:[`item.code`]="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.city`]="{ item }">
            {{ item.city }}
          </template>
          <template v-slot:[`item.country`]="{ item }">
            {{ item.country }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center space-x-3">
              <a class="text-gray-500 hover:text-gray-700" @click="$refs.supplierFormModal.open(item)" v-tooltip="'Edit'">
                <PencilIcon class="w-5 h-5"/>
              </a>
            </div>
          </template>
          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="filters.total" :lastPage="filters.last_page" :perPage="filters.per" v-model="filters.page"/>
          </template>
        </TTable>
      </div>
      <SupplierFormModal ref="supplierFormModal" />
    </div>
  </div>
</template>