import BaseModel from "@/models/BaseModel"

export default class Comment extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.author = null
    this.content = ''
    this.commentableId = null
    this.commentableType = null
    this.userIds = []
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let note = new this()

    note.id = json.id
    note.author = json.author
    note.userId = json.userId
    note.content = json.content
    note.createdAt = json.created_at
    note.updatedAt = json.updated_at

    return note
  }

  toJson(){
    return {
      comment: {
        content: this.content,
        commentable_id: this.commentableId,
        commentable_type: this.commentableType,
        user_ids: this.userIds,
      }
    }
  }
}