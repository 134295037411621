<template>
  <div class="flex min-h-full">
    <div class="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell">
          <h2 class="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        </div>

        <div class="mt-8">

          <div class="mt-6">
            <div class="space-y-6">
              <div class=" rounded-md p-4" :class="{'bg-red-50' : error == 'input', 'bg-yellow-50' : error == 'server'}" v-if="error">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <fa :icon="['fas', 'times-circle']" class="text-lg" :class="{'text-red-400' : error == 'input', 'text-yellow-400' : error == 'server'}" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm" :class="{'text-red-700' : error == 'input', 'text-yellow-700' : error == 'server'}">
                      {{ error == 'input' ? $t('The email or password provided is incorrect.') : $t('errors.signIn.server') }}
                    </p>
                  </div>
                </div>
              </div>
              
              <div>
                <Label name="email" required :errors="errors">{{ $t('E-mail') }}</Label>
                <InputText v-model="user.email" name="email" class="w-full" :errors="errors"/>
              </div>
              <div>
                <Label name="password" required :errors="errors">{{ $t('Password') }}</Label>
                <InputText type="password" v-model="user.password" name="password" class="w-full" :errors="errors"/>
              </div>
              <div class="text-sm flex items-center justify-end">
                <router-link :to="{name: 'dashboard.reset_password.index'}" class="font-medium text-blue-600 hover:text-blue-500">
                  Forgot your password?
                </router-link>
              </div>

              <div class="">
                <Button @click="login" class="w-full">
                  {{ $t('Sign-in') }}
                </Button>
              </div>

            </div>

            <div class="mt-6">
              <div class="relative">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="bg-white px-2 text-gray-500">Or</span>
                </div>
              </div>
              <h2 class="mt-6 text-lg font-semibold tracking-tight text-gray-900">Do you want us to help your business?</h2>
              <Button color="white" @click="openBeacon" class="w-full">
                {{ $t('Talk to a specialist now') }}
              </Button>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <div class="absolute z-90 w-full h-full bg-gray-800 opacity-40"></div>
      <div class="absolute z-90 w-full h-full flex flex-col items-center justify-center space-y-5">
        <div class="flex flex-col items-center justify-center space-y-2">
          <h2 class="text-5xl text-white"> {{ description.title }}</h2>
          <p class="text-2xl text-white">{{ description.description }}</p>
        </div>
        <img class="h-12 w-auto" src="/assets/logo_white_full.svg" alt="Sitnsell" />
      </div>
      <img class="absolute inset-0 h-full w-full object-cover" :src="loginImage" alt="">
    </div>
  </div>



</template>
<script>
import { ref } from 'vue'
export default {
  data(){
    return {
      loading: false,
      error: '',
    }
  },
  setup() {
    const user = {
      email: '',
      password: '',
    }

    return {
      user,
    }
  },
  computed: {
    loginImage(){
      return '/assets/images/login'+(Math.floor(Math.random()*3)+1)+'.jpg'
    },
    description(){
      const sets = [
        {
          title: 'It takes so little time to do so much.',
          description: 'Increase your profits substantially using Sitnsell systems.'
        },
        {
          title: 'All your products are one click away from being sold.',
          description: 'Start selling faster and easier using the Sitnsell suite of tools.'
        },
        {
          title: 'Get rid of the clutter in your warehouse.',
          description: 'Use an intelligent warehouse system that will put your operations in order.'
        }
      ]

      return sets[Math.floor(Math.random()*sets.length)]
    }
  },
  methods: {
    async login(){
      this.loading = true
      this.error = ''
      try{
        await this.$store.dispatch('auth/login', this.user)

        if (this.$route.query.redirect) {
          this.$router.push({ path: this.$route.query.redirect })
        } else {
          this.$router.push({ name: 'dashboard.home' })
        }
        this.$bus.emit('user-session-updated', true)
      }catch (e) {
        console.log(e.response)
        if(e.response){
          if(e.response.status == 422){
            this.error = 'input'
          }else{
            this.error = 'server'
          }
        }
        this.user.password = ''
      }

      this.loading = false
    },
    openBeacon(){
      window.Beacon('toggle')
      window.Beacon('navigate', '/ask/chat')
    }
  }
}
</script>