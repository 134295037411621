import ServerError from './ServerError'

export default class NotFoundError extends ServerError {
  constructor (error) {
    super()

    this.error = error
  }

  getMessage () {
    if (this.error) {
      return this.error.message
    }

    return null
  }

  clear () {
    this.error = null
  }
}