import { RotateIcon, CircleCheckIcon, ListCheckIcon, SpellCheckIcon, SpinnerIcon, BugIcon, CircleExclamationIcon, ArrowRightFromBracketIcon } from '@snsicons/icons/solid'

const fulfillmentNetworks = [
  {
    code: 'MFN',
    name: 'MFN',
    color: 'green',
    disabled: false,
  },
  {
    code: 'FBA',
    name: 'FBA',
    color: 'purple',
    disabled: true
  },
  {
    code: 'WFS',
    name: 'WFS',
    color: 'blue',
    disabled: true
  },
]

export default fulfillmentNetworks