import BaseModel from "@/models/BaseModel"
import Aisle from '@/models/Aisle'

export default class Shelf extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.barcode = ''
    this.aisleId = null
    this.aisle = null
    this.inventoryCount = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let shelf = new this()

    shelf.id = json.id
    shelf.name = json.name
    shelf.barcode = json.barcode
    shelf.inventoryCount = json.inventory_count
    shelf.aisleId = json.aisle_id
    shelf.aisle = this.initRelation(json.aisle, Aisle, {})
    shelf.createdAt = json.created_at
    shelf.updatedAt = json.updated_at

    return shelf
  }

  toJson(){
    return {
      name: this.name
    }
  }
}