<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <MagnifyingGlassDollarIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Update cost for up to {{ total }} products ?
                </DialogTitle>
                <div class="mt-2">
                  <div class="text-sm text-gray-500">
                    The value entered in the field below will be applied to standard or variation products.
                    <div>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm">
                            {{ $store.getters['auth/user'].company.defaultCurrency.symbol }}
                          </span>
                        </div>
                        <InputText type="number" v-model="cost" name="cost" :errors="errors" class="w-full pl-7"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 flex justify-end sm:mt-6">
              <Button color="blue" @click="updateMany">
                Apply changes
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { MagnifyingGlassDollarIcon } from '@snsicons/icons/solid'

export default {
  components: {
    MagnifyingGlassDollarIcon,
  },
  data(){
    return {
      loading: false,
      opened: false,
      brands: [],
      filters: {},
      total: 0,
      cost: 0,
    }
  },
  computed: {
    brandOptions(){
      let options = []
      this.brands.forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
  },
  methods: {
    open(preset = {}){
      this.filters = preset.filters
      this.total = preset.total
      this.opened = true
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.cost = 0
    },
    async updateMany(){
      this.loading = true
      try {
        const data = {
          column: 'cost',
          column_value: this.cost
        }
        let response = await this.$repositories.products.updateMany(data, this.filters)
        this.close()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
  }
}
</script>