<template>
  <nav aria-label="Progress">
    <ol :class="listStyle">
      <component :is="stepStyle" :title="step.title" :active="isActive(index+1)" v-for="(step, index) in steps" :key="step.title"></component>
    </ol>
  </nav>
</template>
<script>

import Simple from '@/components/Tailwind/Steps/Styles/Simple'

export default {
  components: {
    Simple,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    stepStyle: {
      type: String,
      default: 'simple'
    }
  },

  computed:{
    listStyle(){
      var klass = ''
      
      if(this.stepStyle == 'simple'){
        klass = 'space-y-4 md:flex md:space-y-0 md:space-x-8'
      }

      return klass
    }
  },
  methods: {
    isActive(step){
      return step >= 1 && step <= this.currentStep
    },
  }
}
</script>