<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <PencilAltIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('Do you update the weight for the product with the {sku} sku?', { sku: product.sku }) }}
                </DialogTitle>
                <div class="mt-2">
                  <div class="text-sm text-gray-500">
                    <Label name="weight_value" required :errors="errors">{{ $t('Weight') }}</Label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <InputText v-model="product.weightValue" name="cost" :errors="errors" class="pr-12 w-full"/>
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <label for="weight_unit" class="sr-only">Weigth Unit</label>
                        <select id="weight_unit" name="weight_unit" v-model="product.weightUnit" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                          <option :value="weightUnit.code" v-for="weightUnit in weightUnitOptions" :key="weightUnit.code">
                            {{ weightUnit.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button @click="updateProduct">
                {{ $t('Update product weight') }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { PencilAltIcon } from '@heroicons/vue/24/outline'
import Product from '@/models/Product'
import _ from 'lodash'

export default {
  components: {
    PencilAltIcon,
  },
  data(){
    return {
      loading: false,
      opened: false,
      product: new Product(),
      errors: {},
    }
  },
  computed: {
    weightUnitOptions(){
      let options = []
      options.push({
        label: 'lb',
        code: "lb",
      })
      options.push({
        label: 'oz',
        code: "oz",
      })
      options.push({
        label: 'kg',
        code: "kg",
      })
      options.push({
        label: 'g',
        code: "g",
      })
      return options
    }
  },
  methods: {
    open(preset = {}){
       try{
        let product = new Product()
        _.assign(product, JSON.parse(JSON.stringify(preset)))
        this.product = product
        this.opened = true
      }catch(e){
        console.log(e)
      }
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.product = new Product()
    },
    async updateProduct(){
      try{
        await this.$repositories.products.update(this.product.id, this.product)
        this.close()
      }catch(error) {
        this.errors = error.errors
      }
    },
  }
}
</script>