import BaseModel from "@/models/BaseModel"
import BillingProduct from '@/models/BillingProduct'

export default class BillingSubscription extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.companyId = null
    this.billingProductId = null
    this.billingProduct = null
    this.stripeId = ''
    this.status = ''
    this.invoiceStartAt = null
    this.invoiceEndAt = null
    this.cancelAt = null
    this.trialsEndAt = null
    this.gracePeriod = null
    
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let billingSubscription = new this()
    
    billingSubscription.id = json.id
    billingSubscription.companyId = json.company_id
    billingSubscription.billingProductId = json.billing_product_id
    billingSubscription.stripeId = json.stripe_id
    billingSubscription.status = json.status
    billingSubscription.invoiceStartAt = json.invoice_start_at
    billingSubscription.invoiceEndAt = json.invoice_end_at
    billingSubscription.cancelAt = json.cancel_at
    billingSubscription.trialsEndAt = json.trials_end_at
    billingSubscription.gracePeriod = json.grace_period

    billingSubscription.billingProduct = this.initRelation(json.billing_product, BillingProduct, null)

    billingSubscription.createdAt = json.created_at
    billingSubscription.updatedAt = json.updated_at

    return billingSubscription
  }

  toJson(){
    return {
      company: {
        name: this.name,
        street: this.street,
        street2: this.street2,
        state: this.state,
        country: this.country,
        zip_code: this.zip_code,
        phone_number: this.phoneNumber,
        card_info: this.cardInfo,
        stripe_card_id: this.stripeCardId,
        invoice_email: this.invoiceEmail,
      }
    }
  }
}