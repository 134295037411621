import { defineAbility } from '@casl/ability';

export default defineAbility((can, cannot) => {

  const user = JSON.parse(`${localStorage.getItem('user')}`)

  if(!user){
    return
  }

  if(user.role == 'owner'){
    can('view', 'Billing')
  }else{
    cannot('view', 'Billing')
  }

  if(user.role == 'owner' || user.role == 'manager'){
    can('view', 'Settings')
    can('view', 'Sales Analytics')
    can('edit', 'review_assign_to')
  }else{
    cannot('view', 'Settings')
    cannot('view', 'Sales Analytics')
    cannot('edit', 'review_assign_to')
  }

  // if (user.role == 'data_management') {
      
  //   cannot('edit', 'Review', ['complete_details', 'reason'])
  // }

  // if (user.role == 'manager' || user.role == 'owner') {
  //   can('edit', 'Review')
  // }
})