<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Repricing Strategies
      </template>
      <template v-slot:actions>
        <Button color="white" @click="$refs.strategyFormModal.open()" :loading="loading" :disabled="disabled" class="flex items-center">
          <PlusIcon class="w-4 h-4 text-green-600" />
          <span>
            Add New
          </span>
        </Button>
      </template>
    </PageHeader>
    <div class="flex-1 relative z-0 overflow-y-auto sm:mx-2 md:mx-10 my-4" >
      <Loading v-model:active="loading" :is-full-page="fullPage"/>

      <div class="mt-10 mx-auto w-96 h-96 text-center flex flex-col items-center justify-center" v-if="filters.total == 0">
        <svg class="text-blue-600 w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
        </svg>
        <h2 class="py-4 text-md font-bold leading-7 text-gray-500"> There are no repricing strategies yet. </h2>
        <Button @click="$refs.strategyFormModal.open()" color="blue">
          Create Strategy
        </Button>
      </div>

        <Card class="mx-4 my-2 px-4 py-6 bg-white" v-for="strategy in strategies" :key="strategy.id">
          <CardBody class="space-y-5">
            <div class="flex justify-between">
              <div class="flex space-x-2">
                <h2 class="text-lg font-bold text-gray-700">
                  {{ strategy.name }}
                </h2>
                <Badge color="yellow" small v-if="strategy.listingsCount == 0" class="flex items-center space-x-1">
                  Unused
                </Badge>
              </div>
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton
                    class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md"
                  >
                    <EllipsisVerticalIcon
                      class="w-5 h-5 ml-2 -mr-1 text-gray-400 hover:text-gray-300"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="px-1 py-1">
                      <MenuItem v-slot="{ active }">
                        <button
                          @click="$refs.strategyFormModal.open(strategy)"
                          :class="[
                            active ? 'bg-gray-400 text-white' : 'text-gray-900',
                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                          ]"
                        >
                          Edit
                        </button>
                      </MenuItem>
                      <!-- <MenuItem v-slot="{ active }">
                        <button
                          :class="[
                            active ? 'bg-red-500 text-white' : 'text-red-900',
                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                          ]"
                        >
                          <TrashCanIcon
                            :active="active"
                            class="w-5 h-5 mr-2 text-red-400"
                            aria-hidden="true"
                          />
                          Delete
                        </button>
                      </MenuItem> -->
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
            
            <dl class="grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-3 divide-x divide-gray-200 bg-gray-50 rounded-lg">
              <div class="sm:col-span-1 px-8 py-4 space-y-2">
                <h5 class="text-sm font-bold leading-7 text-gray-400">
                  Strategy Details
                </h5>
                <div class="grid grid-cols-2 space-y-2">
                  <div class="col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Strategy Type
                    </dt>
                    <dd class="mt-1">
                      {{ strategyType(strategy) }}
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Min Price
                    </dt>
                    <dd class="mt-1">
                      {{ strategy.minMax.type }}
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Max Price
                    </dt>
                    <dd class="mt-1">
                      {{ strategy.minMax.type }}
                    </dd>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-1 px-8 py-4 space-y-2">
                <h5 class="text-sm font-bold leading-7 text-gray-400">
                  Strategy Performance 30 days
                </h5>
                <div class="grid grid-cols-2 space-y-2">
                  <div class="col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Items Sold
                    </dt>
                    <dd class="mt-1">
                      {{ strategy.itemsSold }}
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Gross Sales
                    </dt>
                    <dd class="mt-1">
                      {{ $filters.currency.format(strategy.grossSales, strategy.currency) }}
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Gross Profit
                    </dt>
                    <dd class="mt-1">
                      {{ $filters.currency.format(strategy.grossProfit, strategy.currency) }}
                    </dd>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-1 px-8 py-4 space-y-2">
                <h5 class="text-sm font-bold leading-7 text-gray-400">
                  Listings with this strategy
                </h5>
                <div class="grid grid-cols-2 space-y-2">
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Active Listings
                    </dt>
                    <dd class="mt-1">
                      <router-link target="_blank" :to="{name: 'dashboard.listings.index', query: { strategy: strategy.id, status: 'published' }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                        <span>{{ strategy.activeListings }}</span>
                        <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
                      </router-link>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Listings Assigned
                    </dt>
                    <dd class="mt-1">
                      <router-link target="_blank" :to="{name: 'dashboard.listings.index', query: { strategy: strategy.id }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                        <span>{{ strategy.listingsCount }}</span>
                        <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
                      </router-link>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">
                      Listings missing cost
                    </dt>
                    <dd class="mt-1">
                      <router-link target="_blank" :to="{name: 'dashboard.listings.index', query: { strategy: strategy.id, cost_presence: 'not_present' }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                        <span>{{ strategy.listingsWithoutCost }}</span>
                        <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
                      </router-link>
                    </dd>
                  </div>
                </div>
              </div>
            </dl>
          </CardBody>
        </Card>

      <Pagination class="sticky bottom-0" :totalItems="filters.total" :lastPage="filters.last_page" :perPage="filters.per" v-model="filters.page"/>

      <StrategyFormModal ref="strategyFormModal" />
    </div>
  </div>
</template>

<script>
import StrategyFormModal from '@/components/Strategy/StrategyFormModal.vue'
import _ from 'lodash'
import { EllipsisVerticalIcon, ArrowUpRightFromSquareIcon, TrashCanIcon, PlusIcon } from '@snsicons/icons/solid'

export default {
  components: {
    TrashCanIcon,
    EllipsisVerticalIcon,
    StrategyFormModal,
    ArrowUpRightFromSquareIcon,
    PlusIcon
  },
  data(){
    return {
      loading: false,
      strategies: [],
      filters: {
        page: 1,
        per: 100,
        total: 0,
        sort_by: 'name',
        sort_direction: 'asc'
      },
      strategiesHeaderTable: [
        {
          text: 'Name',
          value: 'name',
          sortable: 'name',
        },
        {
          text: 'Type',
          value: 'strategy_type',
        },
        {
          text: 'Listings count',
          value: 'listingsCount',
          sortable: 'listings_count'
        },
        {
          text: 'Created at',
          value: 'createdAt',
          sortable: 'created_at'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
    }
  },
  computed:{
    sortableHeaders(){
      let headers = []
      this.strategiesHeaderTable.filter(item => item.hasOwnProperty('sortable')).forEach(item => {
        headers.push({
          text: item.text,
          value: item.sortable,
        })
      })
      return headers
    }
  },
  mounted(){
    let vm = this
    this.fetchStrategies()
    this.$eventBus.on('strategy:created', function(){
      vm.fetchStrategies()
    })
    this.$eventBus.on('strategy:updated', function(){
      vm.fetchStrategies()
    })
    this.$eventBus.on('strategy:deleted', function(){
      vm.fetchStrategies()
    })
  },
  methods:{
    fetchStrategies(){
      this.loading = true
      this.$repositories.strategies.get(this.filters)
        .then((response) => {
          this.strategies = response.data
          this.filters.per = response.pagination.perPage
          this.filters.page = response.pagination.currentPage
          this.filters.total = response.pagination.total
          this.filters.last_page = response.pagination.totalPages
        })
        .finally(() => this.loading = false)
    },
    strategyType(strategy){
      if(strategy.strategyType == 'buy-box'){
        return 'Get the Buy Box'
      }
      if(strategy.strategyType == 'lowest-price'){
        return 'Compete with Lowest Price'
      }
      if(strategy.strategyType == 'featured-merchant'){
        return 'Compete with Lowest Featured Merchant'
      }
      return ''
    },
  }
}
</script>
