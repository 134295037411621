import BaseModel from "@/models/BaseModel"
import User from '@/models/User'

export default class Notification extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.recipient = null
    this.notificationType = null
    this.params = null
    this.readAt = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let note = new this()

    note.id = json.id
    note.recipient = this.initRelation(json.recipient, User, null)
    note.params = json.params
    note.notificationType = json.notification_type
    note.readAt = json.read_at
    note.createdAt = json.created_at
    note.updatedAt = json.updated_at

    return note
  }

  toJson(){
    return {
      notification: {
        id: this.id
      }
    }
  }
}