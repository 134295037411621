import { eventBus } from '@/composables/eventBus'

import APIService from '@/repositories/APIService'
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'order_cancellation_reasons'
import OrderLineCancellationReason from "@/models/OrderLineCancellationReason"

const platformModule = 'oms'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, OrderLineCancellationReason, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the brands'
      })

      throw error
    }
  },

}
