<template>
  <li class="md:flex-1">
    <a href="#" class="group pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" :class="borderStyle">
      <span class="text-sm font-medium">{{ title }}</span>
    </a>
  </li>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      borderStyle(){
        if(this.active){
          return "border-indigo-600 hover:border-indigo-800"
        }
        return "border-gray-200 hover:border-gray-300"
      }
    }
  }
</script>