import { RotateIcon, CircleCheckIcon, ListCheckIcon, SpellCheckIcon, SpinnerIcon, BugIcon, CircleExclamationIcon, ArrowRightFromBracketIcon } from '@snsicons/icons/solid'

const listingStatusesEnum = [
  {
    code: 'draft',
    name: 'Draft',
    description: 'Listings in the "Draft" status have not been sent to marketplaces yet. They require additional data to be filled in before they can be published.',
    color: 'draft',
    icon: ListCheckIcon
  },
  {
    code: 'ready_to_publish',
    name: 'Ready to publish',
    description: "Ready to publish status means that the listing is ready for publication, just one last review and change the status to 'In Progress'",
    color: 'ready_to_publish',
    icon: SpellCheckIcon
  },
  {
    code: 'in_progress',
    name: 'In Progress',
    description: '"In Progress" status indicates that the listings have been sent to marketplaces, and the system is awaiting a response from the marketplace to move forward.',
    color: 'progress',
    icon: RotateIcon
  },
  {
    code: 'published',
    name: 'Published',
    description: 'Listings marked as "Published" have been successfully ingested into the marketplace and are ready for sales. They are live and visible to customers.',
    color: 'published',
    icon: CircleCheckIcon
  },
  {
    code: 'failed',
    name: 'Failed',
    description: 'Listings in "Failed" status were not ingested by marketplaces due to various reasons. Immediate attention is needed to resolve the issues preventing publication.',
    color: 'failed',
    icon: BugIcon
  },
  {
    code: 'unpublished',
    name: 'Unpublished',
    description: 'Listings in "Unpublished" status are no longer visible on marketplace due to specific issues. Resolving these issues is necessary to make the listings visible again.',
    color: 'unpublished',
    icon: CircleExclamationIcon
  },
  {
    code: 'retired',
    name: 'Retired',
    description: '"Retired" status signifies that the quantity of the listing sent to the marketplace will be set to 0. It can be thought of as a soft delete since the item will not appear on the site.',
    color: 'retired',
    icon: ArrowRightFromBracketIcon
  },
  {
    code: 'deleting',
    name: 'Deleting',
    description: 'Listings marked as "Deleting" are currently in the process of being removed or deleted from the marketplace. This is a transitional status.',
    color: 'deleting',
    icon: ArrowRightFromBracketIcon
  },
  {
    code: 'deleted',
    name: 'Deleted',
    description: '"Deleted" status indicates that the listing has been successfully removed or deleted from the marketplace and is no longer available for sale. It represents a final and permanent removal.',
    color: 'deleted',
    icon: ArrowRightFromBracketIcon
  },
]

export default listingStatusesEnum