import BaseModel from "@/models/BaseModel"
import Product from '@/models/Product'
import Inventory from '@/models/Inventory'
import Warehouse from '@/models/Warehouse'

export default class Shipment extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.orderLineId = null
    this.status = null
    this.quantity = null
    this.carrier = null
    this.carrierService = null
    this.trackingNumber = null
    this.packingSlip = null
    this.label = null
    this.author = ''
    this.warehouse = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let shipment = new this()

    shipment.id = json.id
    shipment.orderLineId = json.order_line_id
    shipment.status = json.status
    shipment.quantity = json.quantity
    shipment.carrier = json.carrier
    shipment.carrierService = json.carrier_service
    shipment.trackingNumber = json.tracking_number
    shipment.label = json.label
    shipment.author = json.author
    shipment.packingSlip = json.packing_slip
    shipment.warehouse = this.initRelation(json.warehouse, Warehouse, null)
    shipment.createdAt = json.created_at
    shipment.updatedAt = json.updated_at

    return shipment
  }
}