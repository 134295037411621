<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
    <div class="sm:mx-auto sm:w-full sm:max-w-md" v-if="(sent == false)">
      <img class="mx-auto h-12 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell">
      <h2 class="mt-6 mb-1 text-center text-lg font-semibold text-gray-900">Reset your password</h2>
      <p class="text-center text-sm">Enter your email and we'll send you a link to reset your password.</p>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md" v-else>
      <img class="mx-auto h-12 w-auto" src="/assets/logo_normal_full.svg" alt="Sitnsell">
      <h2 class="mt-6 mb-1 text-center text-lg font-semibold text-gray-900">We’ve sent you a password reset link</h2>
      <p class="text-center text-sm">If the email exists in our database, you will receive an email with the password reset link.</p>
      <div class="mt-6 gap-3">
        <router-link :to="{name: 'dashboard.login'}" class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
          Sign in
        </router-link>
      </div>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md" v-if="sent == false">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
          <div>
            <Label name="email" required :errors="errors">{{ $t('E-mail') }}</Label>
            <InputText v-model="email" name="email" class="w-full" :errors="errors"/>
          </div>

          <div>
            <Button @click="resetPassword" class="w-full">
              {{ $t('Reset your password') }}
            </Button>
          </div>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="bg-white px-2 text-gray-500">Did you remember your password?</span>
            </div>
          </div>

          <div class="mt-6 gap-3">
            <router-link :to="{name: 'dashboard.login'}" class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
              Sign in
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return {
      email: '',
      sent: false,
      loading: false,
    }
  },
  methods: {
    async resetPassword(){
      this.loading = true
      try{
        await this.$repositories.users.askResetPassword({reset_password: { email: this.email }})
        this.sent = true
      }catch (e) {
        this.email = ''
      }

      this.loading = false
    },
  }
}
</script>
