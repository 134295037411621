import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'users'
const version = 'v1'
import User from "@/models/User"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`account/${version}/${resource}`, User, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the brands'
      })

      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`account/${version}/${resource}/${id}`, User, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the brand!'
      })
      throw error
    }
  },

  async create(payload){
    try{
      let response = await APIService.postEntity(`account/${version}/${resource}`, payload)
      eventBus.emit('user:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Brand was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create user. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`account/${version}/${resource}/${id}`, payload)
      eventBus.emit('user:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'User was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update user. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`account/${version}/${resource}/${id}`)
      eventBus.emit('user:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'User was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete user. Please try again later!'
      })

      throw error
    }
  },

  async askResetPassword(payload){
    try{
      let response = await APIService.post(`${version}/reset_passwords`, payload)
      eventBus.emit('user:password_reset')

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not reset password. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async showResetPassword(resetToken){
    try{
      return await APIService.fetchEntity(`${version}/reset_passwords/${resetToken}`, User)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not reset password. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async resetPassword(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/reset_passwords/${id}`, payload)
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not reset password. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

}
