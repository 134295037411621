<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class='pb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0 mt-3'>
      <h3 class="text-xl font-bold leading-6 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        {{ $t('Connect New Sales Channel Account') }}
      </h3>
    </div>


    <div class="my-8 px-4">
      <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4" v-if="platform == null">
        <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow" v-for="p in platforms" :key="p.id">
          <div class="flex-1 flex flex-col p-8">
            <img class="w-20 h-20 flex-shrink-0 mx-auto bg-opacity-100 " :src="p.logo" :alt="p.name">
            <h3 class="text-gray-900 leading-5 font-medium">{{ p.name }}</h3>
            <dl class="mt-1 flex-grow flex flex-col justify-between">
              <dd class="text-gray-500 text-sm leading-5">{{ p.short_description }}</dd>
              <dd class="mt-3">
                <Button sm @click="selectPlatform(p)">
                  Connect
                </Button>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
    <ConnectEbayModal ref="connectEbayModal" />
  </div>
</template>
<script>
  import ConnectEbayModal from '@/components/SalesChannel/ConnectEbayModal.vue'
  export default {
    components: {
      ConnectEbayModal
    },
    data(){
      return {
        loading: false,
        platforms: [],
        platform: null,
      }
    },
    mounted(){
      this.fetchPlatforms()
    },
    methods: {
      async fetchPlatforms(){
        this.loading = true
        try{
          let response = await this.$repositories.platforms.get()
          this.platforms = response.data 
        }catch(e){
          console.log(e)
        }finally{
          this.loading = false
        }
        
      },
      async selectPlatform(platform){
        if(platform.code === 'ATVPDKIKX0DER'){
          try{
            let response = await this.$repositories.salesChannels.consentUrl("amazon")
            window.location.replace(response.data.url)
          }catch(e){
            console.log(e)
          }
        }else if(platform.code === 'EBAY_US'){
          // this.$refs.connectEbayModal.open(platform.id)
          try{
            let response = await this.$repositories.salesChannels.consentUrl("ebay", {platform_id: platform.id})
            window.location.replace(response.data.url)
          }catch(e){
            console.log(e)
          }
        }else {
          this.platform = platform
          this.$bus.$emit('salesChannel:create', platform)
        }
      },
    }
  }
</script>