<template>
  <div class="flex flex-col items-center cursor-pointer text-center bg-white relative">
    <Loading v-model:active="loading" :is-full-page="false"/>
    <img :src="fileResource.url" class="h-11 w-11" v-if="fileResource.id"/>
    <img src="@/assets/no-picture.png" class="h-11 w-11" v-else/>
    
  </div>
</template>
<script>
import FileResource from "@/models/FileResource"

  export default {
    props: {
      fileResourceId: {
        type: String,
        default: '',
      }
    },
    data(){
      return {
        loading: false,
        fileResource: new FileResource()
      }
    },
    watch: {
      fileResourceId: function(val){
        if(val){
          this.fetchFileResource()
        }
      }
    },
    mounted(){
      if(this.fileResourceId){
        this.fetchFileResource()
      }
    },
    methods: {
      async fetchFileResource(){
        this.loading = true
        try{
          let response = await this.$repositories.fileResources.find(this.fileResourceId)
          this.fileResource = response.data
        }catch(e){

        }finally{
          this.loading = false
        }
      }
    },
  }
</script>