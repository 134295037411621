import BaseModel from "@/models/BaseModel"

export default class PriceHistory extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.newPrice = null
    this.oldPrice = null
    this.competitionPrice = null
    this.competitionDetails = null
    this.currency = {}
    this.meta = null
    this.isBuyBox = false
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let priceHistory = new this()

    priceHistory.id = json.id
    priceHistory.oldPrice = json.old_price
    priceHistory.newPrice = json.new_price
    priceHistory.competitionPrice = json.competition_price
    priceHistory.currency = json.currency
    priceHistory.meta = json.meta
    priceHistory.isBuyBox = json.buy_box
    priceHistory.competitionDetails = json.competition_details
    priceHistory.createdAt = json.created_at
    priceHistory.updatedAt = json.updated_at

    return priceHistory
  }
}