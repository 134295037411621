<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Update Payment Method
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex w-full border-t py-2">
        <div class="flex-shrink space-x-2">
          <button class="text-blue-700 ml-2 p-2 text-sm hover:underline" @click="close">
            Cancel
          </button>
        </div>
        <div class="flex-grow text-right">
          <Button blue @click="addCard">
            Add payment Method
          </Button>
        </div>
      </div>
    </template>
    <p class="text-sm text-red-400" v-if="cardError">
      {{ cardError }}
    </p>
    <StripeElements
      v-if="stripeLoaded"
      v-slot="{ elements, instance }"
      ref="elms"
      :stripe-key="stripeKey"
      :instance-options="instanceOptions"
      :elements-options="elementsOptions"
    >
      <StripeElement
        ref="card"
        :elements="elements"
        :options="cardOptions"
      />
    </StripeElements>
  </FormModal>
</template>
<script>
  import { StripeElements, StripeElement } from 'vue-stripe-js'
  import Company from '@/models/Company'
  import { loadStripe } from '@stripe/stripe-js'
  import { defineComponent, ref, onBeforeMount } from 'vue'
  import _ from 'lodash'
import CardOnly from './CardOnly.vue'
  
  export default {
    components: {
      StripeElements,
      StripeElement,
      CardOnly
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        stripe: '',
        elements: '',
        company: new Company(),
        errors: [],
        cardError: '',
      }
    },
    setup(props, context) {
      console.log(context)
      const stripeKey = ref(process.env.VUE_APP_STRIPE_PUBLIC_KEY) // test key
      const instanceOptions = ref({
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      })
      const elementsOptions = ref({
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      })
      const cardOptions = ref({
        // https://stripe.com/docs/stripe.js#element-options
        type: 'card',
        
      })
      const stripeLoaded = ref(false)
      const card = ref()
      const elms = ref()

      onBeforeMount(() => {
        const stripePromise = loadStripe(stripeKey.value)
        stripePromise.then(() => {
          stripeLoaded.value = true
        })
      })

      return {
        stripeKey,
        stripeLoaded,
        instanceOptions,
        elementsOptions,
        cardOptions,
        card,
        elms,
      }
    },

    computed: {
      isEditing(){
        return this.company.hasOwnProperty('id')
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let company = new Company()
          _.assign(company, preset)
          this.company = company
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.company = new Company()
        this.stripe = null
      },
      async saveCard () {
        const stripe = this.$refs.cardElementRef.stripe;
        const card = this.$refs.cardElementRef.element;
        const paymentMethod = await stripe.createPaymentMethod('card', card);
        this.updatePaymentMethod(paymentMethod.paymentMethod)
      },

      async addCard() {
        // Get stripe element
        const cardElement = this.card.stripeElement
        console.log(cardElement)

        // Access instance methods, e.g. createToken()
        this.elms.instance.createPaymentMethod({card: cardElement, type: 'card'}).then((result) => {
          this.cardError = ''
          if(result.error){
            this.cardError = result.error.message
          }else{
            this.updatePaymentMethod(result.paymentMethod)
          }
        })
      },
      async updatePaymentMethod(paymentMethod){
        console.log(paymentMethod)
        this.savingForm = true
        this.company.stripeCardId = paymentMethod.id
        this.company.cardInfo = {
          funding: paymentMethod.card.funding,
          brand: paymentMethod.card.brand,
          last4: paymentMethod.card.last4,
          exp_month: paymentMethod.card.exp_month,
          exp_year: paymentMethod.card.exp_year,
        }
        try{
          await this.$repositories.billingProfile.update(this.company.id, this.company)
          this.close()
        }catch(error){
          this.errors = error.errors
        }
        this.savingForm = false
      },
    }
  }
</script>