import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'shelves'
const version = 'v1'
import Shelf from "@/models/Shelf"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Shelf, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the shelves'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Shelf, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the shelves!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('shelf:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Shelf was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create shelf. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('shelf:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Shelf was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update shelf. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async wipe(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}/wipe`, payload)
      eventBus.emit('shelf:wiped')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Shelf inventory was wiped!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not wipe shelf inventory. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.destroyEntity(`${version}/${resource}/${id}`)
      eventBus.emit('shelf:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Shelf was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete shelf. Please try again later!'
      })

      throw error
    }
  }

}
