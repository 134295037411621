import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Department from "@/models/Department"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'departments'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Department, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the categories'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Department, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the category!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('department:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Category was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }

      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create category. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('department:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Category was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not update category. Please verify form!'
      })
      throw error
    }
  },

  async destroy(id, payload = {}){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`, payload)
      eventBus.emit('department:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Category was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not delete category. Please try again later!'
      })
      throw error
    }
  },

}
