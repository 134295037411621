import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Pull from "@/models/Pull"

import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'pulls'
const version = 'v1'

export default {
  async create(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('pull:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Pull was created with success!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not cancel order line. Please try again later!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },
}
