<script>
import _ from 'lodash'
export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      loading: true,
      fullPage: false,
      meta: { sums: {} },
      tableData: [],
      tableHeaders: [
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'Sales Volume',
          value: 'sales_volume',
        },
        {
          text: 'Gross Sales',
          value: 'gross_sales',
        },
        {
          text: 'Gross Profit',
          value: 'gross_profit',
        },
        {
          text: 'Gross Profit Margin',
          value: 'gross_profit_margin',
        },
      ],
      chartTypes: [
        {
          title: 'Sales Volume',
          key: 'sales_volume',
        },
        {
          title: 'Orders Volume',
          key: 'orders_volume',
        },
        {
          title: 'Gross Sales',
          key: 'gross_sales',
        },
        {
          title: 'Gross Profit',
          key: 'gross_profit',
        },
        {
          title: 'Gross Profit Margin (%)',
          key: 'gross_profit_margin',
        }
      ],
      selectedChart: 'sales_volume',
      date: {
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      currentDate: new Date(),
      salesChannels: [],
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.fetchChartData()
      },
      deep: true,
    },
  },
  mounted(){
    this.fetchChartData()
  },
  computed: {
    chartData(){
      return [
        {
          name: _.find(this.chartTypes, {key: 'gross_profit'}).title,
          data: _.map(this.tableData, 'gross_profit')
        },
        {
          name: _.find(this.chartTypes, {key: 'gross_sales'}).title,
          data: _.map(this.tableData, 'gross_sales')
        },
      ]
    },
    ordersVolumeData(){
      return [
        {
          name: _.find(this.chartTypes, {key: 'sales_volume'}).title,
          data: _.map(this.tableData, 'sales_volume'),
          type: 'line',
        },
        {
          name: _.find(this.chartTypes, {key: 'orders_volume'}).title,
          data: _.map(this.tableData, 'orders_volume'),
          type: 'column',
        },
      ]
    },
    chartOptions(){
      return {
        chart: {
          type: 'area',
          height: 350,
        },
        colors: ['#008FFB', '#00E396', '#CED4DC'],
        // plotOptions: {
        //   bar: {
        //     horizontal: false,
        //     columnWidth: '55%',
        //     endingShape: 'rounded',
        //   },
        // },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: _.map(this.tableData, 'formatted_datetime'),
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    ordersVolumeOptions(){
      return {
        chart: {
          height: 350,
        },
        colors: ['#008FFB', '#00E396'],
        // plotOptions: {
        //   bar: {
        //     horizontal: false,
        //     columnWidth: '55%',
        //     endingShape: 'rounded',
        //   },
        // },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: _.map(this.tableData, 'datetime'),
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    salesChannelOptions(){
      let options = []
      this.salesChannels.forEach((salesChannel) => {
        options.push({
          label: salesChannel.name+' ('+salesChannel.platform.name+')',
          code: salesChannel.id,
        })
      })
      return options
    },
  },
  methods: {
    async fetchChartData(){
      this.loading = true
      try{
        let response = await this.$repositories.charts.sales({...this.filters, ...{from: this.filters.date[0], to: this.filters.date[1]}})
        this.meta = response.meta
        this.tableData = response.data
      }catch(e){

      }finally{
        this.loading = false
      }
    },
  }
}
</script>
<template>
  <div>
    <Loading v-model:active="loading" :is-full-page="fullPage"/>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6" v-for="chart in chartTypes" :key="chart.key">
        <dt class="text-sm font-medium truncate" :class="'text-gray-500'">
          {{ chart.title }}
        </dt>
        <dd class="mt-1 text-3xl font-semibold" :class="'text-gray-900'">
          {{ meta.sums[chart.key] }}
        </dd>
      </div>
    </dl>
  
    <div class="grid grid-cols-1 md:grid-cols-4 mt-5 gap-x-3">
      <ChartCard class="col-span-2">
        <template v-slot:title>
          {{ $t('Revenue Chart') }}
        </template>
        <apexchart height="400" :options="chartOptions" :series="chartData"></apexchart>
      </ChartCard>
      <ChartCard class="col-span-2">
        <template v-slot:title>
          {{ $t('Orders Volume') }}
        </template>
        <apexchart height="400" :options="ordersVolumeOptions" :series="ordersVolumeData"></apexchart>
      </ChartCard>
    </div>
  </div>

  
</template>