import actions from './actions'
import state from './state'
import getters from './getters'
import mutations from './mutations'

const order_cancellation_reasons = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

export default order_cancellation_reasons