import BaseModel from "@/models/BaseModel"

export default class ScheduledReport extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.reportType = null
    this.frequency = null
    this.timeOfDay = 12
    this.dayOfWeek = null
    this.dayOfMonth = null
    this.filters = {}
    this.recipients = ['']
    this.emailable = false
    this.createdBy = null
    this.timeZone = null
    this.frequencyInWords = ''
    this.cycles = 0
    this.ranAt = null
    this.nextAt = null
    this.updatedBy = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let scheduledReport = new this()

    scheduledReport.id = json.id
    scheduledReport.reportType = json.report_type
    scheduledReport.name = json.name
    scheduledReport.frequencyInWords = json.frequency_in_words
    scheduledReport.frequency = json.frequency
    scheduledReport.timeOfDay = json.time_of_day
    scheduledReport.dayOfWeek = json.day_of_week
    scheduledReport.dayOfMonth = json.day_of_month
    scheduledReport.filters = json.filters
    scheduledReport.recipients = json.recipients
    scheduledReport.emailable = json.emailable
    scheduledReport.createdBy = json.created_by
    scheduledReport.timeZone = json.time_zone
    scheduledReport.cycles = json.cycles
    scheduledReport.ranAt = json.ran_at
    scheduledReport.nextAt = json.next_at
    scheduledReport.updatedBy = json.updated_by
    scheduledReport.createdAt = json.created_at
    scheduledReport.updatedAt = json.updated_at

    return scheduledReport
  }

  toJson(){
    return {
      scheduled_report: {
        name: this.name,
        report_type: this.reportType,
        frequency: this.frequency,
        time_of_day: this.timeOfDay,
        day_of_week: this.dayOfWeek,
        day_of_month: this.dayOfMonth,
        filters: this.filters,
        recipients: this.recipients,
        time_zone: this.timeZone,
        emailable: this.emailable
      }
    }
  }
}