export default {
  add(state, packageType){
    let found = state.packageTypes.find((old) => old.id == packageType.id)
    if(found){
      found = packageType
    }else{
      state.packageTypes.push(packageType)
    }
  },
  update(state, packageType){
    let found = state.packageTypes.find((old) => old.id == packageType.id)
    if(found){
      let index = state.packageTypes.indexOf(found)
      state.packageTypes[index] = packageType
    }
  },
  reset(state){
    state.packageTypes = []
  }
}