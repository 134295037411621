import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import ServerError from '@/repositories/Response/ServerError'
import DataResponse from '@/repositories/Response/DataResponse'
import PagedPagination from '@/models/PagedPagination'
import ValidationError from '@/repositories/Response/ValidationError'
import PaginatedResponse from '@/repositories/Response/PaginatedResponse'
import CanceledRequest from "@/repositories/Response/CanceledRequest";
import TokenStorageService from "@/services/TokenStorageService";
import AuthorizationError from "@/repositories/Response/AuthorizationError";
import NotFoundError from '@/repositories/Response/NotFoundError';



const BASE_URL = process.env.VUE_APP_API_URL
const CancelToken = axios.CancelToken;


const createCancelTokenHandler = (propertyName) => {
  // initializing the cancel token handler object
  const cancelTokenHandler = {};

  const cancelTokenRequestHandler = {
    cancelToken: undefined
  }

  cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`)

  // creating a new cancel token
  cancelTokenRequestHandler.cancelToken = CancelToken.source();

  // returning the new cancel token
  return cancelTokenRequestHandler.cancelToken;
}

export default {
  _axios: null,
  _tokenRefreshRequest: null,

  get api () {
    if (this._axios === null) {
      this.refresh()
    }

    return this._axios
  },

  refresh () {
    this._axios = axios.create({
      baseURL: BASE_URL,
      timeout: 60000,
      headers: {
        Authorization: TokenStorageService.getToken(),
        'Access-Control-Allow-Origin': '*',
      },
    })

    this.setInterceptors()
  },

  /**
   * Fetch entities from api and transform them into model.
   *
   * @param   {string} path
   * @param   {object} model
   * @param   {object} params
   * @returns {Promise<DataResponse|ServerError>}
   */
  async fetchEntities (path, model, params = {}) {
    let cancelTokenHandlerObject = createCancelTokenHandler(model.constructor.name+'-fetchEntities')
    try {
      console.log(cancelTokenHandlerObject)
      const response = await this.get(path, params, cancelTokenHandlerObject)
      
      let entities = response.data.data.map(entity => model.fromJson(entity))
      let pagination = _.get(response.data, 'meta.pagination')

      if (pagination) {
        return new PaginatedResponse(entities, PagedPagination.fromJson(pagination))
      }

      return new DataResponse(entities)
    } catch (result) {
      console.log(result)
      this.handleError(result)
    }
  },

  async fetchEntity (path, model, params = {}) {
    try {
      const response = await this.api.get(path, { params })

      return new DataResponse(model.fromJson(response.data.data))
    } catch (result) {
      this.handleError(result)
    }
  },

  async postEntity (path, model) {
    try {
      const response = await this.api.post(path, model.toJson())

      return new DataResponse(model.constructor.fromJson(response.data.data))
    } catch (result) {
      this.handleError(result)
    }
  },

  async putEntity (path, model) {
    try {
      const response = await this.api.put(path, model.toJson())

      return new DataResponse(model.constructor.fromJson(response.data.data))
    } catch (result) {
      this.handleError(result)
    }
  },

  async get (path, params = {}, cancelTokenHandlerObject = null) {
    console.log(cancelTokenHandlerObject)
    if(cancelTokenHandlerObject){
      return await this.api.get(path, { params: this.transformParamsToQueryStrings(params) }, { cancelToken: cancelTokenHandlerObject.token })
    }else{
      return await this.api.get(path, { params: this.transformParamsToQueryStrings(params) })
    }
  },

  async post (path, data, config = {}) {
    return await this.api.post(path, data, config)
  },

  async put (path, data, params = {}) {
    return await this.api.put(path, data, { params: this.transformParamsToQueryStrings(params) })
  },

  async delete (path, payload, config = {}) {
    try {
      return await this.api.delete(path, payload, config)
    } catch (result) {
      this.handleError(result)
    }
  },

  transformParamsToQueryStrings(params = null) {
    if (!params || typeof params !== 'object') {
      return params
    }

    let newParams = {}
    _.map(params, (paramValue, key, object) => {
      if (paramValue && typeof paramValue === "object") {
        let objectKeys = Object.keys(paramValue)
        if (objectKeys.includes('comparison') && objectKeys.includes('value')) {
          if (Array.isArray(paramValue.value) && paramValue.value.length === 0) {
            return;
          }

          let paramKey = `${key}[${paramValue.comparison}]`
          newParams[paramKey] = Array.isArray(paramValue.value) ? paramValue.value.join(',') : paramValue.value

          return;
        }
      }

      newParams[key] = paramValue
    })

    return newParams
  },

  /**
   * @param  {Error} result
   * @throws {ServerError|ValidationError}
   */
  handleError (result) {
    console.log(result)
    if (process.env.NODE_ENV === 'development') {
      console.log(result.response)
    }

    if (axios.isCancel(result)) {
      throw new CanceledRequest()
    }

    if(!result.response){
      throw new ServerError()
    }

    switch (result.response.status) {
      case 422:
        throw new ValidationError(result.response.data)

      case 403:
        throw new AuthorizationError(result.response.data)

      case 404:
        throw new NotFoundError(result.response.data)

      default:
        throw new ServerError()
    }
  },

  async withErrorHandling (callback) {
    try {
      return await callback(this)
    } catch (e) {
      this.handleError(e)
    }
  },

  async setInterceptors () {
    // Response interceptor
    await this.api.interceptors.response.use(function (response) {
      return response;
    }, async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401) {
        if (error.response.status === 401) {
          TokenStorageService.deleteToken()
          window.location = '/login'
        }
      }

      throw error
    });
  },

  async refreshAuthToken (originalRequest) {
    let response = await this.api.post('/auth/refresh-token', {
      timezone: moment.tz.guess()
    })

    if (response.headers.authorization) {
      TokenStorageService.setToken(response.headers.authorization)
      // if (Vue.prototype.$webSockets) {
      //   Vue.prototype.$webSockets.setAuthToken(response.headers.authorization)
      // }
    }

    // Transform the original request to use the new token
    let authorizedRequest = originalRequest

    authorizedRequest.url = authorizedRequest.url.replace(BASE_URL, '')
    authorizedRequest.headers['Authorization'] = response.headers.authorization

    // Proceed to the original request
    return await this.api(authorizedRequest);
  },
}

