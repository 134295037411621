<script>
  import { RotateIcon, HouseIcon } from '@snsicons/icons/regular'
  import ScanShelfIntro from '@/components/Scanning/ScanShelfIntro.vue'

  export default {
    components: {
      HouseIcon,
      ScanShelfIntro,
      RotateIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    methods: {
      scan(event){
        if(event.code === 'Enter'){
          this.fetchShelf()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      async fetchShelf(){
        this.loading = true
        try{
          let response = await this.$repositories.scannings.shelf({barcode: this.barcode, warehouse_id: this.$store.getters['user_prefs/currentWarehouse']})
          await this.rescan(response.data.id)
        }catch(e){
        console.log(e)
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
      async rescan(shelfId){
        this.loading = true
        try{
          await this.$repositories.scannings.rescan({inventory_movement: { shelf_id: shelfId } })
          this.$router.push({name: 'scanning.rescan.shelf', params: {shelfId: shelfId}})
        }catch(e){
          console.log(e)
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
    }
  }
</script>
<template>
  <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
    <loading :active="loading" :is-full-page="false"></loading>
    <router-link :to="{name: 'scanning.home'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
      <HouseIcon class="h-5 text-blue-500" />
      <span>Go Home</span>
    </router-link>
    <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
      <span class="bg-yellow-50 text-yellow-500 rounded-lg inline-flex p-4 ring-4 ring-white">
        <RotateIcon class="h-5 w-5"/>
      </span>
      <div class="flex flex-col justify-start">
        <h3 class="font-semibold text-base">Rescan Shelf</h3>
        <p class="text-sm text-gray-500">Scan the shelf you want to reset inventory in order to continue. <span class="font-semibold">Scanning the barcode of the shelf will result in the deletion of the inventory from the scanned shelf.</span></p>
      </div>
    </div>
    <ScanShelfIntro />

  </div>
</template>