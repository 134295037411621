<script>
import RevenueWidget from "@/components/Widgets/RevenueWidget.vue"
import { MagnifyingGlassIcon, CircleInfoIcon } from '@snsicons/icons/solid'
import _ from 'lodash'
import moment from 'moment'
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';
import InventoryByBrandPieWidget from "@/components/Widgets/InventoryByBrandPieWidget.vue";
import InventoryByDepartmentPieWidget from "../../components/Widgets/InventoryByDepartmentPieWidget.vue";
import InventoryCostByBrandPieWidget from "../../components/Widgets/InventoryCostByBrandPieWidget.vue";
import InventoryCostByDepartmentPieWidget from "../../components/Widgets/InventoryCostByDepartmentPieWidget.vue";

export default {
    data() {
      return {
        loading: true,
        warehouses: [],
        tableData: [],
        filters: {
            warehouse: "",
            category: "",
            brand: "",
            sales_channel: "",
            date: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
        },
        salesMeta: {
          sums: {}
        },
        chartTypes: [
          {
            title: 'Going Ins (Qty.)',
            key: 'going_ins',
          },
          {
            title: 'Going Outs (Qty.)',
            key: 'going_outs',
          },
        ],
        inventoryCount: 0,
        productsCount: 0,
        shipmentsCount: 0,
      };
    },
    watch: {
        filters: {
            handler(newValue) {
                this.fetchInventories();
                this.fetchProducts();
                this.fetchShipments();
                this.fetchChartData()
            },
            deep: true,
        },
    },
    computed: {
      datePresets() {
        return [
          { label: 'Today', range: [new Date(), new Date()] },
          { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
          {
            label: 'Last month',
            range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
          },
          {
            label: 'Last 3 months',
            range: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())],
          },
          {
            label: 'Last 6 months',
            range: [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())],
          },
          { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] },
          { label: 'Last year', range: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
        ]
      },
      chartData(){
        return [
          {
            name: _.find(this.chartTypes, {key: 'going_ins'}).title,
            data: _.map(this.tableData, 'going_ins')
          },
          {
            name: _.find(this.chartTypes, {key: 'going_outs'}).title,
            data: _.map(this.tableData, 'going_outs')
          },
        ]
      },
      chartOptions(){
        return {
          chart: {
            type: 'area',
            height: 350,
          },
          colors: ['#00E396', '#E91E63'],
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '55%',
          //     endingShape: 'rounded',
          //   },
          // },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: _.map(this.tableData, 'formatted_datetime'),
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            }
          },
          markers: {
            size: 4,
            colors: ['#00E396', '#E91E63'],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
              size: 7,
            }
          },
          yaxis: {
            show: true
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        }
      },
      filterOptions(){
        const options = [
          {
            name: 'brand',
            display: 'Brand',
            options: this.brandOptions,
            type: 'single',
            verb: 'is',
            group: 'Details',
          },
          {
            name: 'category',
            display: 'Category',
            options: this.categoryOptions,
            type: 'single',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'warehouse',
            display: 'Warehouse',
            options: this.warehouseOptions,
            type: 'single',
            verb: 'is',
            group: 'Location'
          },
          {
            name: 'term',
            display: 'Search Term',
            type: 'hidden',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'date',
            display: 'Date',
            type: 'alwaysHidden',
            verb: 'is',
            group: 'Details'
          },
        ]
        return options
      },
      warehouseOptions() {
          let options = [];
          this.warehouses.forEach((warehouse) => {
              options.push({
                  label: warehouse.name,
                  code: warehouse.id,
              });
          });
          return options;
      },
      brandOptions() {
          let options = [];
          this.$store.getters["brands/all"].forEach((brand) => {
              options.push({
                  label: brand.name,
                  code: brand.id,
              });
          });
          return options;
      },
      categoryOptions() {
          let options = [];
          this.$store.getters["categories/all"].forEach((category) => {
              options.push({
                  label: category.name,
                  code: category.id,
              });
          });
          return options;
      },
      salesChannelOptions(){
        let options = []
        this.$store.getters["sales_channels/all"].forEach((salesChannel) => {
          options.push({
            label: salesChannel.name,
            code: salesChannel.id,
          })
        })
        return options
      },
    },
    mounted() {
      this.fetchWarehouses();
      this.fetchInventories();
      this.fetchProducts();
      this.fetchShipments();
      this.fetchChartData()
      this.$store.dispatch("brands/fetch")
      this.$store.dispatch("categories/fetch")
      this.$store.dispatch("sales_channels/fetch")
    },
    methods: {
        async fetchProducts() {
            this.loading = true;
            this.products = [];
            try {
                let response = await this.$repositories.products.get({ ...this.filters, ...{ per: 1 } });
                this.productsCount = response.pagination.total;
            }
            catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async fetchWarehouses() {
            try {
                let response = await this.$repositories.warehouses.get({ per: 10000 });
                this.warehouses = response.data;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchShipments() {
            try {
                let response = await this.$repositories.warehouses.get({ ...this.filters, ...{ per: 1, brand_id: this.filters.brand } });
                this.shipmentsCount = response.pagination.total;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchChartData(){
          try{
            let response = await this.$repositories.charts.inventoryMovements({...this.filters, ...{from: this.filters.date[0], to: this.filters.date[1]} })
            this.tableData = response.data
          }catch(_){}
        },
        debounceTerm: _.debounce(function (event) {
          this.filters.term = event.target.value
        }, 800),
        async fetchInventories() {
            this.loading = true;
            this.inventories = [];
            try {
                let response = await this.$repositories.inventories.get({ ...this.filters, ...{ per: 1, brand_id: this.filters.brand } });
                this.inventoryCount = response.pagination.searchCount[0].total;
            }
            catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
    components: { MagnifyingGlassIcon, CircleInfoIcon, InventoryByBrandPieWidget, InventoryByDepartmentPieWidget, InventoryCostByBrandPieWidget, InventoryCostByDepartmentPieWidget }
}
</script>
<template>
  <div>
    <div class="w-full flex justify-between pb-2">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div class="flex items-center space-x-2">
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" placeholder="Search...." />
        </div>
      </div>
    </div>
    <div>
      <dl class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-5">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="flex items-center space-x-1 text-sm font-medium text-gray-500 truncate">
            <span>Inventory as of today</span>
            <CircleInfoIcon class="h-3 w-3 text-gray-600" v-tooltip="'Based on your applied filters'"/>
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ inventoryCount }}
          </dd>
        </div>
      </dl>
    </div>
    <div class="mt-4">
      <ChartCard class="col-span-2">
        <template v-slot:title>
          {{ $t('Inventory Movements') }}
        </template>
        <template v-slot:subtitle>
          {{ 'Inventory Movements during period ' }} <strong>{{ moment(filters.date[0]).format("dddd, MMMM Do YYYY") }}</strong> {{ ' and ' }} <strong>{{ moment(filters.date[1]).format("dddd, MMMM Do YYYY") }} </strong>
        </template>
        <template v-slot:actions>
          <Datepicker v-model="filters.date" range :enableTimePicker="false" :presetRanges="datePresets" position="right"/>
        </template>
        <apexchart height="400" :options="chartOptions" :series="chartData"></apexchart>
      </ChartCard>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      <ChartCard >
        <template v-slot:title>
          {{ $t('Inventory By Brand') }}
        </template>
        <template v-slot:subtitle>
          {{ 'Only the warehouse filter can be applied to this chart' }}
        </template>
        <InventoryByBrandPieWidget :filters="filters" />
      </ChartCard>
      <ChartCard >
        <template v-slot:title>
          {{ $t('Inventory Cost By Brand') }}
        </template>
        <template v-slot:subtitle>
          {{ 'Only the warehouse filter can be applied to this chart.' }}
        </template>
        <InventoryCostByBrandPieWidget :filters="filters" />
      </ChartCard>
      <ChartCard >
        <template v-slot:title>
          {{ $t('Inventory By Category') }}
        </template>
        <template v-slot:subtitle>
          {{ 'Only the warehouse filter can be applied to this chart' }}
        </template>
        <InventoryByDepartmentPieWidget :filters="filters" />
      </ChartCard>
      <ChartCard >
        <template v-slot:title>
          {{ $t('Inventory Cost By Category') }}
        </template>
        <template v-slot:subtitle>
          {{ 'Only the warehouse filter can be applied to this chart.' }}
        </template>
        <InventoryCostByDepartmentPieWidget :filters="filters" />
      </ChartCard>
    </div>
  </div>
</template>