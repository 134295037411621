<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div :class="['w-screen', windowSize]">
              <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Panel title
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <slot/>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
  import { XIcon } from '@heroicons/vue/24/solid'

  export default {
    components: {
      XIcon
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'md'
      }
    },
    emits: ['close'],
    data(){
      return {
        opened: false,
      }
    },
    watch: {
      show: function(newValue){
        this.opened = newValue
      }
    },
    computed:{
      windowSize(){
        let size = 'max-w-md'
        if(this.size == 'md'){
          size = 'max-w-md'
        }

        if(this.size == 'lg'){
          size = 'max-w-2xl'
        }

        if(this.size == 'xl'){
          size = 'max-w-3xl'
        }
        return size
      }
    },
    methods: {
      close(){
        this.opened = false
        this.$emit('close')
      }
    }
  }
</script>