import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'suppliers'
const version = 'v1'
import Supplier from "@/models/Supplier"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Supplier, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the suppliers!'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Supplier, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the supplier!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('supplier:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Supplier was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }

      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create supplier. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('supplier:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Supplier was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update supplier. Please verify form!'
      })
      throw error
    }
  },

  async destroy(id, payload = {}){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`, payload)
      eventBus.emit('supplier:deleted')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Supplier was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete supplier. Please try again later!'
      })
      throw error
    }
  },

  // image(id, payload) {
    // return new Promise((resolve, reject) => {
      // Vue.prototype.$api.put(`${version}/${resource}/${id}/image`, payload)
      //   .then((response) => {
      //     eventBus.emit('app:message', {
      //       type: 'success',
      //       message: 'Product images was uploaded with success!'
      //     })
      //     eventBus.emit('supplier:updated')
      //     resolve(response)
      //   })
      //   .catch((error) => {
      //     if (!Vue.prototype.$api.isCancel(error)) {
      //       eventBus.emit('app:message', {
      //         type: 'error',
      //         message: 'Could not update product image. Please verify form!'
      //       })
      //     }
      //     reject(error)
      //   })
    // })
  // }

}
