import BaseModel from "@/models/BaseModel"

export default class Currency extends BaseModel{
  constructor(json) {
    super(json)

    this.id = ''
    this.symbolFirst = true
    this.thousandsSeparator = ','
    this.symbol = ''
    this.isoCode = ''
    this.subUnit = ''
    this.decimalMark = '.'
    this.smallestDenomination = 0
    this.subunitToUnit = 0
  }

  static fromJson(json){
    let currency = new this()

    currency.id = json.id
    currency.decimalMark = json.decimal_mark
    currency.isoCode = json.iso_code
    currency.thousandsSeparator = json.thousands_separator
    currency.smallestDenomination = json.smallest_denomination
    currency.subUnit = json.sub_unit
    currency.subunitToUnit = json.subunit_to_unit
    currency.symbol = json.symbol
    currency.symbolFirst = json.symbol_first

    return currency
  }
}