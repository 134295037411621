import BaseModel from "@/models/BaseModel"
import RateRequestLocation from "./RateRequestLocation"
import moment from 'moment'

export default class RateRequest extends BaseModel{
  constructor(json) {
    super(json)

    this.heightValue = 0
    this.lengthValue = 0
    this.orderLineIds = []
    this.shipDate = moment().format("YYYY-MM-DD")
    this.shipFrom = {}
    this.shipTo = {}
    this.sizeUnit = 'inch'
    this.warehouseId = null
    this.weightUnit = 'lb'
    this.weightValue = 0
    this.widthValue = 0
  }

  toJson(){
    return {
      height_value: this.heightValue,
      length_value: this.lengthValue,
      order_line_ids: this.orderLineIds,
      ship_date: this.shipDate,
      ship_from: this.shipFrom,
      ship_to: this.shipTo,
      size_unit: this.sizeUnit,
      warehouse_id: this.warehouseId,
      weight_unit: this.weightUnit,
      weight_value: this.weightValue,
      width_value: this.widthValue,
    }
  }
}