import { eventBus } from '@/composables/eventBus'
import Platform from '@/models/Platform'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import ValidationError from '@/repositories/Response/ValidationError'
import APIService from '@/repositories/APIService'

const resource = 'platforms'
const version = 'v1'
const platformModule = 'saleschannel'

export default {

  async get(payload){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, Platform, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales channels'
      })
      throw error
    }
  },


}
