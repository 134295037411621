import DepartmentsRepository from "@/repositories/DepartmentsRepository"

export default {
  async fetch(context){
    const response = await DepartmentsRepository.get({per: 10000, page: 1, sort_by: 'name', sort_direction: 'asc'})
    response.data.forEach((category) => {
      context.dispatch('addOrUpdate', category)
    })
  },
  addOrUpdate(context, category){
    let found = context.getters['find'](category.id)

    if(found){
      context.commit('update', category)
    }else{
      context.commit('add', category)
    }
  },
  reset(context){
    context.commit('reset')
  }
}