<template>
  <div class="bg-white">
    <div class="flex ">
      <div class="pt-4 my-3 w-full">
        <input ref="import" type="file" name="import" @change="uploadFile" accept=".xlsx" class="hidden">
        <Button @click="$refs.import.click()" color="green" class="w-full">
          Upload file
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
  import FileResource from '@/models/FileResource'
  import FileSaver from 'file-saver'
  import Upload from '@/models/Upload'
  import APIService from '@/repositories/APIService'
  
  export default {
    data(){
      return {
        savingForm: false,
        upload: new Upload(),
        fileResource: new FileResource(),
        file: null,
      }
    },
    methods: {
      handleFile(){
        this.uploadFile()
      },

      getFormData(){
        let formData = new FormData();
        formData.append('file', this.$refs.import.files[0])

        return formData
      },
      
      async uploadFile(){
        console.log('up')
        this.hasError = false
        try {
          let response = await APIService.post('/account/v1/file_resources', this.getFormData(), {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          if (response.data && response.data.data && response.data.data.id) {
            let fileModel = FileResource.fromJson(response.data.data)
            this.file = fileModel
            this.upload.fileResourceId = this.file.id
            this.saveUpload()
          }
        }catch(e){
          console.log(e)
          this.hasError = true
        }
      },

      downloadTemplate(){
        this.savingForm = true
        this.$repositories.uploads.template(this.upload.uploadType)
          .then((response) => {
            let byteCharacters = atob(response.data.file)
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);

            let blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
            FileSaver.saveAs(blob, `Import ${this.upload.uploadType}.xlsx`)
          })
          .catch((error) => {
            if(error.response.status === 422){
              this.errors = error.response.data
            }
          })
          .finally(() => this.savingForm = false)
      },

      saveUpload(){
        this.savingForm = true
        this.errors = []
        this.storeUpload()
      },
      async storeUpload(){
        this.savingForm = true
        try{
          let response = await this.$repositories.uploads.store(this.upload)
        }catch(e){

        }
        this.savingForm = false
      },
    },

  }
</script>