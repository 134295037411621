import APIService from "@/repositories/APIService.js"
import AuthRepository from "@/repositories/AuthRepository.js"
import TokenStorageService from "@/services/TokenStorageService"

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, token: localStorage.getItem('snsToken'), user }
  : { loggedIn: false, user: null, token: null };


function beaconLogin(user){
  window.Beacon('identify', {
    name: user.fullname,
    email: user.email,
    company: user.company
  })
}

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    user: () => JSON.parse(localStorage.getItem('user'))
  },
  actions: {
    async login({ dispatch, commit }, user) {
      try {
        let logInResponse = await AuthRepository.login(user)
        console.log(logInResponse)
        TokenStorageService.setToken('Bearer ' + logInResponse.headers.authorization)
        commit('setToken', 'Bearer ' + logInResponse.headers.authorization)
        
        APIService.refresh()
        
        await dispatch('sync')
      } catch (e) {
        console.log(e)
        TokenStorageService.deleteToken()
        throw e
      }
    },
    async sync({ dispatch, commit }) {
      try {
        let response = await AuthRepository.getAuthenticatedUser()
        let user = response.data
        dispatch('setUserData', user)
        beaconLogin(user)
        localStorage.setItem('user', JSON.stringify(user))
      } catch (e) {
        console.error('Could not fetch user data.')
      }
    },
    logout({ commit }) {
      TokenStorageService.deleteToken();
      commit('logout');
    },
  },
  mutations: {
    setToken(state, token){
      state.loggedIn = true;
      state.token = token
    },
    setUserData(state, user) {
      state.user = user;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.loggedIn = false;
    },
    registerFailure(state) {
      state.loggedIn = false;
    }
  }
};