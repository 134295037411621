<template>
  <dl>
    <div class="px-3 py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="competitionDetails.hasOwnProperty('seller_id')">
      <dt class="text-xs text-left font-bold text-gray-500">
        Competition
      </dt>
      <dd class="mt-1 text-right text-xs font-normal text-gray-900 sm:mt-0 sm:col-span-2">
        {{ competitionDetails.seller_id }}
      </dd>
    </div>
    <div class="px-3 py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="competitionDetails.price">
      <dt class="text-xs text-left font-bold text-gray-500">
        Item Price
      </dt>
      <dd class="mt-1 text-right text-xs font-normal text-gray-900 sm:mt-0 sm:col-span-2">
        {{ competitionDetails.price.formatted }}
      </dd>
    </div>
    <div class="px-3 py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="competitionDetails.shipping">
      <dt class="text-xs text-left font-bold text-gray-500">
        Shipping
      </dt>
      <dd class="mt-1 text-right text-xs font-normal text-gray-900 sm:mt-0 sm:col-span-2">
        {{ competitionDetails.shipping.formatted }}
      </dd>
    </div>
    <div class="px-3 py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="competitionDetails.hasOwnProperty('featured_merchant')">
      <dt class="text-xs text-left font-bold text-gray-500">
        Featured
      </dt>
      <dd class="mt-1 text-right text-xs font-normal text-gray-900 sm:mt-0 sm:col-span-2">
        <span v-if="competitionDetails.featured_merchant">
          Yes
        </span>
        <span v-else>
          No
        </span>
      </dd>
    </div>
    <div class="px-3 py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="competitionDetails.hasOwnProperty('fba')">
      <dt class="text-xs text-left font-bold text-gray-500">
        FBA
      </dt>
      <dd class="mt-1 text-xs text-right font-normal text-gray-900 sm:mt-0 sm:col-span-2">
        <span v-if="competitionDetails.fba">
          Yes
        </span>
        <span v-else>
          No
        </span>
      </dd>
    </div>
  </dl>
</template>
<script>
  export default {
    props: {
      competitionDetails: {
        type: Object,
        default: null
      }
    }
  }
</script>