import { get, isArray } from 'lodash'

export default class BaseModel {
  constructor() {}

  static initRelation(json, model, defaultValue = null){
    let data = get(json, 'data', defaultValue)

    if(!data){
      return null
    }

    if(isArray(data)){
      return data.map(singleEntity => model.fromJson(singleEntity))
    }

    return model.fromJson(data)
  }

  static fromJson(json){
    throw `From JSON conversion for model ${this.constructor.name} is not implemented`
  }

  toJson(){
    throw `To JSON conversion for model ${this.constructor.name} is not implemented`
  }
}