import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import CarrierAccount from "@/models/CarrierAccount"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'carrier_accounts'
const version = 'v1'
const platformModule = 'oms'

export default {
  
  async get(payload = {}){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, CarrierAccount, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the carrier account!'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${platformModule}/${version}/${resource}/${id}`, CarrierAccount, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the carrier account!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${platformModule}/${version}/${resource}`, payload)
      eventBus.emit('carrier_account:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Carrier account was successfully saved!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create carrier account. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${platformModule}/${version}/${resource}/${id}`, payload)
      eventBus.emit('carrier_account:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Carrier account was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update carrier account. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async addMoney(id, payload){
    try{
      let response = await APIService.putEntity(`${platformModule}/${version}/${resource}/${id}/add_money`, payload)
      eventBus.emit('carrier_account:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Your carrier account was charged with amount specified!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not charge your account. Please try again later!'
      })

      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async autobuy(id, payload){
    try{
      let response = await APIService.putEntity(`${platformModule}/${version}/${resource}/${id}/autobuy`, payload)
      eventBus.emit('carrier_account:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Carrier account was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update carrier account. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`${platformModule}/${version}/${resource}/${id}`)
      eventBus.emit('carrier_account:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Carrier account was successfully removed!!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete carrier account. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      
      throw error
    }
  }

}
