<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-2000" v-if="show">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show && !isDownload" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <ThumbsUpIcon class="h-6 w-6 text-green-400" aria-hidden="true" v-if="isSuccess"/>
                <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" v-if="isError"/>
                <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" v-if="isWarning"/>
              </div>
              <div class="ml-3 w-0 flex-1 ">
                <p class="text-sm font-medium text-gray-900" v-if="config.title">
                  {{ config.title }}
                </p>
                <p class=" text-sm text-gray-500" v-if="config.message">
                  {{ config.message }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="show = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="show && isDownload" class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
          <div class="w-0 flex-1 p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium text-gray-900">{{ config.title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ config.message }}</p>
              </div>
            </div>
          </div>
          <div class="flex border-l border-gray-200">
            <a :href="config.url" class="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500">
              Download
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { XIcon, XCircleIcon, ExclamationIcon } from '@heroicons/vue/24/solid'
import { ThumbsUpIcon } from '@snsicons/icons/solid'

export default {
  components: {
    XIcon,
    XCircleIcon,
    ExclamationIcon,
    ThumbsUpIcon
  },
  data(){
    return {
      show: false,
      config: {},
    }
  },
  mounted(){
    this.$eventBus.on('app:message', (config = {}) => {
      this.show = true
      this.config = config
      setTimeout(() => this.show = false, this.isDownload ? 5500 : 4000)
    })
  },
  computed:{
    isError(){
      return this.config.type == 'error'
    },
    isWarning(){
      return this.config.type == 'warning'
    },
    isSuccess(){
      return this.config.type == 'success'
    },
    isDownload(){
      return this.config.type == 'download'
    },
  }
}
</script>