<template>
  <FormModal :show="opened" @close="close" size="w-300">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Supplier
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="saveSupplier()">
        {{ $t('Save') }}
      </Button>
    </template>

    <div class="w-full grid grid-cols-2 gap-4">
      <div class="grid grid-cols-2 gap-2">
        <div>
          <Label name="code" required :errors="errors" :hint="'Only capital letters without other characters. Eg: SUPPL'">{{ $t('Supplier Code') }}</Label>
          <InputText v-model="supplier.code" name="code" class="w-full" :errors="errors" :disabled="isEditing"/>
          <FormValidation :errors="errors" name="code" />
        </div>
        <div>
          <Label name="name" required :errors="errors">{{ $t('Supplier Name') }}</Label>
          <InputText v-model="supplier.name" name="name" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="name" />
        </div>
        <div>
          <Label name="street" required :errors="errors">{{ $t('Street') }}</Label>
          <InputText v-model="supplier.street" name="street" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="street" />
        </div>
        <div>
          <Label name="street2" :errors="errors">{{ $t('Street 2') }}</Label>
          <InputText v-model="supplier.street2" name="street2" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="street2" />
        </div>
        <div>
          <Label name="city" required :errors="errors">{{ $t('City') }}</Label>
          <InputText v-model="supplier.city" name="city" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="city" />
        </div>
        <div>
          <Label name="postal_code" required :errors="errors">{{ $t('Zip Code/Postal Code') }}</Label>
          <InputText v-model="supplier.postalCode" name="postal_code" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="postal_code" />
        </div>

        <div class="md:mt-2">
          <Label name="country" required :errors="errors">{{ $t('Country') }}</Label>
          <Select v-model="supplier.country" labelKey="label" valueKey="code" :searchable="true" :items="countryOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="country"></FormValidation>
        </div>
        <div class="md:mt-2">
          <Label name="state_or_province" required :errors="errors">{{ $t('State or Province') }}</Label>
          <Select v-model="supplier.stateOrProvince" labelKey="label" valueKey="code" :searchable="true" :items="stateOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="state_or_province"></FormValidation>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-2">
        <div>
          <Label name="gst_vat_number" :errors="errors">{{ $t('GST/VAT Number') }}</Label>
          <InputText v-model="supplier.gstVatNumber" name="gst_vat_number" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="gst_vat_number" />
        </div>
        <div>
          <Label name="bank_name" :errors="errors">{{ $t('Bank Name') }}</Label>
          <InputText v-model="supplier.bankName" name="bank_name" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="bank_name" />
        </div>
        <div>
          <Label name="bank_branch" :errors="errors">{{ $t('Bank Branch') }}</Label>
          <InputText v-model="supplier.banckBranch" name="bank_branch" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="bank_branch" />
        </div>
        <div class="">
          <Label name="bank_account" :errors="errors">{{ $t('Bank Account/IBAN') }}</Label>
          <InputText v-model="supplier.bankAccount" name="bank_account" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="bank_account" />
        </div>
        <div>
          <Label name="contact_name" :errors="errors">{{ $t('Contact Name') }}</Label>
          <InputText v-model="supplier.contactName" name="contact_name" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="contact_name" />
        </div>
        <div>
          <Label name="contact_phone" :errors="errors">{{ $t('Contact Phone') }}</Label>
          <InputText v-model="supplier.contactPhone" name="contact_phone" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="contact_phone" />
        </div>
        <div class="col-span-2">
          <Label name="contact_email" :errors="errors">{{ $t('Contact Email') }}</Label>
          <InputText v-model="supplier.contactEmail" name="contact_email" class="w-full" :errors="errors"/>
          <FormValidation :errors="errors" name="contact_email" />
        </div>
      </div>
      
    </div>
  </FormModal>
</template>
<script>
  import Supplier from "@/models/Supplier";
  import _ from 'lodash'
  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        supplier: new Supplier(),
        errors: [],
        countries: [],
        states: [],
        timezones: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
      'supplier.country': function(value){
        this.fetchStates()
      }
    },
    computed: {
      isEditing(){
        return this.supplier.id != null
      },
      countryOptions(){
        let countries = []
        this.countries.forEach((country) => {
          countries.push({
            label: country.name,
            code: country.id,
          })
        })
        return countries
      },
      stateOptions(){
        let states = []
        this.states.forEach((state) => {
          states.push({
            label: state.name,
            code: state.id,
          })
        })
        return states
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let supplier = new Supplier()
          _.assign(supplier, JSON.parse(JSON.stringify(preset)))
          this.supplier = supplier
          this.fetchCountries()
          this.fetchStates()
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.supplier = new Supplier()
        this.errors = []
        this.countries = []
        this.states = []
      },
      async saveSupplier(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          await this.updateSupplier()
        }else{
          await this.storeSupplier()
        }
      },
      async storeSupplier(){
        try{
          await this.$repositories.suppliers.store(this.supplier)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateSupplier(){
        try{
          await this.$repositories.suppliers.update(this.supplier.id, this.supplier)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
      
      async fetchCountries(){
        try{
          let response = await this.$repositories.countries.get()
          this.countries = response.data
        }catch(e){
          console.log(e)
        }
      },
      async fetchStates(){
        try{
          let response = await this.$repositories.states.get({country_id: this.supplier.country})
          this.states = response.data
        }catch(_){}
      },
    }
  }
</script>