import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import Integration from "@/models/Integration"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'integrations'
const version = 'v1'


export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Integration, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the integration.'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Integration, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the integrations!'
      })
      throw error
    }
  }
}
