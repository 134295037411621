<script setup>
import { BellIcon, BellOnIcon } from '@snsicons/icons/solid'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useAbility } from '@casl/vue'

const route = useRoute()
const store = useStore()
const { can } = useAbility()

onMounted(() => {
  store.dispatch('notifications/syncCounter')
})
</script>

<template>
  <router-link :to="{name: 'dashboard.notifications.index'}" :class="['flex items-center p-4 rounded-lg relative']" v-tooltip.right="'Notifications'">
    <span class="sr-only">Notifications</span>
    <BellOnIcon class="w-6 h-6 text-orange-600 animate-pulse" v-if="store.getters['notifications/counter'] > 0"/>
    <BellIcon class="w-6 h-6" v-else/>
    <span class="absolute top-0 right-0 block font-bold text-red-400 animate-pulse" v-if="store.getters['notifications/counter'] > 0">
      {{ store.getters['notifications/counter'] }}
    </span>
  </router-link>
</template>