import BaseModel from "@/models/BaseModel"

export default class Brand extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.productsCount = 0
    this.velocity30 = 0
    this.velocity60 = 0
    this.velocity90 = 0
    this.velocity365 = 0
    this.inventory = 0
    this.listings = 0
    this.publishedListings = 0
    this.unpublishedListings = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let brand = new this()

    brand.id = json.id
    brand.name = json.name
    brand.productsCount = json.products_count
    brand.velocity30 = json.velocity_30
    brand.velocity60 = json.velocity_60
    brand.velocity90 = json.velocity_90
    brand.velocity365 = json.velocity_365
    brand.listings = json.listings
    brand.publishedListings = json.published_listings
    brand.unpublishedListings = json.unpublished_listings
    brand.inventory = json.inventory
    brand.createdAt = json.created_at
    brand.updatedAt = json.updated_at

    return brand
  }

  toJson(){
    return {
      name: this.name
    }
  }
}