import BaseModel from "@/models/BaseModel"

export default class Country extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
  }

  static fromJson(json){
    let country = new this()

    country.id = json.id
    country.name = json.name

    return country
  }
}