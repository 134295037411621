import BaseModel from "@/models/BaseModel"
import Shipment from '@/models/Shipment'

export default class ShipmentPackage extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.orderLineId = null
    this.quantity = null
    this.shipment = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let shipmentPackage = new this()

    shipmentPackage.id = json.id
    shipmentPackage.orderLineId = json.order_line_id
    shipmentPackage.quantity = json.quantity

    shipmentPackage.shipment = this.initRelation(json.shipment, Shipment, {})
    shipmentPackage.createdAt = json.created_at
    shipmentPackage.updatedAt = json.updated_at

    return shipmentPackage
  }
}