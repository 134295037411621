import OrderCancellationReasonsRepository from "@/repositories/OrderCancellationReasonsRepository"

export default {
  async fetch(context){
    const response = await OrderCancellationReasonsRepository.get({per: 10000, page: 1, sort_by: 'name', sort_direction: 'asc'})
    response.data.forEach((orderCancellationReason) => {
      context.dispatch('addOrUpdate', orderCancellationReason)
    })
  },
  addOrUpdate(context, orderCancellationReason){
    let found = context.getters['find'](orderCancellationReason.id)

    if(found){
      context.commit('update', orderCancellationReason)
    }else{
      context.commit('add', orderCancellationReason)
    }
  }
}