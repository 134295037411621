<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <PercentIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Do you want to discount price for {{ total }} listings ?
                </DialogTitle>
                <div class="mt-2">
                  <Label name="discount_value" required :errors="errors">{{ $t('Discount Value (%)') }}</Label>
                  <InputText v-model="discountValue" name="discount_value" class="w-full" />
                </div>
                <div class="mt-2">
                  <Label name="round_type" required :errors="errors">{{ $t('Round Type') }}</Label>
                  <Select :items="roundTypeOptions" v-model="roundType" labelKey="label" valueKey="code" :searchable="true" class="w-full" />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button @click="updateMany" color="green" :disabled="canSubmit">
                {{ $t('Apply Discount') }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { PercentIcon } from '@snsicons/icons/solid'

export default {
  components: {
    PercentIcon,
  },
  data(){
    return {
      loading: false,
      opened: false,
      filters: {},
      discountValue: 0,
      roundType: null,
      total: 0,
    }
  },
  computed: {
    roundTypeOptions(){
      return [
        {
          code: null,
          label: "Do not apply"
        },
        {
          code: 0.99,
          label: ".99"
        },
        {
          code: 0.98,
          label: ".98"
        },
        {
          code: 0.97,
          label: ".97"
        },
        {
          code: 0.96,
          label: ".96"
        },
        {
          code: 0.95,
          label: ".95"
        },
        {
          code: 0.94,
          label: ".94"
        }
      ]
    }
  },
  methods: {
    open(preset = {}){
      this.filters = preset.filters
      this.total = preset.total
      this.opened = true
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.filters = {}
      this.total = 0
    },
    async updateMany(){
      try {
        await this.$repositories.listings.discount({discount_value: this.discountValue, round_type: this.roundType}, this.filters)
        this.close()
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>