import BaseModel from "@/models/BaseModel"
import Platform from '@/models/Platform'

export default class OrderLineCancellationReason extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.platformId = null
    this.displayName = ''
    this.name = ''
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let orderLineCancellationReason = new this()

    orderLineCancellationReason.id = json.id
    orderLineCancellationReason.platformId = json.platform_id
    orderLineCancellationReason.displayName = json.display_name
    orderLineCancellationReason.name = json.name
    orderLineCancellationReason.createdAt = json.created_at
    orderLineCancellationReason.updatedAt = json.updated_at

    return orderLineCancellationReason
  }
}