import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Rate from '@/models/Rate'

import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";
import Shipment from '@/models/Shipment';

const resource = 'shipping_rates'
const version = 'v1'

export default {

  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Rate, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the shipping rates!'
      })

      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.post(`${version}/${resource}`, payload.toJson())
      eventBus.emit('rate:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Shipping Rates were found!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create find rates. Please try again!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async buy(id, payload){
    try{
      let response = await APIService.post(`${version}/${resource}/${id}/buy`, payload.toJson())
      eventBus.emit('rate:bought')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Request report was created!'
      })

      return Shipment.fromJson(response.data.data)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create find rates. Please try again!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

}
