import BaseModel from "@/models/BaseModel"

export default class OrderLineCancellation extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.orderLineId = null
    this.reason = ''
    this.userId = null
    this.user = null
    this.quantity = 0
    this.author = null
    this.quantity = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let orderLineCancellation = new this()

    orderLineCancellation.id = json.id
    orderLineCancellation.orderLineId = json.order_line_id
    orderLineCancellation.reason = json.reason
    orderLineCancellation.userId = json.user_id
    orderLineCancellation.quantity = json.quantity
    orderLineCancellation.author = json.full_name
    orderLineCancellation.quantity = json.quantity
    orderLineCancellation.createdAt = json.created_at
    orderLineCancellation.updatedAt = json.updated_at

    return orderLineCancellation
  }
  
  toJson(){
    return {
      order_line_cancellation: {
        order_line_id: this.orderLineId,
        quantity: this.quantity,
        reason: this.reason
      }
    }
  }
}