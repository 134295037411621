import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

import PlatformCategory from "@/models/PlatformCategory"
const resource = 'platform_categories'
const version = 'v1'
const platformModule = 'saleschannel'
export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, PlatformCategory, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the sales market categories'
      })

      throw error
    }
  }

}
