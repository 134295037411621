export default {
  all: (state) => {
    return state.brands
  },
  find: (state) => (id) => {
    return state.brands.find((brand) => brand.id == id)
  },
  findByName: (state) => (name) => {
    return state.brands.find((brand) => brand.name == name)
  }
}