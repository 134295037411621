<script>
  import Order from '@/models/Order'
  import ScanProductIntro from '@/components/Scanning/ScanProductIntro.vue'
  import { BarcodeReadIcon, MagnifyingGlassIcon } from '@snsicons/icons/solid'
  import { HouseIcon } from '@snsicons/icons/regular'
  export default {
    components: {
      BarcodeReadIcon,
      HouseIcon,
      ScanProductIntro,
      MagnifyingGlassIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
        order: new Order(),
        filters: {
          order_status: ['fulfilled', 'partial_fulfilled', 'ready', 'partial_ready', 'partial_shipped'],
        },
        orders: [],
        orderHeaders: [
          {
            text: 'Sales Channel',
            value: 'salesChannel',
          },
          {
            text: 'Order Date',
            value: 'placedAt',
          },
          {
            text: 'Order #',
            value: 'purchaseOrderId',
          },
          {
            text: 'Ship By',
            value: 'estimatedShipDate',
          },
          {
            text: 'Quantity',
            value: 'quantity',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
        pagination: {
          page: 1,
          per: 100,
          last_page: 1,
          total: 0,
        },
        sortBy: {
          sort_by: 'placed_at',
          sort_direction: 'desc'
        },
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
      //this.fetchOrders(1)
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    watch: {
      'pagination.page': function(newValue){
        this.fetchOrders(newValue)
      },
    },
    methods: {
      scan(event){
        if(event.code === 'Enter'){
          this.confirm()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      async confirm(){
        this.loading = true
        try{
          let response = await this.$repositories.processingTables.create({barcode: this.barcode, warehouse_id: this.$store.getters['user_prefs/currentWarehouse']})
          this.order = response
          this.$router.push({name: 'scanning.processing_table.order', params: {orderId: this.order.id}})
        }catch(e){
          
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
      async fetchOrders(page){
        this.loading = true
        try{
          let response = await this.$repositories.orders.get({...this.filters, ...this.sortBy, ...this.pagination, ...{page: page}})
          this.orders = response.data
          this.pagination.per = response.pagination.perPage
          this.pagination.page = response.pagination.currentPage
          this.pagination.total = response.pagination.total
          this.pagination.last_page = response.pagination.totalPages
          this.searchCount = response.pagination.searchCount
        }catch(e){
          console.log(e)
        }
        this.loading = false
      },
    }
  }
</script>
<template>
  <div class="mx-auto p-4 bg-white">
    <loading :active="loading" :is-full-page="true"></loading>
    <div class="bg-white rounded-t-lg px-4">
      <router-link :to="{name: 'scanning.home'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
        <HouseIcon class="h-5 text-blue-500" />
        <span>Go Home</span>
      </router-link>
      <div class="flex bg-white items-center justify-start border-b border-gray-200 py-4 space-x-4">
        <span class="bg-lime-50 text-lime-500 rounded-lg inline-flex p-4 ring-4 ring-white">
          <BarcodeReadIcon class="h-5 w-5"/>
        </span>
        <div class="flex flex-col justify-start">
          <h3 class="font-semibold text-base">Processing Table</h3>
          <p class="text-sm text-gray-500">Scan the product barcode to start processing orders.</p>
        </div>
      </div>
    </div>

    <ScanProductIntro>
      <template v-slot:error>
        <span v-if="error">{{ error }}</span>
        <span v-else></span>
      </template>
    </ScanProductIntro>
    
  </div>

</template>