<script>
import { TriangleExclamationIcon } from '@snsicons/icons/solid'
export default {
  components: {
    TriangleExclamationIcon
  },
  data(){
    return {
      loading: false,
      ordersCount: [],
      pagination: {
        total: 0,
      }
    }
  },
  mounted(){
    this.fetchOrders()
  },
  methods: {
    async fetchOrders(){
      this.loading = true
      try{
        let response = await this.$repositories.orders.get({per: 1, order_status: ['unfulfillable']})
        this.pagination = response.pagination
        this.ordersCount = response.pagination.searchCount
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <div class="w-full bg-red-600 py-3 px-3 sm:px-6 lg:px-8" v-if="pagination.total > 0">
    <div class="flex items-center justify-between flex-wrap" >
      <div class="w-full flex-1 flex items-center">
        <span class="flex p-2 rounded-lg bg-red-800">
          <TriangleExclamationIcon class="h-6 w-6 text-white" aria-hidden="true" />
        </span>
        <p class="ml-3 font-medium text-white">
          There <span v-if="pagination.total > 1"> are <span class="font-bold">{{ pagination.total }}</span> orders</span> <span v-else> is <span class="font-bold">{{ pagination.total }}</span> order</span> that cannot be shipped because they do not have a quantity registered in the system.
        </p>
      </div>
      <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
        <router-link :to="{name: 'dashboard.orders.index', query: { status: 'unfulfillable' }}" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50">
          Fix Orders 
        </router-link>
      </div>
    </div>
  </div>
</template>