<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-center border-b border-gray-200 pb-10">
      <svg id="Capa_1" enable-background="new 0 0 512 512" height="96" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m166 181c-90.981 0-166 74.019-166 165s75.019 166 166 166 165-75.019 165-166-74.019-165-165-165z" fill="#91e6a9"/><path d="m331 346c0-90.981-74.019-165-165-165v331c90.981 0 165-75.019 165-166z" fill="#26bfa6"/><path d="m211 294.789-45 45-15 15-30-30-21.211 21.211 51.211 51.211 15-15 66.211-66.211z" fill="#f0f7ff"/><path d="m211 294.789-45 45v42.422l66.211-66.211z" fill="#dfe7f4"/><path d="m346 0c-90.981 0-165 75.019-165 166s74.019 165 165 165 166-74.019 166-165-75.019-166-166-166z" fill="#ff80aa"/><path d="m512 166c0-90.981-75.019-166-166-166v331c90.981 0 166-74.019 166-165z" fill="#f37"/><path d="m397.211 136-21.211-21.211-30 30-30-30-21.211 21.211 30 30-30 30 21.211 21.211 30-30 30 30 21.211-21.211-30-30z" fill="#f0f7ff"/><path d="m397.211 196-30-30 30-30-21.211-21.211-30 30v42.422l30 30z" fill="#dfe7f4"/></g></svg>
      <h2 class="text-2xl font-bold leading-7 text-blue-500 sm:text-3xl sm:truncate py-2">
        Review Your Strategy
      </h2>
      <p class="text-md text-gray-500">Look things over before strategy is created.</p>
    </div>
    <div class="grid grid-cols-2 mt-6">
      <div class="w-2/3">
        <div class="rounded-md bg-blue-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/information-circle -->
              <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">
                You can come back at any time to tweak your strategies by fiding them in the Strategies section, found in the left hand sidebar.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="w-2/3 mb-6">
          <div class="text-xs text-gray-600 font-medium">Name your strategy<span class="text-red-500">*</span></div>
          <InputText v-model="localValue.name" name="name" class="w-full" :errors="errors" />
          <FormValidation :errors="errors" name="name" />
          <div class="text-xs text-gray-400">Using a descriptive name will help differentiate your strategies.</div>
        </div>
        <div class="w-2/3 mb-6">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Type of Strategy
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ strategyType }}
              </dd>
            </div>
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Minimum Price
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ minimumPrice }}
              </dd>
            </div>
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Maximum Price
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ maximumPrice }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import _ from 'lodash'
import Strategy from '@/models/Strategy'

export default {
  props: {
    modelValue: {
      type: Object,
    }
  },
  data(){
    return {
      localValue: new Strategy(),
      errors: [],
    }
  },
  watch: {
    localValue: {
      handler(){
        this.$emit('input', this.localValue)
      },
      deep: true
    },
  },
  beforeMount(){
    this.localValue = this.modelValue
  },
  computed:{
    strategyType(){
      if(this.localValue.strategyType == 'buy-box'){
        return 'Get the Buy Box'
      }

      if(this.localValue.strategyType == 'lowest-price'){
        return 'Compete with Lowest Price'
      }

      if(this.localValue.strategyType == 'featured-merchant'){
        return 'Compete with Lowest Featured Merchant'
      }

      return ''
    },

    minimumPrice(){
      if(this.localValue.minMax.type == 'manual'){
        return 'Enter your own'
      }

      if(this.localValue.strategyType == 'roi'){
        return 'ROI based, '+this.localValue.strategyType.roi_min+'%'
      }

      if(this.localValue.strategyType == 'profit-margin'){
        return 'Profit Margin based, '+this.localValue.strategyType.profit_margin_min+'%'
      }

      if(this.localValue.strategyType == 'fixed-profit'){
        return 'Fixed Profit based, '+this.localValue.strategyType.profit_margin_min+'$,€,£...'
      }
      return ''
    },

    maximumPrice(){
      if(this.localValue.minMax.type == 'manual'){
        return 'Enter your own'
      }

      if(this.localValue.strategyType == 'roi'){
        return 'ROI based, '+this.localValue.strategyType.roi_max+'%'
      }

      if(this.localValue.strategyType == 'profit-margin'){
        return 'Profit Margin based, '+this.localValue.strategyType.profit_margin_max+'%'
      }

      if(this.localValue.strategyType == 'fixed-profit'){
        return 'Fixed Profit based, '+this.localValue.strategyType.profit_margin_max+'$,€,£...'
      }
      return ''
    }
  }, 
}
</script>