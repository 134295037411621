export default {
  all: (state) => {
    return state.orderCancellationReasons
  },
  find: (state) => (id) => {
    return state.orderCancellationReasons.find((orderCancellationReason) => orderCancellationReason.id == id)
  },
  findByPlatformId: (state) => (platformId) => {
    return state.orderCancellationReasons.filter((orderCancellationReason) => orderCancellationReason.platformId == platformId)
  }
}