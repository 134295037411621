import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layouts/app.vue'
import ScanningLayout from '@/layouts/scanning.vue'
import DashboardIndex from '@/pages/dashboard.vue'

import LoginPage from '@/pages/login.vue'

import DashboardProductsIndex from '@/pages/products/index.vue'
import DashboardProductIndex from '@/pages/products/_productId/index.vue'
import DashboardInventoriesIndex from '@/pages/inventories/index.vue'
import DashboardOrdersIndex from '@/pages/orders/index.vue'
import DashboardOrderDetails from '@/pages/orders/_orderId/details.vue'

import DashboardSettingsIndex from '@/pages/settings/index.vue'
import DashboardSalesChannelsNew from '@/pages/sales_channels/new.vue'

import DashboardBillingIndex from '@/pages/billing/index.vue'

import DashboardUsersIndex from '@/pages/users/index.vue'

import DashboardReviewsIndex from '@/pages/reviews/index.vue'

import DashboardSalesChannelsIndex from '@/pages/sales_channels/index.vue'
import DashboardBrandsAndDepartmentsIndex from '@/pages/brands_and_departments/index.vue'

import DashboardAnalyticsIndex from '@/pages/analytics/index.vue'
import DashboardAnalyticsSales from '@/pages/analytics/sales.vue'
import DashboardAnalyticsBrands from '@/pages/analytics/brands.vue'
import DashboardAnalyticsInventory from '@/pages/analytics/inventory.vue'
import DashboardAnalyticsListings from '@/pages/analytics/listings.vue'
import DashboardAnalyticsKpis from '@/pages/analytics/kpi.vue'

import DashboardCarrierAccountsIndex from '@/pages/carriers/index.vue'

import DashboardWarehousesIndex from '@/pages/warehouses/index.vue'
import DashboardWarehouseIndex from '@/pages/warehouses/_warehouseId/index.vue'

import DashboardListingsIndex from '@/pages/listings/index.vue'
import DashboardListingsReview from '@/pages/listings/review.vue'
import DashboardListingsErrors from '@/pages/listings/errors.vue'
import DashboardListingsNew from '@/pages/listings/new.vue'
import DashboardListingIndex from '@/pages/listings/_listingId/index.vue'
import DashboardListingInventorySource from '@/pages/listings/_listingId/inventory_source.vue'

import DashboardNotificationsIndex from '@/pages/notifications/index.vue'

import DashboardStrategiesIndex from '@/pages/strategies/index.vue'

import DashboardImportsIndex from '@/pages/imports/index.vue'
import DashboardReportsIndex from '@/pages/reports/index.vue'
import DashboardScheduledReportsIndex from '@/pages/scheduled_reports/index.vue'

import ScanningsIndex from '@/pages/scannings/index.vue'
import ScanningsPicklistIndex from '@/pages/scannings/pickups/index.vue'
import ScanningsPicklistShelf from '@/pages/scannings/pickups/shelf.vue'

import ScanningsGoingInIndex from '@/pages/scannings/goingin/index.vue'
import ScanningsGoingInShelf from '@/pages/scannings/goingin/shelf.vue'

import ScanningsGoingOutIndex from '@/pages/scannings/goingout/index.vue'
import ScanningsGoingOutShelf from '@/pages/scannings/goingout/shelf.vue'

import ScanningsRescanIndex from '@/pages/scannings/rescan/index.vue'
import ScanningsRescanShelf from '@/pages/scannings/rescan/shelf.vue'

import ScanningsCheckIndex from '@/pages/scannings/check/index.vue'

import ScanningsProcessingTableIndex from '@/pages/scannings/processing_table/index.vue'
import ScanningsProcessingTableOrder from '@/pages/scannings/processing_table/order.vue'

import DashboardInventoryMovementsIndex from '@/pages/inventory_movements/index.vue'

import DashboardSuppliersIndex from '@/pages/suppliers/index.vue'

import DashboardResetPasswordIndex from '@/pages/reset_password/index.vue'
import DashboardResetPasswordReset from '@/pages/reset_password/reset.vue'

import DashboardIntegrationsIndex from '@/pages/integrations/index.vue'
import DashboardIntegrationsNew from '@/pages/integrations/new.vue'

// if (store.getters['auth/isLoggedIn']) {
//   next({name: 'dashboard.index'})
// }
export default function (store: any) {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/scanning',
      name: 'scanning.index',
      redirect: 'scanning.home',
      component: ScanningLayout,
      beforeEnter: (to, from, next) => {
        store.dispatch('initialize')
        next();
      },
      children: [
        {
          path: 'home',
          name: 'scanning.home',
          component: ScanningsIndex
        },
        {
          path: 'goingin',
          name: 'scanning.goingin.index',
          component: ScanningsGoingInIndex
        },
        {
          path: 'goingin/:shelfId',
          name: 'scanning.goingin.shelf',
          component: ScanningsGoingInShelf
        },
        {
          path: 'rescan',
          name: 'scanning.rescan.index',
          component: ScanningsRescanIndex
        },
        {
          path: 'rescan/:shelfId',
          name: 'scanning.rescan.shelf',
          component: ScanningsRescanShelf
        },
        {
          path: 'goingout',
          name: 'scanning.goingout.index',
          component: ScanningsGoingOutIndex
        },
        {
          path: 'goingout/:shelfId',
          name: 'scanning.goingout.shelf',
          component: ScanningsGoingOutShelf
        },
        {
          path: 'picklist',
          name: 'scanning.picklist.index',
          component: ScanningsPicklistIndex
        },
        {
          path: 'picklist/:shelfId',
          name: 'scanning.picklist.shelf',
          component: ScanningsPicklistShelf
        },
        {
          path: 'processing_table',
          name: 'scanning.processing_table.index',
          component: ScanningsProcessingTableIndex
        },
        {
          path: 'processing_table/:orderId',
          name: 'scanning.processing_table.order',
          component: ScanningsProcessingTableOrder
        },
        {
          path: 'check',
          name: 'scanning.check.index',
          component: ScanningsCheckIndex
        },
      ]
    },
    {
      path: '/',
      name: 'dashboard.index',
      redirect: 'dashboard.home',
      component: Layout,
      beforeEnter: (to, from, next) => {
        store.dispatch('initialize')
        next();
      },
      children: [
        {
          path: '/',
          name: 'dashboard.home',
          component: DashboardIndex
        },
        {
          path: '/analytics',
          name: 'dashboard.analytics.index',
          redirect: '/analytics/inventory',
          component: DashboardAnalyticsIndex,
          children: [
            {
              path: 'inventory',
              name: 'dashboard.analytics.inventory',
              component: DashboardAnalyticsInventory
            },
            {
              path: 'kpis',
              name: 'dashboard.analytics.kpis',
              component: DashboardAnalyticsKpis
            },
            {
              path: 'brands',
              name: 'dashboard.analytics.brands',
              component: DashboardAnalyticsBrands
            },
            {
              path: 'sales',
              name: 'dashboard.analytics.sales',
              component: DashboardAnalyticsSales
            },
            {
              path: 'listings',
              name: 'dashboard.analytics.listings',
              component: DashboardAnalyticsListings
            },
          ]
        },
        {
          path: '/strategies',
          name: 'dashboard.strategies.index',
          component: DashboardStrategiesIndex
        },
        {
          path: '/suppliers',
          name: 'dashboard.suppliers.index',
          component: DashboardSuppliersIndex
        },
        {
          path: '/notifications',
          name: 'dashboard.notifications.index',
          component: DashboardNotificationsIndex
        },
        {
          path: '/brands_and_departments',
          name: 'dashboard.brands_and_departments.index',
          component: DashboardBrandsAndDepartmentsIndex
        },
        {
          path: '/reports',
          name: 'dashboard.reports.index',
          component: DashboardReportsIndex
        },
        {
          path: '/scheduled_reports',
          name: 'dashboard.scheduled_reports.index',
          component: DashboardScheduledReportsIndex
        },
        {
          path: '/imports',
          name: 'dashboard.imports.index',
          component: DashboardImportsIndex
        },
        {
          path: '/products',
          name: 'dashboard.products.index',
          component: DashboardProductsIndex
        },
        {
          path: '/inventories',
          name: 'dashboard.inventories.index',
          component: DashboardInventoriesIndex
        },
        {
          path: '/inventory_movements',
          name: 'dashboard.inventory_movements.index',
          component: DashboardInventoryMovementsIndex
        },
        {
          path: '/listings',
          name: 'dashboard.listings.index',
          component: DashboardListingsIndex
        },
        {
          path: '/listings/review',
          name: 'dashboard.listings.review',
          component: DashboardListingsReview
        },
        {
          path: '/listings/errors',
          name: 'dashboard.listings.errors',
          component: DashboardListingsErrors
        },
        {
          path: '/listings/new/:productId/:salesChannelId',
          name: 'dashboard.listings.new',
          component: DashboardListingsNew
        },
        {
          path: '/listings/:listingId',
          name: 'dashboard.listing.index',
          component: DashboardListingIndex
        },
        {
          path: '/listings/:listingId/inventory_source',
          name: 'dashboard.listing.inventory_source',
          component: DashboardListingInventorySource
        },
        {
          path: '/products/:productId',
          name: 'dashboard.product.index',
          component: DashboardProductIndex
        },
        {
          path: '/orders',
          name: 'dashboard.orders.index',
          component: DashboardOrdersIndex
        },
        {
          path: '/reviews',
          name: 'dashboard.reviews.index',
          component: DashboardReviewsIndex
        },
        {
          path: '/orders/:orderId/details',
          name: 'dashboard.order.details',
          component: DashboardOrderDetails
        },
        {
          path: '/settings',
          name: 'dashboard.settings.index',
          component: DashboardSettingsIndex,
        },
        {
          path: '/settings/users',
          name: 'dashboard.users.index',
          component: DashboardUsersIndex
        },
        {
          path: '/settings/sales_channels',
          name: 'dashboard.sales_channels.index',
          component: DashboardSalesChannelsIndex
        },
        {
          path: '/settings/sales_channels/new',
          name: 'dashboard.sales_channels.new',
          component: DashboardSalesChannelsNew
        },
        {
          path: '/settings/carriers',
          name: 'dashboard.carriers.index',
          component: DashboardCarrierAccountsIndex
        },
        {
          path: '/settings/warehouses',
          name: 'dashboard.warehouses.index',
          component: DashboardWarehousesIndex
        },
        {
          path: '/settings/integrations',
          name: 'dashboard.integrations.index',
          component: DashboardIntegrationsIndex
        },
        {
          path: '/settings/integrations/new',
          name: 'dashboard.integrations.new',
          component: DashboardIntegrationsNew
        },
        {
          path: '/settings/warehouses/:warehouseId',
          name: 'dashboard.warehouse.index',
          component: DashboardWarehouseIndex
        },
        {
          path: '/settings/billing',
          name: 'dashboard.billing.index',
          component: DashboardBillingIndex
        },
        {
          path: '/about',
          name: 'About',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
        }
      ]
    },
    
    {
      path: '/login',
      name: 'dashboard.login',
      component: LoginPage,
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/loggedIn']) {
          next({name: 'dashboard.index'})
        }
        next();
      }
    },

    {
      path: '/reset_password',
      name: 'dashboard.reset_password.index',
      component: DashboardResetPasswordIndex,
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/loggedIn']) {
          next({name: 'dashboard.index'})
        }
        next();
      }
    },
    {
      path: '/reset_password/:token',
      name: 'dashboard.reset_password.reset',
      component: DashboardResetPasswordReset,
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/loggedIn']) {
          next({name: 'dashboard.index'})
        }
        next();
      }
    },
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach((to, from, next) => {
    if(from && from.fullPath){
      store.dispatch('route/updateLastRoute', from.fullPath)
    }
    next()
  })

  // router.beforeEach(async (to, from, next) => {
  //   if(to.name != 'dashboard.login' && !store.state.auth.loggedIn){
  //     next({name: 'dashboard.login'})
  //   }else{
  //     next()
  //   }
  // })

  return router
}


