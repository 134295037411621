<script>
  import {  XmarkIcon } from '@snsicons/icons/regular'
  import { CircleQuestionIcon } from '@snsicons/icons/solid'
  import Review from "@/models/Review";
  import _ from 'lodash'
  import 'linkifyjs/lib/linkify-string'
  import { useAbility } from '@casl/vue'

  export default {
    components: {
      CircleQuestionIcon,
      XmarkIcon
    },
    setup() {
      const { can } = useAbility();

      return {
        can
      };
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        review: new Review(),
        errors: {},
        users: [],
      }
    },
    computed: {
      isEditing(){
        return this.review.id != null
      },
      linkifyOptions(){
        return {
          target: {
            url: '_blank'
          },
          className: 'text-blue-600 hover:text-blue-700'
        }
      },
      userOptions(){
        let options = []
        this.users.forEach((user) => {
          options.push({
            label: user.fullname,
            code: user.id,
          })
        })

        return options
      },
      initiatedBySystem(){
        return this.isEditing && this.review.initiatorId == null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          this.fetchUsers()
          let review = new Review()
          _.assign(review, JSON.parse(JSON.stringify(preset)))
          this.review = review
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.review = new Review()
        this.errors = {}
      },

      async saveReview(){
        if(this.isEditing){
          await this.updateReview()
        }else{
          await this.storeReview()
        }
      },

      async updateReview(){
        try{
          await this.$repositories.reviews.update(this.review.id, this.review)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },

      async storeReview(){
        try{
          await this.$repositories.reviews.create(this.review)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },

      async fetchUsers(){
        try{
          let response = await this.$repositories.users.get()
          this.users = response.data
        }catch(_){}
      },

    }
  }
</script>

<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-1000" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <!-- <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild> -->

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-blue-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        {{ isEditing ? $t('Update review details') : $t('Create new review') }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="rounded-md text-white hover:text-white" @click="close()">
                          <span class="sr-only">Close panel</span>
                          <XmarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        Get started by filling in the information below to create your new project.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <Label name="initiator_id" :errors="errors">{{ $t('Initiated By') }}</Label>
                          <p class="text-sm" v-if="isEditing">{{ review.initiatedBy }}</p>
                          <p class="text-sm" v-else>{{ $t('This review will be initiated by you, {user}', { user: $store.getters['auth/user'].fullname}) }}</p>
                          <FormValidation :errors="errors" name="initiator_id" />
                        </div>
                        <div v-if="!isEditing">
                          <Label name="assignee_id" :errors="errors" :required="true">{{ $t('Assign To') }}</Label>
                          <Select :items="userOptions" v-model="review.assigneeId" labelKey="label" valueKey="code" :searchable="true" class="w-full" />
                          <FormValidation :errors="errors" name="assignee_id" />
                        </div>
                        <div v-else>
                          <Label name="assignee_id" :errors="errors" :required="true">{{ $t('Assign To') }}</Label>
                          <Select :items="userOptions" v-model="review.assigneeId" labelKey="label" valueKey="code" :searchable="true" class="w-full" v-if="can('edit', 'review_assign_to')"/>
                          <p class="text-sm" v-else>{{ review.assignedTo }}</p>
                          <FormValidation :errors="errors" name="assignee_id" />
                        </div>
                        <div>
                          <Label name="reason" :required="!isEditing" :errors="errors">{{ $t('Reason') }}</Label>
                          <p class="text-sm" v-html="review.reason.linkify(linkifyOptions)" v-if="isEditing">
                          </p>
                          <Textarea v-model="review.reason" name="reason" class="w-full" :errors="errors" v-else/>
                          <p class="text-sm text-gray-400" v-if="!isEditing">{{ $t('This field can only be changed at creation time.') }}</p>
                          <FormValidation :errors="errors" name="reason" />
                        </div>
                       
                        <div>
                          <Label name="complete_details" :errors="errors">{{ $t('Actions Taken') }}</Label>
                          <p class="text-sm" v-html="review.completeDetails.linkify(linkifyOptions)" v-if="review.completedAt">
                          </p>
                          <Textarea v-model="review.completeDetails" name="complete_details" class="w-full" :errors="errors" v-else/>
                          <p class="text-sm text-gray-400" v-if="!review.completedAt">{{ $t("If you fill this field, this review will be marked as completed by {user}.", { user: $store.getters['auth/user'].fullname }) }}</p>
                          <FormValidation :errors="errors" name="complete_details" />
                        </div>
                      </div>
                      <div class="pt-4 pb-6">
                        <div class="text-sm">
                          <a href="#" class="group inline-flex items-start space-x-1 text-gray-500 hover:text-gray-900" v-if="review.completedAt > 0">
                            <CircleQuestionIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            <p>
                              {{ $t('This revision was completed by {user} on {date}', { user: review.completedBy, date: $filters.date.dateTimeFormat(review.completedAt)}) }}
                            </p>
                          </a>
                          <a href="#" class="group inline-flex items-start space-x-1 text-gray-500 hover:text-gray-900" v-else>
                            <CircleQuestionIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            <p>
                              {{ $t('Follow-up emails will be sent every 2 days until this review is complete.') }}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2">
                  <Button color="white" @click="close">
                    {{ $t('Close') }}
                  </Button>
                  <Button color="blue" @click="saveReview">
                    {{ $t('Save') }}
                  </Button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>