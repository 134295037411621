import BaseModel from "@/models/BaseModel"

export default class State extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
  }

  static fromJson(json){
    let state = new this()

    state.id = json.id
    state.name = json.name

    return state
  }
}