<script>
import moment from 'moment'
import RequestReportFormModal from '@/components/RequestReport/RequestReportFormModal.vue'
import { CalendarCheckIcon, ClockIcon, FileChartColumnIcon } from '@snsicons/icons/solid'
export default {
  components: {
    RequestReportFormModal,
    CalendarCheckIcon,
    ClockIcon,
    FileChartColumnIcon
  },
  data(){
    return {
      loading: false,
      requestReports: [],
      requestReportHeader: [
        {
          text: 'Report Name',
          value: 'reportName',
        },
        {
          text: 'Requester',
          value: 'requester',
        },
        {
          text: 'Requested At',
          value: 'createdAt',
        },
        {
          text: 'Completed At',
          value: 'completedAt',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Request Type',
          value: 'requestType',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      filters: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
        deliver_method: 'async',
        sort_by: 'created_at',
        sort_direction: 'desc',
      },
      reloadRequestReports: null,
    }
  },
  watch: {
    'filters.page': function(value){
      this.fetchRequestReports()
    }
  },
  mounted(){
    let vm = this
    this.fetchRequestReports()
    this.$eventBus.on('request_report:created', function(){
      vm.fetchRequestReports()
    })
  },
  methods: {
    async fetchRequestReports(){
      this.loading = true
      try{
        let response = await this.$repositories.requestReports.get(this.filters)
        this.requestReports = response.data
        this.filters.per = response.pagination.perPage
        this.filters.page = response.pagination.currentPage
        this.filters.total = response.pagination.total
        this.filters.last_page = response.pagination.totalPages
      }catch(_){}
      this.loading = false
    },
  }
}
</script>


<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Export
      </template>
      <template v-slot:actions>
        <Button color="white" @click="$router.push({name: 'dashboard.scheduled_reports.index'})" :loading="loading" :disabled="disabled" class="flex items-center space-x-1">
          <ClockIcon class="w-4 h-4 text-green-600" />
          <span>
            Schedule Export
          </span>
        </Button>
        <Button color="white" @click="$refs.requestReportFormModal.open()" :loading="loading" :disabled="disabled" class="flex items-center space-x-1">
          <FileChartColumnIcon class="w-4 h-4 text-green-600" />
          <span>
            Request Export
          </span>
        </Button>
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full pb-5">
        <TTable :headers="requestReportHeader" :items="requestReports" :loading="loading" :allowBulk="false" >
          <template v-slot:[`item.reportName`]="{ item }">
            <div class="flex items-center space-x-2">
              <span class="capitalize">
                {{ item.reportName }}
              </span>
              
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="capitalize">
              {{ item.status }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ $filters.date.dateTimeFormat(item.createdAt) }}
          </template>
          <template v-slot:[`item.completedAt`]="{ item }">
            <span v-if="item.completedAt">
              {{ $filters.date.dateTimeFormat(item.completedAt) }}
            </span>
          </template>
          <template v-slot:[`item.requestType`]="{ item }">
            <Badge color="green" rounded small v-if="item.scheduled">
              <span class="flex items-center space-x-2" >
                <span>Scheduled</span>
              </span>
            </Badge>

            <Badge color="gray" rounded small v-else>
              <span class="flex items-center space-x-2" >
                <span>Requested</span>
              </span>
            </Badge>
            
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.fileResource">
              <a :href="item.fileResource.url" target="_blank" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                Download
              </a>
            </span>
          </template>
          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="filters.total" :lastPage="filters.last_page" :perPage="filters.per" v-model="filters.page"/>
          </template>
        </TTable>
      </div>
      <RequestReportFormModal ref="requestReportFormModal" />
    </div>
  </div>
</template>