<script>
import FileSaver from 'file-saver'
import { FileArrowDownIcon } from '@snsicons/icons/solid'

export default {
  components: {
    FileArrowDownIcon,
  },
  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    resource: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return { 
      loading: false
    }
  },
  channels: {
    ExportChannel: {
      connected() {},
      rejected(data) {
        console.log("--Rejected--")
        console.log(data)
        console.log("--Rejected--")
      },
      received(data) {
        this.loading = false
        if(data && data.hasOwnProperty('message')){
          this.$eventBus.emit('app:alert', {
            type: 'success',
            message: data.message,
            title: "Export Info",
          })
        }
        if(data && data.hasOwnProperty('filename')){
          let blob = new Blob([data.content], { type: data.type })
          FileSaver.saveAs(blob, data.filename)
        }
      },
      disconnected() {},
    },
  },
  mounted(){
    this.$cable.subscribe({
      channel: "ExportChannel"
    })
  },
  methods: {
    async exportReport() {
      this.loading = true
      this.$cable.perform({
        channel: "ExportChannel",
        action: this.resource,
        data: {
          filters: this.filters,
        },
      })
    },
  }
}
</script>
<template>
  <Button color="white" @click="exportReport" :loading="loading" :disabled="disabled" class="flex items-center">
    <FileArrowDownIcon class="w-4 h-4 text-green-600" />
    <span>
      Export
    </span>
  </Button>
</template>