import BaseModel from "@/models/BaseModel"
import CarrierService from '@/models/CarrierService'

export default class Carrier extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.logo = ''
    this.disclaimer = ''
    this.code = ''

    this.carrierServices = null
  }

  static fromJson(json){
    let carrier = new this()

    carrier.id = json.id
    carrier.name = json.name
    carrier.logo = json.logo
    carrier.disclaimer = json.disclaimer
    carrier.code = json.code

    carrier.carrierServices = this.initRelation(json.carrier_services, CarrierService, [])

    return carrier
  }
}