import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Listing from '@/models/Listing'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import ValidationError from '@/repositories/Response/ValidationError'

const resource = 'listings'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`saleschannel/${version}/${resource}`, Listing, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the listings'
      })
      throw error
    }
  },

  async errors(){
    try{
      return await APIService.get(`saleschannel/${version}/${resource}/errors`)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the listings'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`saleschannel/${version}/${resource}/${id}`, Listing, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the listing!'
      })
      throw error
    }
  },

  async new(payload = {}){
    try{
      return await APIService.fetchEntity(`saleschannel/${version}/${resource}/new`, Listing, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the listing!'
      })
      throw error
    }
  },

  async create(payload){
    try{
      let response = await APIService.postEntity(`saleschannel/${version}/${resource}`, payload)
      eventBus.emit('listing:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create listing. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`saleschannel/${version}/${resource}/${id}`, payload)
      eventBus.emit('listing:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update listing. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async updatePrice(id, payload){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/${id}`, payload)
      eventBus.emit('listing:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update listing. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async retire(params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/retire`, {}, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('listing:retired')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed.  In a few minutes all selected listings will be marked for retiring.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete brand. Please try again later!'
      })
      throw error
    }
  },

  async unretire(params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/unretire`, {}, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('listing:unretired')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed.  In a few minutes all selected listings will be marked for retiring.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete brand. Please try again later!'
      })
      throw error
    }
  },

  async delete(params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/delete`, {}, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('listing:deleted')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed.  In a few minutes all selected listings will be marked for retiring.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete brand. Please try again later!'
      })
      throw error
    }
  },

  async publish(params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/publish`, {}, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed. In a few minutes all selected listings will be marked for publishing.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing. Please try again later!'
      })
      throw error
    }
  },

  async sendToMax(params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/send_to_max`, {}, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed. In a few minutes all selected listings will be sent to max.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing. Please try again later!'
      })
      throw error
    }
  },

  async discount(payload = {}, params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/discount`, payload, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed. In a few minutes all selected listings will be discounted.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing. Please try again later!'
      })
      throw error
    }
  },

  async updateMany(payload, params = {}){
    try{
      let response = await APIService.put(`saleschannel/${version}/${resource}/update_many`, { update_many: payload }, params)
      
      eventBus.emit('listing:updated')
      eventBus.emit('app:alert', {
        type: 'success',
        title: 'Action was placed!',
        message: 'The bulk update action was placed. In a few minutes all selected listings will changed with requested details.'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing. Please try again later!'
      })
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`saleschannel/${version}/${resource}/${id}`)
      eventBus.emit('listing:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing. Please try again later!'
      })
    }
  },

  async ai(payload){
    try{
      let response = await APIService.post(`saleschannel/${version}/${resource}/ai`, payload)
      eventBus.emit('listing:ai')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Improvement Applied!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },
}
