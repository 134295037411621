import BaseModel from "@/models/BaseModel"

export default class CarrierService extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.code = ''
  }

  static fromJson(json){
    let carrierService = new this()

    carrierService.id = json.id
    carrierService.name = json.name
    carrierService.code = json.code

    return carrierService
  }
}