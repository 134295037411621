import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'return_orders'
const version = 'v1'
import ReturnOrder from "@/models/ReturnOrder"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, ReturnOrder, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the products!'
      })
      throw error
    }
  },
}