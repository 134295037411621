<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ isEditing ? 'Edit' : 'Create' + ' Brand' }}
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex justify-between items-center w-full">
        <Button color="red" @click="deleteBrand" v-if="isEditing" v-tooltip="brand.productsCount > 0 ? 'Before deleting a brand, you must assign products from this brand to another brand.' : ''">
          {{ $t('Delete') }}
        </Button>
        <Button color="blue" @click="saveBrand">
          {{ isEditing ? $t('Update') : $t('Create') }}
        </Button>
      </div>
    </template>

    <div>
      <div>
        <Label name="name" required :errors="errors">{{ $t('Brand Name') }}</Label>
        <InputText v-model="brand.name" name="name" class="w-full" :errors="errors"/>
        <FormValidation :errors="errors" name="name" />
      </div>
    </div>

  </FormModal>
</template>
<script>
  import Brand from "@/models/Brand";
  import _ from 'lodash'
  const BASE_BRAND = {
    name: '',
  }
  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        brand: new Brand(),
        errors: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.brand.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let brand = new Brand()
          _.assign(brand, preset)
          this.brand = brand
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.brand = new Brand()
        this.errors = []
      },
      saveBrand(){
        this.savingForm = true
        this.errors = []
        if(this.isEditing){
          this.updateBrand()
        }else{
          this.storeBrand()
        }
      },
      async storeBrand(){
        try{
          let response = await this.$repositories.brands.store(this.brand)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
      async updateBrand(){
        try{
          let response = await this.$repositories.brands.update(this.brand.id, this.brand)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },
      async deleteBrand(){
        if(this.brand.productsCount > 0){
          return
        }
        try{
          await this.$repositories.brands.destroy(this.brand.id)
          this.$store.dispatch('brands/fetch')
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      }
    }
  }
</script>