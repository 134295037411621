<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-center border-b border-gray-200 pb-10">
      <svg id="Capa_1" enable-background="new 0 0 512 512" height="96" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><circle cx="236.056" cy="275.944" fill="#e94444" r="236.056"/><circle cx="236.057" cy="275.944" fill="#ebebeb" r="171.169"/><circle cx="236.057" cy="275.944" fill="#e94444" r="106.281"/><circle cx="236.057" cy="275.944" fill="#5a5a5a" r="41.394"/><path d="m236.056 39.887c-6.886 0-13.698.312-20.434.89 120.8 10.361 215.622 111.682 215.622 235.166s-94.822 224.806-215.622 235.167c6.736.578 13.548.89 20.434.89 130.37 0 236.056-105.686 236.056-236.056s-105.685-236.057-236.056-236.057z" fill="#d83131"/><g><path d="m454.893 1.077-51.785 51.785c-2.226 2.226-3.388 5.3-3.19 8.441l2.999 47.781 61.011-61.011-2.802-44.64c-.197-3.142-4.007-4.582-6.233-2.356z" fill="#0055a3"/><path d="m510.924 57.107-51.785 51.785c-2.226 2.226-5.3 3.388-8.441 3.19l-47.781-2.999 61.011-61.011 44.64 2.802c3.141.197 4.581 4.008 2.356 6.233z" fill="#0055a3"/><g><path d="m236.056 283.667c-1.976 0-3.953-.754-5.462-2.262-3.016-3.016-3.016-7.907 0-10.923l257.957-257.956c3.017-3.016 7.907-3.016 10.924 0 3.016 3.016 3.016 7.907 0 10.923l-257.957 257.956c-1.508 1.508-3.486 2.262-5.462 2.262z" fill="#bfbfbf"/></g></g></g></svg>
      <h2 class="text-2xl font-bold leading-7 text-blue-500 sm:text-3xl sm:truncate py-2">
        Getting the Buy Box
      </h2>
      <p class="text-md text-gray-500">Customize how Sitnsell competes for the Buy Box ownership.</p>
    </div>
    <div class="mt-6">
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Choose how to reprice against your competition<span class="text-red-500">*</span></div>
        <div class="flex space-x-3">
          <div class="flex flex-col">
            <Select :items="priceOptions" v-model="localValue.beat_by.type" labelKey="label" valueKey="code" />
            <FormValidation :errors="errors" name="beat_by.type" />
          </div>
          <div class="flex flex-col">
            <Select :items="methodOptions" v-model="localValue.beat_by.method" labelKey="label" valueKey="code" v-if="localValue.beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="beat_by.method" />
          </div>
          <div class="flex flex-col">
            <InputText v-model="localValue.beat_by.value" name="name" class="w-full" :errors="errors" v-if="localValue.beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="beat_by.value" />
          </div>
        </div>
        <div class="text-xs text-gray-400">Enter the dollar amount or percentage that will be used to reprice you against your competition. The correct currency will be used automatically.</div>
      </div>

      <div class="w-2/3" :class="localValue.is_fba_beat_by_enabled ? 'mb-3' : 'mb-6'">
        <div class="text-xs text-gray-600 font-medium">Would you like to compete differently against FBA and SFP ?</div>
        <div class="pt-1">
          <Toggle v-model="localValue.is_fba_beat_by_enabled" />
        </div>
      </div>

      <div class="w-2/3 mb-6" v-if="localValue.is_fba_beat_by_enabled">
        <div class="flex space-x-3">
          <div class="flex flex-col">
            <Select :items="priceOptions" v-model="localValue.fba_beat_by.type" labelKey="label" valueKey="code" />
            <FormValidation :errors="errors" name="fba_beat_by.type" />
          </div>
          <div class="flex flex-col">
            <Select :items="methodOptions" v-model="localValue.fba_beat_by.method" labelKey="label" valueKey="code" v-if="localValue.fba_beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="fba_beat_by.method" />
          </div>
          <div class="flex flex-col">
            <InputText v-model="localValue.fba_beat_by.value" name="name" class="w-full" :errors="errors" v-if="localValue.fba_beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="fba_beat_by.value" />
          </div>
        </div>
        <div class="text-xs text-gray-400">Enter the dollar amount or percentage that will be used to reprice you against your competition. The correct currency will be used automatically.</div>
      </div>

      <div class="w-2/3" :class="localValue.is_amazon_beat_by_enabled ? 'mb-3' : 'mb-6'">
        <div class="text-xs text-gray-600 font-medium">Would you like to compete differently against Amazon?</div>
        <div class="pt-1">
          <Toggle v-model="localValue.is_amazon_beat_by_enabled" />
        </div>
      </div>

      <div class="w-2/3 mb-6" v-if="localValue.is_amazon_beat_by_enabled">
        <div class="flex space-x-3">
          <div class="flex flex-col">
            <Select :items="priceOptions" v-model="localValue.amazon_beat_by.type" labelKey="label" valueKey="code" />
            <FormValidation :errors="errors" name="amazon_beat_by.type" />
          </div>
          <div class="flex flex-col">
            <Select :items="methodOptions" v-model="localValue.amazon_beat_by.method" labelKey="label" valueKey="code" v-if="localValue.amazon_beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="amazon_beat_by.method" />
          </div>
          <div class="flex flex-col">
            <InputText v-model="localValue.amazon_beat_by.value" name="name" class="w-full" :errors="errors" v-if="localValue.amazon_beat_by.type != 'match_price'"/>
            <FormValidation :errors="errors" name="amazon_beat_by.value" />
          </div>
        </div>
        <div class="text-xs text-gray-400">Enter the dollar amount or percentage that will be used to reprice you against your competition. The correct currency will be used automatically.</div>
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">How do you want to treat Seller Fulfilled Prime (SFP)?</div>
        <Select :items="treatSFPOptions" v-model="localValue.seller_fulfilled_prime" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="seller_fulfilled_prime" />
      </div>
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">When there is no competition</div>
        <Select :items="noCompetitionOptions" v-model="localValue.no_competition" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="no_competition" />
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">When the competition is below your Min Price</div>
        <Select :items="competitionOptions" v-model="localValue.competition_below_min_price" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="competition_below_min_price" />
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">When the competition matches your Min Price</div>
        <Select :items="competitionOptions" v-model="localValue.competition_matches_min_price" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="competition_matches_min_price" />
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">When your own price matches your Min Price</div>
        <Select :items="matchMinPriceOptions" v-model="localValue.my_price_matches_min_price" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="my_price_matches_min_price" />
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">When Buy Box is suppressed</div>
        <Select :items="buyBoxSuppressedOptions" v-model="localValue.when_buy_box_is_suppressed" labelKey="label" valueKey="code" class="w-1/3"/>
        <FormValidation :errors="errors" name="when_buy_box_is_suppressed" />
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Price out-of-stock listings to Max Price</div>
        <div class="text-xs text-gray-400">Maintain your max price on listings when out of stock. In-stock listings will reprice according to strategy settings.</div>
        <div class="pt-1">
          <Toggle v-model="localValue.out_of_stock_go_to_max" />
        </div>
      </div>

      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Don't lower my price when in the Buy Box</div>
        <div class="text-xs text-gray-400">Prevent your price from being lowered when you have the Buy Box, even if competitors are offering lower prices than you.</div>
        <div class="pt-1">
          <Toggle v-model="localValue.dont_lower_my_price" />
        </div>
      </div>

    </div>
  </div>

</template>
<script>

import _ from 'lodash'

const BASE_BUY_BOX = {
  beat_by: {
    type: 'price_below',
    method: 'fixed',
    value: "0.01",
  },
  is_fba_beat_by_enabled: false,
  fba_beat_by: {
    type: 'price_below',
    by: 'fixed',
    value: "0.01",
  },
  is_amazon_beat_by_enabled: false,
  amazon_beat_by: {
    type: 'price_below',
    by: 'fixed',
    value: "0.01",
  },
  seller_fulfilled_prime: 'FBA',
  no_competition: 'do_not_reprice',
  competition_below_min_price: 'min_price',
  competition_matches_min_price: 'min_price',
  my_price_matches_min_price: 'min_price',
  when_buy_box_is_suppressed: 'min_price',
  out_of_stock_go_to_max: false,
  dont_lower_my_price: false,
}


export default {
  props: {
    modelValue: {
      type: Object,
    }
  },
  data(){
    return {
      localValue: JSON.parse(JSON.stringify(BASE_BUY_BOX)),
    }
  },
  emits: ['update:modelValue'],
  watch: {
    localValue: {
      handler(){
        this.$emit('update:modelValue', this.localValue)
      },
      deep: true
    },
    hasErrors(value){
      this.$emit('hasErrors', value)
    }
  },
  beforeMount(){
    this.localValue = _.defaultsDeep(JSON.parse(JSON.stringify(this.modelValue)), JSON.parse(JSON.stringify(BASE_BUY_BOX)))
  },
  computed:{
    errors(){
      let errors = {}

      if(this.localValue.beat_by.type == null){
        errors['beat_by.type'] = 'is required'
      }

      if(this.localValue.beat_by.method == null && this.localValue.beat_by.type != 'match_price'){
        errors['beat_by.method'] = 'is required'
      }

      if(parseFloat(this.localValue.beat_by.value) <= 0 && this.localValue.beat_by.type != 'match_price'){
        errors['beat_by.value'] = 'must be greater than 0'
      }

      if(this.localValue.is_fba_beat_by_enabled){
        if(this.localValue.fba_beat_by.type == null){
          errors['fba_beat_by.type'] = 'is required'
        }

        if(this.localValue.fba_beat_by.method == null){
          errors['fba_beat_by.method'] = 'is required'
        }

        if(parseFloat(this.localValue.fba_beat_by.value) <= 0){
          errors['fba_beat_by.value'] = 'must be greater than 0'
        }
      }

      if(this.localValue.is_amazon_beat_by_enabled){
        if(this.localValue.amazon_beat_by.type == null){
          errors['amazon_beat_by.type'] = 'is required'
        }

        if(this.localValue.amazon_beat_by.method == null && this.localValue.amazon_beat_by.type != 'match_price'){
          errors['amazon_beat_by.method'] = 'is required'
        }

        if(parseFloat(this.localValue.amazon_beat_by.value) <= 0 && this.localValue.amazon_beat_by.type != 'match_price'){
          errors['amazon_beat_by.value'] = 'must be greater than 0'
        }
      }

      if(this.localValue.no_competition == null){
        errors['no_competition'] = 'is required'
      }

      if(this.localValue.competition_below_min_price == null){
        errors['competition_below_min_price'] = 'is required'
      }

      if(this.localValue.competition_matches_min_price == null){
        errors['competition_matches_min_price'] = 'is required'
      }

      if(this.localValue.my_price_matches_min_price == null){
        errors['my_price_matches_min_price'] = 'is required'
      }

      if(this.localValue.when_buy_box_is_suppressed == null){
        errors['when_buy_box_is_suppressed'] = 'is required'
      }

      if(this.localValue.out_of_stock_go_to_max == null){
        errors['out_of_stock_go_to_max'] = 'is required'
      }

      if(this.localValue.dont_lower_my_price == null){
        errors['dont_lower_my_price'] = 'is required'
      }

      return errors
    },
    hasErrors(){
      return Object.keys(this.errors).length > 0
    },
    treatSFPOptions(){
      return [
        {
          label: "Same as FBA (Recommended)",
          code: "FBA"
        },
        {
          label: "Same as MFN",
          code: "MFN"
        }
      ]
    },
    priceOptions(){
      return [
        {
          label: 'Price Below',
          code: 'price_below'
        },
        {
          label: 'Price Above',
          code: 'price_above'
        },
        {
          label: 'Match Price',
          code: 'match_price'
        },
      ]
    },
    noCompetitionOptions(){
      return [
        {
          label: 'Do Not Reprice',
          code: 'do_not_reprice'
        },
        {
          label: 'Use Min Price',
          code: 'min_price'
        },
        {
          label: 'Use Max Price',
          code: 'max_price'
        },
      ]
    },
    competitionOptions(){
      return [
        {
          label: 'Do Not Reprice',
          code: 'do_not_reprice'
        },
        {
          label: 'Ignore Competition',
          code: 'ignore'
        },
        {
          label: 'Use Min Price',
          code: 'min_price'
        },
        {
          label: 'Use Max Price',
          code: 'max_price'
        },
      ]
    },
    matchMinPriceOptions(){
      return [
        {
          label: 'Use Min Price',
          code: 'min_price'
        },
        {
          label: 'Use Max Price',
          code: 'max_price'
        },
      ]
    },
    buyBoxSuppressedOptions(){
      return [
        {
          label: 'Do Not Reprice',
          code: 'do_not_reprice'
        },
        {
          label: 'Use Min Price',
          code: 'min_price'
        },
        {
          label: 'Use Max Price',
          code: 'max_price'
        },
        {
          label: 'Compete with lowest featured merchant',
          code: 'lowest_featured'
        },
      ]
    },
    methodOptions(){
      return [
        {
          label: '$/€/£...',
          code: 'fixed'
        },
        {
          label: 'Percentage (%)',
          code: 'percentage'
        },
      ]
    },
  }, 
}
</script>
