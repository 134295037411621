import BrandsRepository from "@/repositories/BrandsRepository"

export default {
  async fetch(context){
    const response = await BrandsRepository.get({per: 10000, page: 1, sort_by: 'name', sort_direction: 'asc'})
    response.data.forEach((brand) => {
      context.dispatch('addOrUpdate', brand)
    })
  },
  addOrUpdate(context, brand){
    let found = context.getters['find'](brand.id)

    if(found){
      context.commit('update', brand)
    }else{
      context.commit('add', brand)
    }
  },
  reset(context){
    context.commit('reset')
  }
}