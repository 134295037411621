export default {
  all: (state) => {
    return state.salesChannels
  },
  find: (state) => (id) => {
    return state.salesChannels.find((salesChannel) => salesChannel.id == id)
  },
  findByName: (state) => (name) => {
    return state.salesChannels.find((salesChannel) => salesChannel.name == name)
  }
}