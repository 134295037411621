import BaseModel from "@/models/BaseModel"

export default class Department extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.productsCount = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let department = new this()

    department.id = json.id
    department.name = json.name
    department.productsCount = json.products_count
    department.createdAt = json.created_at
    department.updatedAt = json.updated_at

    return department
  }

  toJson(){
    return {
      name: this.name
    }
  }
}