import BaseModel from "@/models/BaseModel"

export default class Pull extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.quantity = 0
    this.author = ''
    this.pullMethod = ''
    this.pickupId = null
    this.voided = false

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let pull = new this()

    pull.id = json.id
    pull.quantity = json.quantity
    pull.author = json.author
    pull.pullMethod = json.pull_method
    pull.pickupId = json.pickup_id
    pull.voided = json.voided

    pull.createdAt = json.created_at
    pull.updatedAt = json.updated_at

    return pull
  }

  toJson(){
    return {
      pull: {
        quantity: this.quantity,
        pull_method: this.pullMethod,
        pickup_id: this.pickupId,
        voided: this.voided
      }
    }
  }
}