const storageVariableName = 'snsToken'

export default class TokenStorageService {

  static getToken() {
    return localStorage.getItem(storageVariableName)
  }

  static setToken(token) {
    return localStorage.setItem(storageVariableName, token)
  }

  static deleteToken() {
    return localStorage.removeItem(storageVariableName)
  }

}