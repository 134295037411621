import { eventBus } from '@/composables/eventBus'
import History from '@/models/History'
import Order from '@/models/Order'
import ValidationError from '@/repositories/Response/ValidationError'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import APIService from '@/repositories/APIService'

const version = 'v1'
const resource = 'orders'

export default {

  async get(payload){
    try{
      return await APIService.fetchEntities(`oms/${version}/${resource}`, Order, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the orders'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`oms/${version}/${resource}/${id}`, Order, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the order!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`oms/${version}/${resource}`, payload)
      eventBus.emit('order:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order was created!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }

      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create order. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`oms/${version}/${resource}/${id}`, payload)
      eventBus.emit('order:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.mit('app:message', {
        type: 'error',
        message: 'Could not update order. Please verify form!'
      })
      throw error
    }
  },

  async destroy(id, payload = {}){
    try{
      let response = await APIService.delete(`oms/${version}/${resource}/${id}`, payload)
      eventBus.emit('order:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete department. Please try again later!'
      })
      throw error
    }
  },

  async cancel(id, payload){
    try{
      let response = await APIService.post(`oms/${version}/${resource}/${id}/cancel`, payload)
      eventBus.emit('order:canceled')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order was canceled with success!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not cancel order. Please verify form!'
      })
      throw error
    }
  },

  async print(id){
    try{
      let response = await APIService.postEntity(`oms/${version}/${resource}/${id}/print`)
      eventBus.emit('order:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Order was printed with success!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not print order!'
      })
      throw error
    }
  },

  async history(id){
    try{
      return await APIService.fetchEntities(`oms/${version}/${resource}/${id}/history`, History)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching order history!'
      })
      throw error
    }
  },

}
