<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
            <div>
              <div :class="'mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'">
                <GaugeSimpleMaxIcon :class="'h-6 w-6 text-green-600'" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  You are about to send prices to max {{ total }} listings from the filter. Are you sure about this operation?
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    This operation is irreversible and recorded in the system.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button @click="updateMany" color="green" class="w-full">
                {{ $t('Send to max ') }} {{ total }} listings
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { GaugeSimpleMaxIcon } from '@snsicons/icons/solid'

export default {
  components: {
    GaugeSimpleMaxIcon,
  },
  data(){
    return {
      opened: false,
      filters: {},
      total: 0,
      cost: 0,
    }
  },
  computed:{
    futureStatus(){
      return this.$enums.listingStatuses.find(item => item.code == 'in_progress')
    }
  },
  methods: {
    open(preset = {}){
      this.filters = preset.filters
      this.total = preset.total
      this.opened = true
    },
    close() {
      this.reset()
      this.opened = false
    },
    reset() {
      this.filter = {}
      this.total = 0
    },
    async updateMany(){
      try {
        await this.$repositories.listings.sendToMax(this.filters)
        this.close()
      } catch (error) {
        
      }
    },
  }
}
</script>