import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import Carrier from "@/models/Carrier"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const platformModule = 'oms'
const resource = 'carriers'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${platformModule}/${version}/${resource}`, Carrier, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the shipping carriers.'
      })
      throw error
    }
  },
}