import BaseModel from "@/models/BaseModel"
import Warehouse from '@/models/Warehouse'

export default class Aisle extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.numberOfRows = 1
    this.numberOfColumns = 1
    this.warehouseId = null
    this.warehouse = null
    this.shelfCount = 0
    this.inventoryCount = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let aisle = new this()

    aisle.id = json.id
    aisle.name = json.name
    aisle.numberOfColumns = json.number_of_columns
    aisle.numberOfRows = json.number_of_rows
    aisle.warehouseId = json.warehouse_id
    aisle.warehouse = this.initRelation(json.warehouse, Warehouse, null)
    aisle.createdAt = json.created_at
    aisle.shelfCount = json.shelf_count
    aisle.inventoryCount = json.inventory_count
    aisle.updatedAt = json.updated_at

    return aisle
  }

  toJson(){
    return {
      aisle: {
        name: this.name,
        warehouse_id: this.warehouseId,
        number_of_columns: this.numberOfColumns,
        number_of_rows: this.numberOfRows
      }
    }
  }
}