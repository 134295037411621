import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'uploads'
const version = 'v1'
import Upload from "@/models/Upload"

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Upload, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the imports'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('upload:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Import was uploaded!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create user. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

}
