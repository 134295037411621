import BaseModel from "@/models/BaseModel"
import Shelf from '@/models/Shelf'
import Product from '@/models/Product'

export default class Inventory extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.shelfId = null
    this.shelf = null
    this.productId = null
    this.product = null
    this.quantity = 0
    this.lockedQuantity = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let inventory = new this()

    inventory.id = json.id
    inventory.quantity = json.quantity
    inventory.lockedQuantity = json.locked_quantity
    inventory.shelf = this.initRelation(json.shelf, Shelf, null)
    inventory.product = this.initRelation(json.product, Product, null)

    inventory.createdAt = json.created_at
    inventory.updatedAt = json.updated_at

    return inventory
  }
}