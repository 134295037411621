<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Print Warehouse Shelves Barcodes for Aisle {{ aisle.name }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="print" :disabled="!canSubmit">
        {{ $t('Print') }}
      </Button>
    </template>
    <div>
      <div class="">
        <p class="text-sm leading-6 font-medium text-gray-500 pb-2" id="modal-headline">
          {{ $t('In order to be able to scan the entrances and exits of the warehouse, you need to print and paste the labels with their barcodes on the respective shelves.') }}
        </p>
        <div class="mt-4 grid grid-cols-2 space-x-5">
          <div>
            <Label name="label_model" :errors="errors" required>{{ $t('Label Model') }}</Label>
            <Select :items="labelModelOptions" v-model="labelModel" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
            <FormValidation :errors="errors" name="label_model" />
          </div>
        </div>
      </div>
    </div>
    
  </FormModal>
</template>
<script>
  import FileSaver from 'file-saver'
  import Aisle from '@/models/Aisle'
  import _ from 'lodash'
  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        aisle: new Aisle(),
        errors: [],
        labelModel: '',
      }
    },
    mounted(){
      
    },
    computed: {
      labelModelOptions(){
        let options = [
          {
            code: 'Avery7165',
            label: 'Avery 7165',
          },
          {
            code: 'Avery7160',
            label: 'Avery7160',
          },
          {
            code: 'Avery5160',
            label: 'Avery 5160',
          },
          {
            code: 'Avery5163',
            label: 'Avery 5163',
          },
          {
            code: 'Avery6572',
            label: 'Avery 6572',
          },
          {
            code: 'Avery6871',
            label: 'Avery 6871',
          },
          {
            code: 'Envelope10',
            label: 'Envelope 10',
          }
        ]
        return options
      },
      canSubmit(){
        return this.labelModel ? true : false
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    methods: {
      open(preset = {}) {
        try{
          let aisle = new Aisle()
          _.assign(aisle, JSON.parse(JSON.stringify(preset)))
          this.aisle = aisle
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.aisle = new Aisle()
      },
      async print(){
        this.errors = []
        this.savingForm = true
        try{
          let response = await this.$repositories.aisles.print(this.aisle.id, {label_model: this.labelModel})
          let byteCharacters = response.data;
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);

          let blob = new Blob([byteArray], { type: "application/pdf" })
          FileSaver.saveAs(blob, "Shelf barcodes for aisle "+this.aisle.name+".pdf")
          this.close()
        }catch(error){
          if(error.response.status === 422){
            this.errors = error.response.data
          }
        }
      },
    }
  }
</script>
