<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";

export default{
  name: 'Pie',
  props: {
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {

    }
  },
  watch: {
    chartData: {
      handler: function(){
        this.root.dispose()
        this.initializeChart()
      },
      deep: true
    }
  },
  mounted(){
    this.initializeChart()
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose()
    }
  },
  methods: {
    initializeChart(){
      let root = am5.Root.new(this.$refs.chart);
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Responsive.new(root)
      ]);

      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalHorizontal
        }) 
      );

      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Series",
          valueField: "count",
          categoryField: "sales_channel"
        })
      );
      series.data.setAll(this.chartData);

      series.labels.template.set("text", "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value})")

      this.root = root
    }
  }
}

</script>
<template>
  <div class="w-full h-64 my-4" ref="chart"></div>
</template>