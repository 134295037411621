import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import Timezone from "@/models/Timezone"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'timezones'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Timezone, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the timezones'
      })

      throw error
    }
  },
}