<script>
  import Order from '@/models/Order'
  import Warehouse from '@/models/Warehouse'
  import { PenToSquareIcon, PlusIcon, PencilIcon, FileInvoiceIcon, TruckFastIcon, SquareExclamationIcon, TriangleExclamationIcon, BoxDollarIcon } from '@snsicons/icons/solid'
  import OrderCard from '@/components/Order/OrderCard.vue'
  import ShippingRatesWidget from '@/components/Widgets/ShippingRatesWidget.vue'
  import ProductWeightFormModal from '@/components/Product/ProductWeightFormModal.vue'
  import { BarcodeReadIcon } from '@snsicons/icons/solid'
  import { HouseIcon } from '@snsicons/icons/regular'
  export default {
    components: {
      FileInvoiceIcon,
      TruckFastIcon,
      TriangleExclamationIcon,
      PenToSquareIcon,
      ShippingRatesWidget,
      ProductWeightFormModal,
      BarcodeReadIcon,
      HouseIcon,
      BoxDollarIcon
    },
    data(){
      return {
        barcode: '',
        loading: false,
        order: new Order(),
        orderLines: [],
        warehouse: new Warehouse()
      }
    },
    mounted(){
      this.$nextTick(() => {
        window.addEventListener("keypress", this.scan)
      })
      this.fetchOrder()
      this.fetchOrderLines()
      this.fetchWarehouse()

      this.$eventBus.on('product:updated', () => {
        this.fetchOrderLines()
      })

      this.$eventBus.on('rate:bought', () => {
        this.fetchOrderLines()
      })
    },
    unmounted() {
      window.removeEventListener('keypress', this.scan)
    },
    computed: {
      linesToBeProcessed(){
        let orderLines = []
        this.orderLines.forEach((orderLine) => {
          if(orderLine.isolatedQuantity > 0){
            orderLines.push(orderLine)
          }
        })
        return orderLines
      },
      confirmedQuantity(){
        let quantity = 0
        this.orderLines.forEach((item) => {
          quantity += item.confirmedQuantity
        })
        return quantity
      },
      pickedQuantity(){
        let quantity = 0
        this.orderLines.forEach((item) => {
          quantity += item.pickedQuantity
        })
        return quantity
      },
      isReadyToBeShipped(){
        return this.confirmedQuantity == this.pickedQuantity
      },
      confirmedPercentage(){
        return Math.ceil(this.confirmedQuantity / this.pickedQuantity * 100)
      },
      confirmedColor(){
        if(this.confirmedPercentage > 0 && this.confirmedPercentage < 10){
          return 'amber'
        }

        if(this.confirmedPercentage > 10 && this.confirmedPercentage <= 50){
          return 'orange'
        }

        if(this.confirmedPercentage > 50 && this.confirmedPercentage <= 75){
          return 'teal'
        }

        if(this.confirmedPercentage > 75 && this.confirmedPercentage <= 100){
          return 'green'
        }
        return 'gray'
      },
    },
    methods: {
      scan(event){
        if(event.code === 'Enter'){
          this.confirm()
        }else{
          this.barcode = this.barcode+event.key
        }
      },
      async confirm(){
        this.loading = true
        try{
          let response = await this.$repositories.processingTables.create({barcode: this.barcode, order_id: this.$route.params.orderId, warehouse_id: this.$store.getters['user_prefs/currentWarehouse']})
          this.order = response
          this.fetchOrder()
          this.fetchOrderLines()
        }catch(e){
          
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
      async fetchOrder(){
        this.loading = true
        try{
          let response = await this.$repositories.orders.find(this.$route.params.orderId)
          this.order = response.data
        }catch(e){
          
        }finally{
          this.barcode = ''
          this.loading = false
        }
      },
      async fetchOrderLines() {
        try{
          const response = await this.$repositories.orderLines.get({order_id: this.$route.params.orderId, warehouse_id: this.$store.getters['user_prefs/currentWarehouse'], includes: 'pickups,order_line_cancellations,order_line_confirmations'})
          this.orderLines = response.data
        }catch(e){
          console.log(e)
        }
      },
      async fetchWarehouse(){
        try{
          let response = await this.$repositories.warehouses.find(this.$store.getters['user_prefs/currentWarehouse'], {exclude: ['quantity']})
          this.warehouse = response.data
        }catch(error){
          console.log(error)
        }
      }
    }
  }
</script>
<template>
  <div>
    <div class="mx-auto p-4 bg-white rounded-lg shadow-lg">
      <loading :active="loading" :is-full-page="true"></loading>
      <router-link :to="{name: 'scanning.processing_table.index'}" class="flex items-center space-x-3 py-3.5 text-blue-500 border-b border-gray-200 cursor-pointer">
        <HouseIcon class="h-5 text-blue-500" />
        <span>Start Over</span>
      </router-link>
      <div class="flex items-center justify-start border-b border-gray-200 py-4 space-x-4">
        <span class="bg-lime-50 text-lime-500 rounded-lg inline-flex p-4 ring-4 ring-white">
          <BarcodeReadIcon class="h-5 w-5"/>
        </span>
        <div class="flex flex-col justify-start">
          <h3 class="font-semibold text-base">Sales Order <span class="text-blue-500">{{ order.purchaseOrderId }}</span> from <span class="text-blue-500">{{ order.platformName }} {{ order.integrationName }}</span> </h3>
          <p class="text-sm text-gray-500">Scan the product barcode in order to continue.</p>
        </div>
      </div>

    </div>

    <div class="px-2 py-4 mx-4 grid grid-cols-1 md:grid-cols-3 gap-4">
      <div :class="['bg-white border border-gray-100 rounded-xl shadow-md col-span-2']">

        <div class="rounded-md bg-yellow-50 p-4" v-if="order.secondDay">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">Attention needed</h3>
              <div class="mt-2 text-sm text-yellow-700">
                <p>The buyer has requested that this order be shipped via a service like UPS 2nd Day Air®.</p>
              </div>
            </div>
          </div>
        </div>

        <div :class="[ 'px-5 py-5 bg-white border-b rounded-xl' ]" >
          <div :class="['rounded-md p-3 mb-4', order.isUrgent ? 'bg-red-50' : '']" v-if="order.isUrgent">
            <div class="flex">
              <div class="flex-shrink-0">
                <TriangleExclamationIcon class="h-5 w-5 text-red-400" aria-hidden="true"/>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p :class="['text-sm', 'text-red-700']">
                  The order must be shipped as soon as possible because the delivery deadline has been exceeded.
                </p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                  <a href="#" :class="['whitespace-nowrap font-medium', 'text-red-700 hover:text-red-600']">Learn more <span aria-hidden="true">&rarr;</span></a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="md:flex md:content-start md:justify-between">
              <div>
                <div class="flex-1 min-w-0 space-y-3">
                  <h2 class="text-2xl font-extrabold leading-7 text-gray-700">
                    Sales Order from {{ order.platformName }}
                  </h2>
                  <p class="text-lg font-medium text-gray-400">
                    {{ order.integrationName }} - #{{ order.purchaseOrderId}}
                  </p>
                </div>
              </div>
              <div class="flex space-x-25">
                <div class="flex items-center space-x-2">
                  <div class="inline-block bg-white rounded-full shadow-xl px-6 py-5">
                    <BoxDollarIcon class="w-7 h-7 text-gray-600" />
                  </div>
                  <div>
                    <h4 class="text-xs font-bold leading-6 text-gray-500 uppercase">Paid for shipping</h4>
                    <p class="text-sm text-gray-800" v-if="order.currency">{{ $filters.currency.format(order.shippingCost, order.currency) }}</p>
                  </div>
                </div>
                <div class="flex items-center space-x-2">
                  <div class="inline-block bg-white rounded-full shadow-xl px-6 py-5">
                    <FileInvoiceIcon class="w-7 h-7 text-gray-600" />
                  </div>
                  <div>
                    <h4 class="text-xs font-bold leading-6 text-gray-500 uppercase">Order Date</h4>
                    <p class="text-sm text-gray-800">{{ $filters.date.dateFormat(order.placedAt)}}</p>
                  </div>
                </div>
                <div class="flex items-center space-x-2">
                  <div class="inline-block bg-white rounded-full shadow-xl p-5">
                    <TruckFastIcon class="w-7 h-7 text-gray-600" />
                  </div>
                  <div>
                    <h4 class="text-xs font-bold leading-6 text-gray-500 uppercase">
                      Expected Shipment Date
                    </h4>
                    <p class="text-sm text-gray-800">
                      {{ $filters.date.dateFormat(order.estimatedShipDate)}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10 bg-gray-100 p-5 rounded-xl shadow-lg">
            <div class="">
              <ul role="list" class="space-y-2">
                <li>
                  <div href="#" class="block">
                    <div class="px-4 py-4 text-xs font-bold items-center grid grid-cols-6">
                      <div class="text-sm text-gray-400 col-span-2">
                        Item
                      </div>
                      <div class="text-sm text-center text-gray-400">
                        Quantity
                      </div>
                      <div class="items-center text-center grid grid-cols-3 gap-2">
                        <div class="text-sm text-gray-400">Picked</div>
                        <div class="text-sm text-gray-400">Packed</div>
                        <div class="text-sm text-gray-400">Shipped</div>
                      </div>
                      <div class="text-sm text-right text-gray-400">
                        Item Price
                      </div>
                      <div class="text-sm text-right text-gray-400">
                        Total
                      </div>
                    </div>
                  </div>
                </li>
                <li v-for="orderLine in linesToBeProcessed" :key="orderLine.id" :class="['border rounded-lg shadow-md bg-white']">
                  <div class="block">
                    <div class="px-4 py-4 items-center grid grid-cols-6">
                      <div class="text-sm col-span-2 space-y-1">
                        <div class="font-bold text-gray-600 truncate" v-tooltip="orderLine.productName">{{ orderLine.productName }}</div>
                        <div class="font-normal flex space-x-1 items-center text-gray-500">
                          <p>SKU: {{ orderLine.sku }} </p>
                          <CopyButton :copy="orderLine.sku" class="w-3 h-3 text-gray-500"/>
                        </div>
                        <div class="font-normal flex space-x-1 items-center text-gray-500">
                          <p>Barcode: {{ orderLine.barcode }} </p>
                          <CopyButton :copy="orderLine.barcode" class="w-3 h-3 text-gray-500"/>
                        </div>
                        <div class="flex space-x-2 font-bold text-blue-500 hover:text-blue-700 cursor-pointer" @click="$refs.productWeightFormModal.open(orderLine.product)" v-if="orderLine.product">
                          <p>{{ $t('Weight') }}: {{ orderLine.product.weightValue }} {{ orderLine.product.weightUnit }}</p>
                          <PenToSquareIcon class="w-4 h-4 text-blue-500" />
                        </div>
                      </div>
                      <div class="text-md text-gray-400 text-center ">
                        {{ orderLine.isolatedQuantity }}
                      </div>
                      <div class="items-center grid grid-cols-3 gap-2">
                        <ProgressCircle :percent="orderLine.pickedQuantity / orderLine.isolatedQuantity * 100">
                          <span :class="[orderLine.pickedQuantity == 0 ? 'text-orange-600' : 'text-green-600']">{{ orderLine.pickedQuantity }}</span>
                        </ProgressCircle>
                        <ProgressCircle :percent="(orderLine.confirmedQuantity - orderLine.isolatedQuantity) / orderLine.isolatedQuantity * 100">
                          <span :class="[orderLine.confirmedQuantity == 0 ? 'text-orange-600' : 'text-teal-600']">{{ orderLine.confirmedQuantity }}</span>
                        </ProgressCircle>
                        <ProgressCircle :percent="(orderLine.shippedQuantity - orderLine.isolatedQuantity) / orderLine.isolatedQuantity * 100">
                          <span :class="[orderLine.shippedQuantity == 0 ? 'text-orange-600' : 'text-green-600']">{{ orderLine.shippedQuantity }}</span>
                        </ProgressCircle>
                      </div>
                      <div class="text-md text-gray-400 text-right">
                        {{ $filters.currency.format(orderLine.price, orderLine.currency) }}
                      </div>
                      <div class="text-md text-gray-400 text-right">
                        {{ $filters.currency.format(orderLine.total, orderLine.currency) }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="warehouse.id && isReadyToBeShipped && order">
        <Card >
          <overlay :active="loading" :is-full-page="false"></overlay>
          <CardHeader>
            <h2 class="text-base font-medium text-gray-900">
              {{ $t('Shipping Rates') }}
            </h2>
            <p class="text-sm text-gray-500">
              {{ $t('Insurance and signature confirmation will be set according to the carrier account settings.') }}
            </p>
          </CardHeader>
          <CardBody class="px-6">
            <ShippingRatesWidget :warehouse="warehouse" :order-lines="linesToBeProcessed" :order="order" v-if="warehouse.id && isReadyToBeShipped && order"/>
          </CardBody>
        </Card>
      </div>

      <div class="px-4 sm:px-0 space-y-2" v-else>
        <div :class="['border-4 border-dashed border-'+confirmedColor+'-200 rounded-lg flex flex-col h-full mx-auto items-center justify-center']">
          <p :class="['text-xl text-'+confirmedColor+'-400 font-bold']">Confirmed Quantity</p>
          <p :class="['text-xl text-'+confirmedColor+'-400 font-bold']">{{ confirmedQuantity }} / {{ pickedQuantity }}</p>
          <p :class="['text-xl text-'+confirmedColor+'-400 font-bold animate-pulse']" v-if="isReadyToBeShipped && confirmedQuantity > 0">
            Please wait for the shipping rates widget to load.
          </p>
        </div>
      </div>
    </div>
    <ProductWeightFormModal ref="productWeightFormModal" />
  </div>

</template>