import BaseModel from "@/models/BaseModel"
import Inventory from '@/models/Inventory'

export default class InventoryMovement extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.inventoryId = null
    this.inventory = null
    this.manual = false
    this.scanningMethod = 'scan'
    this.author = null
    this.quantity = 0
    this.remainingQuantity = 0
    this.productBarcode = ''
    this.shelfId = null

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let inventoryMovement = new this()

    inventoryMovement.id = json.id
    inventoryMovement.author = json.author
    inventoryMovement.quantity = json.quantity
    inventoryMovement.remainingQuantity = json.remaining_quantity
    inventoryMovement.scanningMethod = json.scanning_method
    inventoryMovement.inventoryId = json.inventory_id
    inventoryMovement.inventory = this.initRelation(json.inventory, Inventory, null)
    inventoryMovement.createdAt = json.created_at
    inventoryMovement.updatedAt = json.updated_at

    return inventoryMovement
  }

  toJson(){
    return {
      inventory_movement: {
        inventory_id: this.inventoryId,
        quantity: this.quantity,
        scanning_method: this.scanningMethod,
        product_barcode: this.productBarcode,
        shelf_id: this.shelfId
      }
    }
  }
}