<script>
import { CircleCheckIcon } from '@snsicons/icons/solid'
export default {
  components: {
    CircleCheckIcon
  },
  data(){
    return {
      loading: false,
      errors: [],
    }
  },
  mounted(){
    this.fetchErrors()
  },
  computed: {
  },
  methods: {
    async fetchErrors(){
      this.loading = true
      try{
        let response = await this.$repositories.listings.errors()
        this.errors = response.data.data
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <Card v-if="errors.length > 0">
    <CardHeader>
      <h2 class="text-base font-medium text-gray-900">
        {{ $t('The most common listing errors') }}
      </h2>
    </CardHeader>
    <CardBody class="flex flex-col justify-between px-4">
      <ul class="divide-y divide-gray-200">
        <li v-for="error in errors" :key="error.error" class="py-2 text-sm">
          <router-link :to="{name: 'dashboard.listings.index', query: { error_category: error.error }}" class="text-blue-500 hover:text-blue-600">{{ $t(""+error.error+"") }}</router-link> <span class="font-bold">{{ error.count }} listing(s)</span>
        </li>
      </ul>
    </CardBody>
  </Card>
</template>