import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StripeElement = _resolveComponent("StripeElement")!
  const _component_StripeElements = _resolveComponent("StripeElements")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.stripeLoaded)
      ? (_openBlock(), _createBlock(_component_StripeElements, {
          key: 0,
          ref: "elms",
          "stripe-key": _ctx.stripeKey,
          "instance-options": _ctx.instanceOptions,
          "elements-options": _ctx.elementsOptions
        }, {
          default: _withCtx(({ elements, instance }) => [
            _createVNode(_component_StripeElement, {
              ref: "card",
              elements: elements,
              options: _ctx.cardOptions
            }, null, 8, ["elements", "options"])
          ]),
          _: 1
        }, 8, ["stripe-key", "instance-options", "elements-options"]))
      : _createCommentVNode("", true)
  ]))
}