import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import State from "@/models/State"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'states'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`account/${version}/${resource}`, State, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the states'
      })
      throw error;
    }
  },
}