<script>
  import { CircleQuestionIcon, XmarkIcon } from '@snsicons/icons/solid'
  import Order from "@/models/Order"
  import _ from 'lodash'

  export default {
    components: {
      CircleQuestionIcon,
      XmarkIcon
    },
    data(){
      return {
        opened: false,
        order: new Order(),
        errors: {},
        reason: '',
        platformId: null
      }
    },
    computed: {
      cancellationReasons(){
        let options = []

        if(this.order.salesChannel && this.order.salesChannel.platform.code == "EBAY_US" && this.order.status == "awaiting_payment"){
          options.push({
            label: "Order Unpaid",
            code: "ORDER_UNPAID"
          })
          options.push({
            label: "Buyer asked to cancel",
            code: "BUYER_ASKED_CANCEL"
          })
        }else{
          this.$store.getters['order_cancellation_reasons/findByPlatformId'](this.platformId).forEach((item) => {
            options.push({
              label: item.displayName,
              code: item.name
            })
          })
        }
        return options
      },
      canSubmit(){
        return this.reason
      }
    },
    methods: {
      open(preset = {}, platformId) {
        try{
          this.$store.dispatch('order_cancellation_reasons/fetch')
          let order = new Order()
          _.assign(order, JSON.parse(JSON.stringify(preset)))
          this.order = order
          this.platformId = platformId
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.reason = ''
        this.errors = {}
        this.platformId = null
      },

      async cancelOrder(){
        try{
          await this.$repositories.orders.cancel(this.order.id, { reason: this.reason })
          this.close()
        }catch(error) {
          console.log(error)
        }
      },
    }
  }
</script>

<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-2000" @close="close()">
      <div class="absolute inset-0 overflow-hidden">

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-red-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        {{ $t('Cancel Order') }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="rounded-md text-white hover:text-white" @click="close()">
                          <span class="sr-only">Close panel</span>
                          <XmarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-red-300">
                        {{ $t('Cancellation of this order will refund the customer the amount paid for the canceled quantity. This action is irreversible, so act responsibly, as this action is recorded under the name of the person doing it.') }}
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <Label name="reason" :errors="errors" required>
                            {{ $t('Cancellation reason') }}
                          </Label>
                          <Select :items="cancellationReasons" v-model="reason" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
                          <FormValidation :errors="errors" name="reason" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2" v-if="!reason">
                  <a href="#" class="group inline-flex text-sm items-start space-x-1 text-red-500 hover:text-red-900">
                    <p>
                      {{ $t("The information required in the fields marked with a star is compulsory to continue.") }}
                    </p>
                  </a>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2" v-else>
                  
                  <Button color="white" @click="close">
                    {{ $t('Close') }}
                  </Button>
                  <Button color="blue" @click="cancelOrder" :disabled="!canSubmit">
                    {{ $t('Save') }}
                  </Button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>