<script>
import { ArrowUpRightFromSquareIcon } from '@snsicons/icons/solid'
import { FilterIcon, PlusIcon, PencilIcon } from '@heroicons/vue/24/solid'
import { XIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, GripDotsIcon, RotateIcon, HandSparklesIcon } from '@snsicons/icons/solid'
import ProductsAdjustCostModal from '@/components/Product/ProductsAdjustCostModal'
import ProductsChangeBrandModal from '@/components/Product/ProductsChangeBrandModal'
import ProductsModifyDepartmentModal from '@/components/Product/ProductsModifyDepartmentModal'
import ProductFormModal from '@/components/Product/ProductFormModal'
import CopyButton from '@/components/UI/CopyButton.vue'
import _ from 'lodash'
import BrandFormModal from '../../components/Brand/BrandFormModal.vue'
import CategoryFormModal from '../../components/Category/CategoryFormModal.vue'

export default {
  components: {
    MagnifyingGlassIcon,
    BrandFormModal,
    CategoryFormModal,
    PencilIcon,
    ArrowUpRightFromSquareIcon,
},
  data() {
    return {
      loading: false,
      brandTerm: '',
      categoryTerm: '',
      brands: [],
      categories: [],
      brandHeaders: [
        {
          text: 'Brand',
          value: 'name',
        },
        {
          text: 'Associated products',
          value: 'productsCount',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      categoryHeaders: [
        {
          text: 'Category',
          value: 'name',
        },
        {
          text: 'Associated products',
          value: 'productsCount',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    }
  },
  beforeMount(){
    this.$store.dispatch('brands/fetch')
    this.$store.dispatch('categories/fetch')
  },
  computed:{
    brandOptions(){
      let brands = _.sortBy(this.brands, ['name'])
      if(this.brandTerm){
        return brands.filter((item) => {
          return item.name.toLowerCase().includes(this.brandTerm.toLowerCase())
        })
      }else{
        return brands
      }
    },
    categoriesOptions(){
      let categories = _.sortBy(this.categories, ['name'])
      if(this.categoryTerm){
        return categories.filter((item) => {
          return item.name.toLowerCase().includes(this.categoryTerm.toLowerCase())
        })
      }else{
        return categories
      }
    },
  },
  mounted(){
    const vm = this
    this.reloadBrands()
    this.reloadCategories()
    
    this.$eventBus.on('brand:created', () => {
      this.reloadBrands()
    })

    this.$eventBus.on('brand:updated', () => {
      this.reloadBrands()
    })

    this.$eventBus.on('brand:destroyed', () => {
      this.reloadBrands()
    })

    this.$eventBus.on('department:created', () => {
      this.reloadCategories()
    })

    this.$eventBus.on('department:updated', () => {
      this.reloadCategories()
    })

    this.$eventBus.on('department:destroyed', () => {
      this.reloadCategories()
    })
  },
  
  
  methods: {
    debounceBrandTerm: _.debounce(function (event) {
      this.brandTerm = event.target.value
    }, 800),
    reloadBrands(){
      this.$store.dispatch('brands/reset')
      this.$store.dispatch('brands/fetch')
      this.brands = this.$store.getters['brands/all']
    },

    reloadCategories(){
      this.$store.dispatch('categories/reset')
      this.$store.dispatch('categories/fetch')
      this.categories = this.$store.getters['categories/all']
    },
  }
}
</script>

<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        {{ $t('Brands And Categories') }}
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">

      <div class="grid grid-cols-2 gap-4">
        <div class="bg-white shadow rounded">
          <div class="w-full flex justify-between p-4">
            <div class="flex items-center space-x-4">
              <h4 class="text-base font-bold leading-7 sm:truncate">
                {{ $t('Brands') }}
              </h4>
            </div>
            <div class="flex items-center space-x-2">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
                </div>
                <Input v-model="brandTerm" class="pl-8" placeholder="Search...." />
              </div>
              <Button color="blue" @click="$refs.brandFormModal.open()">
                Add new brand
              </Button>
            </div>
          </div>

          <div class="w-full">
            <TTable :headers="brandHeaders" :items="brandOptions" :loading="loading" :allowBulk="false" style="height: 80vh;">
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:[`item.productsCount`]="{ item }">
                <router-link target="_blank" :to="{name: 'dashboard.products.index', query: { brand: item.id }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                  <span>{{ item.productsCount }}</span>
                  <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
                </router-link>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="flex items-center space-x-3">
                  <a class="text-blue-500 hover:text-blue-700" @click="$refs.brandFormModal.open(item)" v-tooltip="'Edit'">
                    <PencilIcon class="w-5 h-5"/>
                  </a>
                </div>
              </template>

            </TTable>
          </div>
        </div>

        <div class="bg-white shadow rounded">
          <div class="w-full flex justify-between p-4">
            <div class="flex items-center space-x-4">
              <h4 class="text-base font-bold leading-7 sm:truncate">
                {{ $t('Categories') }}
              </h4>
            </div>
            <div class="flex space-x-2 items-center">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
                </div>
                <Input v-model="categoryTerm" class="pl-8" placeholder="Search...." />
              </div>
              <Button color="blue" @click="$refs.categoryFormModal.open()">
                Add new category
              </Button>
            </div>
          </div>

          <div class="w-full">
            <TTable :headers="categoryHeaders" :items="categoriesOptions" :loading="loading" :allowBulk="false" style="height: 80vh;">
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:[`item.productsCount`]="{ item }">
                <router-link target="_blank" :to="{name: 'dashboard.products.index', query: { category: item.id }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                  <span>{{ item.productsCount }}</span>
                  <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
                </router-link>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="flex items-center space-x-3">
                  <a class="text-blue-500 hover:text-blue-700" @click="$refs.categoryFormModal.open(item)" v-tooltip="'Edit'">
                    <PencilIcon class="w-5 h-5"/>
                  </a>
                </div>
              </template>

            </TTable>
          </div>
        </div>
      </div>
      <BrandFormModal ref="brandFormModal" />
      <CategoryFormModal ref="categoryFormModal" />
    </div>
  </div>
</template>