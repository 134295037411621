import StrategiesRepository from "@/repositories/StrategiesRepository"

export default {
  async fetch(context){
    const response = await StrategiesRepository.get({per: 10000, page: 1})
    response.data.forEach((strategy) => {
      context.dispatch('addOrUpdate', strategy)
    })
  },
  addOrUpdate(context, strategy){
    let found = context.getters['find'](strategy.id)

    if(found){
      context.commit('update', strategy)
    }else{
      context.commit('add', strategy)
    }
  }
}