export default{
  notifications: {
    'GeneratedReportNotification': {
      component: 'generated-report-notification',
      title: 'Requested report was generated',
    },
    'MentionNotification': {
      component: 'mention-notification',
      title: 'Requested report was generated',
    },
    'FinishedImportNotification': {
      component: 'finished-import-notification',
      title: 'Requested report was generated',
    },
    'FinishedReviewNotification': {
      component: 'finished-review-notification',
      title: 'Requested report was generated',
    },
    'AssignedReviewNotification': {
      component: 'assigned-review-notification',
      title: 'Requested report was generated',
    },
  },
  notificationPreferences: [
    {
      title: 'Comment Mention',
      key: {
        sms: 'mention_sms',
        email: 'mention_email',
        database: 'mention_database',
      },
    },
  ],
  getNotificationComponentFor(type) {
    if (this.notifications[type]) {
      return this.notifications[type].component
    }

    return null;
  },
}