import BaseModel from "@/models/BaseModel"
import FileResource from '@/models/FileResource'

export default class Brand extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.invoiceNumber = ''
    this.companyId = null
    this.billAt = null
    this.receiptId = null
    this.receipt = null
    this.reportId = null
    this.report = null
    this.amount = '0'
    this.paidAmount = '0'
    this.status = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let billingInvoice = new this()

    billingInvoice.id = json.id
    billingInvoice.invoiceNumber = json.invoice_number
    billingInvoice.companyId = json.company_id
    billingInvoice.billAt = json.bill_at
    billingInvoice.receiptId = json.receipt_id
    billingInvoice.receipt = this.initRelation(json.receipt, FileResource, null)
    billingInvoice.reportId = json.report_id
    billingInvoice.report = this.initRelation(json.report, FileResource, null)
    billingInvoice.amount = json.amount
    billingInvoice.status = json.status
    billingInvoice.paidAmount = json.paid_amount
    billingInvoice.createdAt = json.created_at
    billingInvoice.updatedAt = json.updated_at

    return billingInvoice
  }
}