import SalesChannelsRepository from "@/repositories/SalesChannelsRepository"

export default {
  async fetch(context){
    const response = await SalesChannelsRepository.get({per: 10000, page: 1, sort_by: 'name', sort_direction: 'asc'})
    response.data.forEach((brand) => {
      context.dispatch('addOrUpdate', brand)
    })
  },
  addOrUpdate(context, salesChannel){
    let found = context.getters['find'](salesChannel.id)

    if(found){
      context.commit('update', salesChannel)
    }else{
      context.commit('add', salesChannel)
    }
  }
}