<script>
  import RequestReport from "@/models/RequestReport";
  import _ from 'lodash'
  import { CircleQuestionIcon } from '@snsicons/icons/solid'

  export default {
    components: {
      CircleQuestionIcon
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        requestReport: new RequestReport(),
        errors: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      reportTypeOptions(){
        let options = []
        options.push({
          label: 'Inventory Report',
          code: 'inventory'
        })
        options.push({
          label: 'Inventory Age Report',
          code: 'inventory-age-report'
        })
        options.push({
          label: 'Inventory Movements',
          code: 'inventory-movements'
        })
        options.push({
          label: 'Listings Report',
          code: 'listings'
        })
        options.push({
          label: 'Products Report',
          code: 'products'
        })
        options.push({
          label: 'Sales Report',
          code: 'sales'
        })
        options.push({
          label: 'Orders Report',
          code: 'orders'
        })
        options.push({
          label: 'Canceled Orders Report',
          code: 'canceled-orders'
        })
        options.push({
          label: 'Listings at Maximum Price',
          code: 'listings-at-maximum'
        })
        options.push({
          label: 'Listings at Minimum Price',
          code: 'listings-at-minimum'
        })
        options.push({
          label: 'Listings with No Minimum Price',
          code: 'listings-no-minimum'
        })
        return _.sortBy(options, 'label')
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let requestReport = new RequestReport()
          _.assign(requestReport, preset)
          this.requestReport = requestReport
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.requestReport = new RequestReport()
        this.errors = []
      },
      async saveRequestReport(){
        this.savingForm = true
        this.errors = []
        await this.storeRequestReport()
      },
      async storeRequestReport(){
        try{
          let response = await this.$repositories.requestReports.store(this.requestReport)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },
    }
  }
</script>
<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close" :open="opened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <CircleQuestionIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('Select the report you want us to generate.') }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <Select :items="reportTypeOptions" v-model="requestReport.reportType" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button @click="saveRequestReport" >
                {{ $t("Request Report") }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>