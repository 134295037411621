import BaseModel from "@/models/BaseModel"
import Currency from "@/models/Currency"

export default class ReturnOrder extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    
    this.listingId = null
    this.orderId = null
    this.orderNumber = null
    this.returnId = ''
    this.returnType = ''
    this.returnReason = ''
    this.carrier = ''
    this.trackingId = ''
    this.labelType = ''
    this.labelPaidBy = ''
    this.labelCost = 0
    this.amount = 0
    this.quantity = 0
    this.currency = {}
    this.itemName = ''
    this.sku = ''
    this.asin = ''
    

    this.requestedAt = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let returnOrder = new this()

    returnOrder.id = json.id
    returnOrder.amount = json.amount
    returnOrder.asin = json.asin
    returnOrder.carrier = json.carrier
    returnOrder.createdAt = json.created_at
    returnOrder.currency = Currency.fromJson(json.currency)
    returnOrder.itemName = json.item_name
    returnOrder.labelCost = json.labelCost
    returnOrder.labelPaidBy = json.label_paid_by
    returnOrder.labelType = json.label_type
    returnOrder.listingId = json.listing_id
    returnOrder.orderId = json.order_id
    returnOrder.orderNumber = json.order_number
    returnOrder.quantity = json.quantity
    returnOrder.requestedAt = json.requested_at
    returnOrder.returnId = json.return_id
    returnOrder.returnReason = json.returnReason
    returnOrder.returnType = json.return_type
    returnOrder.sku = json.sku
    returnOrder.trackingId = json.tracking_id
    returnOrder.updatedAt = json.updated_at

    return returnOrder
  }
}