<script>
  import CarrierAccount from "@/models/CarrierAccount";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        carrierAccount: new CarrierAccount(),
        errors: [],
        carriers: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.carrierAccount.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let carrierAccount = new CarrierAccount()
          _.assign(carrierAccount, JSON.parse(JSON.stringify(preset)))
          this.carrierAccount = carrierAccount
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.carrierAccount = new CarrierAccount()
        this.errors = []
      },
      async updateCarrierAccount(){
        try{
          await this.$repositories.carrierAccounts.update(this.carrierAccount.id, this.carrierAccount)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },

    }
  }
</script>



<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Change the limits for automatic replenishment of the account') }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="updateCarrierAccount">
        {{ $t('Update') }}
      </Button>
    </template>

    <div class="grid grid-cols-2 gap-3">
      <div>
        <Label name="balance_threshold" required :errors="errors">
          {{ $t('Balance Threshold') }}
        </Label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">
              {{ carrierAccount.currency.symbol }}
            </span>
          </div>
          <InputText type="number" v-model="carrierAccount.balanceThreshold" name="balance_threshold" class="w-full pl-7" :errors="errors"/>
        </div>
        <FormValidation :errors="errors" name="balance_threshold" />
      </div>
      <div>
        <Label name="refill_amount" required :errors="errors">
          {{ $t('Refill Amount') }}
        </Label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm">
              {{ carrierAccount.currency.symbol }}
            </span>
          </div>
          <InputText type="number" v-model="carrierAccount.refillAmount" name="refill_amount" class="w-full pl-7" :errors="errors"/>
        </div>
        <FormValidation :errors="errors" name="refill_amount" />
      </div>
    </div>
  
  </FormModal>
</template>