import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import Inventory from "@/models/Inventory"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'inventories'
const version = 'v1'


export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Inventory, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the inventory.'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`warehouse/${version}/${resource}/${id}`, Inventory, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the inventory!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`warehouse/${version}/${resource}`, payload)
      eventBus.emit('inventory:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      if(error instanceof ValidationError){
        throw error
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create inventory. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`warehouse/${version}/${resource}/${id}`, payload)
      eventBus.emit('inventory:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update inventory. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.destroyEntity(`warehouse/${version}/${resource}/${id}`)
      eventBus.emit('inventory:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete inventory. Please try again later!'
      })
      throw error
    }
  }

}
