import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import ListingGallery from '@/models/ListingGallery'
import CanceledRequest from '@/repositories/Response/CanceledRequest'
import ValidationError from '@/repositories/Response/ValidationError'

const resource = 'listing_galleries'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`saleschannel/${version}/${resource}`, ListingGallery, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the listings'
      })
      throw error
    }
  },
  async create(payload){
    try{
      await APIService.postEntity(`saleschannel/${version}/${resource}`, payload)
      eventBus.emit('listing_gallery:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing gallery was created!'
      })
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create listing gallery. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },
  async destroy(id){
    try{
      let response = await APIService.delete(`saleschannel/${version}/${resource}/${id}`)
      eventBus.emit('listing_gallery:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Listing was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete listing gallery. Please try again later!'
      })
    }
  },
}