<script>
  import CarrierAccount from "@/models/CarrierAccount";
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        carrierAccount: new CarrierAccount(),
        errors: [],
        carriers: [],
      }
    },
    watch: {
      opened (newValue) {
        if (!newValue) {
          this.reset()
        }
      },
    },
    computed: {
      isEditing(){
        return this.carrierAccount.id != null
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let carrierAccount = new CarrierAccount()
          _.assign(carrierAccount, JSON.parse(JSON.stringify(preset)))
          this.carrierAccount = carrierAccount
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.carrierAccount = new CarrierAccount()
        this.errors = []
      },
      async updateCarrierAccount(){
        try{
          let response = await this.$repositories.carrierAccounts.update(this.carrierAccount.id, this.carrierAccount)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
        this.savingForm = false
      },

    }
  }
</script>



<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        {{ $t('Change shipping service options') }}
      </div>
    </template>
    <template v-slot:actions>
      <Button color="blue" @click="updateCarrierAccount">
        {{ $t('Update') }}
      </Button>
    </template>

    <div v-for="(carrierService, index) in carrierAccount.carrier.carrierServices" :key="index">
      <div class="relative flex items-start">
        <div class="flex items-center h-5">
          <input :id="'service_'+carrierService.id" name="carrierServiceIds[]" type="checkbox" v-model="carrierAccount.carrierServiceIds" :value="carrierService.id" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded">
        </div>
        <div class="ml-3 text-sm">
          <label :for="'service_'+carrierService.id" class="font-medium text-gray-700">{{ carrierService.name }}</label>
        </div>
      </div>
    </div>
  
  </FormModal>
</template>