import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
import InventoryMovement from '@/models/InventoryMovement'

import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'inventory_movements'
const version = 'v1'

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, InventoryMovement, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching inventory movements.'
      })
    }
  },

  async create(payload){
    try{
      return await APIService.postEntity(`${version}/${resource}`, InventoryMovement, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching pickups.'
      })

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('inventory_movement:updated', response)
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Inventory movement was updated!'
      })

      return response
    }catch(error){
      if(error instanceof ValidationError){
        throw error
      }
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update quantity. Please verify form!'
      })
      throw error
    }
  },
}
