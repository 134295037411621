import BaseModel from '@/models/BaseModel'

export default class PerformanceMetric extends BaseModel{
  constructor (json) {
    super(json)

    this.id = null
    this.name = ''
    this.key = ''
    this.value = ''
    this.targetValue = ''
    this.targetCondition = null
    this.score = 0

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let performanceMetric = new this()

    performanceMetric.id = json.id
    performanceMetric.name = json.name
    performanceMetric.key = json.key
    performanceMetric.value = json.value
    performanceMetric.targetValue = json.target_value
    performanceMetric.targetCondition = json.target_condition
    performanceMetric.score = json.score
    performanceMetric.createdAt = json.created_at
    performanceMetric.updatedAt = json.updated_at

    return performanceMetric
  }

}