import BaseModel from "@/models/BaseModel"

import Inventory from '@/models/Inventory'
import Pull from '@/models/Pull'
import Product from '@/models/Product'

export default class Pickup extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.orderLineId = null
    this.orderLine = null
    this.userId = null
    this.productId = null
    this.product = null
    this.inventoryId = null
    this.inventory = null
    this.quantity = 0
    this.pastDue = false
    this.dueToday = false
    this.pickedQuantity = 0
    this.fullName = ''
    this.dueAt = null
    this.pulls = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let pick = new this()

    pick.id = json.id
    pick.quantity = json.quantity
    pick.pickedQuantity = json.picked_quantity
    pick.fullName = json.full_name
    pick.orderLineId = json.order_line_id
    pick.userId = json.userId
    pick.productId = json.product_id
    pick.pastDue = json.past_due
    pick.dueToday = json.due_today
    pick.product = this.initRelation(json.product, Product, [])
    pick.inventoryId = json.inventory_id
    pick.inventory = this.initRelation(json.inventory, Inventory, [])
    pick.pulls = this.initRelation(json.pulls, Pull, [])
    pick.dueAt = json.due_at
    pick.createdAt = json.created_at
    pick.updatedAt = json.updated_at

    return pick
  }
}