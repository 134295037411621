import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"

import Connection from "@/models/Connection"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

const resource = 'connections'
const version = 'v1'


export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Connection, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the connection.'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Connection, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the connections!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('connection:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Connection was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      if(error instanceof ValidationError){
        throw error
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create integration. Please verify form!'
      })
      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('connection:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Connection was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update connection. Please verify form!'
      })
      if(error instanceof ValidationError){
        throw error
      }
      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`)
      eventBus.emit('connection:destroyed')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Connection was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete connection. Please try again later!'
      })
      throw error
    }
  }

}
