<script>
import { PencilIcon } from '@snsicons/icons/solid'
import Listing from '@/models/Listing'
export default{
  components: {
    PencilIcon
  },
  props: {
    listing: {
      type: Object,
      default: new Listing()
    }
  },
  data(){
    return {
      localValue: new Listing(),
      isEditing: false,
    }
  },
  mounted(){
    this.localValue = this.listing
  },
  computed: {
    strategyOptions(){
      let options = []
      
      this.$store.getters['strategies/all'].forEach((strategy) => {
        options.push({
          label: strategy.name,
          code: strategy.id,
        })
      })
      options.push({
        label: "None",
        code: "none",
      })
      return options
    },
  },
  methods: {
    async updateListing(value){
      try{
        if(value == "none"){
          this.localValue.strategyId = null
        }else{
          this.localValue.strategyId = value
        }
        this.localValue.skipValidateFieldsSchema = true
        await this.$repositories.listings.update(this.localValue.id, this.localValue)
      }catch(error) {
        console.log(error)
      }finally{
        this.isEditing = false
      }
    },
  }
}
</script>
<template>
  <div class="flex items-center">
    <Select :items="strategyOptions" :value="localValue.strategyId" @update:modelValue="updateListing($event)" labelKey="label" valueKey="code" v-if="isEditing" />
    <div class="flex space-x-1 items-center text-blue-500 hover:text-blue-700 text-sm underline decoration-dotted decoration-current decoration-1 cursor-pointer " @click="isEditing = true" v-else>
      {{ localValue.strategyId ? $store.getters['strategies/find'](localValue.strategyId).name : 'None' }}
      <PencilIcon class="w-3 h-3" />
    </div>
  </div>
</template>