import BaseModel from "@/models/BaseModel"
import Integration from "@/models/Integration"
import Platform from "@/models/Platform"

export default class Connection extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.integrationType = ''
    this.apiKey = ''
    this.apiSecret = ''
    this.status = ''
    this.integration = null
    this.platforms = []
    this.integrationId = null

    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let connection = new this()

    connection.id = json.id
    connection.name = json.name
    connection.integrationType = json.integration_type
    connection.apiKey = json.api_key
    connection.apiSecret = json.api_secret
    connection.status = json.status
    connection.integration = this.initRelation(json.integration, Integration, null)
    connection.integrationId = this.integration_id
    connection.platforms = this.initRelation(json.platforms, Platform, [])
    
    connection.createdAt = json.created_at
    connection.updatedAt = json.updated_at

    return connection
  }

  toJson(){
    return {
      connection: {
        api_key: this.apiKey,
        api_secret: this.apiSecret,
        name: this.name,
        integration_id: this.integrationId
      }
    }
  }
}