<template>
  <Listbox v-model="localValue">
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full py-2 pl-3 pr-10 text-left border border-gray-300 rounded-lg cursor-default focus:outline-none sm:text-sm"
        :class="disabled ? 'bg-gray-200' : 'bg-white'"
      >
        <span class="block truncate" v-if="isEmpty">
          {{ placeholder }} 
        </span>
        <span class="block truncate" v-else>
          <span v-if="multiple">{{ value.length }} {{ selectedCounterText }}</span>
          <span v-if="localValue">{{ getOptionLabel(localValue) }}</span>
        </span>
        
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          v-if="!disabled"
          class="absolute w-full z-1000 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div v-if="searchable" class="w-full sticky top-0 bg-white z-20">
            <input type="text"
                v-model="keyword"
                ref="searchField"
                class="p-1 border-b border-gray-300 rounded-t w-full bg-white hover:bg-gray-50 p-2 focus:outline-none"
                :placeholder="searchPlaceholder">
          </div>
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="option in options" :key="option"
            :value="option"
            as="template"
          >
            <li
              :class="[
                active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-10 pr-4',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ getOptionLabel(option) }}</span
              >
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
  <!-- <popover ref="popper" class="relative" @show="handleShow" @hide="handleHide">
    <popover-trigger :class="[triggerClass, disabled ? 'disabled' : '']">
      <div v-if="isEmpty" class="leading-6">
        <slot v-if="multiple" name="selectedItems">
          <div class="flex">
            <div class="flex-grow whitespace-nowrap overflow-hidden">
              {{ value.length }} {{ selectedCounterText }}
            </div>
            <div class="flex-shrink">
              <i class="fa fa-angle-down font-black text-base"></i>
            </div>
          </div>
        </slot>
        <slot v-else-if="localValue" name="selectedItem" v-bind:item="findOptionByValue(localValue) || localValue">
          <div class="flex">
            <div class="flex-grow">
              {{ getOptionLabel(findOptionByValue(localValue) || localValue) }}
            </div>
            <div class="flex-shrink">
              <i class="fa fa-angle-down font-black text-base"></i>
            </div>
          </div>
        </slot>
      </div>
      <div v-else>
        <slot name="placeholder">
          <div class="flex leading-6">
            <div class="flex-grow">
              {{ placeholder }}
            </div>
            <div class="flex-shrink">
              <i class="fa fa-angle-down font-black text-base"></i>
            </div>
          </div>
        </slot>
      </div>
    </popover-trigger>

    <popover-content v-if="!disabled" v-slot="{ hide }">
      <popover-list class="rounded-md shadow-xs bg-white focus:outline-none w-96 pb-2 border">
        <div v-if="searchable" class="w-full px-2 pt-2">
          <input type="text"
                 v-model="keyword"
                 ref="searchField"
                 class="p-1 border rounded w-full bg-gray-100 hover:bg-gray-200 focus:bg-white focus:outline-none"
                 :placeholder="searchPlaceholder">
        </div>
        <div class="flex mb-1 text-xs px-4 py-2 mb-1 border-b" v-if="multiple && Array.isArray(localValue)">
          <div class="flex-grow">
            {{ localValue.length }} selected
          </div>
          <div class="pl-2">
            <button :class="{ disabled: !localValue.length }" class="text-blue-500 hover:underline focus:outline-none" @click="selectNone">
              Clear all
            </button>
          </div>
        </div>
        <div v-if="loading">
          <loading-spinner></loading-spinner>
        </div>
        <div v-else class="max-h-76 overflow-y-scroll">
          <div class="w-full p-2 text-xs" v-if="multiple && bulkSelect">
            <button class="text-blue-600 mx-2 focus:outline-none" @click="selectAll">All</button>
            | <button class="text-blue-600 mx-2 focus:outline-none" @click="selectNone">None</button>
          </div>
          <div>
            <popover-list-option v-for="(option, key) in options" @optionClicked="handleOptionClick(option, hide)" :key="key" >
              <slot name="option" v-bind:option="option" >
                <div class="cursor-pointer relative group pr-4 pl-1 py-2 font-medium outline-none hover:bg-blue-100 text-sm inline-flex items-center w-full">
                <span class="w-6 text-center" v-if="isOptionSelected(option)">
                  <i  class="fa fa-check-circle text-blue-600"></i>
                </span>
                  {{ getOptionLabel(option) }}
                </div>
              </slot>
            </popover-list-option>
          </div>
        </div>
        <div v-if="manualApply" class="px-4 text-right border-t">
          <button class="text-blue-600 mx-2 focus:outline-none" @click="apply(hide, true)">Apply</button>
        </div>
      </popover-list>
    </popover-content>
  </popover> -->
</template>

<script>
import _ from 'lodash'
import { SelectorIcon, CheckIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    SelectorIcon,
    CheckIcon
  },

  props: {
    /**
     * Contains the currently selected value. Very similar to a
     * `value` attribute on an <input>. You can listen for changes
     * using 'change' event using v-on.
     */
    modelValue: {
      required: true
    },

    triggerClass: {
      type: String,
      default: 'focus:outline-none bg-gray-100 hover:bg-gray-200 border border-gray-100 py-1 px-2 rounded focus:bg-white'
    },

    /**
     * Contains the items that will be converted to select options.
     */
    items: {
      type: [Array, Function],
      required: true
    },

    /**
     * If the items prop is an array of objects, you can provide the
     * path to the object attribute that should be displayed as on the
     * dropdown.
     */
    labelKey: {
      type: String,
      required: false
    },

    /**
     * If the items prop is an array of objects, you can provide the
     * path to the object attribute that should be used as the value
     * of the dropdown option.
     */
    valueKey: {
      type: String,
      required: false
    },

    /**
     * Whether the component should be disabled or not.
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * The text that should be displayed when there is no option
     * selected.
     */
    placeholder: {
      type: String,
      default: function () {
        return 'Pick an option...'
      }
    },

    /**
     * If enabled, the component will not emit the `input` event
     * unless the `apply` button is clicked.
     */
    manualApply: {
      type: Boolean,
      default: false
    },

    /**
     * Allow the user to search through the options.
     */
    searchable: {
      type: Boolean,
      default: false
    },

    /**
     * The placeholder for the search input field.
     */
    searchPlaceholder: {
      type: String,
      default: function () {
        return 'Search...'
      }
    },

    /**
     * Allow the user to pick multiple options. If enabled, the
     * v-model attribute should be an array.
     */
    multiple: {
      type: Boolean,
      default: false
    },

    /**
     * Provide the user with the "all", "none" and "only" options.
     */
    bulkSelect: {
      type: Boolean,
      default: false
    },

    /**
     * Show a counter on the main input instead of listing the selected
     * options. Only available if the "multiple" prop is true.
     */
    showCounter: {
      type: Boolean,
      default: false
    },

    /**
     * If the "showCounter" prop is true, you can customize the text
     * displayed after the number.
     */
    selectedCounterText: {
      type: String,
      default: 'selected'
    },

    apiFilters: {
      type: Object,
      required: false
    }
  },
  emits: ['update:modelValue'],

  data() {
    return {
      localValue: null,
      response: null,
      keyword: '',
      loading: false,
      optionsCache: []
    }
  },

  watch: {
    localValue(){
      this.$emit('update:modelValue', this.localValue)
    },
    modelValue() {
      this.localValue = this.modelValue
    },

    keyword () {
      if (typeof this.items === "function") {
        this.handleKeywordChange()
      }
    },
  },

  created() {
    this.reset()
  },

  computed: {
    /**
     * Check whether the component has selected items or it's empty.
     * @returns {boolean}
     */
    isEmpty () {
      if (Array.isArray(this.localValue)) {
        return this.localValue.length == 0
      }

      return !this.localValue
    },

    rawItems () {
      if (typeof this.items === 'function') {
        if (this.response) {
          return this.response.data
        }

        return []
      }

      return this.items
    },

    /**
     * Return the dropdown options.
     */
    options () {
      if (Array.isArray(this.items)) {
        if (this.searchable) {
          const items = this.items.filter((item) => {
            return item[this.labelKey].toLowerCase().includes(this.keyword.toLowerCase())
          }).map(item => item.code)
          return items
        }
        
        return this.items.map(item => item.code)
      }

      if (typeof this.items === 'function') {
        if (this.response) {
          return this.response.data
        }
      }

      return []
    },
  },

  methods: {
    /**
     * Reset the component to the default settings.
     */
    reset () {
      if (this.modelValue) {
        this.localValue = _.clone(this.modelValue)
      } else {
        this.localValue = null
      }
      this.loading = false
      this.response = null
      this.keyword = ''
    },

    /**
     * Get the text to be displayed as a label for the given dropdown
     * option.
     * @param option
     * @returns {*}
     */
    getOptionLabel (option) {
      if (this.labelKey) {
        const label = this.items.find(item => item[this.valueKey] == option)
        if(label){
          return label[this.labelKey]
        }else{
          return '...'
        }
      }

      return option
    },

    /**
     * Get the value for the given option.
     * @param option
     * @returns {*}
     */
    getOptionValue (option) {
      if (this.valueKey) {
        return _.get(option, this.valueKey)
      }

      return option
    },

    /**
     * Find the option that has the given value.
     * @param value
     * @returns {T}
     */
    findOptionByValue (value) {
      return this.rawItems.find((option) => {
        if (typeof value === 'object' && typeof option === 'object') {
          if (value.hasOwnProperty('id') && option.hasOwnProperty('id')) {
            return value.id === option.id
          }

          return JSON.stringify(this.getOptionValue()) === JSON.stringify(value)
        }

        return this.getOptionValue(option) === value
      })
    },

    /**
     * Handle the "show" event.
     */

    handleKeywordChange: _.debounce(function () { this.fetchOptions() }, 300),

    async fetchOptions () {
      this.loading = true
      try {
        this.response = await this.items(this.keyword)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },

    /**
     * Handle the "hide" event.
     */
    handleHide () {
      this.reset()
    },

    /**
     * Handle the option click event.
     * @param option
     * @param hide
     */
    handleOptionClick (option, hide) {
      let optionValue = this.getOptionValue(option)

      if (this.multiple) {
        if (this.isOptionSelected(option)) {
          let optionIndex = this.localValue.indexOf(optionValue)
          this.localValue.splice(optionIndex, 1)
        } else {
          this.localValue.push(optionValue)
        }
      } else {
        if (this.localValue === optionValue) {
          this.localValue = null
        } else {
          this.localValue = optionValue
        }
      }

      this.$forceUpdate()

      if (!this.manualApply) {
        this.apply(hide)
      }
    },

    /**
     * Select all of the dropdown options.
     */
    selectAll() {
      this.localValue = this.options.map((option) => {
        return this.getOptionValue(option)
      })

      if (!this.manualApply) {
        this.apply()
      }
    },

    /**
     * Only select the given option.
     * @param option
     */
    selectOnly(option) {
      this.localValue = [this.getOptionValue(option)]

      if (!this.manualApply) {
        this.apply()
      }
    },

    /**
     * Deselect all of the options.
     */
    selectNone() {
      this.localValue = []

      if (!this.manualApply) {
        this.apply()
      }
    },

    /**
     * Check whether an option is selected or not.
     * @param option
     * @returns {*}
     */
    isOptionSelected(option) {
      if (this.localValue) {
        if (this.multiple) {
          if (typeof this.localValue === 'object' && typeof option === 'object' && this.localValue.hasOwnProperty('id') && option.hasOwnProperty('id')) {
            return this.localValue.id === option.id
          }

          return this.localValue.includes(
              this.getOptionValue(option)
          )
        }

        if (typeof this.localValue === 'object' && typeof option === 'object' && this.localValue.hasOwnProperty('id') && option.hasOwnProperty('id')) {
          return this.localValue.id === option.id
        }

        return this.localValue === this.getOptionValue(option)
      }

      return false
    },

    /**
     * Apply the selected values to the property that's tied via
     * v-model.
     * @param hide
     * @param forceHide
     */
    apply(forceHide = false) {
      console.log(this.localValue)
      this.$emit('update:modelValue', this.localValue.code)

      // if (!this.multiple || forceHide) {
      //   hide()
      // }
    },
  }
}
</script>