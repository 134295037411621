import BaseModel from "@/models/BaseModel"
import User from '@/models/User'
export default class Warehouse extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.name = ''
    this.street = ''
    this.street2 = ''
    this.city = ''
    this.state = ''
    this.zipCode = ''
    this.country = 'US'
    this.email = ''
    this.fullAddress = ''
    this.phoneNumber = ''
    this.aislesCount = 0
    this.shelvesCount = 0
    this.inventorySum = 0
    this.users = []
    this.timezone = null
    this.operatingHours = {}
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let warehouse = new this()

    warehouse.id = json.id
    warehouse.name = json.nickname
    warehouse.street = json.street
    warehouse.street2 = json.street2
    warehouse.city = json.city
    warehouse.state = json.state
    warehouse.zipCode = json.zip_code
    warehouse.fullAddress = json.full_address
    warehouse.phoneNumber = json.phone_number
    warehouse.email = json.email
    warehouse.aislesCount = json.aisles_count
    warehouse.shelvesCount = json.shelves_count
    warehouse.inventorySum = json.inventory_sum
    warehouse.users = this.initRelation(json.users, User, [])
    warehouse.country = json.country
    warehouse.timezone = json.timezone
    warehouse.operatingHours = json.operating_hours
    warehouse.createdAt = json.created_at
    warehouse.updatedAt = json.updated_at

    return warehouse
  }

  toJson(){
    return {
      nickname: this.name,
      street: this.street,
      street2: this.street2,
      city: this.city,
      state: this.state,
      zip_code: this.zipCode,
      country: this.country,
      email: this.email,
      phone_number: this.phoneNumber,
      timezone: this.timezone,
      operating_hours: this.operatingHours
    }
  }
}