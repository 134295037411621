<script>
  import GeneratedReportNotification from './Types/GeneratedReportNotification'
  import MentionNotification from './Types/MentionNotification'
  import FinishedImportNotification from './Types/FinishedImportNotification'
  import AssignedReviewNotification from './Types/AssignedReviewNotification'
  import FinishedReviewNotification from './Types/FinishedReviewNotification'
  import { BellIcon, ChevronDownIcon } from '@snsicons/icons/solid'
  import Notification from '@/models/Notification'
  export default {
    components: {
      GeneratedReportNotification,
      FinishedImportNotification,
      MentionNotification,
      FinishedReviewNotification,
      AssignedReviewNotification,
      BellIcon,
      ChevronDownIcon
    },
    props: {
      notification: {
        type: Object,
        default: new Notification()
      }
    },
    data(){
      return {
        note: new Notification(),
      }
    },
    mounted(){
      this.note = this.notification
    },
    methods: {
      async markAsRead() {
        if(this.note.readAt){
          return
        }

        try {
          let response = await this.$repositories.notifications.update(this.note.id, this.note)
          this.note = response.data
          
          this.$store.dispatch('notifications/syncCounter')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

<template>
  <Disclosure v-slot="{ open }">
    <div class="block hover:bg-gray-50">
      <DisclosureButton as="div" class="cursor-pointer flex items-center px-4 py-4 sm:px-6" @click="markAsRead">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0 items-start">
            <div class="p-3 rounded-full bg-gray-500 text-white">
              <BellIcon class="w-5 h-5" />
            </div>
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            
            <component :is="$config.getNotificationComponentFor(note.notificationType)"
              :notification="note"
              :open="open"
              :class="note.readAt > 0 ? 'opacity-50 hover:opacity-70' : 'text-bold'"
              ></component>
            <div class="flex items-center">
              <p class="text-sm text-gray-500">
                {{ $filters.date.timeAgo(note.createdAt) }} ago
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="flex space-x-1 text-sm">
            <ChevronDownIcon :class="open ? 'transform rotate-180' : ''" class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <p class="text-gray-400">{{ open ? $t('Show Less') : $t('Show More') }}</p>
          </div>
        </div>
      </DisclosureButton>
    </div>
  </Disclosure>
</template>

