import { createApp } from 'vue'
import App from '@/layouts/blank.vue'
import createRouter from './router'
import store from './store'
import enums from './enums'
import { eventBus } from '@/composables/eventBus.js'
import debounce from '@/directives/debouncer'
import moment from 'moment-timezone'
import { Can, abilitiesPlugin } from '@casl/vue';
import ability from './abilities'
import RepositoryFactory from '@/repositories/RepositoryFactory.js'
import { registerComponents } from '@/components'
import ActionCableVue from "actioncable-vue"
import TokenStorageService from '@/services/TokenStorageService'

import './styles/tailwind.css'
import 'floating-vue/dist/style.css'
import config from '@/configs'

import FloatingVue from 'floating-vue'
import Maska from 'maska'
import mitt from 'mitt'
import i18n from './i18n'
import '@vuepic/vue-datepicker/dist/main.css'
import VueApexCharts from "vue3-apexcharts";


const actionCableVueOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: `wss://${process.env.VUE_APP_WSS_URL}/cable?token=${TokenStorageService.getToken()}`,
  connectImmediately: true,
};
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const app = createApp(App)

const routerx = createRouter(store)
app.use(abilitiesPlugin, ability, { useGlobalProperties: true })
app.component(Can.name, Can)
app.use(FloatingVue)
app.use(Maska)
app.component('Loading', Loading)
app.directive('debounce', (el,binding) => debounce(el,binding))
app.config.globalProperties.$enums = enums
app.config.globalProperties.$config = config
app.config.globalProperties.$eventBus = eventBus
app.config.globalProperties.$repositories = RepositoryFactory
app.config.globalProperties.moment = moment
app.provide('$repositories', RepositoryFactory)
app.provide('eventBus', eventBus)
app.provide('$enums', enums)

app.use(VueApexCharts);

app.use(ActionCableVue, actionCableVueOptions)

app.config.globalProperties.$filters = {
  date: {
    dateFormat(value) {
      if (Number.isInteger(value)) {
        return moment.unix(value).tz(store.state.auth.user.timezone).format("MMM DD, YYYY")
      }else{
        return moment(value).tz(store.state.auth.user.timezone).format("MMM DD, YYYY")
      }
    },
    dateTimeFormat(value) {
      if (Number.isInteger(value)) {
        return moment.unix(value).tz(store.state.auth.user.timezone).format("ddd, MMM DD YYYY, h:mm A")
      }else{
        return moment(value).tz(store.state.auth.user.timezone).format("ddd, MMM DD YYYY, h:mm A")
      }
    },
    timeFormat(value) {
      if (Number.isInteger(value)) {
        return moment.unix(value).tz(store.state.auth.user.timezone).format("h:mm A")
      }else{
        return moment(value).tz(store.state.auth.user.timezone).format("h:mm A")
      }
    },
    dateTimeFormatWithTimeHint(value) {
      if (Number.isInteger(value)) {
        return moment.unix(value).tz(store.state.auth.user.timezone).format("ddd, MMM DD YYYY, h:mm A")
      }else{
        return moment(value).tz(store.state.auth.user.timezone).format("ddd, MMM DD YYYY, h:mm A")
      }
    },
    timeAgo(value) {
      if (Number.isInteger(value)) {
        return moment.unix(value).tz(store.state.auth.user.timezone).fromNow(true);
      }else{
        return moment(value).tz(store.state.auth.user.timezone).fromNow(true);
      }
    },
  },
  currency:{
    format(value, currency){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency.isoCode || "USD",
      })

      return formatter.format(value)
    }
  },
  string: {
    titleCase(text){
      return text.toLowerCase().split(' ').map((word) => {
        return word.replace(word[0], word[0].toUpperCase())
      }).join(' ')
    }
  }
}

app.provide('$filters', app.config.globalProperties.$filters)

registerComponents(app)

app.use(store).use(i18n).use(routerx).mount('#app')
