import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'aisles'
const version = 'v1'
import Aisle from "@/models/Aisle"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`${version}/${resource}`, Aisle, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the aisles'
      })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`${version}/${resource}/${id}`, Aisle, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the aisles!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`${version}/${resource}`, payload)
      eventBus.emit('aisle:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Aisle was created!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }

      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create aisle. Please verify form!'
      })

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`${version}/${resource}/${id}`, payload)
      eventBus.emit('aisle:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Aisle was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update aisle. Please verify form!'
      })

      throw error
    }
  },

  async delete(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}`)
      eventBus.emit('aisle:deleted')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Aisle was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete aisle. Please try again later!'
      })

      throw error
    }
  },

  async wipe(id){
    try{
      let response = await APIService.delete(`${version}/${resource}/${id}/wipe`)
      eventBus.emit('aisle:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Aisle inventory wipe procedure started!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not wipe aisle inventory. Please try again later!'
      })

      throw error
    }
  },

  async print(id, payload){
    try{
      return await APIService.post(`${version}/${resource}/${id}/print`, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when generating labels!'
      })
      throw error
    }
  },

  

}
