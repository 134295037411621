<template>
  <div v-if="hasError" class="text-red-500 p-1 rounded bg-red-100 text-xs mb-1">
    {{ error }}
  </div>
</template>

<script>
  export default {
    props: {
      errors: {
        type: [Array, Object],
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },
    computed: {
      hasError(){
        if(this.errors[this.name] == null){
          return false
        }
        return true
      },
      error(){
        if(this.errors[this.name] != null && Array.isArray(this.errors[this.name])){
          return this.errors[this.name][0]
        }
        return this.errors[this.name]
      }
    }
  }
</script>