<script setup>
import InventoryMovement from '@/models/InventoryMovement'
import InventoryMovementRow from '@/components/Scanning/InventoryMovementRow.vue'
import { computed, defineProps, onMounted, inject, ref , onUpdated} from 'vue'
import _ from 'lodash'

const props = defineProps({
  movements: { 
    type: Array,
    default: () => []
  }
})
const eventBus = inject('eventBus')
let movements = ref([])

onUpdated(() => {
  movements.value = props.movements
})

const totalInventoryScanned = computed(() => {
  return _.sumBy(movements.value, function(inv) {
    return parseInt(inv.quantity) 
  })
})

</script>
<template>
  <ul role="list" class="divide-y divide-gray-200">
    <li class="flex py-4">
      <div class="w-full flex items-center justify-between">
        <div class="flex space-x-4">
          <slot name="actions"></slot>
        </div>
        <div class="flex items-center space-x-4">
          <p class="text-gray-500 font-semibold">
            <slot name="totalHeader"></slot>
          </p>
          <div class="rounded-lg bg-gray-100 h-12 w-12 flex items-center text-sm justify-center text-gray-800 font-semibold">
            {{ totalInventoryScanned }}
          </div>
        </div>
      </div>
    </li>
    <InventoryMovementRow :movement="movement" :index="(movements.length-index)" v-for="(movement, index) in movements" :key="movement.id" />
  </ul>
</template>