const getters = {
  all: (state) => {
    return state.categories
  },
  find: (state) => (id) => {
    return state.categories.find((category) => category.id == id)
  },
  findByName: (state) => (name) => {
    return state.categories.find((category) => category.name == name)
  }
}

export default getters