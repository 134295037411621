import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args))),
    "aria-current": "page",
    class: _normalizeClass(['relative inline-flex items-center px-4 py-2 border text-sm font-medium', _ctx.isActive ? 'border-blue-500 bg-blue-500 hover:bg-blue-700 text-white' : 'border-gray-300 text-gray-500 hover:bg-gray-50 bg-white'])
  }, [
    (_ctx.page === null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, ". . ."))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.page), 1))
  ], 2))
}