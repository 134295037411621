<script>
  import { XmarkIcon } from '@snsicons/icons/regular'
  import User from "@/models/User";
  import _ from 'lodash'

  export default {
    components: {
      XmarkIcon
    },
    data(){
      return {
        opened: false,
        savingForm: false,
        user: new User(),
        errors: {},
        timezones: [],
      }
    },
    computed: {
      isEditing(){
        return this.user.id != null
      },
      timezoneOptions(){
        let options = []
        this.timezones.forEach((timezone) => {
          options.push({
            label: timezone.name,
            code: timezone.id,
          })
        })

        return options
      },
    },
    methods: {
      open(preset = {}) {
        try{
          this.fetchTimezones()
          let user = new User()
          _.assign(user, JSON.parse(JSON.stringify(preset)))
          this.user = user
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },

      close() {
        this.reset()
        this.opened = false
      },

      reset() {
        this.user = new User()
        this.errors = {}
      },

      async saveUser(){
        if(this.isEditing){
          await this.updateUser()
        }else{
          await this.storeUser()
        }
      },

      async updateUser(){
        try{
          console.log(this.user)
          await this.$repositories.users.update(this.user.id, this.user)
          this.close()
        }catch(error) {
          console.log(error)
          this.errors = error.errors
        }
      },

      async storeUser(){
        try{
          await this.$repositories.users.create(this.user)
          this.close()
        }catch(error) {
          this.errors = error.errors
        }
      },

      async fetchTimezones(){
        try{
          let response = await this.$repositories.timezones.get()
          this.timezones = response.data
        }catch(_){}
      },

    }
  }
</script>

<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-1000" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-blue-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        {{ isEditing ? $t('Update user details') : $t('Create new user') }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="rounded-md text-white hover:text-white" @click="close()">
                          <span class="sr-only">Close panel</span>
                          <XmarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        Add or modify the user by filling out the form below.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <Label name="firstname" required :errors="errors">{{ $t('Firstname') }}</Label>
                          <InputText v-model="user.firstname" name="firstname" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="firstname" />
                        </div>
                        <div>
                          <Label name="lastname" required :errors="errors">{{ $t('Lastname') }}</Label>
                          <InputText v-model="user.lastname" name="lastname" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="lastname" />
                        </div>
                        <div>
                          <Label name="username" required :errors="errors">{{ $t('Username') }}</Label>
                          <InputText v-model="user.username" name="username" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="username" />
                        </div>
                        <div>
                          <Label name="email" required :errors="errors">{{ $t('E-mail') }}</Label>
                          <InputText v-model="user.email" name="email" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="email" />
                        </div>
                        <div v-if="!isEditing">
                          <Label name="password" required :errors="errors">{{ $t('Password') }}</Label>
                          <InputText v-model="user.password" name="password" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="password" />
                        </div>
                        <div v-if="!isEditing">
                          <Label name="password_confirmation" required :errors="errors">{{ $t('Password Confirmation') }}</Label>
                          <InputText v-model="user.passwordConfirmation" name="password_confirmation" class="w-full" :errors="errors"/>
                          <FormValidation :errors="errors" name="password_confirmation" />
                        </div>
                        <div>
                          <Label name="timezone" :errors="errors" required>{{ $t('Timezone') }}</Label>
                          <Select :items="timezoneOptions" v-model="user.timezone" labelKey="label" valueKey="code" :searchable="true" class="w-full"/>
                          <FormValidation :errors="errors" name="timezone" />
                        </div>
                        <fieldset>
                          <legend class="text-sm font-medium text-gray-900">
                            {{ $t('Role') }}
                          </legend>
                          <div class="mt-2 space-y-5">
                            <div class="relative flex items-start" v-for="role in $enums.roles" :key="role.name">
                              <div class="absolute flex items-center h-5">
                                <input :id="role.name" name="role" :value="role.name" v-model="user.role" type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300" :checked="user.role == role.name" />
                              </div>
                              <div class="pl-7 text-sm">
                                <label :for="role.name" class="font-medium text-gray-900">
                                  {{ role.display_name }}
                                </label>
                                <p id="privacy-public-description" class="text-gray-500">
                                  {{ role.description }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2">
                  <Button color="white" @click="close">
                    {{ $t('Close') }}
                  </Button>
                  <Button color="blue" @click="saveUser">
                    {{ $t('Save') }}
                  </Button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>