import { App } from 'vue'

import {
  Dialog,
  DialogTitle,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  TabGroup, TabList, Tab, TabPanels, TabPanel,
  Disclosure, DisclosureButton, DisclosurePanel,
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Switch,
} from '@headlessui/vue'

import ProductPreviewImage from '@/components/Product/ProductPreviewImage.vue'
import Avatar from '@/components/UI/Avatar.vue'
import NewFeatureLabel from '@/components/UI/NewFeatureLabel.vue'
import BackButton from '@/components/UI/BackButton.vue'
import Badge from '@/components/Tailwind/Badge.vue'
import Card from '@/components/Tailwind/Card.vue'
import CardBody from '@/components/Tailwind/CardBody.vue'
import CardFooter from '@/components/Tailwind/CardFooter.vue'
import CardHeader from '@/components/Tailwind/CardHeader.vue'
import Chart from '@/components/Chart/Chart.vue'
import ChartCard from '@/components/UI/ChartCard.vue'
import CommentForm from '@/components/Comment/CommentForm.vue'
import CommentList from '@/components/Comment/CommentList.vue'
import CopyButton from '@/components/UI/CopyButton.vue'
import CsvExportButton from '@/components/UI/CsvExport.vue'
import Datepicker from '@vuepic/vue-datepicker'
import DateTimeLabel from '@/components/UI/DateTimeLabel.vue'
import DeleteConfirmationModal from '@/components/UI/DeleteConfirmationModal.vue'
import ExportButton from '@/components/UI/ExportButton.vue'
import FilterButton from '@/components/UI/FilterButton.vue'
import FiltersBar from '@/components/UI/FiltersBar.vue'
import FormModal from '@/components/Tailwind/FormModal.vue'
import FormValidation from '@/components/Tailwind/FormValidation.vue'
import ImageUpload from '@/components/UI/ImageUpload.vue'
import Input from '@/components/Tailwind/Input.vue'
import InventoryMovementOperationBadge from '@/components/InventoryMovement/InventoryMovementOperationBadge.vue'
import Label from '@/components/Tailwind/Label.vue'
import ListingFulfillmentBadge from '@/components/Listing/ListingFulfillmentBadge.vue'
import ListingStatusBadge from '@/components/Listing/ListingStatusBadge.vue'
import ListingStatusNotificationBar from '@/components/Listing/ListingStatusNotificationBar.vue'
import ListingSuppressedBadge from '@/components/Listing/ListingSuppressedBadge.vue'
import LoadingOverlay from '@/components/UI/LoadingOverlay.vue'
import OrderBadge from '@/components/Order/OrderBadge.vue'
import OrderCard from '@/components/Order/OrderCard.vue'
import OrderLine from '@/components/Order/OrderLine.vue'
import OrderStatusAlert from '@/components/Order/OrderStatusAlert.vue'
import Pagination from '@/components/Tailwind/Pagination/Pagination.vue'
import ProgressButton from '@/components/Tailwind/ProgressButton.vue'
import ProgressCircle from '@/components/UI/ProgressCircle.vue'
import ReviewFormModal from '@/components/Review/ReviewFormModal.vue'
import ReviewLine from '@/components/Review/ReviewLine.vue'
import ShareLink from '@/components/UI/ShareLink.vue'
import SlideOver from '@/components/Tailwind/SlideOver.vue'
import Snackbar from '@/components/Tailwind/Snackbar.vue'
import TButton from '@/components/Tailwind/Button.vue'
import Textarea from '@/components/Tailwind/Textarea.vue'
import Toggle from '@/components/Tailwind/Toggle.vue'
import TSelect from '@/components/Tailwind/Select.vue'
import TTable from '@/components/Tailwind/Table.vue'
import UserAvatar from '@/components/User/UserAvatar.vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import Step from '@/components/Tailwind/Steps/Steps.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'

export const registerComponents = (app: App): void => {
  app.component('Avatar', Avatar)
  app.component('BackButton', BackButton)
  app.component('Badge', Badge)
  app.component('Button', TButton)
  app.component('Card', Card)
  app.component('CardBody', CardBody)
  app.component('CardFooter', CardFooter)
  app.component('CardHeader', CardHeader)
  app.component('Chart', Chart)
  app.component('ChartCard', ChartCard)
  app.component('CommentForm', CommentForm)
  app.component('CommentList', CommentList)
  app.component('CopyButton', CopyButton)
  app.component('Datepicker', Datepicker)
  app.component('DateTimeLabel', DateTimeLabel)
  app.component('DeleteConfirmationModal', DeleteConfirmationModal)
  app.component('Dialog', Dialog)
  app.component('DialogOverlay', DialogOverlay)
  app.component('DialogTitle', DialogTitle)
  app.component('Disclosure', Disclosure)
  app.component('DisclosureButton', DisclosureButton)
  app.component('DisclosurePanel', DisclosurePanel)
  app.component('ExportButton', ExportButton)
  app.component('FilterButton', FilterButton)
  app.component('FiltersBar', FiltersBar)
  app.component('FormModal', FormModal)
  app.component('FormValidation', FormValidation)
  app.component('ImageUpload', ImageUpload)
  app.component('Input', Input)
  app.component('InputText', Input)
  app.component('InventoryMovementOperationBadge', InventoryMovementOperationBadge)
  app.component('Label', Label)
  app.component('Listbox', Listbox)
  app.component('ListboxButton', ListboxButton)
  app.component('ListboxLabel', ListboxLabel)
  app.component('ListboxOption', ListboxOption)
  app.component('ListboxOptions', ListboxOptions)
  app.component('ListingFulfillmentBadge', ListingFulfillmentBadge)
  app.component('ListingStatusBadge', ListingStatusBadge)
  app.component('ListingStatusNotificationBar', ListingStatusNotificationBar)
  app.component('LoadingOverlay', LoadingOverlay)
  app.component('Menu', Menu)
  app.component('MenuButton', MenuButton)
  app.component('MenuItem', MenuItem)
  app.component('MenuItems', MenuItems)
  app.component('OrderBadge', OrderBadge)
  app.component('OrderCard', OrderCard)
  app.component('OrderLine', OrderLine)
  app.component('OrderStatusAlert', OrderStatusAlert)
  app.component('Pagination', Pagination)
  app.component('progress-button', ProgressButton)
  app.component('ProgressCircle', ProgressCircle)
  app.component('ReviewFormModal', ReviewFormModal)
  app.component('ReviewLine', ReviewLine)
  app.component('Select', TSelect)
  app.component('ShareLink', ShareLink)
  app.component('SlideOver', SlideOver)
  app.component('Snackbar', Snackbar)
  app.component('Switch', Switch)
  app.component('Tab', Tab)
  app.component('TabGroup', TabGroup)
  app.component('TabList', TabList)
  app.component('TabPanel', TabPanel)
  app.component('TabPanels', TabPanels)
  app.component('text-input', Input)
  app.component('Textarea', Textarea)
  app.component('Toggle', Toggle)
  app.component('TransitionChild', TransitionChild)
  app.component('TransitionRoot', TransitionRoot)
  app.component('TTable', TTable)
  app.component('UserAvatar', UserAvatar)
  app.component(VueCountdown.name, VueCountdown)
  app.component('ListingSuppressedBadge', ListingSuppressedBadge)
  app.component('Step', Step)
  app.component('ProductPreviewImage', ProductPreviewImage)
  app.component('NewFeatureLabel', NewFeatureLabel)
  app.component('PageHeader', PageHeader)
}