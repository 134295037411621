import BaseModel from "@/models/BaseModel"
import Currency from "@/models/Currency";

export default class Product extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.sku = ''
    this.barcode = ''
    this.brandId = null
    this.categoryId = null
    this.fileResourceId = null
    this.lowStockAlert = 0
    this.weightValue = 0
    this.weightUnit = 'lb'
    this.imageData = null
    this.lengthValue = 0
    this.widthValue = 0
    this.heightValue = 0
    this.notes = ''
    this.name = ''
    this.size = ''
    this.color = ''
    this.cost = 0
    this.price = 0
    this.priceReference = 0
    this.currency = 'USD'
    this.varies = false
    this.quantitySum = 0
    this.lockedQuantity = 0
    this.msrp = 0
    this.velocity30 = 0
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let product = new this()

    product.id = json.id
    product.name = json.name
    product.sku = json.sku
    product.barcode = json.barcode
    product.quantitySum = json.quantity_sum
    product.lockedQuantity = json.locked_quantity
    product.lowStockAlert = json.low_stock_alert
    product.weightValue = parseFloat(json.weight_value)
    product.fileResourceId = json.file_resource_id
    product.weightUnit = json.weight_unit
    product.lengthValue = json.length_value
    product.widthValue = json.width_value
    product.heightValue = json.height_value
    product.notes = json.notes
    product.size = json.size
    product.color = json.color
    product.cost = json.cost
    product.msrp = json.msrp
    product.currency = Currency.fromJson(json.currency)
    product.brandId = json.brand_id
    product.categoryId = json.category_id
    product.velocity30 = json.velocity_30
    product.priceReference = json.reference_price

    product.createdAt = json.created_at
    product.updatedAt = json.updated_at

    return product
  }

  toJson(){
    return {
      product: {
        name: this.name,
        parent_id: this.parentId,
        sku: this.sku,
        barcode: this.barcode,
        brand_id: this.brandId,
        department_id: this.categoryId,
        low_stock_alert: this.lowStockAlert,
        weight_value: this.weightValue,
        weight_unit: this.weightUnit,
        length_value: this.lengthValue,
        height_value: this.heightValue,
        width_value: this.widthValue,
        file_resource_id: this.fileResourceId,
        reference_price: this.priceReference,
        size_unit: 'in',
        size: this.size,
        color: this.color,
        cost: this.cost,
        msrp: this.msrp,
      }
    }
  }
}