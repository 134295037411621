import AislesRepository from './AislesRepository'
import AuthRepository from './AuthRepository'
import BillingProfileRepository from '@/repositories/BillingProfileRepository'
import BrandsRepository from './BrandsRepository'
import CarrierAccountsRepository from "./CarrierAccountsRepository"
import CarriersRepository from "./CarriersRepository"
import ChartsRepository from './ChartsRepository'
import CommentsRepository from './CommentsRepository'
import CountriesRepository from './CountriesRepository'
import DepartmentsRepository from './DepartmentsRepository'
import FileResourcesRepository from '@/repositories/FileResourcesRepository'
import InventoriesRepository from './InventoriesRepository'
import InventoryMovementsRepository from './InventoryMovementsRepository'
import ListingsRepository from './ListingsRepository'
import ListingGalleriesRepository from "@/repositories/ListingGalleriesRepository"
import NotificationsRepository from '@/repositories/NotificationsRepository'
import OrderCancellationReasonsRepository from "@/repositories/OrderCancellationReasonsRepository"
import OrderLineCancellationsRepository from "@/repositories/OrderLineCancellationsRepository"
import OrderLinesRepository from "@/repositories/OrderLinesRepository"
import OrdersRepository from "@/repositories/OrdersRepository"
import PackageTypesRepository from "@/repositories/PackageTypesRepository"
import PickupsRepository from "@/repositories/PickupsRepository"
import PlatformCategoriesRepository from "@/repositories/PlatformCategoriesRepository"
import ProcessingTablesRepository from "@/repositories/ProcessingTablesRepository"
import ProductsRepository from './ProductsRepository'
import PullsRepository from "@/repositories/PullsRepository"
import RatesRepository from "@/repositories/RatesRepository"
import RequestReportsRepository from '@/repositories/RequestReportsRepository'
import ReturnOrdersRepository from '@/repositories/ReturnOrdersRepository'
import ReviewsRepository from '@/repositories/ReviewsRepository'
import RolesRepository from '@/repositories/RolesRepository'
import SalesChannelsRepository from "./SalesChannelsRepository"
import ScanningsRepository from './ScanningsRepository'
import ShelvesRepository from './ShelvesRepository'
import StatesRepository from './StatesRepository'
import StrategiesRepository from '@/repositories/StrategiesRepository'
import TimezonesRepository from '@/repositories/TimezonesRepository'
import UploadsRepository from "@/repositories/UploadsRepository"
import UsersRepository from './UsersRepository'
import WarehousesRepository from './WarehousesRepository'
import ReportsRepository from '@/repositories/ReportsRepository'
import PlatformsRepository from "./PlatformsRepository"
import ScheduledReportsRepository from '@/repositories/ScheduledReportsRepository'
import SuppliersRepository from '@/repositories/SuppliersRepository'
import IntegrationsRepository from '@/repositories/IntegrationsRepository'
import ConnectionsRepository from '@/repositories/ConnectionsRepository'

// import AislesRepository from './AislesRepository'

// import ChartsRepository from './ChartsRepository'
// import CompaniesRepository from './CompaniesRepository'
// 

// import FeedbacksRepository from './FeedbacksRepository'

// 


// import ScanningsRepository from './ScanningsRepository'
// import ShelvesRepository from './ShelvesRepository'
// 
// 
// 

// 
// 
// 

// import OrderCancellationReasonsRepository from "@/repositories/OrderCancellationReasonsRepository"
// import OrderLineCancellationsRepository from "@/repositories/OrderLineCancellationsRepository"
// import PickupsRepository from "@/repositories/PickupsRepository"
// import ProcessingTablesRepository from "@/repositories/ProcessingTablesRepository"
// import PackageTypesRepository from "@/repositories/PackageTypesRepository"
// import RatesRepository from "@/repositories/RatesRepository";
// import ShipmentsRepository from "@/repositories/ShipmentsRepository";
// 
// 
// import UploadsRepository from "@/repositories/UploadsRepository"
// import ProductConditionsRepository from '@/repositories/ProductConditionsRepository'
// import NotesRepository from '@/repositories/NotesRepository'
// 
// 
// 
// import BulkOperationsRepository from '@/repositories/BulkOperationsRepository'
// import BillingProfileRepository from '@/repositories/BillingProfileRepository'

const repositories = {
  aisles: AislesRepository,
  auth: AuthRepository,
  billingProfile: BillingProfileRepository,
  brands: BrandsRepository,
  carrierAccounts: CarrierAccountsRepository,
  connections: ConnectionsRepository,
  carriers: CarriersRepository,
  charts: ChartsRepository,
  comments: CommentsRepository,
  countries: CountriesRepository,
  departments: DepartmentsRepository,
  fileResources: FileResourcesRepository,
  inventories: InventoriesRepository,
  inventoryMovements: InventoryMovementsRepository,
  listings: ListingsRepository,
  listingGalleries: ListingGalleriesRepository,
  notifications: NotificationsRepository,
  orderCancellationReasons: OrderCancellationReasonsRepository,
  orderLineCancellations: OrderLineCancellationsRepository,
  orderLines: OrderLinesRepository,
  orders: OrdersRepository,
  packageTypes: PackageTypesRepository,
  pickups: PickupsRepository,
  platformCategories: PlatformCategoriesRepository,
  processingTables: ProcessingTablesRepository,
  products: ProductsRepository,
  pulls: PullsRepository,
  rates: RatesRepository,
  requestReports: RequestReportsRepository,
  returnOrders: ReturnOrdersRepository,
  reviews: ReviewsRepository,
  roles: RolesRepository,
  salesChannels: SalesChannelsRepository,
  scannings: ScanningsRepository,
  shelves:ShelvesRepository,
  states: StatesRepository,
  strategies: StrategiesRepository,
  timezones: TimezonesRepository,
  uploads: UploadsRepository,
  users: UsersRepository,
  warehouses: WarehousesRepository,
  reports: ReportsRepository,
  platforms: PlatformsRepository,
  scheduledReports: ScheduledReportsRepository,
  suppliers: SuppliersRepository,
  integrations: IntegrationsRepository
  
  // charts: ChartsRepository,
  // products: ProductsRepository,
  // warehouses: WarehousesRepository,

  
  // aisles: AislesRepository,
  // companies: CompaniesRepository,
  // users: UsersRepository,
  // shelves: ShelvesRepository,
  // scannings: ScanningsRepository,
  // 
  // 
  // salesChannels: SalesChannelsRepository,
  // feedbacks: FeedbacksRepository,

  // platformCategories: PlatformCategoriesRepository,
  // listings: ListingsRepository,
  // listingGalleries: ListingGalleriesRepository,
  
  // orderCancellationReasons: OrderCancellationReasonsRepository,
  // orderLineCancellations: OrderLineCancellationsRepository,
  // pickups: PickupsRepository,
  // processingTables: ProcessingTablesRepository,
  // packageTypes: PackageTypesRepository,
  // rates: RatesRepository,
  // shipments: ShipmentsRepository,
  // 
  // uploads: UploadsRepository,
  // productConditions: ProductConditionsRepository,
  // strategies: StrategiesRepository,
  // notes: NotesRepository,
  // 
  // requestReports: RequestReportsRepository,
  // notifications: NotificationsRepository,
  // bulkOperations: BulkOperationsRepository,
  // 
}

const target = {};

export default new Proxy(target, {
  get(target, name){
    return repositories[name]
  }
})
