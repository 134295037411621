<script>
import RevenueWidget from "@/components/Widgets/RevenueWidget.vue"
import { MagnifyingGlassIcon } from '@snsicons/icons/solid'
import SalesValueByBrandPieWidget from "@/components/Widgets/SalesValueByBrandPieWidget.vue";
import SalesValueByCategoryPieWidget from "../../components/Widgets/SalesValueByCategoryPieWidget.vue";
import _ from 'lodash'

export default {
    data() {
      return {
        loading: true,
        warehouses: [],
        filters: {
            warehouse: "",
            category: "",
            brand: "",
            sales_channel: "",
            date: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
        },
        salesMeta: {
          sums: {}
        },
        inventoryCount: 0,
        productsCount: 0,
        shipmentsCount: 0,
        chartTypes: [
          {
            title: 'Sales Volume',
            key: 'sales_volume',
          },
          {
            title: 'Gross Sales',
            key: 'gross_sales',
          },
          {
            title: 'Gross Profit',
            key: 'gross_profit',
          },
          {
            title: 'Gross Profit Margin (%)',
            key: 'gross_profit_margin',
          }
        ],
      };
    },
    watch: {
        filters: {
            handler(newValue) {
                this.fetchInventories();
                this.fetchProducts();
                this.fetchShipments();
                this.fetchSalesData()
            },
            deep: true,
        },
    },
    computed: {
      filterOptions(){
        const options = [
          {
            name: 'brand',
            display: 'Brand',
            options: this.brandOptions,
            type: 'single',
            verb: 'is',
            group: 'Details',
          },
          {
            name: 'category',
            display: 'Category',
            options: this.categoryOptions,
            type: 'single',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'sales_channel',
            display: 'Sales Channel',
            options: this.salesChannelOptions,
            type: 'single',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'warehouse',
            display: 'Warehouse',
            options: this.warehouseOptions,
            type: 'single',
            verb: 'is',
            group: 'Location'
          },
          {
            name: 'term',
            display: 'Search Term',
            type: 'hidden',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'date',
            display: 'Date',
            type: 'alwaysHidden',
            verb: 'is',
            group: 'Details'
          },
        ]
        return options
      },
        warehouseOptions() {
            let options = [];
            this.warehouses.forEach((warehouse) => {
                options.push({
                    label: warehouse.name,
                    code: warehouse.id,
                });
            });
            return options;
        },
        brandOptions() {
            let options = [];
            this.$store.getters["brands/all"].forEach((brand) => {
                options.push({
                    label: brand.name,
                    code: brand.id,
                });
            });
            return options;
        },
        categoryOptions() {
            let options = [];
            this.$store.getters["categories/all"].forEach((category) => {
                options.push({
                    label: category.name,
                    code: category.id,
                });
            });
            return options;
        },
        salesChannelOptions(){
          let options = []
          this.$store.getters["sales_channels/all"].forEach((salesChannel) => {
            options.push({
              label: salesChannel.name,
              code: salesChannel.id,
            })
          })
          return options
        },
    },
    mounted() {
        this.fetchWarehouses();
        this.fetchInventories();
        this.fetchProducts();
        this.fetchShipments();
        this.fetchSalesData()
        this.$store.dispatch("brands/fetch")
        this.$store.dispatch("categories/fetch")
        this.$store.dispatch("sales_channels/fetch")
    },
    methods: {
        async fetchSalesChannels() {
            this.salesChannels = [];
            try {
                let response = await this.$repositories.salesChannels.get({ per: 10000 });
                this.salesChannels = response.data;
                this.setTableHeaders();
            }
            catch (error) {
            }
        },
        async fetchProducts() {
            this.loading = true;
            this.products = [];
            try {
                let response = await this.$repositories.products.get({ ...this.filters, ...{ per: 1 } });
                this.productsCount = response.pagination.total;
            }
            catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async fetchWarehouses() {
            try {
                let response = await this.$repositories.warehouses.get({ per: 10000 });
                this.warehouses = response.data;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchShipments() {
            try {
                let response = await this.$repositories.warehouses.get({ ...this.filters, ...{ per: 1, brand_id: this.filters.brand } });
                this.shipmentsCount = response.pagination.total;
            }
            catch (error) {
                console.log(error);
            }
        },
        debounceTerm: _.debounce(function (event) {
          this.filters.term = event.target.value
        }, 800),
        async fetchSalesData(){
          try{
            console.log(this.filters)
            let response = await this.$repositories.charts.sales({...this.filters, ...{from: this.filters.date[0], to: this.filters.date[1]} })
            this.salesMeta = response.meta
          }catch(_){}
        },
        async fetchInventories() {
            this.loading = true;
            this.inventories = [];
            try {
                let response = await this.$repositories.inventories.get({ ...this.filters, ...{ per: 1, brand_id: this.filters.brand } });
                this.inventoryCount = response.pagination.searchCount[0].total;
            }
            catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
    components: { RevenueWidget, MagnifyingGlassIcon, SalesValueByBrandPieWidget, SalesValueByCategoryPieWidget }
}
</script>
<template>
  <div>
    <div class="w-full flex justify-between pb-2">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div class="flex items-center space-x-2">
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" placeholder="Search...." />
        </div>
        <Datepicker v-model="filters.date" range :enableTimePicker="false"/>
      </div>
    </div>
    <div>
      <RevenueWidget :filters="this.filters" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-4 gap-3">
      <ChartCard>
        <template v-slot:title>
          Sales By Brand
        </template>
        <SalesValueByBrandPieWidget :filters="filters" />
      </ChartCard>
      <ChartCard>
        <template v-slot:title>
          Sales By Category
        </template>
        <SalesValueByCategoryPieWidget :filters="filters" />
      </ChartCard>
    </div>
  </div>
</template>