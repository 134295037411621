<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :class="['inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all md:my-8 md:align-middle '+size]">
            <div class="mx-6 py-4 border-b border-gray-200">
              <slot name="title" />
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="close">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div class="px-6 py-4 space-y-2">
              <slot />
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <slot name="actions"/>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import { ref, watch } from 'vue'
  import { XIcon } from '@heroicons/vue/24/solid'

  export default {
    components: {
      XIcon
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'w-100'
      }
    },
    emits: ['close'],
    data(){
      return {
        opened: false,
      }
    },
    watch: {
      show: function(newValue){
        this.opened = newValue
      }
    },
    methods: {
      close(){
        this.opened = false
        this.$emit('close')
      }
    }
  }
</script>
