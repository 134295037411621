<script>
export default {
  data(){
    return {
      loading: false,
      ordersCount: [],
      pagination: {
        total: 0,
      }
    }
  },
  mounted(){
    this.fetchOrders()
  },
  methods: {
    async fetchOrders(){
      this.loading = true
      try{
        let response = await this.$repositories.orders.get({per: 1, order_status: ['awaiting_fulfillment', 'fulfilled', 'partial_fulfilled', 'partial_shipped', 'ready', 'partial_ready'], status: 'past_due'})
        this.pagination = response.pagination
      }catch(e){
        console.log(e)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <Card class="border border-red-500" v-if="pagination.total > 0">
    <CardHeader>
      <div class="flex items-center space-x-2">
        <h2 class="text-base font-medium text-red-700">
          {{ $t('Past Due Orders') }}
        </h2>
      </div>
    </CardHeader>
    <CardBody class="flex flex-col justify-between">
      <div class="text-center py-20 text-green-800 px-2">
        <h2 class="text-4xl leading-2 font-medium text-red-900">
          {{ pagination.total }}
        </h2>
        <p class="text-base font-medium text-red-600">
          These orders have exceeded the shipping deadline and must be shipped as soon as possible! 
          <span class="font-semibold">
            Unfortunately, the ODR value may have already been affected!
          </span>
        </p>
      </div>
      <div class="flex justify-between p-4 space-x-2">
        <Button color="red" @click="$router.push({name: 'dashboard.orders.index', query: { status: 'past_due' }})" class="w-full">
          View Orders
        </Button>
      </div>

    </CardBody>
  </Card>
</template>