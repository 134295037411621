import BrandsRepository from "@/repositories/BrandsRepository"
import when from 'when'
import storage from '@/utils/storage'

export default {
  setCurrentWarehouse (context, data) {
    context.commit('setCurrentWarehouse', data)
    storage.set('currentWarehouse', data)
  },

  currentWarehouse(context, currentWarehouse) {
    let d    = when.defer();
    context.dispatch('setCurrentWarehouse', currentWarehouse)
    return d.promise;
  },

  setCurrentBox (context, data) {
    context.commit('setCurrentBox', data)
    storage.set('currentBox', data)
  },

  currentBox(context, currentBox) {
    let d    = when.defer();
    context.dispatch('setCurrentBox', currentBox)
    return d.promise;
  },
}