<script>
import { FilterIcon, PlusIcon } from '@heroicons/vue/24/solid'
import { XIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, GripDotsIcon, RotateIcon, HandSparklesIcon } from '@snsicons/icons/solid'
import ProductsAdjustCostModal from '@/components/Product/ProductsAdjustCostModal'
import ProductsChangeBrandModal from '@/components/Product/ProductsChangeBrandModal'
import ProductsModifyDepartmentModal from '@/components/Product/ProductsModifyDepartmentModal'
import ProductFormModal from '@/components/Product/ProductFormModal'
import CopyButton from '@/components/UI/CopyButton.vue'
import _ from 'lodash'

const BASE_FILTERS = {
  term: '',
  brand: [],
  category: [],
  warehouse: [],
  aisle: '',
  shelf: '',
  quantity: 'with',
  product: '',
  sort_by: 'updated_at',
  sort_direction: 'desc',
}

export default {
  components: {
    CopyButton,
    MagnifyingGlassIcon,
    GripDotsIcon,
    HandSparklesIcon
  },
  data() {
    return {
      inventories: [],
      departments: [],
      salesChannels: [],
      warehouses: [],
      brands: [],
      selectFiltered: false,
      loading: false,
      selected: [],
      selectAll: false,
      searchCount: [],
      inventoryHeaders: [
        {
          text: 'Brand',
          value: 'brand',
        },
        {
          text: 'Product',
          value: 'name',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Barcode',
          value: 'barcode',
        },
        {
          text: 'Size',
          value: 'size',
        },
        {
          text: 'Shelf',
          value: 'shelf',
        },
        {
          text: 'Quantity',
          value: 'quantity',
          sortable: 'quantity'
        },
        {
          text: 'Last update',
          value: 'updatedAt',
          sortable: 'updated_at'
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      filters: BASE_FILTERS,
      sortBy: {
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.inventories.forEach(product => {
          this.selected.push(product.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchInventories(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        console.log(params)
        this.fetchInventories(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})

        this.fetchInventories(1)
      },
      deep: true,
    },
    // 'filters.department': function(value){
      
    // },
    // 'filters.brand': function(value){
    //   this.$router.push({path: this.$route.path,query: {...this.$route.query, brand: value.toString()}})
    //   this.fetchInventories()
    // },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.inventories.length
    },
    filterOptions(){
      const options = [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'category',
          display: 'Category',
          options: this.categoryOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'warehouse',
          display: 'Warehouse',
          options: this.warehouseOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Location'
        },
        {
          name: 'quantity',
          display: 'Quantity',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'shelf',
          display: 'Shelf',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'aisle',
          display: 'Aisle',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'sort_by',
          display: 'Sort',
          type: 'alwaysHidden',
          verb: 'by',
          group: 'Details'
        },
        {
          name: 'sort_direction',
          display: 'Sort direction',
          type: 'alwaysHidden',
          verb: '',
          group: 'Details'
        },
        {
          name: 'product',
          display: 'Product',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
      ]
      return options
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With",
        code: 'with',
      })
      options.push({
        label: "Without",
        code: 'without',
      })
      return options
    },
    parentageTypeOptions(){
      let options = []
      options.push({
        label: "Parent",
        code: 'parent',
      })
      options.push({
        label: "Child",
        code: 'child',
      })
      options.push({
        label: "Standard",
        code: 'standard',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    warehouseOptions(){
      let options = []
      this.warehouses.forEach((warehouse) => {
        options.push({
          label: warehouse.name,
          code: warehouse.id,
        })
      })
      return options
    },
  },
  beforeMount(){
    this.setFilters()
  },
  unmounted(){
    this.filters = BASE_FILTERS
  },
  mounted(){
    const vm = this
  
    this.fetchSalesChannels()
    this.fetchWarehouses()
  },
  
  methods: {
    async fetchInventories(page){
      this.loading = true
      this.inventories = []
      try {
        let response = await this.$repositories.inventories.get({...this.filters, ...this.pagination, ...{page: page}})
        this.inventories = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.searchCount = response.pagination.searchCount
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },

    async fetchWarehouses(){
      try {
        let response = await this.$repositories.warehouses.get({per: 10000})
        this.warehouses = response.data
      } catch (error) {
        console.log(error)
      }
    },

    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),

    clearSelection(){
      this.selected = []
      this.selectFiltered = false
      this.selectAll = false
    },
    setFilters() {
      let params = this.$route.query;
      let filters = _.cloneDeep(BASE_FILTERS)
      

      if (params.term) {
        filters.term = params.term
      }

      if (params.quantity) {
        filters.quantity = params.quantity
      }

      if (params.department) {
        filters.department = params.department.split(",")
      }

      if (params.brand) {
        filters.brand = params.brand.split(",")
      }

      if (params.warehouse) {
        filters.warehouse = params.warehouse.split(",")
      }

      if (params.parentage_type) {
        filters.parentage_type = params.parentage_type.split(",")
      }

      if (params.missing_on_channel) {
        filters.missing_on_channel = params.missing_on_channel.split(",")
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        filters.only_with_quantity = params.only_with_quantity
      }

      if (params.without_image) {
        filters.without_image = params.without_image
      }

      if (params.shelf) {
        filters.shelf = params.shelf
      }

      if (params.product) {
        filters.product = params.product
      }

      if (params.aisle) {
        filters.aisle = params.aisle
      }


      if (params.sort_by) {
        filters.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        filters.sort_direction = params.sort_direction
      }
      this.filters = filters
    },
  }
}
</script>

<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Inventory
      </template>
      <template v-slot:actions>
        <ExportButton resource="inventories" :filters="filters" />
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full">
        <TTable :headers="inventoryHeaders" :items="inventories" :loading="loading" :pagination="pagination" :filters="filters" :allowBulk="false" style="height: 70vh;">
          <template #actions>
            <Button color="red" size="xs" v-tooltip="$t('Wipe selected inventory')">
              <HandSparklesIcon class="w-4 h-4" />
            </Button>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="w-72 truncate text-left" v-tooltip="item.product.name">
              {{ item.product.name }}
            </div>
          </template>
          <template v-slot:[`item.sku`]="{ item }">
            <div class="flex space-x-1">
              <span>{{ item.product.sku }}</span>
              <CopyButton class="w-4 h-4" :copy="item.product.sku" />
            </div>
          </template>
          <template v-slot:[`item.barcode`]="{ item }">
            <div class="flex space-x-1">
              <span>{{ item.product.barcode }}</span>
              <CopyButton class="w-4 h-4" :copy="item.product.barcode" />
            </div>
          </template>
          <template v-slot:[`item.size`]="{ item }">
            <div class="w-48 truncate text-left" v-tooltip="item.product.size">
              {{ item.product.size }}
            </div>
          </template>
          <template v-slot:[`item.brand`]="{ item }">
            {{ item.product.brandId ? $store.getters['brands/find'](item.product.brandId).name : $t('Not Set') }}
          </template>
          <template v-slot:[`item.shelf`]="{ item }">
            {{ item.shelf.aisle.warehouse.name }} {{ item.shelf.name }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ $filters.date.dateTimeFormat(item.updatedAt) }}
          </template>
          
          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center space-x-3">
              <router-link target="_blank" :to="{name: 'dashboard.inventory_movements.index', query: {shelf: item.shelf.name, warehouse: item.shelf.aisle.warehouseId, product: item.product.barcode}}" class="text-gray-500 hover:text-gray-700" v-tooltip="'View inventory movements'">
                <GripDotsIcon class="w-5 h-5"/>
              </router-link>
            </div>
          </template>

          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
          </template>
        </TTable>
      </div>
      
    </div>
  </div>
</template>