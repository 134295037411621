<template>
  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        Previous
      </a>
      <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ ' ' }}
          <span class="font-medium">{{ start+1 }}</span>
          {{ ' ' }}
          to
          {{ ' ' }}
          <span class="font-medium">{{ end }}</span>
          {{ ' ' }}
          of
          {{ ' ' }}
          <span class="font-medium">{{ totalItems }}</span>
          {{ ' ' }}
          results
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a @click="goToFirstPage()" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <Page v-for="page in pages" :page="page" :current="modelValue" @update="updatePageHandler" :key="`pagination-page-${page}`" />
          <a @click="goToLastPage()" href="#" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
import Page from './Page.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    Page,
  },
  props: {
    lastPage:{
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
    offset: {
      type: Number,
      default: 4,
    },
    totalItems: {
      type: Number,
    }
  },
  emits: ['update:modelValue'],
  computed: {
    pages() {
      let from = this.modelValue - this.offset;
      if (from < 1) {
          from = 1;
      }
      let to = from + (this.offset * 2);
      if (to >= this.lastPage) {
          to = this.lastPage;
      }
      let pages = [];
      for (let page = from; page <= to; page++) {
          pages.push(page);
      }
      return pages;
    },
    start(){
      return this.modelValue * this.perPage - this.perPage
    },
    end(){
      let end = this.start + this.perPage
      return this.totalItems < end ? this.totalItems : end
    }
  },
  mounted(){

  },
  methods: {
    changePage(page){
      this.updatePageHandler(page)
    },
    goToNextPage(){
      this.updatePageHandler(this.modelValue + 1)
    },
    goToPrevPage(){
      this.updatePageHandler(this.modelValue - 1)
    },
    goToLastPage(){
      this.updatePageHandler(this.lastPage)
    },
    goToFirstPage(){
      this.updatePageHandler(1)
    },
    updatePageHandler(params){
      this.$emit('update:modelValue', params)
    }
  },
  // setup(props, { emit }){
  //   const pagination = computed(() : (number | null)[] => {
  //     const res = []
  //     const minPaginationElems = 5 + props.rangeSize * 2
  //     let rangeStart = props.pages <= minPaginationElems ? 1 : props.modelValue - props.rangeSize

  //     let rangeEnd = props.pages <= minPaginationElems ? props.pages : props.modelValue + props.rangeSize

  //     rangeEnd = rangeEnd > props.pages ? props.pages : rangeEnd
  //     rangeStart = rangeStart < 1 ? 1 : rangeStart

  //     if(props.pages > minPaginationElems){
  //       const isStartBoundaryReached = rangeStart - 1 < 3
  //       const isEndBoundaryReached = props.pages - rangeEnd < 3

  //       if(isStartBoundaryReached){
  //         rangeEnd = minPaginationElems - 2
  //         for(let i = 1; i< rangeStart; i++){
  //           res.push(i)
  //         }
  //       }else{
  //         res.push(1)
  //         res.push(null)
  //       }

  //       if(isEndBoundaryReached){
  //         rangeStart = props.pages - ( minPaginationElems - 3)
  //         for(let i = rangeStart; i<= props.pages; i++){
  //           res.push(i)
  //         }
  //       }else{
  //         for(let i = rangeStart; i <= rangeEnd; i++){
  //           res.push(i)
  //         }
  //         res.push(null)
  //         res.push(props.pages)
  //       }
  //     }else{
  //       for(let i = rangeStart; i <= rangeEnd; i++){
  //         res.push(i)
  //       }
  //     }

  //     return res
  //   })

  //   function updatePageHandler(params: number){
  //     emit('update:modelValue', params)
  //   }

  //   const isPrevControlsActive = computed((): boolean => {
  //     return props.modelValue > 1
  //   })
  //   const isNextControlsActive = computed((): boolean => {
  //     return props.modelValue < props.pages
  //   })

  //   function goToFirst(): void { 
  //     if(isPrevControlsActive.value){
  //       emit('update:modelValue', 1)
  //     }
  //   }

  //   function goToPrev(): void {
  //     if(isPrevControlsActive.value){
  //       emit('update:modelValue', props.modelValue - 1)
  //     }
  //   }

  //   function goToLast(): void {
  //     if(isNextControlsActive.value){
  //       emit('update:modelValue', props.pages)
  //     }
  //   }

  //   function goToNext(): void {
  //     if(isNextControlsActive.value){
  //       emit('update:modelValue', props.modelValue + 1)
  //     }
  //   }


  //   return {
  //     pagination,
  //     updatePageHandler,
  //     isPrevControlsActive,
  //     isNextControlsActive,
  //     goToFirst,
  //     goToPrev,
  //     goToLast,
  //     goToNext,
  //   }
  // }
  
}
</script>