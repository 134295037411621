export default {
  add(state, salesChannel){
    let found = state.salesChannels.find((oldSalesChannel) => oldSalesChannel.id == salesChannel.id)
    if(found){
      found = salesChannel
    }else{
      state.salesChannels.push(salesChannel)
    }
  },
  update(state, salesChannel){
    let found = state.salesChannels.find((oldSalesChannel) => oldSalesChannel.id == salesChannel.id)
    if(found){
      let index = state.salesChannels.indexOf(found)
      state.salesChannels[index] = salesChannel
    }
  },
  reset(state){
    state.salesChannels = []
  }
}