import { eventBus } from '@/composables/eventBus'
import APIService from "@/repositories/APIService"
const resource = 'strategies'
const version = 'v1'
import Strategy from "@/models/Strategy"
import ValidationError from "@/repositories/Response/ValidationError";
import CanceledRequest from "@/repositories/Response/CanceledRequest";

export default {
  async get(payload){
    try{
      return await APIService.fetchEntities(`saleschannel/${version}/${resource}`, Strategy, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }
        eventBus.emit('app:message', {
          type: 'error',
          message: 'An error occurred when fetching the strategies'
        })
      throw error
    }
  },

  async find(id, payload = {}){
    try{
      return await APIService.fetchEntity(`saleschannel/${version}/${resource}/${id}`, Strategy, payload)
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'An error occurred when fetching the strategy!'
      })
      throw error
    }
  },

  async store(payload){
    try{
      let response = await APIService.postEntity(`saleschannel/${version}/${resource}`, payload)
      eventBus.emit('strategy:created')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Strategy was created!'
      })

      return response
    }catch(error){
      
      if(error instanceof CanceledRequest){
        return 
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not create strategy. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async update(id, payload){
    try{
      let response = await APIService.putEntity(`saleschannel/${version}/${resource}/${id}`, payload)
      eventBus.emit('strategy:updated')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Strategy was updated!'
      })

      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return 
      }
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not update strategy. Please verify form!'
      })

      if(error instanceof ValidationError){
        throw error
      }

      throw error
    }
  },

  async destroy(id){
    try{
      let response = await APIService.destroyEntity(`saleschannel/${version}/${resource}/${id}`)
      eventBus.emit('strategy:deleted')
      eventBus.emit('app:message', {
        type: 'success',
        message: 'Strategy was deleted!'
      })
      return response
    }catch(error){
      if(error instanceof CanceledRequest){
        return
      }
      
      eventBus.emit('app:message', {
        type: 'error',
        message: 'Could not delete strategy. Please try again later!'
      })
      throw error
    }
  }

}
