<template>
  <div class="space-x-2">
    <span v-for="filter in filledFilters" :key="filter" :class="['inline-flex rounded-lg items-center py-2 pl-3 pr-1 text-sm font-medium bg-transparent border border-gray-400 text-gray-500', findFilterOption(filter).type == 'alwaysHidden' ? 'hidden' : '']">
      {{ findFilterOption(filter).display }} <span class="font-bold text-cyan-500 px-1">{{ findFilterOption(filter).verb }}</span> {{ selectedFiltersAsString(findFilterOption(filter)) }}
      <button @click="removeFilter(findFilterOption(filter))" type="button" class="flex-shrink-0 ml-0.5 h-5 w-5 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:filters'],
  data(){
    return {
      selected: null,
      localFilters: {},
    }
  },
  beforeMount(){
    this.localFilters = this.filters
  },
  watch:{
    filters: {
      handler(value){
        this.localFilters = this.filters
      },
      deep: true 
    }
  },
  computed:{
    filledFilters(){
      let keys = []
      for(const [key, value] of Object.entries(this.localFilters)){
        if(Array.isArray(value) && value.length > 0){
          keys.push(key)
        }
        if((typeof value === 'string' || value instanceof String) && value){
          keys.push(key)
        }
      }
      return keys
    }
  },

  methods: {
    findFilterOption(filter){
      return _.find(this.filterOptions, f => f.name == filter)
    },
    selectedFiltersAsString(filter){
      if(filter.type == 'multiple'){
        return filter.options.filter(option => this.localFilters[filter.name].includes(option.code)).map(option => option.label).join(' or ')
      }

      if(filter.type == 'number-range'){
        if(Array.isArray(this.localFilters[filter.name])){
          if(this.localFilters[filter.name][0] && this.localFilters[filter.name][1]){
            return 'from '+this.localFilters[filter.name][0] + ' to '+this.localFilters[filter.name][1]
          }

          if(this.localFilters[filter.name][0]){
            return 'from '+this.localFilters[filter.name][0] + " to Any"
          }

          if(this.localFilters[filter.name][1]){
            return 'from Any to '+this.localFilters[filter.name][1]
          }
        }
      }

      if(filter.type == 'single'){
        const filtered = filter.options.filter(option => this.localFilters[filter.name] == option.code)[0]
        if(filtered){
          return filtered.label
        }else{
          return ''
        }
      }

      if(filter.type == 'date-range'){
        if(new Date(this.localFilters[filter.name][0])){
          return new Date(this.localFilters[filter.name][0]).toDateString()+" and "+new Date(this.localFilters[filter.name][1]).toDateString()
        }else{
          return this.localFilters[filter.name][0].toDateString()+" and "+this.localFilters[filter.name][1].toDateString()
        }
        
      }

      if(filter.type == 'hidden'){
        return this.localFilters[filter.name]
      }
    },
    removeFilter(filter){
      if(filter.type == 'multiple'){
        this.localFilters[filter.name] = []
      }else if(filter.type == 'number-range'){
        this.localFilters[filter.name] = []
      }else if(filter.type == 'date-range'){
        this.localFilters[filter.name] = []
      }else if(filter.type == 'integer'){
        this.localFilters[filter.name] = 0
      }else{
        this.localFilters[filter.name] = ''
      }
      this.$emit('update:filters', this.localFilters)
    },
  }

}
</script>