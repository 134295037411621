<template>
  <div class="sm:hidden sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow w-full">
    <div class="flex-1 px-4 flex justify-between">
      <button type="button" @click="mobileMenuOpen = true" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>

      <div class="ml-4 flex items-center md:ml-6">

        <router-link :to="{name: 'dashboard.notifications.index'}" :class="[$route.name == 'dashboard.notifications.index' ? 'text-gray-400' : 'text-gray-400', 'flex items-center p-4 rounded-lg relative']">
          <span class="sr-only">Notifications</span>
          <BellOnIcon class="w-6 h-6 text-orange-600 animate-pulse" v-if="$store.getters['notifications/counter'] > 0"/>
          <BellIcon class="w-6 h-6" v-else/>
          <span class="absolute top-0 right-0 block font-bold text-red-400 animate-pulse" v-if="$store.getters['notifications/counter'] > 0">
            {{ $store.getters['notifications/counter'] }}
          </span>
        </router-link>

        <!-- Profile dropdown -->
        <Menu as="div" class="ml-3 relative">
          <div>
            <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">Open user menu</span>
              <UserAvatar class="block h-8 w-8" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem>
                <a href="#" @click="$store.dispatch('auth/logout') && $router.push({name: 'dashboard.login'})" :class="['hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700']">
                  Log Out
                </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="fixed inset-0 z-40 flex md:hidden" @close="mobileMenuOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative max-w-xs w-full bg-blue-700 pt-5 pb-4 flex-1 flex flex-col">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-1 right-0 -mr-14 p-1">
                <button type="button" class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white" @click="mobileMenuOpen = false">
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  <span class="sr-only">Close sidebar</span>
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 px-4 flex items-center">
              <img class="h-8 w-auto" src="https://cdn.sitnsell.com/platform/logo_small.png" alt="Workflow" />
            </div>
            <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
              <nav class="h-full flex flex-col">
                <div class="space-y-1">
                  <router-link v-for="item in navigation" :key="item.name" :to="{name: item.route}" :class="[item.current ? 'bg-blue-800 text-white' : 'text-blue-100 hover:bg-blue-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                    <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-blue-300 group-hover:text-white', 'mr-3 h-6 w-6']"/>
                    <span>{{ item.name }}</span>
                  </router-link>

                  
                  <router-link v-if="can('view', 'Settings')" :to="{name: 'dashboard.settings.index'}" :class="[$route.name == 'dashboard.settings.index' ? 'bg-blue-800 text-white' : 'text-blue-100 hover:bg-blue-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']" :aria-current="$route.name == 'dashboard.settings.index' ? 'page' : undefined">
                    <GearIcon :class="[$route.name == 'dashboard.settings.index' ? 'text-white' : 'text-blue-300 group-hover:text-white', 'mr-3 h-6 w-6']"/>
                    <span>Settings</span>
                  </router-link>
                </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/24/solid'
import{
  FileExcelIcon, 
  FileImportIcon, 
  BellIcon, 
  BoxesStackedIcon, 
  FileInvoiceDollarIcon,
  GearIcon, 
  HouseIcon, 
  MegaphoneIcon, 
  ShelvesIcon,
  BellOnIcon,
  ChartMixedIcon,
  CircleDollarIcon
} from '@snsicons/icons/solid'
import { ref } from 'vue'
import { useAbility } from '@casl/vue'

export default {
  components: {
    XIcon,
    FileExcelIcon, 
    FileImportIcon, 
    BellIcon, 
    BoxesStackedIcon, 
    FileInvoiceDollarIcon,
    GearIcon, 
    HouseIcon, 
    MegaphoneIcon, 
    ShelvesIcon,
    BellOnIcon,
    ChartMixedIcon,
    CircleDollarIcon
  },
  data(){
    return {
      mobileMenuOpen: false
    }
  },
  setup() {
    const { can } = useAbility();

    return {
      can
    };
  },
  computed:{
    userNavigation(){
      return [
        { 
          name: 'Home', 
          route: 'dashboard.home', 
          icon: HouseIcon, 
          current: this.$route.name == 'dashboard.home' ? true : false 
        },
        { 
          name: 'Products', 
          route: 'dashboard.products.index', 
          icon: BoxesStackedIcon, 
          current: this.$route.name == 'dashboard.products.index' ? true : false
        },
      ]
    },
    navigation(){
      return [
        { 
          name: 'Home', 
          route: 'dashboard.home', 
          icon: HouseIcon, 
          current: this.$route.name == 'dashboard.home' ? true : false 
        },
        { 
          name: 'Products', 
          route: 'dashboard.products.index', 
          icon: BoxesStackedIcon, 
          current: this.$route.name == 'dashboard.products.index' ? true : false
        },
        { 
          name: 'Inventory', 
          route: 'dashboard.inventories.index', 
          icon: ShelvesIcon, 
          current: this.$route.name == 'dashboard.inventories.index' ? true : false  
        },
        { 
          name: 'Orders', 
          route: 'dashboard.orders.index', 
          icon: FileInvoiceDollarIcon, 
          current: this.$route.name == 'dashboard.orders.index' ? true : false
        },
        { 
          name: 'Listings', 
          route: 'dashboard.listings.index', 
          icon: MegaphoneIcon, 
          current: this.$route.name == 'dashboard.listings.index' ? true : false 
        },
        { 
          name: 'Import Data', 
          route: 'dashboard.imports.index', 
          icon: FileImportIcon, 
          current: this.$route.name == 'dashboard.imports.index' ? true : false 
        },
        {
          name: 'Reports',
          route: 'dashboard.reports.index',
          icon: FileExcelIcon, 
          current: this.$route.name == 'dashboard.reports.index' ? true : false
        },
        {
          name: 'Analytics',
          route: 'dashboard.analytics.index',
          icon: ChartMixedIcon, 
          current: this.$route.name == 'dashboard.analytics.index' ? true : false
        },
        {
          name: 'Strategies',
          route: 'dashboard.strategies.index',
          icon: CircleDollarIcon, 
          current: this.$route.name == 'dashboard.strategies.index' ? true : false
        },
      ]
    },
  }
}
</script>