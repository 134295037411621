<template>
  <div class="flex flex-col">
    <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">
            Walmart listings are ineligible for ROI-based and Profit-based min/max prices because Walmart does not expose their fee structure via an API.
          </p>
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="bg-white w-64 shadow rounded hover:shadow-lg overflow-hidden my-5 cursor-pointer mx-2" :class="[minMaxType.code == 'manual' ? 'border-gray-500' : 'border-green-500', minMaxType.code == localValue.type ? 'border-2' : 'border']" @click="selectMinMax(minMaxType)" v-for="minMaxType in minMaxOptions" :key="minMaxType.title">
        <div class="text-white text-center py-2" :class="minMaxType.code == 'manual' ? 'bg-gray-500' : 'bg-green-500'">
          {{ minMaxType.code == 'manual' ? 'Manual' : 'Automatic' }}
        </div>
        <div :class="minMaxType.code == localValue.type ? 'bg-green-50' : ''">
          <div class="text-center py-4">
            <i class="fa-2x" :class="minMaxType.icon"></i>
          </div>
          <div class="w-full flex flex-col">
            <h3 class="font-bold text-gray-700 w-full text-center mt-1 cursor-default text-lg">{{ minMaxType.title }}</h3>
            <p class="text-sm p-3 pt-1 cursor-default text-center" v-html="minMaxType.description"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="localValue.type == 'roi'">
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Minimum ROI (%)</div>
        <input-text v-model="localValue.roi_min" name="roi_min"></input-text>
      </div>
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Maximum ROI (%)</div>
        <input-text v-model="localValue.roi_max" name="roi_max"></input-text>
      </div>
    </div>

    <div v-if="localValue.type == 'profit-margin'">
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Minimum Profit (%)</div>
        <input-text v-model="localValue.profit_margin_min" name="profit_margin_min"></input-text>
      </div>
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Maximum Profit (%)</div>
        <input-text v-model="localValue.profit_margin_max" name="profit_margin_max"></input-text>
      </div>
    </div>

    <div v-if="localValue.type == 'fixed-profit'">
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Minimum Profit</div>
        <input-text v-model="localValue.fixed_profit_min" name="fixed_profit_min"></input-text>
        <div class="text-xs text-gray-400">Enter the dollar amount that will be used to calculate the minimum profit. The correct currency will be used automatically.</div>
      </div>
      <div class="w-2/3 mb-6">
        <div class="text-xs text-gray-600 font-medium">Maximum Profit</div>
        <input-text v-model="localValue.fixed_profit_max" name="fixed_profit_max"></input-text>
        <div class="text-xs text-gray-400">Enter the dollar amount that will be used to calculate the maximum profit. The correct currency will be used automatically.</div>
      </div>
    </div> -->
  </div>
</template>
<script>

import _ from 'lodash'

const BASE_MIN_MAX = {
  type: 'manual',
  roi_min: null,
  roi_max: null,
  profit_margin_min: null,
  profit_margin_max: null,
  fixed_profit_min: null,
  fixed_profit_max: null,
}

export default {
  props: {
    modelValue: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      localValue: JSON.parse(JSON.stringify(BASE_MIN_MAX)),
    }
  },
  watch: {
    localValue: {
      handler(value){
        this.$emit('input', value)
      },
      deep: true
    },
  },
  beforeMount(){
    this.localValue = _.defaultsDeep(JSON.parse(JSON.stringify(this.modelValue)), JSON.parse(JSON.stringify(BASE_MIN_MAX)))
  },
  computed: {
    minMaxOptions(){
      return [
        {
          title: 'Enter Your Own',
          description: 'Manual min and max price for each listing.',
          code: 'manual',
          icon: 'fas fa-keyboard',
        },
        {
          title: 'ROI',
          description: 'Calculated based on return on investment per item. <span class="font-bold text-gray-700">Excludes Walmart</span>',
          code: 'roi',
          icon: 'fas fa-analytics',
        },
        {
          title: 'Profit Margin',
          description: 'Calculated based on profit percentage. <span class="font-bold text-gray-700">Excludes Walmart</span>',
          code: 'profit-margin',
          icon: 'far fa-badge-percent'
        },
        {
          title: 'Fixed Profit',
          description: 'Calculated based on fixed amount of profit. <span class="font-bold text-gray-700">Excludes Walmart</span>',
          code: 'fixed-profit',
          icon: 'far fa-badge-dollar'
        },
      ]
    }
  },
  methods: {
    selectMinMax(minMaxType){
      this.localValue.type = minMaxType.code
    },
  }
}
</script>