<template>
  <button :disabled="loading"  type="button" class="relative" :class="{ 'disabled:opacity-50': loading }">
    <fa :icon="['fad', 'circle-notch']" class="fa-lg fa-spin mr-1" v-if="loading" />
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'ProgressButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      working: false,
    }
  },
  methods: {
    wrap (invoker) {
      return async () => {
        if (this.working) {
          return
        }
        this.working = true
        await new Promise((resolve) => {
          resolve()
          invoker()
        }).then(() => {
          console.log('then')
          this.working = false
        })
        console.log(this.working)
      }
    },
  },
  computed: {
    attrs () {
      // const bindings = {}
      // Object.keys(this.$attrs).forEach((event) => {
      //   console.log(event)
      //   bindings[event] = this.wrap(this.$attrs.list[event])
      // })
      // return bindings
      const onRE = /^on[^a-z]/
      const attributes = {}
      const listeners = { click: this.wrap(this.$attrs.onClick) }
      const { $attrs } = this
      
      for (const property in $attrs) {
        if (!onRE.test(property)) {
          attributes[property] = $attrs[property]
        }
      }

      return { attributes, listeners }
    },
  },
}
</script>