<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                <WarehouseIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('To continue, please select the warehouse from which you will work!') }}
                </DialogTitle>
                <div class="mt-2">
                  <ul class="grid grid-cols-1 gap-6 md:grid-cols-3">
                    <li class="cursor-pointer col-span-1 flex flex-col text-center border border-cool-gray-100 bg-white rounded-lg shadow-md" v-for="warehouse in warehouses" :key="warehouse.id" @click="select(warehouse)">
                      <div class="flex-1 flex flex-col p-8">
                        <h3 class="mt-1 text-gray-900 text-lg leading-5 font-medium">{{ warehouse.name }}</h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { WarehouseIcon } from '@snsicons/icons/solid'
  export default {
    components: {
      WarehouseIcon
    },
    data(){
      return {
        opened: false,
        warehouses: [],
      }
    },
    methods: {
      open(preset = {}) {
        try{
          this.fetchWarehouses()
          this.opened = true
        }catch(e){
          console.log(e)
        }
      },
      close(){
        this.opened = false
      },
      async fetchWarehouses(){
        try{
          let response = await this.$repositories.warehouses.get({per: 10000, user_id: this.$store.getters['auth/user'].id, exclude: ['quantity']})
          this.warehouses = response.data
        }catch(error){
          console.log(error)
        }
      },
      select(warehouse){
        this.$store.dispatch('user_prefs/setCurrentWarehouse', warehouse.id)
        this.$eventBus.emit('warehouse:selected')
        this.$eventBus.emit('app:alert', {
          type: 'success',
          title: 'The warehouse has been changed to '+warehouse.name+'!',
          message: 'From now on, all scanning operations performed will be marked as being made from this warehouse. If you want to change the warehouse, please click on the "Change Warehouse" button on the main scanning page.'
        })
        this.close()
      }
    }
  }
</script>
