import BaseModel from "@/models/BaseModel"
import FileResource from '@/models/FileResource'

export default class Upload extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.author = ''
    this.uploadType = null
    this.fileResourceId = null
    this.fileResource = null
    this.errorFileId = null
    this.errorFile = null
    this.errorCount = 0
    this.successCount = 0
    this.counter = 0
    this.status = null
    this.errors = null
    this.results = null
    this.createdAt = null
    this.updatedAt = null
  }

  static fromJson(json){
    let upload = new this()

    upload.id = json.id
    upload.author = json.by
    upload.counter = json.counter
    upload.uploadType = json.upload_type
    upload.status = json.status
    upload.errors = json.errors
    upload.successCount = json.success_count
    upload.errorCount = json.error_count
    upload.results = json.results
    upload.fileResourceId = json.aisle_id
    upload.fileResource = this.initRelation(json.file_resource, FileResource, null)
    upload.errorFileId = json.aisle_id
    upload.errorFile = this.initRelation(json.error_file, FileResource, null)
    upload.createdAt = json.created_at
    upload.updatedAt = json.updated_at

    return upload
  }

  toJson(){
    return {
      upload: {
        upload_type: this.uploadType,
        file_resource_id: this.fileResourceId,
      }
    }
  }
}