<script>
import { ArrowUpRightFromSquareIcon } from '@snsicons/icons/solid'
import { FilterIcon, PlusIcon, PencilIcon } from '@heroicons/vue/24/solid'
import { XIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, GripDotsIcon, RotateIcon, HandSparklesIcon } from '@snsicons/icons/solid'
import ProductsAdjustCostModal from '@/components/Product/ProductsAdjustCostModal'
import ProductsChangeBrandModal from '@/components/Product/ProductsChangeBrandModal'
import ProductsModifyDepartmentModal from '@/components/Product/ProductsModifyDepartmentModal'
import ProductFormModal from '@/components/Product/ProductFormModal'
import CopyButton from '@/components/UI/CopyButton.vue'
import _ from 'lodash'
import BrandFormModal from '../../components/Brand/BrandFormModal.vue'
import CategoryFormModal from '../../components/Category/CategoryFormModal.vue'

export default {
  components: {
    MagnifyingGlassIcon,
    BrandFormModal,
    CategoryFormModal,
    PencilIcon,
    ArrowUpRightFromSquareIcon,
},
  data() {
    return {
      loading: false,
      brandTerm: '',
      categoryTerm: '',
      brands: [],
      filters: {
        term: '',
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      },
      brandHeaders: [
        {
          text: 'Brand',
          value: 'name',
        },
        {
          text: 'Inventory on hand',
          value: 'inventory',
        },
        {
          text: 'Velocity 30 days',
          value: 'velocity30',
        },
        {
          text: 'Velocity 60 days',
          value: 'velocity60',
        },
        {
          text: 'Velocity 90 days',
          value: 'velocity90',
        },
        {
          text: 'Velocity 12 month',
          value: 'velocity365',
        },
        {
          text: 'Listings',
          value: 'listings',
        },
        {
          text: 'Published listings',
          value: 'publishedListings',
        },
        {
          text: 'Unpublished listings',
          value: 'unpublishedListings',
        },
      ],
    }
  },
  mounted(){
    this.fetchBrands()
  },
  watch: {
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.fetchBrands(1)
      },
      deep: true,
    },
    'pagination.page': function(newValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchBrands(newValue)
    },
  },
  computed: {
    filterOptions(){
      return [
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
      ]
    }
  },
  methods: {
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
    async fetchBrands(page = 1){
      this.loading = true
      try {
        let response = await this.$repositories.brands.get({...this.filters, ...this.pagination, ...{include: ['analytics']}, ...{page: page}})
        this.brands = response.data
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
      } catch (error) {
        console.log(error)
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="sm:mx-2">

      <div class="bg-white shadow rounded">
        <div class="w-full flex justify-between p-4">
          <div class="flex items-center space-x-4">
            <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
          </div>
          <div class="flex items-center space-x-2">
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
              </div>
              <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
            </div>
          </div>
        </div>

        <div class="w-full">
          <TTable :headers="brandHeaders" :items="brands" :loading="loading" :allowBulk="false" style="height: 90vh;">
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.listings`]="{ item }">
              <router-link target="_blank" :to="{name: 'dashboard.products.index', query: { brand: item.name }}" class="flex items-center space-x-1 text-sm font-bold text-blue-600 hover:text-blue-700">
                <span>{{ item.listings }}</span>
                <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
              </router-link>
            </template>

            <template v-slot:[`item.publishedListings`]="{ item }">
              <router-link target="_blank" :to="{name: 'dashboard.listings.index', query: { brand: item.name, status: ['published'] }}" class="flex items-center space-x-1 text-sm font-bold text-green-600 hover:text-green-700">
                <span>{{ item.publishedListings }}</span>
                <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
              </router-link>
            </template>

            <template v-slot:[`item.unpublishedListings`]="{ item }">
              <router-link target="_blank" :to="{name: 'dashboard.listings.index', query: { brand: item.name, status: 'unpublished,draft,failed,retired' }}" class="flex items-center space-x-1 text-sm font-bold text-yellow-600 hover:text-yellow-700">
                <span>{{ item.unpublishedListings }}</span>
                <ArrowUpRightFromSquareIcon class="w-3 h-3 text-gray-400" />
              </router-link>
            </template>
            
            <template v-slot:pagination>
              <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
            </template>
          </TTable>
        </div>
      </div>
    </div>
  </div>
</template>