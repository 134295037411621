<template>
  <FormModal :show="opened" @close="close" size="w-200">
    <template v-slot:title>
      <div class="text-xl text-gray-700 flex-grow">
        Update Billing Informations
      </div>
    </template>
    <template v-slot:actions>
      <div class="flex w-full border-t py-2">
        <div class="flex-shrink space-x-2">
          <button class="text-blue-700 ml-2 p-2 text-sm hover:underline" @click="close">
            Cancel
          </button>
        </div>
        <div class="flex-grow text-right">
          <Button blue @click="updateCompany">
            Update Billing Informations
          </Button>
        </div>
      </div>
    </template> 
    <div>
      
      <div class="grid grid-cols-3 gap-4">
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Company Name<span class="text-red-500">*</span></div>
          <Input v-model="company.name" name="name" class="w-full"></Input>
          <FormValidation :errors="errors" name="name"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Company Email<span class="text-red-500">*</span></div>
          <Input v-model="company.email" name="email" class="w-full"></Input>
          <FormValidation :errors="errors" name="email"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Phone Number<span class="text-red-500">*</span></div>
          <Input v-model="company.phoneNumber" name="phone_number" class="w-full"></Input>
          <FormValidation :errors="errors" name="phone_number"></FormValidation>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4">
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Street<span class="text-red-500">*</span></div>
          <Input v-model="company.street" name="street" class="w-full"></Input>
          <FormValidation :errors="errors" name="street"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Street 2</div>
          <Input v-model="company.street2" name="street2" class="w-full"></Input>
          <FormValidation :errors="errors" name="street2"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">City<span class="text-red-500">*</span></div>
          <Input v-model="company.city" name="street2" class="w-full"></Input>
          <FormValidation :errors="errors" name="city"></FormValidation>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4">
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Country<span class="text-red-500">*</span></div>
          <Select v-model="company.country" :searchable="true" :show-counter="true" label-key="label" value-key="code" :items="countryOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="country"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">State<span class="text-red-500">*</span></div>
          <Select v-model="company.state" :searchable="true" :show-counter="true" label-key="label" value-key="code" :items="stateOptions" placeholder="None" class="w-full"></Select>
          <FormValidation :errors="errors" name="state"></FormValidation>
        </div>
        <div class="w-full mb-6">
          <div class="text-xs text-gray-600 font-medium">Zip Code</div>
          <Input v-model="company.zipCode" name="street2" class="w-full"></Input>
          <FormValidation :errors="errors" name="zip_code"></FormValidation>
        </div>
      </div>
    </div>

  </FormModal>
</template>
<script>
  import Company from '@/models/Company'
  import _ from 'lodash'

  export default {
    data(){
      return {
        opened: false,
        savingForm: false,
        company: new Company(),
        errors: [],
        countries: [],
        states: [],
      }
    },
    watch: {
      'company.country': function(value){
        if(value){
          this.fetchStates()
        }
      }
    },
    computed: {
      isEditing(){
        return this.company.hasOwnProperty('id')
      },
      countryOptions(){
        var countries = []
        this.countries.forEach((country) => {
          countries.push({
            label: country.name,
            code: country.id,
          })
        })
        return countries
      },
      stateOptions(){
        var states = []
        this.states.forEach((state) => {
          states.push({
            label: state.name,
            code: state.code,
          })
        })
        return states
      }
    },
    methods: {
      open(preset = {}) {
        try{
          let company = new Company()
          _.assign(company, preset)
          this.company = company
          this.opened = true
          this.fetchCountries()
        }catch(e){
          console.log(e)
        }
      },
      close() {
        this.reset()
        this.opened = false
      },
      reset() {
        this.company = new Company()
      },
      fetchCountries(){
        this.$repositories.countries.get()
          .then((response) => {
            this.countries = response.data
          })
      },
      fetchStates(){
        this.$repositories.states.get({country_id: this.company.country})
          .then((response) => {
            this.states = response.data
          })
      },
      async updateCompany(){
        try{
          await this.$repositories.billingProfile.update(this.company.id, this.company)
          this.close()
        }catch(error){
          this.errors = error.errors
        }
      }
    }
  }
</script>