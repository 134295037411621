<script>
import { FilterIcon, PlusIcon } from '@heroicons/vue/24/solid'
import { XIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, HandSparklesIcon } from '@snsicons/icons/solid'
import InventoryMovementList from '@/components/InventoryMovement/InventoryMovementList.vue'
import _ from 'lodash'

const BASE_FILTERS = {
  term: '',
  brand: [],
  category: [],
  warehouse: [],
  author: [],
  aisle: '',
  shelf: '',
  quantity: '',
  product: '',
  sort_by: 'created_at',
  sort_direction: 'desc',
}

export default {
  components: {
    MagnifyingGlassIcon,
  },
  data() {
    return {
      inventoryMovements: [],
      authors: [],
      departments: [],
      warehouses: [],
      brands: [],
      loading: false,
      selected: [],
      selectAll: false,
      inventoryMovementHeaders: [
        {
          text: 'Product',
          value: 'name',
        },
        {
          text: 'Brand',
          value: 'brand',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Barcode',
          value: 'barcode',
        },
        {
          text: 'Shelf',
          value: 'shelf',
        },
        {
          text: 'Quantity',
          value: 'quantity',
          sortable: 'quantity'
        },
        {
          text: 'Operation',
          value: 'operation',
        },
        {
          text: 'By',
          value: 'author',
        },
        {
          text: 'Initiated at',
          value: 'createdAt',
          sortable: 'created_at'
        },
      ],
      filters: BASE_FILTERS,
      sortBy: {
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
      pagination: {
        page: 1,
        per: 100,
        last_page: 1,
        total: 0,
      }
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.inventories.forEach(product => {
          this.selected.push(product.id)
        })
      }else{
        this.selected = []
      }
    },
    'pagination.page': function(newValue, oldValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.clearSelection()
      this.fetchInventoryMovements(newValue)
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.clearSelection()
        this.fetchInventoryMovements(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.clearSelection()
        this.fetchInventoryMovements(1)
      },
      deep: true,
    },
    // 'filters.department': function(value){
      
    // },
    // 'filters.brand': function(value){
    //   this.$router.push({path: this.$route.path,query: {...this.$route.query, brand: value.toString()}})
    //   this.fetchInventories()
    // },
  },
  computed: {
    canSelectAllFiltered(){
      return this.pagination.total > this.inventories.length
    },
    filterOptions(){
      const options = [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'category',
          display: 'Category',
          options: this.categoryOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'author',
          display: 'Author',
          options: this.authorOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'warehouse',
          display: 'Warehouse',
          options: this.warehouseOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Location'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'shelf',
          display: 'Shelf',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'aisle',
          display: 'Aisle',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'product',
          display: 'Product',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'sort_by',
          display: 'Sort',
          type: 'alwaysHidden',
          verb: 'by',
          group: 'Details'
        },
        {
          name: 'sort_direction',
          display: 'Sort direction',
          type: 'alwaysHidden',
          verb: '',
          group: 'Details'
        },
      ]
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.id,
        })
      })
      return options
    },
    authorOptions(){
      let options = []
      this.authors.forEach((author) => {
        options.push({
          label: author,
          code: author,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    warehouseOptions(){
      let options = []
      this.warehouses.forEach((warehouse) => {
        options.push({
          label: warehouse.name,
          code: warehouse.id,
        })
      })
      return options
    },
  },
  beforeMount(){
    this.setFilters()
  },
  unmounted(){
    this.filters = BASE_FILTERS
  },
  mounted(){
    this.fetchWarehouses()
    this.$store.dispatch('brands/fetch')
  },
  
  methods: {
    async fetchInventoryMovements(page){
      this.loading = true
      this.inventoryMovements = []
      try {
        let response = await this.$repositories.inventoryMovements.get({...this.filters, ...this.pagination, ...{page: page}})
        this.inventoryMovements = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.authors = response.pagination.authors
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },

    async fetchWarehouses(){
      try {
        let response = await this.$repositories.warehouses.get({per: 10000})
        this.warehouses = response.data
      } catch (error) {
        console.log(error)
      }
    },

    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),
    search(){
      this.setFilters()
      this.fetchInventoryMovements()
    },
    applyFilters(){
      this.setFilters()
      this.fetchInventoryMovements()
    },
    removeFilter(filters){
      this.filters = filters
    },
    clearSelection(){
      this.selected = []
      this.selectFiltered = false
      this.selectAll = false
    },
    setFilters() {
      let params = this.$route.query;
      let filters = _.cloneDeep(BASE_FILTERS)

      if (params.term) {
        filters.term = params.term
      }

      if (params.quantity) {
        filters.quantity = params.quantity
      }

      if (params.department) {
        filters.department = params.department.split(",")
      }

      if (params.brand) {
        filters.brand = params.brand.split(",")
      }

      if (params.warehouse) {
        filters.warehouse = params.warehouse.split(",")
      }

      if (params.parentage_type) {
        filters.parentage_type = params.parentage_type.split(",")
      }

      if (params.missing_on_channel) {
        filters.missing_on_channel = params.missing_on_channel.split(",")
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        filters.only_with_quantity = params.only_with_quantity
      }

      if (params.without_image) {
        filters.without_image = params.without_image
      }

      if (params.shelf) {
        filters.shelf = params.shelf
      }

      if (params.aisle) {
        filters.aisle = params.aisle
      }

      if (params.author) {
        filters.author = params.author
      }

      if (params.product) {
        filters.product = params.product
      }


      if (params.sort_by) {
        filters.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        filters.sort_direction = params.sort_direction
      }

      this.filters = filters
    },
  }
}
</script>

<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Inventory Movements
      </template>
      <template v-slot:actions>
      
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full">
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <TTable :headers="inventoryMovementHeaders" :items="inventoryMovements" :allowBulk="false" :loading="loading" :pagination="pagination" :filters="filters" style="height: 80vh;">
            <template v-slot:[`item.name`]="{ item }">
              <div class="w-72 truncate text-left" v-tooltip="item.inventory.product.name">
                {{ item.inventory.product.name }}
              </div>
            </template>
            <template v-slot:[`item.sku`]="{ item }">
              <div class="flex space-x-1">
                <span>{{ item.inventory.product.sku }}</span>
                <CopyButton class="w-4 h-4" :copy="item.inventory.product.sku" />
              </div>
            </template>
            <template v-slot:[`item.barcode`]="{ item }">
              <div class="flex space-x-1">
                <span>{{ item.inventory.product.barcode }}</span>
                <CopyButton class="w-4 h-4" :copy="item.inventory.product.barcode" />
              </div>
            </template>
            <template v-slot:[`item.size`]="{ item }">
              <div class="w-48 truncate text-left" v-tooltip="item.inventory.product.size">
                {{ item.inventory.product.size }}
              </div>
            </template>
            <template v-slot:[`item.brand`]="{ item }">
              {{ item.inventory.product.brandId ? $store.getters['brands/find'](item.inventory.product.brandId).name : $t('Not Set') }}
            </template>
            <template v-slot:[`item.shelf`]="{ item }">
              {{ item.inventory.shelf.aisle.warehouse.name }} {{ item.inventory.shelf.name }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ $filters.date.dateTimeFormat(item.createdAt) }}
            </template>

            <template v-slot:[`item.operation`]="{ item }">
              <InventoryMovementOperationBadge :operation="item.scanningMethod" />
            </template>

            <template v-slot:pagination>
              <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
            </template>
          </TTable>
        </div>
      </div>
    </div>
  </div>
</template>