<script>
import RevenueWidget from "@/components/Widgets/RevenueWidget.vue"
import { MagnifyingGlassIcon, CircleInfoIcon } from '@snsicons/icons/solid'
import _ from 'lodash'
import moment from 'moment'
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';
import ListingsByStatusDonutWidget from "@/components/Widgets/ListingsByStatusDonutWidget.vue";

export default {
    data() {
      return {
        loading: true,
        warehouses: [],
        tableData: [],
        filters: {
            department_id: [],
            brand: [],
            quantity: 'with',
            date: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
        },
      };
    },
    watch: {
        filters: {
            handler(newValue) {
                this.fetchChartData()
            },
            deep: true,
        },
    },
    computed: {
      datePresets() {
        return [
          { label: 'Today', range: [new Date(), new Date()] },
          { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
          {
            label: 'Last month',
            range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
          },
          {
            label: 'Last 3 months',
            range: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())],
          },
          {
            label: 'Last 6 months',
            range: [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())],
          },
          { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] },
          { label: 'Last year', range: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
        ]
      },
      chartData(){
        return [
          {
            name: _.find(this.chartTypes, {key: 'going_ins'}).title,
            data: _.map(this.tableData, 'going_ins')
          },
          {
            name: _.find(this.chartTypes, {key: 'going_outs'}).title,
            data: _.map(this.tableData, 'going_outs')
          },
        ]
      },
      chartOptions(){
        return {
          chart: {
            type: 'area',
            height: 350,
          },
          colors: ['#00E396', '#E91E63'],
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '55%',
          //     endingShape: 'rounded',
          //   },
          // },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: _.map(this.tableData, 'formatted_datetime'),
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            }
          },
          markers: {
            size: 4,
            colors: ['#00E396', '#E91E63'],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
              size: 7,
            }
          },
          yaxis: {
            show: true
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        }
      },
      filterOptions(){
        const options = [
          {
            name: 'brand',
            display: 'Brand',
            options: this.brandOptions,
            type: 'multiple',
            verb: 'is',
            group: 'Details',
          },
          {
            name: 'department_id',
            display: 'Category',
            options: this.categoryOptions,
            type: 'multiple',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'term',
            display: 'Search Term',
            type: 'hidden',
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'quantity',
            display: 'Quantity',
            type: 'single',
            options: this.quantityOptions,
            verb: 'is',
            group: 'Details'
          },
          {
            name: 'date',
            display: 'Date',
            type: 'alwaysHidden',
            verb: 'is',
            group: 'Details'
          },
        ]
        return options
      },
      quantityOptions(){
        let options = []
        options.push({
          label: "With",
          code: 'with',
        })
        options.push({
          label: "Without",
          code: 'without',
        })
        return options
      },
      brandOptions() {
          let options = [];
          this.$store.getters["brands/all"].forEach((brand) => {
              options.push({
                  label: brand.name,
                  code: brand.name,
              });
          });
          return options;
      },
      categoryOptions() {
          let options = [];
          this.$store.getters["categories/all"].forEach((category) => {
              options.push({
                  label: category.name,
                  code: category.id,
              });
          });
          return options;
      },
      salesChannelOptions(){
        let options = []
        this.$store.getters["sales_channels/all"].forEach((salesChannel) => {
          options.push({
            label: salesChannel.name,
            code: salesChannel.id,
          })
        })
        return options
      },
    },
    mounted() {
      this.fetchChartData()
      this.$store.dispatch("brands/fetch")
      this.$store.dispatch("categories/fetch")
      this.$store.dispatch("sales_channels/fetch")
    },
    methods: {
        debounceTerm: _.debounce(function (event) {
          this.filters.term = event.target.value
        }, 800),
        async fetchChartData(){
          try{
            let response = await this.$repositories.charts.inventoryMovements({...this.filters, ...{from: this.filters.date[0], to: this.filters.date[1]} })
            this.tableData = response.data
          }catch(_){}
        },
    },
    components: { MagnifyingGlassIcon, ListingsByStatusDonutWidget }
}
</script>
<template>
  <div>
    <div class="w-full flex justify-between pb-2">
      <div class="flex items-center space-x-4">
        <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
        <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
      </div>
      <div class="flex items-center space-x-2">
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" placeholder="Search...." />
        </div>
      </div>
    </div>
    <ListingsByStatusDonutWidget :filters="filters" />
  </div>
</template>